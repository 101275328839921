<app-agora-modal classes="become-delegate" id="become-delegate-modal" type="pop-up">
  <div class="become-delegate-modal-container flex column" >
    <div class="flex column center-all center">
      <h1 *ngIf="currentClient && !wishlistMode">{{'BECOMEDELEGATE.TITLE.NO.WISHLIST' | translate}}</h1>
      <h1 *ngIf="currentClient && wishlistMode">{{'BECOMEDELEGATE.TITLE.WISHLIST' | translate}}</h1>
    </div>
    <div class="flex column block-margin-top">
      <p class="center" *ngIf="wishlistMode" [innerHTML]="'BECOMEDELEGATE.CAPTION.HOTESSE' | translate"></p>
      <p class="center" *ngIf="!wishlistMode" [innerHTML]="'BECOMEDELEGATE.CAPTION.HOTESSE.NO.WISHLIST' | translate"></p>
      <div class="cta-container m-fw-btn" *ngIf="wishlistMode">
        <ag-button *ngIf="!currentClient?.isDemoRequest" [label]="'BECOMEDELEGATE.ACTION.HOTESSE' | translate" (triggerFunction)="becomeHostess()" ></ag-button>
        <ag-button *ngIf="currentClient?.isDemoRequest" [leftIcon]="'#icon-checkmark'" iconClasses="leftIcon" classes="btn-valid" [label]="'BECOMEDELEGATE.ACTION.HOTESSE.DONE' | translate" ></ag-button>
      </div>
      <div class="cta-container m-fw-btn" *ngIf="!wishlistMode">
        <ag-button *ngIf="!currentClient?.isDemoRequest" [label]="'BECOMEDELEGATE.ACTION.HOTESSE.NO.WISHLIST' | translate" (triggerFunction)="becomeHostess()" ></ag-button>
        <ag-button *ngIf="currentClient?.isDemoRequest" [leftIcon]="'#icon-checkmark'" iconClasses="leftIcon" classes="btn-valid" [label]="'BECOMEDELEGATE.ACTION.HOTESSE.DONE' | translate" ></ag-button>
      </div>
    </div>
    <div class="v-images-container flex row">
      <div class="image">
        <img src="/assets/images/join_victoria1.jpg" alt="image">
      </div>
      <div class="image">
        <img src="/assets/images/join_victoria2.jpg" alt="image">
      </div>
      <div  class="image">
        <img src="/assets/images/join_victoria3.jpg" alt="image">
      </div>
    </div>
    <div class="flex column">
      <p class="center" [innerHTML]="'BECOMEDELEGATE.CAPTION.DELEGATE' | translate"></p>
      <div class="cta-container m-fw-btn margin-bottom-8">
        <ag-button  *ngIf="!currentClient?.isDelegateRequest" [label]="'BECOMEDELEGATE.ACTION.DELEGATE' | translate" (triggerFunction)="becomeDelegate()" ></ag-button>
        <ag-button  *ngIf="currentClient?.isDelegateRequest" [leftIcon]="'#icon-checkmark'" iconClasses="leftIcon" classes="btn-valid" [label]="'BECOMEDELEGATE.ACTION.DELEGATE.DONE' | translate"></ag-button>
      </div>
    </div>
    <p class="sm-info">{{'BECOMEDELEGATE.SOCKETS.WARNING' | translate}}</p>
  </div>
</app-agora-modal>
