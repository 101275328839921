import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../../../../core/services/loader.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {DatatableService} from '../../../../../core/services/datatable.service';
import {OrderService} from "../../../../../core/services/order.service";
import {CookieService} from "ngx-cookie-service";
import {TranslationService} from "../../../../../core/services/translation.service";
import {ClientOrder} from "../../../../../core/models/clientOrder.model";

@Component({
  selector: 'app-waiting-orders-list-data-wrapper',
  templateUrl: './waiting-orders-list-data-wrapper.component.html',
  styleUrls: ['./waiting-orders-list-data-wrapper.component.scss'],
})
export class WaitingOrdersListDataWrapperComponent implements OnInit {

  @Input() datatableTitle: string;
  loaded = false;
  pageSize = 5;
  pageIndex = 0;
  hasMore: boolean;
  firstCall = false;
  loadedMore = false;
  orders: ClientOrder[];

  constructor(
    public datatableService: DatatableService,
    private authService: OidcSecurityService,
    private loader: LoaderService,
    public orderService: OrderService,
    private translate: TranslationService,
  ) {
    this.loader.loaded$.subscribe(res => {
      if(this.firstCall) {
        this.loadedMore = false;
      }
      if(!this.firstCall) {
        this.firstCall = true;
      }
      this.loaded = res;
    });
  }

  ngOnInit() {
    this.orderService.currentClientOrders$.subscribe(res => {
      if(this.pageIndex == 0)this.orders = res;
      else this.orders.push(...res);
    })
  }

  getMoreDatas() {
    this.pageIndex += 1;
    this.loadedMore = true;
    this._getValues();
  }

  private _getValues() {
    this.orderService.getOrders(this.translate.getContextId(), this.pageIndex, true);
  }

}
