import { Injectable } from '@angular/core';
import { OidcSecurityService, TokenHelperService } from 'angular-auth-oidc-client';
import jwt_decode from "jwt-decode";
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getCookieDomainByContext, getCookieDomainByLang, getCookieDomainByUrl } from '../utils/filter.utils';
import { FidelityService } from './fidelity.service';
import { StorageService } from './storage.service';
import { TranslationService } from './translation.service';

const cookieName: string = "victoria_jwt_token";
// const cookieSSOName: string = "victoria_jwt_token_sso";

@Injectable({
    providedIn: 'root'
})
export class JWTService {

    private _hasDelegateRole$:BehaviorSubject<boolean> = new BehaviorSubject(false);
    public hasDelegateRole$:Observable<boolean> = this._hasDelegateRole$.asObservable();

    public _connectedAsDelegate$:BehaviorSubject<boolean> = new BehaviorSubject(false);
    public connectedAsDelegate$:Observable<boolean> = this._connectedAsDelegate$.asObservable();

    constructor(private cookie: CookieService, private translate: TranslationService, private oidc:OidcSecurityService, public storage:StorageService) {

    }

    //AUTHENTICATION CHECK
    public isAuthenticated(){
        return this.oidc.isAuthenticated("desktop");
    }

    //SWITCH ENVIRONNEMENT Config
    public initConnectedUserRoles(){
        if(this.isAuthenticated()){
            let roles = this.getRolesFromToken();
            if(roles.includes("r_delegate")){
                this._hasDelegateRole$.next(true);

                if(this.storage.currentRole) this._connectedAsDelegate$.next(this.storage.currentRole == "1");
                else this._connectedAsDelegate$.next(true);
            }
        }
    }

    public switchRole(){
        //1. Conseillère - 3. Cliente
        if(this._hasDelegateRole$.value === true) {
            let isDelegate = this._connectedAsDelegate$.value;

            if(isDelegate) this.storage.currentRole = "3"
            else this.storage.currentRole = "1"

            this._connectedAsDelegate$.next(!this._connectedAsDelegate$.value);
        } else this.storage.currentRole = "3"
    }

    public resetRoles(){
        this._hasDelegateRole$.next(false);
        this._connectedAsDelegate$.next(false);
    }

    //TOKEN MANIPULATION
    //GET
    public getToken(){
        return this.oidc.getAccessToken();
    }

    public isDelegate(){
        let token = this.getToken();
        let decoded = this.decodeToken(token);
        if(decoded==null) return undefined;
        else {
            let roles = decoded.roles;
            return roles.includes("r_delegate");
        }
    }

    private getRolesFromToken(){
        let token = this.getToken();
        let decoded = this.decodeToken(token);
        if (decoded != null) return decoded.roles;
        else return [];
    }

    public getSubFromToken(){
        let token = this.getToken();
        let decoded = this.decodeToken(token);
        if (decoded != null) return decoded.sub;
        else return null;
    }

    public getEmailFromToken(){
        let token = this.getToken();
        let decoded = this.decodeToken(token);
        if (decoded != null) return decoded.email;
        else return null;
    }

    public getClientIdFromToken(){
        let token = this.getToken();
        let decoded = this.decodeToken(token);
        if (decoded != null) return decoded.client_id;
        else return null;
    }

    public getTokenCookieName(){
        return cookieName;
    }

    public hasTokenCookie(){
      return this.cookie.get(cookieName)!==""
    }

    //SET
    public setTokenCookie(lang, duration:any = 1/24){
        this.cookie.set(cookieName, this.getToken(), duration, '/', getCookieDomainByUrl(window.origin), true, 'Strict'); //former cookie domain get by lang -  23/5/23
    }

    //DELETE
    public deleteTokenCookie() {
        this.cookie.delete(cookieName, "/", getCookieDomainByUrl(window.origin)); ////former cookie domain get by context - 23/5/23
    }

    public deleteTokenCookieUsingLang(lang) {
        this.cookie.delete(cookieName, "/", getCookieDomainByUrl(window.origin)); //former cookie domain get by lang - 23/5/23
    }

    public cleanState() {
        localStorage.removeItem("desktop");
        localStorage.removeItem("ios");
        localStorage.removeItem("android");
    }

    //UTILS
    private decodeToken(jwt:string){
        let data = null;

        if (jwt && jwt != null) data = jwt_decode(jwt);

        return data;
    }








    // public getJwtName() {
    //     return cookieName;
    // }

    // public getJwtSSOName() {
    //     return cookieSSOName;
    // }


    // public getSubFromJwt() {
    //     let decoded = this.decodeJwt();
    //     if (decoded != null) return decoded.sub;
    //     else return null;
    // }

    // public getClientIdFromJwt() {
    //     let decoded = this.decodeJwt();
    //     if (decoded != null) return decoded.client_id;
    //     else return null;
    // }

    // public getJWT() {
    //     let cookie = this.cookie.get(cookieName);
    //     return cookie;
    // }

    // public getSSOJWT() {
    //     let cookie = this.cookie.get(cookieSSOName);
    //     return cookie;
    // }

    // public deleteJWT() {
    //     this.cookie.delete(cookieName, "/", getCookieDomainByContext(this.translate.getContextId()));
    // }

    // private decodeJwt() {
    //     let jwt = this.getJWT();
    //     let data = null;

    //     if (jwt && jwt != null) data = jwt_decode(jwt);

    //     return data;
    // }

    // public decodeSSOJwt() {
    //     let jwt = this.getSSOJWT();
    //     let data = null;

    //     if (jwt && jwt != null) data = jwt_decode(jwt);

    //     return data;
    // }
}
