import { Component, Input, OnInit } from '@angular/core';
import { GetDatasService } from 'src/app/core/services/get-datas.service';

@Component({
  selector: 'app-csrhighlight',
  templateUrl: './csrhighlight.component.html',
  styleUrls: ['./csrhighlight.component.scss'],
})
export class CsrhighlightComponent implements OnInit {

  @Input() highlight = '';
  @Input() highlightlink;
  @Input() highlighticon;
  @Input() current=false;
  @Input() alone=false;

  constructor(public dataService:GetDatasService) { }

  ngOnInit() {}


}
