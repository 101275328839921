import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ClientOrder } from "../models/clientOrder.model";
import { JWTService } from "./jwt.service";
import { TranslationService } from "./translation.service";
import { getApiUrlFromOrigin } from "../utils/filter.utils";


const BASE_URL = getApiUrlFromOrigin();

@Injectable({
    providedIn: 'root'
})

export class PublicOrderService {

    private _clientOrder$:BehaviorSubject<ClientOrder> = new BehaviorSubject<ClientOrder>(undefined);
    public clientOrder$:Observable<ClientOrder> = this._clientOrder$.asObservable();

    private headers: HttpHeaders;
    
        constructor(private http:HttpClient, private jwt: JWTService, private translate:TranslationService){}

        public updateClientOrderInternaly(order:ClientOrder){
            this._clientOrder$.next(order);
        }

        public getOrder(orderId, clientGuid){
            this.headers = new HttpHeaders();
            this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
            this.headers = this.headers.append("X-ContextId", this.translate.getContextId().toString());

            //ADD Bearer if connected
            if(this.jwt.isAuthenticated()) this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

            this.http.get(`${BASE_URL}/orders/${orderId}/${clientGuid}`, {headers: this.headers}).subscribe(
                (result:any)  => {
                  if(result) {
                    this._clientOrder$.next(result.data);
                  }
                }
              )
        }

        public confirmOrder(orderId, clientGuid, confirm = false){
            this.headers = new HttpHeaders();
            this.headers = this.headers.append("X-ContextId", this.translate.getContextId().toString());
            this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    
             //ADD Bearer if connected
             if(this.jwt.isAuthenticated()) this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    
            return this.http.post(`${BASE_URL}/orders/${orderId}/${clientGuid}?doConfirm=${confirm}`, null, { headers: this.headers })
        }

        public sendClientAction(orderGuid, clientGuid, actionId){
            this.headers = new HttpHeaders();
            this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
            this.headers = this.headers.append("X-ContextId", this.translate.getContextId().toString());

            //ADD Bearer if connected
            if(this.jwt.isAuthenticated()) this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

            return this.http.post<any>(`${BASE_URL}/orders/${orderGuid}/${clientGuid}/actions/${actionId}`, null, {headers: this.headers})
        }
}