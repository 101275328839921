import {Component, Input, OnInit} from '@angular/core';
import {FidelityService} from '../../../../core/services/fidelity.service';
import {OrderService} from '../../../../core/services/order.service';
import {TranslationService} from '../../../../core/services/translation.service';
import {LoaderService} from '../../../../core/services/loader.service';
import {TranslateModuleConfig} from "@ngx-translate/core";
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent implements OnInit {

  public hasWishlist: boolean = undefined;
  public loaded = false;
  public wishlist: any = [];
  public resources: any;

  public locale:string;
  public order:any;

  constructor(
    public fidelity: FidelityService,
    public orderService: OrderService,
    public translate: TranslationService,
    public loader: LoaderService,
    public translateConfig: TranslationService,
    public breadcrumb:BreadcrumbService,
    public demoService: DemoService,
    public jwt:JWTService,
    private cookie:CookieService,
    private router:Router
  ) {
  }

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');

    this.loader.loaded$.subscribe(_ => this.loaded = true);

    this.translate.localesLoaded.subscribe(res => {
      if (res === true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });

    this.orderService.wishlistOrder$.subscribe((res) => {
      if(res) {
        this.wishlist = res.orderItems;
        this.hasWishlist = true;
      }
      this.loader.loaded();
    });

    this.orderService.panierOrder$.subscribe((res) => {
      this.order = res;
    })
  }

  isInternalNavigation(){
    return this.jwt.isAuthenticated() && this.order && this.order.demoStatusId ==2;
  }

  goToInternalCollection(navigate= false){
      const route = `/${this.locale}/myvictoria/demo/${this.order.demoCode}/jewelry`
      return route;
  }

  goToExternalCollection(){
     const route = this.translateConfig.getCollectionMenuLocalizedLink();
     return route;
  }

  goToCollection(){
    let route = "";

    if(this.isInternalNavigation()) {
      route = `/${this.locale}/myvictoria/demo/${this.order.demoCode}/jewelry`
      this.router.navigate([route])
    } else {
      route = this.translateConfig.getCollectionMenuLocalizedLink();
      window.location.href = route
    }
  }
}
