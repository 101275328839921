import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {TriggerService} from './trigger.service';
import {TranslationService} from './translation.service';
import {JWTService} from './jwt.service';
import {ErrorService} from './error.service';
import {LoaderService} from './loader.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {GetDatasService} from "./get-datas.service";
import {Voucher} from "../models/voucher.model";
import { getApiUrlFromOrigin } from '../utils/filter.utils';

const BASE_URL = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root'
})
export class ActionsService {

  private headers: HttpHeaders;

  //Add voucher
  private _createdVoucher$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public createdVoucher$ : Observable<any> = this._createdVoucher$.asObservable();

  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private trigger: TriggerService,
    private translate: TranslationService,
    private jwt: JWTService,
    private error: ErrorService,
    private loader: LoaderService,
    private getDatasService: GetDatasService,
  ) {
  }

  //Add code
  public postAddCode(clientSub: number, pointCode: string): Observable<any> {
    return this.http.post(
      BASE_URL + `/clients/${clientSub}/actions/addpoint`, {pointCode},
      {withCredentials: true, headers: {Authorization: `Bearer ${this.jwt.getToken()}`}}
    );
  }

  //Add voucher
  public postAddVoucher(clientSub: number, voucherValue: number): Observable<any>  {
    return this.http.post(
      BASE_URL + `/clients/${clientSub}/vouchers`, {amount: voucherValue},
      {withCredentials: true, headers: {Authorization: `Bearer ${this.jwt.getToken()}`}}
    );
  }

  public updateVoucher(voucherValue: Voucher) {
    this._createdVoucher$.next(voucherValue);
  }

  public postAddAction(clientSub: number, action: string): Observable<any>  {

    // this.headers = new HttpHeaders();
    // this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getSSOJWT());

    return this.http.post(
      BASE_URL + `/clients/${clientSub}/actions/onetime/${action}`, {},
      {withCredentials: true, headers: {Authorization: `Bearer ${this.jwt.getToken()}`}}
    );
  }

  public postAddBirthDate(clientSub: number, birthDate: Date): Observable<any>  {

    // this.headers = new HttpHeaders();
    // this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getSSOJWT());

    return this.http.post(
      BASE_URL + `/clients/${clientSub}/actions/onetime/add_birthday`, {birthdayDate: birthDate},
      {withCredentials: true, headers: {Authorization: `Bearer ${this.jwt.getToken()}`}}
    );
  }
}
