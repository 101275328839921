import { Injectable, Renderer2 } from "@angular/core";
import { environment } from "src/environments/environment";
import { ClientOrder } from "../models/clientOrder.model";
import { LoggerService } from "./logger.service";

@Injectable({ 
    providedIn: 'root' 
  }) 
  export class GoogleAnalyticService { 

    loaded:boolean = false;
    GA_TRAKINK_ID

    constructor(private logger:LoggerService){}

    //Call Once in AppComponent
    public createGAElements(contextId:number, render:Renderer2, document:Document){
      if(this.loaded || !environment.production) return;

      this.setGATrackingID(contextId)

      let element = render.createElement('script');
      element.src = this.loadGAScripts(contextId);
      element.async = false;
      render.appendChild(document.body, element);

      element = render.createElement('script');
      element.text = this.initPagePreviewHit();
      render.appendChild(document.body, element);
    }

    public GAAddPageView(url:string){
      if (window.dataLayer && environment.production){
        window.dataLayer.push(
          {
            event: "page_view",
            page_location: url,
          }
        );
      }
    }

    public GARemoveFromCart(product:any, sku:string, quantity:number){
      if (window.dataLayer && environment.production) {
        window.dataLayer.push({
          event: "remove_from_cart",
          ecommerce: {
            currency: 'EUR',
            value: product.unitPrice * quantity,
            items: [
              {
                item_name: product.productGroup?.nameOnly, //Petit nom
                item_id: product.productGroup?.code, //groupcode
                price: product.unitPrice,
                item_brand: "Victoria",
                item_category: product.productGroup?.category?.slug, //le nom de la categorie
                item_variant: sku, //sku
                quantity: quantity,
              },
            ],
          },
        });
      }
    }

    public GAAddToCart(product:any, sku:string){

      if (window.dataLayer && environment.production) {
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'EUR',
            value: product.unitPrice * product.quantity,
            items: [
              {
                item_name: product.productGroup?.nameOnly,
                item_id: product.productGroup?.code,
                price: product.unitPrice,
                item_brand: "Victoria",
                item_category: product.productGroup?.category?.slug,
                item_variant: sku,
                quantity: product.quantity
              },
            ],
          },
        });
      }
    }


    public GACheckout(step:number, option:string, order:any){

      if (window.dataLayer && environment.production) {
        let items = [];
        order.orderItems.forEach(product => {
          items.push({
            item_name: product.productGroup?.nameOnly,
              item_id: product.productGroup?.code,
              price: product.unitPrice,
              item_brand: "Victoria",
              item_category: product.productGroup?.category?.slug,
              item_variant: product.sku,
              quantity: product.quantity,
          });
        });

        let checkoutDatas = {
          actionField: { step: step, option: option }, 
          currency: 'EUR',
          value: items.reduce((n, {price, quantity}) => n + price*quantity, 0), 
          items: items  
        }

        window.dataLayer.push({
          event: "begin_checkout",
          // ecommerce: {
          //   begin_checkout: checkoutDatas,
          // },
          ecommerce: checkoutDatas
        });

      }
    }

    public GAOrderConfirmed(order:any){

      if (window.dataLayer && environment.production) {
        let items = [];
        order.orderItems.forEach(product => {
          items.push({
            item_name: product.productGroup?.nameOnly,
              item_id: product.productGroup?.code,
              price: product.unitPrice,
              item_brand: "Victoria",
              item_category: product.productGroup?.category?.slug,
              item_variant: product.sku,
              quantity: product.quantity,
          });
        });

        let purchaseDatas = {
          transaction_id: order.id?.toString(),
          currency: 'EUR',
          value: items.reduce((n, {price, quantity}) => n + price*quantity, 0), 
          shipping:order.deliveryFee,
          items : items
        }

        window.dataLayer.push({
          event: 'purchase',
          ecommerce: purchaseDatas
          // ecommerce: {
          //   purchase: purchaseDatas,
          // },
        });

      }
    }

    private setGATrackingID(contextId){

      switch(contextId){
        case 1: this.GA_TRAKINK_ID = "GTM-NVTMTL4"; break;
        case 2: this.GA_TRAKINK_ID = "GTM-5LMF6SM"; break;
        case 3: this.GA_TRAKINK_ID = "GTM-53J29LR"; break; 
        default: this.GA_TRAKINK_ID = "0"; break; 
      }
    }

    private loadGAScripts(contextId:number){
        return `https://www.googletagmanager.com/gtm.js?id=${this.GA_TRAKINK_ID}`;
        
    }

    private initPagePreviewHit(){
      this.loaded = true;
      return `
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${this.GA_TRAKINK_ID}');
      `;
    }
  }
  
