import {Component, OnInit} from '@angular/core';
import {StatusCard} from '../../../../shared/models/status-card.model';
import {ClientSummary} from '../../../../core/models/client-summary.model';
import {Subject} from 'rxjs';
import {GetDatasService} from '../../../../core/services/get-datas.service';
import { TranslateService } from '@ngx-translate/core';
import {DateHelper} from '../../../../shared/helpers/date.helper';
import {CardType} from '../../../../core/enums/card-type.enum';
import { CookieService } from 'ngx-cookie-service';
import { JWTService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomeComponent implements OnInit {

  currentClientSummary: ClientSummary;
  cardType = CardType;

  clientCode:string;

  locale:string


  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    private getDatasService: GetDatasService,
    private translate:TranslateService,
    private cookies:CookieService,
    public jwtService:JWTService
  ) {}

  ngOnInit() {
    this.locale = this.cookies.get("v_locale")?.replace("_","-");
    this.getDatasService.summary$.subscribe(data => {
      if(data) {
        this.currentClientSummary = data;
        this.eventsSubject.next();
      }
    });

    this.getDatasService.clientData$.subscribe(res => {
      if(res) this.clientCode = res.clientCode;
    })

  //   this.authService.userData$.subscribe( d=> {
  //     let userData = d.allUserData.find(x => x.configId=="desktop")?.userData;
  //     if(userData && !this.getDatasService.summaryLoaded()){
  //       this.getDatasService.getSummary(userData.sub)
  //     }
  // })


  }

}
