import {Component, Input, OnInit} from '@angular/core';
import {OrderService} from 'src/app/core/services/order.service';
import {ActivatedRoute} from "@angular/router";
import { ScreenService } from 'src/app/core/services/screen.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-voucher-encoding',
  templateUrl: './voucher-encoding.component.html',
  styleUrls: ['./voucher-encoding.component.scss'],
})
export class VoucherEncodingComponent implements OnInit {

  //////////////////////////////////////////////////////
  //INPUT VALUES////////////////////////////////////////
  @Input() resources: any;
  @Input() order: any;
  @Input() contextId: number;
  @Input() demoCode: string;
  @Input() isOrderValidation = false;
  @Input() redirect = true;
  //OUTPUT ACTIONS//////////////////////////////////////

  //INTERNAL MEMBERS////////////////////////////////////
  promoCode: string;
  hasError: boolean;
  errorCode: number;
  used = false;
  isBusy = false;

  //////////////////////////////////////////////////////
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private screen: ScreenService,
    private loader: LoaderService
  ) {
  }

  ngOnInit() {
    //console.log("Voucher Encoding Init");
    this.loader.loaded$.subscribe(res => {
      if(res) this.isBusy = false;
    })
  }

  validatePromoCode() {

    this.hasError = false;

    if(this.isBusy) return;
    else if (this.promoCode) {
      this.isBusy = true;
      this.orderService.checkVoucherValidity(this.contextId, this.promoCode).then(res => {
        if (res && res.data) {
          switch (res.data.statusId) {
            case 1:
              if(this.isOrderValidation && this.demoCode) {
                const clientGuid = this.route.snapshot.params?.clientGuid ?? this.order.clientSub;
                this.orderService.postVoucherClientOrder(this.demoCode, clientGuid, this.promoCode, this.contextId, this.order.resolvedId, this.redirect);
                this.notifyAsDone()
              } else {
                this.orderService.postVoucherOrderGuest(this.contextId, this.promoCode);
                this.notifyAsDone()
              }
              this.promoCode = '';
              break;
            case 2: //Handle Error Message
            case 3:
            case 4:
            case 5:
              this.hasError = true;
              this.isBusy = false;
              this.errorCode = res.data.statusId + 10;
              break;
          }
        }
      }, err => {
        this.isBusy = false;
      });
    }
  }

  notifyAsDone(){
    if(this.screen.isMobile){
      this.used = true;
      document.getElementsByClassName("encode-voucher-done")[0]?.classList.toggle('done')
      document.getElementsByClassName('encode-voucher-check')[0]?.classList.toggle('display');
      setTimeout(() => {
        this.used = false;
        document.getElementsByClassName('encode-voucher-check')[0]?.classList.toggle('display');
        document.getElementsByClassName("encode-voucher-done")[0]?.classList.toggle('done')
      }, 3500);
    }
  }

}
