
<div *ngIf="loaded" class="payment-mode-selection-container">
  <h1 class="canela">{{resources.checkoutPayment.secondTitle}}</h1>
  <p *ngIf="paymentMethods && isDemoClient && showPaymentToDelegate">
    {{resources.checkoutPayment.description | removeHtmlTag | translateWihParam: {'count': paymentMethods.count + 1} }}
  </p>
  <p *ngIf="paymentMethods && isDemoClient && !showPaymentToDelegate">
    {{resources.checkoutPayment.description | removeHtmlTag | translateWihParam: {'count': paymentMethods.count} }}
  </p>
  <p *ngIf="paymentMethods && !isDemoClient">
    {{resources.checkoutPayment.description | removeHtmlTag | translateWihParam: {'count': paymentMethods.count} }}
  </p>

  <div *ngIf="paymentMethods" class="method-container flex row wrap">
    <div *ngFor="let item of paymentMethods.items" (click)="selectPaymentMethod(item.id)"
      class="method flex ai-center j-start ac-center gap-8" [class.selected]="item.id==selectedMethod" [class.disabled]="item.id=='3X' && order.totalToPay<90">
      <label class="flex ai-center j-center ac-center gap-8">
        <input type="radio" [id]="item.id" [name]="item.id" [checked]="item.id==selectedMethod" [disabled]="item.id=='3X' && order.totalToPay<90">
        <div class="flex gap-8">
          <div class="picto flex ai-center" >
            <img loading="lazy" [src]="item.image.svg"  *ngIf="item.image.svg"/>
            <img loading="lazy" src="/assets/images/cb.svg"  *ngIf="!item.image.svg"/>
          </div>
          <p class="caption">{{item.description}} <span *ngIf="item.id=='3X' && order.totalToPay<90">: {{item.description2}}</span></p>
        </div>
      </label>
    </div>
    <div *ngIf="isDemoClient && showPaymentToDelegate" class="method flex ai-center j-start ac-center gap-8" (click)="selectPaymentMethod('delivery')">
      <label class="flex ai-center j-center ac-center gap-8" [class.selected]="'delivery'==selectedMethod">
        <input type="radio" id="delivery" name="delivery" [checked]="'delivery'==selectedMethod" >
        <div class="flex gap-8">
          <p class="caption custom"> {{'DEMO.TUNNEL.PAYMENT.MANAGE.BOTH' | translate}} <br> <span>{{'DEMO.TUNNEL.INFO.PAYMENTMETHOD' | translate}}</span></p>
        </div>
      </label>
    </div>
  </div>
</div>
<div class="flex loader-container center-all no-box" *ngIf="!loaded">
  <div class="loader"></div>
</div>
