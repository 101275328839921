import { HostListener, Injectable } from '@angular/core';

const DEFAULT_TIMEOUT = 4000

@Injectable({
    providedIn: 'root'
})


export class ScreenService {
    constructor() {

    }

    public get isMobile() {
        return window.innerWidth <= 1181;
    }

    public get isDesktop() {
        return window.innerWidth > 1181;
    }

    public get isTablet() {
        return window.innerWidth >= 786 && window.innerWidth <= 1181;
    }

    public get isPortrait() {
        try {
            if (screen?.orientation) return screen.orientation.type.startsWith("portrait");
            else {
                return (window.orientation == 0 || window.orientation == 180)
            }
        } catch(ex) {
            return true;
        }
    }

    public get isLandscape() {
        try {
            if (screen?.orientation) return screen.orientation.type.startsWith("landscape");
            else {
                return (Math.abs(window.orientation) == 90)
            }
        }catch(ex){
            return false;
        }
    }
}
