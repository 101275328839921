<div class="discount-box" *ngIf="summary && (summary.wallet.amount || order.walletAmount!=0)">

  <div class="wallet-consumption-container">
    <div class="header">{{'ORDER_TUNNEL.REDUCTIONS.WALLET' | translate}}</div>
    <div class="flex ai-center avantages-box-description">
      <div>
        <div class="wallet-bullet flex ai-center j-center">
          <svg viewBox="0 0 32 32">
            <use xlink:href="#icon-wallet"></use>
          </svg>
        </div>
      </div>
      <div
        [innerHTML]="'ORDER_TUNNEL.REDUCTIONS.XWALLETAVAILABLE' | translate: {'X': summary.wallet.amount | agoraCurrency}">
      </div>
    </div>
    <div class="flex row gap-15 ai-center pink-frame">
      <div class="flex row j-space-between pink-frame-input m-column" *ngIf="order.walletAmount === 0">
        <div class="form-control flex row ai-center wallet-input">
          <span>{{'ORDER_TUNNEL.REDUCTIONS.AMOUNT' | translate }}</span>
          <div class="flex row ai-center">
            <form [formGroup]="walletAmountForm">
              <input data-testId="wallet-encoding" type="number" min="0.00" step="0.01" formControlName="walletAmount" (onchange)="validateInputValue()">
            </form>
            <span class="currency">€</span>
          </div>
        </div>
        <button data-testId="wallet-encoding-action"
          class="btn btn-primary {{order.walletAmount > 0 ? 'disabled' : ''}} {{enableWalletButton ? '' : 'disabled'}}"
          [ngClass]="demoCode ? 'secondary' : ''" (click)="useWalletAmount()" [disabled]="isBusy || !enableWalletButton">
          {{'GLOBAL.ACTION.USE' | translate}}
          <div *ngIf="isBusy" class="loader loader-mini"></div>
        </button>
      </div>
      <div class="flex row j-space-between ai-center width100 m-column" *ngIf="order.walletAmount > 0">
        <p [innerHTML]="'ORDER_TUNNEL.REDUCTIONS.XFROMTOTAL' | translate: {'X': order.walletAmount | agoraCurrency }"></p>
        <div>
          <button class="btn btn-primary" data-testId="wallet-encoding-action-remove" (click)="useWalletAmount(0)" [disabled]="isBusy">
            {{'GLOBAL.ACTION.REMOVE' | translate }}
            <div *ngIf="isBusy" class="loader loader-mini"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
