import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { filter } from 'rxjs/operators';
import { Breadcrumb } from "../models/breadcrumb.model";

@Injectable({
    providedIn: 'root'
  })
  export class BreadcrumbService {



    // Observable exposing the breadcrumb hierarchy
    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
    readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

    //Show/Hide GoToDemo Banner
    private readonly _goToDemoBanner$= new BehaviorSubject<boolean>(false);
    readonly goToDemoBanner$ = this._goToDemoBanner$.asObservable();

    // Show/Hide BreadCrumb
    private readonly _showBreadcrumb$ = new BehaviorSubject<boolean>(true);
    readonly showBreadcrumb$ = this._showBreadcrumb$.asObservable();

    // Show/Hide Jumbotron
    private readonly _showJumbotron$ = new BehaviorSubject<boolean>(true);
    readonly showJumbotron$ = this._showJumbotron$.asObservable();

    // Show/Hide Newsletter Card
    private readonly _showNewsletter$ = new BehaviorSubject<boolean>(true);
    readonly showNewsletter$ = this._showNewsletter$.asObservable();

    // Show/Hide Footer Menu
    private readonly _showFooterMenu$ = new BehaviorSubject<boolean>(true);
    readonly showFooterMenu$ = this._showFooterMenu$.asObservable();

    // Show/Hide Footer Disclaimer
    private readonly _showFooterDisclaimer$ = new BehaviorSubject<boolean>(true);
    readonly showFooterDisclaimer$ = this._showFooterDisclaimer$.asObservable();

    // Show/Hide Header-light
    private readonly _showHeader$ = new BehaviorSubject<boolean>(true);
    readonly showHeader$ = this._showHeader$.asObservable();

    // Show/Hide Header-light unless for mobile
    private readonly _showHeaderMobileOnly$ = new BehaviorSubject<boolean>(false);
    readonly showHeaderMobileOnly$ = this._showHeaderMobileOnly$.asObservable();

    // User Color White/Pink
    private readonly _usePinkColor$ = new BehaviorSubject<boolean>(false);
    readonly usePinkColor$ = this._usePinkColor$.asObservable();

    // User Screen Regular/Large size
    private readonly _enlargeScreen$ = new BehaviorSubject<boolean>(false);
    readonly enlargeScreen$ = this._enlargeScreen$.asObservable();

    // User Screen max width
    private readonly _maxScreen$ = new BehaviorSubject<boolean>(false);
    readonly maxScreen$ = this._maxScreen$.asObservable();

    // User Screen Regular/Large size mobile
    private readonly _enlargeScreenMobile$ = new BehaviorSubject<boolean>(false);
    readonly enlargeScreenMobile$ = this._enlargeScreenMobile$.asObservable();

    // Theme loaded notifier
    private readonly _loaded$ = new Subject<boolean>();
    readonly loaded$ = this._loaded$.asObservable();

  // User Color White/Pink
  private readonly _noContainer$ = new BehaviorSubject<boolean>(false);
  readonly noContainer$ = this._noContainer$.asObservable();

  // margin top for agora
  private readonly _marginTop$ = new BehaviorSubject<boolean>(false);
  readonly marginTop$ = this._marginTop$.asObservable();

  private readonly _showBackToCart$ = new BehaviorSubject<boolean>(false);
  readonly showBackToCart$ = this._showBackToCart$.asObservable();

    constructor(private router: Router, private translate:TranslateService) {
      this.router.events.pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationEnd)
      ).subscribe(event => {
        // Construct the breadcrumb hierarchy
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);

        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
      });
    }

    public showBackToCart(){
      this._showBackToCart$.next(true);
    }

    public hideBackToCart(){
      this._showBackToCart$.next(false)
    }

    public async reloadBreadcrumbs(){
      const root = this.router.routerState.snapshot.root;
      const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);

        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
    }

    private async addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
      if (route) {
        //Notify Info Loading
        this._loaded$.next(false);

        // Construct the route URL
        const routeUrl = parentUrl.concat(route.url.map(url => url.path));

        // Add an element for the current route part
        if (route.data.breadcrumb && !route.params.value) {
          const breadcrumb = {
            label: await this.translate.get(this.getLabel(route.data)).toPromise(),
            url: '/' + routeUrl.join('/')
          };
          breadcrumbs.push(breadcrumb);
        //if route has an id, pop the id to keep the parent route navigable
        } else if (route.data.breadcrumb && route.params.value) {

          const routes = [...routeUrl]
          routes.pop()

          const breadcrumb = {
            label: await this.translate.get(this.getLabel(route.data)).toPromise(),
            url: '/' + routes.join('/')
          };
          breadcrumbs.push(breadcrumb);
        }

        //if route has an id, add it to the breadcrumb and make it navigable
        if (route.params.value) {
          const paramCrumb = {
            label: route.params.value,
            url: undefined
          };
          breadcrumbs.push(paramCrumb);
        }

        //New Theme Management Services
        if(route.data.showDemoBanner!= undefined) this._goToDemoBanner$.next(true)
        else this._goToDemoBanner$.next(false);

        if(route.data.showBreadCrumb!= undefined) this._showBreadcrumb$.next(false)
        else this._showBreadcrumb$.next(true);

        if(route.data.showJumbotron!= undefined) this._showJumbotron$.next(false)
        else this._showJumbotron$.next(true);

        if(route.data.showNewsletter!= undefined) this._showNewsletter$.next(false)
        else this._showNewsletter$.next(true);

        if(route.data.showFooterMenu!= undefined) this._showFooterMenu$.next(false)
        else this._showFooterMenu$.next(true);

        if(route.data.showFooterDisclaimer!= undefined) this._showFooterDisclaimer$.next(false)
        else this._showFooterDisclaimer$.next(true);

        if(route.data.showHeader!= undefined) this._showHeader$.next(false)
        else this._showHeader$.next(true);

        if(route.data.showHeaderMobileOnly!= undefined) this._showHeaderMobileOnly$.next(true)
        else this._showHeaderMobileOnly$.next(false);

        if(route.data.usePinkColor!= undefined) this._usePinkColor$.next(true)
        else this._usePinkColor$.next(false);

        if(route.data.enlargeScreen!= undefined) this._enlargeScreen$.next(true);
        else this._enlargeScreen$.next(false);

        if(route.data.maxScreen!= undefined) this._maxScreen$.next(true);
        else this._maxScreen$.next(false);

        if(route.data.enlargeScreenMobile!= undefined) this._enlargeScreenMobile$.next(true);
        else this._enlargeScreenMobile$.next(false);

        if(route.data.noContainer!= undefined) this._noContainer$.next(true);
        else this._noContainer$.next(false);

        if(route.data.marginTop!= undefined && route.data.marginTop) this._marginTop$.next(true);
        else this._marginTop$.next(false);

        // Add another element for the next route part
        this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);

        this._loaded$.next(true);
      }
    }

    private getLabel(data: Data) {
      // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
      return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
    }


  }
