import {Component, OnInit} from '@angular/core';
import {FidelityService} from '../../../../core/services/fidelity.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {GetDatasService} from '../../../../core/services/get-datas.service';
import { NgNavigatorShareService } from 'ng-navigator-share';

@Component({
  selector: 'app-client-code',
  templateUrl: './client-code.component.html',
  styleUrls: ['./client-code.component.scss'],
})
export class ClientCodeComponent implements OnInit {

  codeClient: string;

  constructor(
    private ngNavigatorShareService: NgNavigatorShareService,
    private getDatasService: GetDatasService
  ) {
    /*this.authService.userData$.subscribe(d => {

      const userData = d.allUserData.find(x => x.configId === 'desktop')?.userData;
      if (userData && !this.getDatasService.summaryHasDatas()) {
        this.getDatasService.getClientDatas(userData.sub, true);
      }
    });*/

    this.getDatasService.clientData$.subscribe(res => {
      if(res) {
        this.codeClient = res.clientCode;
      }
    })
  }

  ngOnInit() {
  }

  share() {
    if (!this.ngNavigatorShareService.canShare()) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.ngNavigatorShareService.share({
      title: 'URL',
      url: this.codeClient
    }).then( (response) => {
    })
      .catch( (error) => {
        console.log(error);
      });
  }

}
