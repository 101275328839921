<div class="order-contact-container" *ngIf="profile && delegate && !(error.lookupError$ | async)">
  <h1 class="canela">{{resources.checkoutConsultant.secondTitle}}</h1>
  <p *ngIf="!order.delegateId && !demoCode">{{resources.checkoutConsultant.proposedConsultantDescription | removeHtmlTag | translateWihParam:{name: profile.firstName, delegate: delegate.displayName | uppercase} }}</p>
  <p *ngIf="order.delegateId && !demoCode">{{resources.checkoutConsultant.description | removeHtmlTag | translateWihParam:{name: profile.firstName | titlecase, delegate: delegate.displayName | uppercase} }}</p>
  <p *ngIf="demoCode">{{resources.checkoutConsultant.homepartyDescription | removeHtmlTag | translateWihParam:{name: profile.firstName | titlecase} }}</p>
  <div class="flex column">
    <p class="subtitle" *ngIf="!demoCode">{{resources.profileConsultant.description | removeHtmlTag}}</p>
    <p class="subtitle" *ngIf="demoCode">{{resources.checkoutConsultant.homepartyLabelSecond | translateWihParam:{code: demoCode} }}</p>
    <div class="order-contact-info flex row ai-center" [ngClass]="(demoCode || demoCode) ? 'hasDemo' : ''">
      <div class="img-container">
        <figure>
          <img loading="lazy" [src]="delegate.profileImagePath" />
        </figure>
      </div>
      <div class="teaser-container flex column flex-3 gap-10">
        <div class="header">{{delegate.displayName | uppercase}}</div>
        <div class="location ai-center">
          <svg viewBox="0 0 32 32" class="icon peach"><use xlink:href="#icon-location"></use></svg>
          {{delegate.city | uppercase}}
        </div>
        <div class="teaser">{{delegate.teaser}}</div>
      </div>
    </div>
  </div>

  <!-- Sans Démo -->
  <div class="order-contact-footer flex j-space-between ai-center" *ngIf="!demoCode">
    <div>
      <button class="btn btn-primary j-center" (click)="confirmDelegate()">{{resources.checkoutConsultant.continueButtonLabel}}</button>
    </div>
    <div>
      <a class="ctaLink" (click)="modal.open('#orderSearchDelegate')" >{{resources.checkoutConsultant.chooseButtonLabel}}</a>
    </div>
  </div>

  <!-- Avec Démo -->
  <!--<div class="order-contact-footer flex j-space-between ai-center" *ngIf="demoCode">
    <div>
      <button class="btn btn-primary j-center" (click)="confirmDemoCode()">{{resources.checkoutConsultant.confirmHomepartyButtonLabel}}</button>
    </div>
    <div>
      <a *ngIf="!hasDemoCookie" class="ctaLink" (click)="modal.open('#orderSelectCode')">{{resources.checkoutConsultant.changeHomepartyButtonLabel}}</a>
    </div>
  </div>
-->
</div>
