import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientCommunications } from 'src/app/core/models/clientCommunications.model';
import { RGPDService } from 'src/app/core/services/rgpd.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss'],
})
export class CommunicationsComponent implements OnInit {

  canSendVictoria: boolean;
  sendFromDelegate: boolean;

  clientEmail = "";
  clientHash = "";
  clientHash2 = "";

  communications:ClientCommunications;
  noCommunications:boolean = false;

  private operation = {
    optIn: 1,
    optOut: 2
  };
  private target = {
    victoria: 1,
    fidelity: 2,
    delegate: 500
  };

  constructor(public error:ErrorService, private activeRoute:ActivatedRoute, private router:Router, private rgpdService : RGPDService, private translateConfig: TranslationService) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
        if(params.e && params.c){
            this.clientEmail = params.e;
            this.clientHash = params.c;
            this.clientHash2 = params.d;

            this.rgpdService.getClientPublicCommunication(2, this.clientEmail, this.clientHash, this.clientHash2);

        } else this.router.navigateByUrl("/");
    })

    this.rgpdService.communications$.subscribe(res => {
      if(res){
        this.communications = res;
      }
    })

    this.rgpdService.noCommunications$.subscribe(res => {
      this.noCommunications = res;
    });
  }

  updateValue(value: boolean, target: string, pref?: any) {
    this.rgpdService.postClientPublicCommunication(this.communications, this.target[target], value ? this.operation.optIn : this.operation.optOut, this.translateConfig.getContextId(), this.clientEmail, pref, this.clientHash, this.clientHash2);
  }

  updateRGPD() {
    this.rgpdService.postClientPublicCommunication(null, this.target.victoria, this.operation.optIn, this.translateConfig.getContextId(), this.clientEmail, null, this.clientHash, this.clientHash2);
  }

}
