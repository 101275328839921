import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClientOrder} from '../../../../core/models/clientOrder.model';
import {TranslationService} from '../../../../core/services/translation.service';
import {ErrorService} from '../../../../core/services/error.service';
import {FidelityService} from '../../../../core/services/fidelity.service';
import {OrderService} from '../../../../core/services/order.service';
import {ModalService} from '../../../../core/services/modal.service';
import {CookieService} from 'ngx-cookie-service';
import {StorageService} from '../../../../core/services/storage.service';
import {JWTService} from '../../../../core/services/jwt.service';
import {LoaderService} from '../../../../core/services/loader.service';
import {Router} from '@angular/router';
import {FidelityDelegate} from '../../../../core/models/fidelityDelegate.model';
import {ModalAction} from '../../../../core/models/modalAction.model';

@Component({
  selector: 'app-order-contact-box',
  templateUrl: './order-contact-box.component.html',
  styleUrls: ['./order-contact-box.component.scss'],
})
export class OrderContactBoxComponent implements OnInit {

  @Input() delegate: FidelityDelegate;
  @Input() order: ClientOrder;
  @Input() demoCode: string;
  @Input() contextId: number;
  @Input() profile: any;
  @Output() updateDemoCode:EventEmitter<string> = new EventEmitter<string>()
  @Output() refreshDelegatesToSelect:EventEmitter<FidelityDelegate[]> = new EventEmitter()
  resources: any;

  delegatesToSelect: FidelityDelegate[] = [];
  hasDemoCookie = false;

  constructor(
    public fidelityService: FidelityService,
    private orderService: OrderService,
    public modal: ModalService,
    private cookie: CookieService,
    private storage: StorageService,
    private jwtService: JWTService,
    private loader: LoaderService,
    public translate: TranslationService,
    public error: ErrorService,
    private route: Router
  ) {
    this.modal.triggerAction$.subscribe((data: ModalAction) => {
      if (data && data.actionName == 'validateDemoCode') {this.validateDemoCode(data.value);}
      if (data && data.actionName == 'searchDelegateBy') {this.searchDelegateBy(data.value);}
      if (data && data.actionName == 'selectDelegate') {this.changeDelegate(data.value);}
    });
  }

  ngOnInit() {
    this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });

    this.hasDemoCookie = (this.storage.demoId != undefined);
  }

  confirmDelegate() {
    this.orderService.putGuestOrder(
      {
        delegateId: this.delegate.delegateId,
        locale: this.translate.getCodeLangueFromUserLocale(this.storage.languageCode),
        sub: this.jwtService.getSubFromToken()
      },
      true, 2);
    // this.changeStep.emit(2);
  }

  confirmDemoCode() {
    this.orderService.putGuestOrder(
      {
        demoCode: this.demoCode,
        delegateId: this.delegate.delegateId,
        locale: this.translate.getCodeLangueFromUserLocale(this.storage.languageCode),
        sub: this.jwtService.getSubFromToken()
      },
      true, 2);
    // this.changeStep.emit(2);
  }

  private validateDemoCode(demoCode) {
    if (!demoCode || demoCode=="undefined") {
      this.demoCode = undefined;
      this.modal.close('#orderSelectCode');
    } else {
      this.orderService.checkDemoCodeValidity(this.contextId, demoCode).subscribe(res => {
        if (res.data && res.data.status == 1 && res.data.delegate != null) {
          this.demoCode = res.data.demoCode ?? res.data.demoId;
          this.fidelityService.updateDelegate(res.data.delegate);
          this.modal.close('#orderSelectCode');
          this.storage.demoCode = res.data.demoCode ?? res.data.demoId;
          this.updateDemoCode.emit(this.demoCode);
        }
      }, err => {
        if (err.error && err.error.data) {
          this.modal.raiseError(err.error.data.status);
        }
      });
    }
  }

  private searchDelegateBy(form) {
    this.fidelityService.lookupForOtherDelegates(this.contextId, this.profile, form).subscribe(res => {
      this.delegatesToSelect = res.data;
      this.refreshDelegatesToSelect.emit(this.delegatesToSelect);
      this.modal.open('#orderSelectDelegate');
    }, err => {
      //In case of an error (mainly bad request, follow with no delegate found)
      this.delegatesToSelect = [];
      this.refreshDelegatesToSelect.emit(this.delegatesToSelect);
      this.modal.open('#orderSelectDelegate');
    });
  }

  private changeDelegate(delegate) {
    this.fidelityService.updateDelegate(delegate);
  }

}
