import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GetDatasService} from '../../../../core/services/get-datas.service';
import {Router} from '@angular/router';
import {ActionsService} from '../../../../core/services/actions.service';
import {TriggerService} from '../../../../core/services/trigger.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import {createMask} from "@ngneat/input-mask";
import {ModalService} from "../../../../core/services/modal.service";

@Component({
  selector: 'app-point-encoding',
  templateUrl: './point-encoding.component.html',
  styleUrls: ['./point-encoding.component.scss'],
})
export class PointEncodingComponent implements OnInit {

  pointEncodingForm: FormGroup;
  hasError = false;
  errorCode: number;
  addCodeLoading = false;
  pointCodeMask: any;

  constructor(
    private getDatasService: GetDatasService,
    private formBuilder: FormBuilder,
    private router: Router,
    private jwtService: JWTService,
    private actionsService: ActionsService,
    private trigger: TriggerService,
    private modal: ModalService,
  ) {
  }

  ngOnInit() {
    // @ts-ignore
    if (this.router.rawUrlTree.fragment === 'action-history') {
      const element = document.querySelector('#action-history');
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }
    this.pointEncodingForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
    this.pointCodeMask = createMask<string>(createMask('(A|9)(A|9)(A|9)(A|9)-(A|9)(A|9)(A|9)(A|9)-(A|9)(A|9)(A|9)(A|9)'));
  }

  saveCode() {
    if (!this.pointEncodingForm.valid) {
      this.hasError = true;
      this.errorCode = -1;
      return;
    }

    if(!this.addCodeLoading) {
      this.addCodeLoading = true;
      this.hasError = false;
      this.actionsService.postAddCode(this.jwtService.getSubFromToken(), this.pointEncodingForm.getRawValue().code).subscribe(res => {
        if (res.data) {
          this.getDatasService.updateSummaryInternaly(res.data, true);
          this.trigger.refreshHistoryActionsTable(true);
          this.pointEncodingForm.controls.code.setValue(null);
        }

        this.addCodeLoading = false
      }, error => {
        this.hasError = true;
        this.errorCode = error.error.errors[0].type;
        this.addCodeLoading = false
      });
    }
  }
}
