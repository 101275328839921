import { Component, Input, OnInit } from '@angular/core';
import { ClientOrderItem } from 'src/app/core/models/clientOrderItem.model';
import {TranslationService} from "../../../../../../../core/services/translation.service";
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-order-cart',
  templateUrl: './order-cart.component.html',
  styleUrls: ['./order-cart.component.scss'],
})
export class OrderCartComponent implements OnInit {

  @Input() orderItems : ClientOrderItem[]
  resources: any;

  constructor(private translate:TranslationService) { }

  ngOnInit() {
    this.translate.localesLoaded.subscribe(res => {
      if(res==true){
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });
  }

}
