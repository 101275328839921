import { DemoOrdersModel } from './../../../features/agora/demo/models/demo-orders.model';
import {Component, Input, OnInit} from '@angular/core';
import {GetDatasService} from "../../../core/services/get-datas.service";
import {TranslationService} from "../../../core/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {ModalService} from "../../../core/services/modal.service";
import {OrderService} from "../../../core/services/order.service";
import { AlertService } from 'src/app/core/services/alert.service';
import { Alert } from 'src/app/core/models/alert.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { getVictoriaUrl, slugify } from 'src/app/core/utils/filter.utils';
import { ClientOrder } from 'src/app/core/models/clientOrder.model';
import { CookieService } from 'ngx-cookie-service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wishlist-item',
  templateUrl: './wishlist-item.component.html',
  styleUrls: ['./wishlist-item.component.scss'],
})
export class WishlistItemComponent implements OnInit {

  @Input() wishlistItem: any;
  @Input() index;
  @Input() classCondition: string;
  @Input() noMaxSize = false;
  @Input() width: string;
  @Input() order: ClientOrder

  resources: any;
  locale:string

  itemImage:string;
  itemHoverImage:string;
  isMouseOver = false;

  constructor(
    public getDataService: GetDatasService,
    private translate: TranslationService,
    private translation: TranslateService,
    private cookie :  CookieService,
    public modal: ModalService,
    private orderService: OrderService,
    private jwt:JWTService,
    private router:Router,
  ) {
  }

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');

    this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });

    this.itemImage = this.getDataService.getProductImagesPathForHigh(this.wishlistItem.productGroup.productImage);
    this.itemHoverImage = this.getDataService.getProductImagesPathForHigh(this.wishlistItem.productGroup.productHoverImage);

    // this.orderService.panierOrder$.subscribe(res => {
    //   if(res){
    //     let alertData = new Alert();
    //     alertData.message = "Article ajouté à ton panier";
    //     alertData.urlLink = `/${this.storage.languageCode.replace("_","-")}/public/cart`;
    //     alertData.duration = 6000;

    //     this.alert.raiseAlert(alertData);
    //   }
    // })
  }

  showElem(e: any) {
    e.target.classList?.add("show");
  }

  hoverImage(e:any, isHover:boolean){
    if(this.itemHoverImage && !this.itemHoverImage.endsWith("empty.jpg")) this.isMouseOver = isHover;
  }

  addToCart(index: number) {
    if(this.wishlistItem.productGroup?.products?.length > 1) {
      this.modal.open('#sizeModal' + index);
    } else {
      this.orderService.postGuestOrderItem(this.wishlistItem.sku, this.wishlistItem, false, true);
    }
  }

  confirmDelete(index: number) {
    this.modal.open('#confirmDeleteWishlistItem' + index);
  }

  goToProduct() {
    let link = ""

    if(this.isInternalNavigation()){
      link = `/${this.locale}/myvictoria/demo/${this.order.demoCode}/jewelry/${this.wishlistItem.sku}`
      this.router.navigate([link])
    }
    else {
      link = getVictoriaUrl(this.translation.currentLang, true) +
      '/' + this.translate.getJsonLocales()?.lang +
      '/' + slugify(this.wishlistItem.productGroup.category.name) +
      '/' + this.wishlistItem.productGroup.nameOnly.toLowerCase() +
      '-' + this.wishlistItem.productGroup.code.toLowerCase();
      window.open(link);
    }
  }

  isInternalNavigation(){
    return this.jwt.isAuthenticated() && this.order && this.order.demoStatusId ==2;
  }
}
