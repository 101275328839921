import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ClientOrder} from 'src/app/core/models/clientOrder.model';
import {GoogleAnalyticService} from 'src/app/core/services/google-analytic.service';
import {JWTService} from 'src/app/core/services/jwt.service';
import {LoaderService} from 'src/app/core/services/loader.service';
import {PaymentService} from 'src/app/core/services/payment.service';
import {OrderService} from 'src/app/core/services/order.service';
import {StorageService} from 'src/app/core/services/storage.service';
import {TranslationService} from 'src/app/core/services/translation.service';
import {Voucher} from "../../../../../../core/models/voucher.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {GetDatasService} from "../../../../../../core/services/get-datas.service";
import {ClientSummary} from "../../../../../../core/models/client-summary.model";

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss'],
})
export class OrderPaymentComponent implements OnInit {

  @Input() order: ClientOrder;
  @Input() contextId: number;
  @Input() isDemoClient: boolean;
  @Input() lang: string;
  @Input() error: number;
  @Output() changeStep: EventEmitter<number> = new EventEmitter();
  @Output() changeSelectedMethod: EventEmitter<string> = new EventEmitter();
  @Output() changeDipSet: EventEmitter<boolean> = new EventEmitter();

  resources: any;
  hasError = false;
  errorCode: number;
  promoCode: string;
  loaded = false;
  demoCode: string;

  vouchers: Voucher[];
  summary: ClientSummary;
  selectedMethod:any;

  constructor(
    public jwtService: JWTService,
    public paymentService: PaymentService,
    public storage: StorageService,
    public orderService: OrderService,
    public cookie: CookieService,
    public translate: TranslationService,
    private loader: LoaderService,
    private cookies: CookieService,
    private getDataService: GetDatasService) {

    this.loader.loaded$.subscribe(_ => this.loaded = true);
  }

  ngOnInit() {
    //console.log("Order Payment Init");
    //Force Summary Call to get Fresh Wallet Info
    this.getDataService.getSummary(this.jwtService.getSubFromToken());

    this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });

    this.orderService.getValidVouchers(this.jwtService.getSubFromToken()).subscribe((res: any) => {
      if(res.data) {
        this.vouchers = res.data;
      }
    })

    this.getDataService.summary$.subscribe(res => {
      if(res) {
        this.summary = res;
      }
    })

    this.demoCode = this.storage.demoId ?? this.order?.demoCode;

    if(!this.isDemoClient || !this.demoCode || this.demoCode=="undefined" || this.demoCode==null) this.setIsDipCheck(true);

  }

  selectPaymentMethod(method) {
    this.selectedMethod = method;
    this.changeSelectedMethod.emit(method);
  }

  setIsDipCheck(value){
    this.changeDipSet.emit(value);
  }
}
