<div class="backToCartBanner">
  <img *ngIf="screen.isDesktop" src="/assets/images/logo.svg">
  <img *ngIf="screen.isMobile" src="/assets/images/m-logo.svg">
  <svg viewbox="0 0 32 32" (click)="goToCart()">
    <use xlink:href="#icon-close"></use>
  </svg>

</div>

<div class="order-container" id="dipBox" *ngIf="resources">
  <app-page-title [mainTitle]="resources.checkout.mainTitle" [subTitle]="resources.checkout.shortDescription">
  </app-page-title>
  <app-form-stepper *ngIf="(orderService.panierOrder$ | async) && (!demoCode || !isDemoCart)" (triggerStepBack)="updateStep($event)" [currentStep]="step" [steps]="[
    {step:0, title: resources.checkout.mainSteps[0].label, canNavigate: false},
    {step:1, title: resources.checkoutConsultant.secondTitle, canNavigate: step<4},
    {step:2, title: resources.checkout.mainSteps[1].label, canNavigate: step<4},
    {step:3, title: resources.checkout.mainSteps[2].label}]
    ">
  </app-form-stepper>
  <app-form-stepper *ngIf="(orderService.panierOrder$ | async) && demoCode && isDemoCart" (triggerStepBack)="updateStep($event)" [currentStep]="step" [isDemo]="true" [steps]="[
    {step:1, icon:'reduction', title: 'DEMO.TUNNEL.REDUCTIONS' | translate, canNavigate: step < 4},
    {step:2, icon: 'truck', title: 'DEMO.TUNNEL.DELIVERY' | translate, canNavigate: step < 4},
    {step:3, icon: 'bank-billet', title: 'DEMO.TUNNEL.PAYMENT' | translate}]
    ">
  </app-form-stepper>

  <div *ngIf="step==0" class="fidelity-frame frame-container flex j-center">
    <app-login [isComponent]="true" [redirectUri]="'/' + locale + '/public/checkout'"></app-login>
  </div>

  <div class="error-box" *ngIf="(orderService.panierOrder$ | async) && (orderService.panierOrder$ | async)?.hasError">
    <div class="flex row ai-center">
      <svg viewBox="0 0 28 28" class="icon-warning">
        <use xlink:href="#icon-warning"></use>
      </svg>
      <div>
        <span>{{(orderService.panierOrder$ | async).errorMessage}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="(orderService.panierOrder$ | async) && (demoCode ?? demoCodeUrl) && isDemoCart">

    <div class="delivery-method-info" *ngIf="demoCode && step === 3 && isPaymentDelegate">
      <div class="flex row header ai-center">
        <svg>
          <use xlink:href="#icon-wishlist"></use>
        </svg>
        <p class="p-l bold">{{'DEMO.TUNNEL.ORDER.DELEGATE.THANKS' | translate}}</p>
      </div>
      <div class="body">
        <p class="bold">{{'DEMO.TUNNEL.ORDER.DELEGATE.CHOOSE' | translate}}</p>
        <p class="p-s">{{'DEMO.TUNNEL.ORDER.DELEGATE.RETURN' | translate}}</p>
      </div>
    </div>

    <div *ngIf="step>0" class="frame-container flex j-center ai-start"
         [ngClass]="step == 1 ? 'flex-step-1' : (step == 2 ? 'flex-step-2' : 'flex-step-3')">

      <div *ngIf="step<3 && (orderService.panierOrder$ | async).orderItems" class="order-content flex-2 fullwidth-only">

        <div *ngIf="step === 1" class="discounts-box">
          <h1 class="canela">{{'ORDER_TUNNEL.REDUCTIONS.TITLE' | translate}}</h1>
          <p class="description">{{'ORDER_TUNNEL.REDUCTIONS.CAPTION' | translate}}</p>
            <app-voucher-encoding
              [order]="orderService.panierOrder$ | async"
              [resources]="resources"
              [contextId]="translate.contextId$ | async"
              [demoCode]="demoCode"
            ></app-voucher-encoding>
            <app-voucher-selection
              [order]="orderService.panierOrder$ | async"
              [resources]="resources"
              [contextId]="translate.contextId$ | async"
              [demoCode]="demoCode"
            ></app-voucher-selection>
            <app-wallet-consumption
              [order]="orderService.panierOrder$ | async"
              [resources]="resources"
              [contextId]="translate.contextId$ | async"
              [demoCode]="demoCode"
            ></app-wallet-consumption>
        </div>
        <app-order-delivery *ngIf="step==2" (changeDelivery)="updateDeliveryId($event)" (changeStep)="updateStep($event)"
                            [contextId]="translate.contextId$ | async" [order]="orderService.panierOrder$ | async"
                            [demoCode]="demoCode"
                            [profile]="dataService.clientData$ | async" [deliveryId]="deliveryId"></app-order-delivery>
      </div>



      <div *ngIf="step === 3" class="order-content flex-2 fullwidth-only">
        <app-optin-dip-box [resources]="resources" [demoCode]="demoCode" [order]="order" (setIsDipCheck)="setIsDipCheck($event)" [selectedMethod]="selectedMethod"></app-optin-dip-box>
        <div class="payment-mode-selection-box" *ngIf="order.totalToPay > 0">
          <div *ngIf="mollieError!=0" class="order-payment-error">
            <div class="flex row ai-center">
              <svg viewBox="0 0 28 28" class="icon-warning">
                <use xlink:href="#icon-warning"></use>
              </svg>
              <div>
                <span *ngIf="mollieError==3">{{resources.checkoutPayment.messageLabelError}}</span>
                <span *ngIf="mollieError==5">{{resources.checkoutPayment.topicLabelError}}</span>
                <span *ngIf="mollieError==99">{{resources.resetpassword.shortDescription}}</span>
                <span *ngIf="mollieError!=3 && mollieError!=99 && mollieError!=5">{{'ALERT.ERROR.GLOBAL' | translate}}</span>
              </div>
            </div>
          </div>
          <app-payment-mode-selection
            [order]="order"
            [resources]="resources" [contextId]="translate.contextId$ | async"
            (changeSelectedMethod)="changeSelectedMethod($event)"
            [isDemoClient]="isDemoCart"></app-payment-mode-selection>
        </div>
        <div class="demo-delegate-container" *ngIf="demoCode && step < 4 && step > 2 && delegate && (screen.isDesktop || (screen.isTablet && screen.isLandscape))">
          <div class="h2-container width100">
            <h2 class="bold">{{'DEMO.TUNNEL.ORDER.DELEGATE' | translate}}</h2>
          </div>
          <div class="flex row gap-20">
            <div class="card-img-container flex">
              <img loading="lazy" class="thumb-image" alt="delegate-img" [src]="delegate.profileImagePath">
            </div>
            <div>
              <p class="bold">{{delegate.displayName}}</p>
              <div class="row flex" *ngIf="delegate.city">
                <svg viewBox="0 0 32 32" class="icon peach height100">
                  <use xlink:href="#icon-location"></use>
                </svg> &nbsp; {{delegate.city}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="step<4 && (orderService.panierOrder$ | async).orderItems" class="order-summary-content flex-1 fullwidth-only">
        <app-order-summary [deliveryId]="deliveryId"
                           [order]="orderService.panierOrder$ | async"

                           [selectedMethod]="selectedMethod"
                           [isDipCheck]="isDipChecked"

                           [demoCode]="demoCode"
                           [disableShopButton]="(!isDipChecked || !selectedMethod)"
                           [isDipCheck]="isDipChecked"
                           (triggerPaymentByDelegate)="paymentByDelegate($event)"
                           [noProduct]="(step === 1 || step === 2)"
                           [hiddeDeliveryButton]="true"
                           [disableDeleteVouchersWallet]="step > 2"
                           [contextId]="translate.contextId$ | async"
                           [step]="step"
                           (triggerError)="updateMollieErrorCode($event)"></app-order-summary>
      </div>

      <app-order-payment-status *ngIf="step==4 || step==5" (changeStep)="updateStep($event)" [isDemo]="true" [demoCode]="demoCode ?? demoCodeUrl"
                                (setErrorCode)="updateMollieErrorCode($event)" [order]="orderService.panierOrder$ | async"
                                [profile]="dataService.clientData$ | async" [resources]="resources.checkoutConfirmation">
      </app-order-payment-status>
    </div>
  </div>

  <div *ngIf="(orderService.panierOrder$ | async) && (!demoCode || !isDemoCart)">
    <div *ngIf="step>0" class="frame-container flex j-center ai-start"
         [ngClass]="step == 1 ? 'flex-step-1' : (step == 2 ? 'flex-step-2' : 'flex-step-3')">
      <div *ngIf="step<4 && (orderService.panierOrder$ | async).orderItems" class="order-content flex-2 fullwidth-only">
        <app-order-contact *ngIf="step==1 && (dataService.clientData$ | async) " (changeStep)="updateStep($event)" [contextId]="translate.contextId$ | async"
                           [profile]="dataService.clientData$ | async" [order]="orderService.panierOrder$ | async"></app-order-contact>
        <app-order-delivery *ngIf="step==2" (changeDelivery)="updateDeliveryId($event)" (changeStep)="updateStep($event)"
                            [contextId]="translate.contextId$ | async" [order]="orderService.panierOrder$ | async"
                            [profile]="dataService.clientData$ | async" [deliveryId]="deliveryId"></app-order-delivery>
        <app-order-payment *ngIf="step==3" (changeDipSet)="setIsDipCheck($event)" (changeStep)="updateStep($event)" [contextId]="translate.contextId$ | async"
                           [lang]="translate.getLanguage()" [order]="orderService.panierOrder$ | async" [error]="mollieError" [isDemoClient]="isDemoCart" (changeSelectedMethod)="changeSelectedMethod($event)">
        </app-order-payment>
      </div>
      <div *ngIf="step<4 && (orderService.panierOrder$ | async).orderItems" class="order-summary-content flex-1 fullwidth-only">
        <app-order-summary [deliveryId]="deliveryId" [order]="orderService.panierOrder$ | async" [selectedMethod]="selectedMethod" [isDipCheck]="isDipChecked"
                           [contextId]="translate.contextId$ | async" [step]="step" (triggerError)="updateMollieErrorCode($event)" [disableShopButton]="(!isDipChecked || !selectedMethod)"></app-order-summary>
      </div>

      <app-order-payment-status *ngIf="step==4 || step === 5" (changeStep)="updateStep($event)"
                                (setErrorCode)="updateMollieErrorCode($event)" [order]="orderService.panierOrder$ | async"
                                [profile]="dataService.clientData$ | async" [resources]="resources.checkoutConfirmation">
      </app-order-payment-status>
    </div>
  </div>

  <div class="demo-delegate-container" *ngIf="(orderService.panierOrder$ | async) && demoCode && isDemoCart && step < 4 && step > 2 && delegate && screen.isMobile && !(screen.isTablet && screen.isLandscape)">
    <div class="h2-container width100">
      <h2 class="bold">{{'DEMO.TUNNEL.ORDER.DELEGATE' | translate}}</h2>
    </div>
    <div>
      <div class="card-img-container flex">
        <img loading="lazy" class="thumb-image" alt="delegate-img" [src]="delegate.profileImagePath">
      </div>
      <p class="bold">{{delegate.displayName}}</p>
      <div class="row flex" *ngIf="delegate.city">
        <svg viewBox="0 0 32 32" class="icon peach height100">
          <use xlink:href="#icon-location"></use>
        </svg> &nbsp; {{delegate.city}}
      </div>
    </div>
  </div>

  <div class="demo-order-actions" *ngIf="(orderService.panierOrder$ | async) && demoCode && isDemoCart && step === 3 && orderSummaryComponent && screen.isMobile && !(screen.isTablet && screen.isLandscape) || ((orderService.panierOrder$ | async) && orderSummaryComponent && step === 3 && !isDemoCart && order.totalToPay<=0 && screen.isMobile && !(screen.isTablet && screen.isLandscape))">
    <div class="demo-order-actions-container">
      <div class="flex row j-space-between">
        <p class="bold">{{'DEMO.TUNNEL.TOTALTOPAY' | translate}}</p>
        <p class="bold">{{order.totalToPay | agoraCurrency}}</p>
      </div>
      <div *ngIf="!isDipChecked || !this.selectedMethod" class="validation-text">
        <p class="p-s" *ngIf="!isDipChecked" (click)="goToDIPBox()">{{'DEMO.TUNNEL.VALIDATION.DIP' | translate}}</p>
        <p class="p-s" *ngIf="isDipChecked && !selectedMethod">{{'DEMO.TUNNEL.VALIDATION.PAYMENT_METHOD' | translate}}</p>
      </div>

      <div class="cta-container">
        <button (click)="order.totalToPay>0 ? orderSummaryComponent.saveTunnelDatas() : orderSummaryComponent.saveTunnelDatasWithoutPayment()" [disabled]="orderSummaryComponent.operationPending || orderSummaryComponent.disableShopButton || !selectedMethod">
          <div *ngIf="orderSummaryComponent.operationPending" class="loader loader-mini"></div>
          <label>{{'DEMO.TUNNEL.ACTION.SHOPNOW' | translate}}</label>
        </button>
      </div>
    </div>
  </div>

   <app-modal-become-hostess-delegate *ngIf="(orderService.panierOrder$ | async)" [currentClient]="currentClient" [wishlistMode]="popupWishlistMode"></app-modal-become-hostess-delegate>
</div>
