import { OrderService } from './../../../../../core/services/order.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertTypes } from 'src/app/core/enums/alert-type.enum';
import { Alert } from 'src/app/core/models/alert.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { DemoClientModel } from 'src/app/features/agora/demo/models/demo-client.model';
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';

@Component({
  selector: 'app-modal-become-hostess-delegate',
  templateUrl: './modal-become-hostess-delegate.component.html',
  styleUrls: ['./modal-become-hostess-delegate.component.scss'],
})
export class ModalBecomeHostessDelegateComponent implements OnInit {

  @Input() currentClient:DemoClientModel
  @Input() wishlistMode = false;

  constructor(private demoService:DemoService, private translation:TranslateService, private alertService:AlertService) { }

  ngOnInit() {


  }

  becomeHostess(){
    this.sendRequest(2);
  }

  becomeDelegate(){
    this.sendRequest(1);
  }

  private sendRequest(requestType:number){
    this.demoService.updateClientDatas(true, requestType, this.currentClient.clientGuid).subscribe(res => {
      if (res) {
        switch(requestType){
         case 1: this.currentClient.isDelegateRequest = true; break;
         case 2: this.currentClient.isDemoRequest = true; break;
        }
      }
    }, error => {
      const alertData = new Alert();
      alertData.message = this.translation.instant('ALERT.DEMO.UPDATE_CLIENT');
      alertData.type = AlertTypes.DANGER;
      alertData.icon = '#icon-warning';

      this.alertService.raiseAlert(alertData);
    });
  }

}
