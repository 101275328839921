import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-wallet-consumption',
  templateUrl: './wallet-consumption.component.html',
  styleUrls: ['./wallet-consumption.component.scss'],
})
export class WalletConsumptionComponent implements OnInit, OnChanges {
  //////////////////////////////////////////////////////
  //INPUT VALUES////////////////////////////////////////
  @Input() resources: any;
  @Input() order: any;
  @Input() contextId: number;
  @Input() demoCode: string;
  @Input() isOrderValidation = false;
  @Input() wallet: any;
  @Input() redirect = true;
  //OUTPUT ACTIONS//////////////////////////////////////

  //INTERNAL MEMBERS////////////////////////////////////
  summary: any;
  walletAmountForm: FormGroup;
  enableWalletButton = false;
  disableInputWallet = false;
  isBusy = false;
  //////////////////////////////////////////////////////
  constructor(
    private orderService: OrderService,
    private formBuilder: FormBuilder,
    private getDataService: GetDatasService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    //console.log('Wallet Consumption Init');
    this.loader.loaded$.subscribe(res => {
      if(res) this.isBusy = false;
    })

    this.initWalletForm();

    if (!this.isOrderValidation)
      this.getDataService.summary$.subscribe((res) => {
        if (res) {
          this.summary = res;
        }
      });
    else if (this.wallet != undefined && this.wallet != 0)
      this.summary = { wallet: { amount: this.wallet } };
    else if (this.wallet != undefined && this.wallet == 0)
      this.summary = { wallet: { amount: 0 } };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.order?.firstChange) {
      if (changes.order?.currentValue?.walletAmount) {
        this.walletAmountForm.get('walletAmount').disable();
      } else if (changes.order?.currentValue?.walletAmount === 0) {
        this.walletAmountForm.get('walletAmount').enable();
      }
    }

    if (changes.wallet && changes.wallet?.currentValue) {
      this.summary = { wallet: { amount: changes.wallet.currentValue } };
    } else if (changes.wallet && changes.wallet?.currentValue == 0) {
      this.summary = undefined;
    }
  }

  validateInputValue(){
    const valueUsed =  this.walletAmountForm.get('walletAmount').value
    this.enableWalletButton = valueUsed>0
  }

  useWalletAmount(amount: number = undefined) {
    if (this.isBusy) return;
    else {
      this.isBusy = true;

      let amountUsed =
        amount != undefined
          ? amount
          : this.walletAmountForm.get('walletAmount').value;

      if (amountUsed > this.order?.maxWalletAllowed) {
        amountUsed = this.order.maxWalletAllowed;
      }

      if (this.walletAmountForm.get('walletAmount').value > 0) {
        if (this.isOrderValidation)
          this.orderService.postWalletClientOrder(
            amountUsed,
            this.demoCode,
            this.order.clientSub,
            this.order.resolvedId,
            this.redirect
          );
        else this.orderService.postWalletOrderGuest(amountUsed);

        this.walletAmountForm.get('walletAmount').setValue(null);
        this.walletAmountForm.get('walletAmount').setValue(null);
        this.enableWalletButton = false;
      }

      if (amountUsed === 0 && this.order?.walletAmount > 0) {
        if (this.isOrderValidation)
          this.orderService.postWalletClientOrder(
            0,
            this.demoCode,
            this.order.clientSub,
            this.order.resolvedId,
            this.redirect
          );
        else this.orderService.postWalletOrderGuest(0);

        this.walletAmountForm.get('walletAmount').setValue(null);
        this.enableWalletButton = false;
      }
    }
  }

  private initWalletForm() {
    this.walletAmountForm = this.formBuilder.group({
      walletAmount: { value: null, disabled: this.order.walletAmount > 0 },
    });

    this.walletAmountForm.get('walletAmount').valueChanges.subscribe((v) => {
      this.enableWalletButton = !!v;
    });
  }
}
