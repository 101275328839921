<div data-testId="no-cart-block" class="no-cart-frame flex column ai-center" *ngIf="resources">


  <svg viewBox="0 0 32 32" class="icon">
    <use xlink:href="#icon-bag"></use>
  </svg>
  <p>{{resources.shoppingBag.ctaMessage | removeHtmlTag}}</p>

  <div class="link-container">
    <app-wavedlink [externalUrl]="goToCollection()" [linkText]="resources.shoppingBag.discoverButtonLabel"></app-wavedlink>
  </div>

  <div class="link-container" *ngIf="!isAuthenticated">
    <a (click)="goToAuthentication()">{{resources.shoppingBag.buttonLabel}}</a>
  </div>

</div>
