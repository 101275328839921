import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { ClientOrder } from 'src/app/core/models/clientOrder.model';
import { FidelityDelegate } from 'src/app/core/models/fidelityDelegate.model';
import { ModalAction } from 'src/app/core/models/modalAction.model';
import { ErrorService } from 'src/app/core/services/error.service';
import { FidelityService } from 'src/app/core/services/fidelity.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { OrderService } from 'src/app/core/services/order.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from "../../../../../../core/services/translation.service";


@Component({
  selector: 'app-order-contact',
  templateUrl: './order-contact.component.html',
  styleUrls: ['./order-contact.component.scss'],
})
export class OrderContactComponent implements OnInit {

  @Input() profile: any
  @Input() order: ClientOrder
  @Input() contextId: number
  @Input() demoCode: string;
  resources: any;

  @Output() changeStep: EventEmitter<number> = new EventEmitter()

  delegate: FidelityDelegate
  delegatesToSelect: FidelityDelegate[] = [];

  demoId: string
  loaded = false;
  hasDemoCookie = false;

  locale: string;

  constructor(
    public fidelityService: FidelityService,
    private orderService: OrderService,
    public modal: ModalService,
    private cookie: CookieService,
    private storage: StorageService,
    private jwtService: JWTService,
    private loader: LoaderService,
    public translate: TranslationService,
    public error: ErrorService,
    private route: Router
  ) {

    this.loader.loaded$.subscribe(_ => this.loaded = true);
    this.fidelityService.delegate$.subscribe(res => this.delegate = res)

  }

  ngOnInit() {

    console.log("Profile => ", this.profile);

    this.locale = this.cookie.get("v_locale")?.replace("_", "-");

    const demoId = this.storage.demoId ?? this.order.demoCode;
    const delegateId = this.storage.delegateId

    this.hasDemoCookie = (this.storage.demoId != undefined)

    if (demoId && demoId!="undefined" && demoId!=null && demoId!="null") {
      this.orderService.checkDemoCodeValidity(this.contextId, demoId).subscribe(res => {
        if (res.data && res.data.delegateId) {
          this.demoId = res.data.demoId;
          this.fidelityService.getDelegateSiteProfile(this.contextId, res.data.delegateId, this.profile, true);
        }
      }, error=> {
        this.demoId = undefined;
        if (error.error.data.delegateId)
          this.fidelityService.getDelegateSiteProfile(this.contextId, error.error.data.delegateId, this.profile, true);
        else if (delegateId && delegateId != null && delegateId != '') this.fidelityService.getDelegateSiteProfile(this.contextId, delegateId,  this.profile, true);
        else this.fidelityService.lookupForDelegates(this.contextId, this.profile, 1, true);
      }
      );
    }
    else if (delegateId && delegateId != null && delegateId != '') this.fidelityService.getDelegateSiteProfile(this.contextId, delegateId,  this.profile, true);
    else if (this.profile?.delegateId && this.profile?.delegateId != null && this.profile?.delegateId != '') this.fidelityService.getDelegateSiteProfile(this.contextId, this.profile?.delegateId, this.profile, true);
    else if (this.order.delegateId && this.order.delegateId != null && this.order.delegateId != "" && this.order.delegateId != "undefined") this.fidelityService.getDelegateSiteProfile(this.contextId, this.order.delegateId,   this.profile, true);
    else if(this.profile) this.fidelityService.lookupForDelegates(this.contextId, this.profile, 1, true);

    this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    })
  }

  updateDemoCode(value){
    this.demoCode = value;

    if(this.demoCode) this.orderService.putGuestOrder({demoCode: this.demoCode, delegateId: this.delegate?.delegateId, sub: this.jwtService.getSubFromToken()})
  }

  updateDelegates(delegatesToSelect){
    this.delegatesToSelect = delegatesToSelect ?? [];
  }
}
