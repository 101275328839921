import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CookieModule } from 'ngx-cookie';
import { ThemeModule } from './shared/theme/theme.module';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslationService } from './core/services/translation.service';
import { AuthConfigModule } from './config/auth-config.module';
import { CookieService } from 'ngx-cookie-service';
import { ComponentsModule } from './shared/components/components.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LoginComponent } from './sso/login/login.component';
import { PagesRoutingModule } from './features/pages/page-routing.module';
import { PublicPagesRoutingModule } from './features/publicPages/publicpage-routing.module';
import { StorageService } from './core/services/storage.service';
import { PipeModule } from './core/pipes/pipe.module';
import { ErrorComponent } from './features/pages/components/error/error.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { QRCodeModule } from 'angularx-qrcode';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { AuthStorageService } from './config/AuthStorageService.service';
import { MissingTranslationFromJSONHandler } from './core/utils/missing-translation-handlet';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ErrorHandlerService } from './core/services/errorHandling.service';
import { SupportComponent } from './support/support.component';
import { AgoraCurrency } from './core/pipes/agoraCurrency.pipe';
import { SecurityCheck } from './core/interceptor/securityCheck.interceptor';
import { AgoraDateTime } from './core/pipes/agoraDate.pipe';
import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule,
} from 'ngx-toastr';
import { NoCacheInterceptor } from './core/interceptor/nocache.interceptor';
registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent, ErrorComponent, SupportComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    CookieModule.withOptions(),
    BrowserModule,
    IonicModule.forRoot(),
    ComponentsModule,
    AppRoutingModule,
    PagesRoutingModule,
    PublicPagesRoutingModule,
    ThemeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient, StorageService],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationFromJSONHandler,
      },
      useDefaultLang: false,
    }),
    AuthConfigModule,
    PipeModule,
    InputMaskModule,
    QRCodeModule,
    NgxSliderModule,
    ToastNoAnimationModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    CookieService,
    { provide: AbstractSecurityStorage, useClass: AuthStorageService },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
    MissingTranslationFromJSONHandler,
    AgoraCurrency,
    AgoraDateTime,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
