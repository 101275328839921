import {Component, Input, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Status} from '../../../../core/models/status.model';
import {Forecast} from "../../../../core/models/forecast.model";
import {ClientSummary} from "../../../../core/models/client-summary.model";
import {GetDatasService} from "../../../../core/services/get-datas.service";

@Component({
  selector: 'app-new-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss'],
})
export class NewStatusCardComponent implements OnInit {

  @Input() statusData: Status;
  @Input() forecasts: Forecast[];
  @Input() showTooltip = false;
  @Input() footerInformationTooltipText: string;
  @Input() footerInformationTooltipPosition: string;
  @Input() footerInformationTooltipWidth: string;
  @Input() footerInformationTooltipHeight: string;
  @Input() showStatusLink: boolean;
  @Input() paddingBottom = true;
  @Input() hiddeFooterAction = false;
  @Input() dropPadding = false;

  lastForecast: Forecast;
  actionDate: Date;
  locale: string;
  summary: ClientSummary;

  constructor(
    private cookie: CookieService,
    private getDatasService: GetDatasService,
  ) {

  }

  ngOnInit() {
    this.locale = this.cookie.get("v_locale")?.replace("_", "-");
    if (this.forecasts?.length) {
      const filteredForecast = this.forecasts.filter(x => x.typeId === 1);

      if(filteredForecast?.length) this.lastForecast = filteredForecast.reduce((a, b) => new Date(a.date) < new Date(b.date) ? a : b);

      if (this.lastForecast) {
        this.actionDate = this.lastForecast.date;
      }
    }
    this.getDatasService.summary$.subscribe(res => {
      if (res) {
        this.summary = res;
      }
    });
  }
}
