<div class="order-paid flex column j-start ai-center ac-center" *ngIf="status==2" >
  <h1 class="canela center">{{resources.secondTitle}}</h1>
  <p class='m-center' *ngIf="!toDelegate && profile?.email">{{resources.introText | translateWihParam: {'email': profile.email} }}</p>
  <p class='m-center' *ngIf="!toDelegate && !profile?.email">{{'ORDER.CONFIRMATION.EMAILINFO' | translate }}</p>
  <p class='center'>
    {{ translate.getTranslationMultiple(resources.description | removeHtmlTag,'ORDER.VALIDATION.CLIENT.TODELEGATE', toDelegate, undefined, undefined, true) }}
  </p>
  <div class="flex j-center rowCTA">
    <app-wavedlink *ngIf="!toDelegate" [linkText]="resources.buttonLabel" [externalUrl]="getLogoUrl()"></app-wavedlink>
    <app-wavedlink *ngIf="!toDelegate" [linkText]="'MENU.ORDERS.ORDERS'| translate" [linkUrl]="'/' + locale + '/club/orders'" ></app-wavedlink>
    <app-wavedlink *ngIf="toDelegate" [linkText]="'GLOBAL.ACTION.BACKTODEMO' | translate" [linkUrl]="'/' + locale + '/myvictoria/demo/' + this.demoCode"></app-wavedlink>
  </div>
</div>

<div class="order-paid flex column j-start ai-center ac-center" *ngIf="expired" >
  <h1 class="canela center">{{'DEMO.PAYMENT.LINK.EXPIRED.TITLE' | translate}}</h1>
  <!-- <p class='m-center' *ngIf="!toDelegate">{{resources.introText | translateWihParam: {'email': profile.email} }}</p> -->
  <p class='center'>
    {{'DEMO.PAYMENT.LINK.EXPIRED.DESCRIPTION' | translate}}
  </p>
  <div class="flex j-center rowCTA">
    <app-wavedlink *ngIf="!toDelegate" [linkText]="resources.buttonLabel" [externalUrl]="getLogoUrl()"></app-wavedlink>
    <app-wavedlink *ngIf="!toDelegate" [linkText]="'MENU.ORDERS.ORDERS'| translate" [linkUrl]="'/' + locale + '/club/orders'" ></app-wavedlink>
    <app-wavedlink *ngIf="toDelegate" [linkText]="'GLOBAL.ACTION.BACKTODEMO' | translate" [linkUrl]="'/' + locale + '/myvictoria/demo/' + this.demoCode"></app-wavedlink>
  </div>
</div>



<app-error-box *ngIf="error.paymentInfoError$ | async"></app-error-box>
