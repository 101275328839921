<div class="stepper-container flex j-center" [class.background]="withBackground">
  <div class="flex">
    <ng-template ngFor let-item [ngForOf]="steps">
      <div class="step-main">
        <div class="step-content flex column ai-center">
          <div *ngIf="item.step >= currentStep && !item.icon" class="flex j-center ai-center step" [class.current]="currentStep==item.step">
            {{item.step | number:'02.'}}
          </div>
          <div class="flex center-all step step-icon" *ngIf="item.icon && item.step >= currentStep" [class.current]="currentStep === item.step">
            <svg class="icon">
              <use [attr.xlink:href]="'#icon-' + item.icon"></use>
            </svg>
          </div>
          <div [attr.data-testId]="'checkout-step-' + item.step" *ngIf="item.step < currentStep" class="flex j-center ai-center previous-step" [class.step-backer]="item.canNavigate" (click)="activateStepBack(item)">
            <svg class="icon" *ngIf="item.icon">
              <use [attr.xlink:href]="'#icon-' + item.icon"></use>
            </svg>
            <svg viewBox="0 0 32 32" class="icon" *ngIf="!item.icon">
              <use xlink:href="#icon-checkmark"></use>
            </svg>
          </div>
          <div class="step-title active" [ngClass]="isDemo ? 'isDemo' : 'hide-mobile'">
            {{item.title}}
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
