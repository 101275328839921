export class UpdateClientProfileDto {
    firstName:string
    lastName:string
    address1:string
    address2:string
    city:string
    zipCode:string
    country:string
    genderId:number
    locale:string
    mobile:string
    phone:string
    defaultAddressId:number
}