import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Status} from '../../../../../core/models/status.model';
import {ClientActionsDone} from '../../../../../core/models/clientActionsDone.model';
import {ClientAction} from '../../../../../core/models/clientAction.model';
import {TranslationService} from '../../../../../core/services/translation.service';
import {ActionsService} from '../../../../../core/services/actions.service';
import {GetDatasService} from '../../../../../core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import {CookieService} from "ngx-cookie-service";
import {ModalService} from "../../../../../core/services/modal.service";
import { ErrorService } from 'src/app/core/services/error.service';

@Component({
  selector: 'app-status-datas',
  templateUrl: './status-datas.component.html',
  styleUrls: ['./status-datas.component.scss'],
})
export class StatusDatasComponent implements OnInit, OnChanges {

  @Input() status: Status;
  @Input() clientActionsDone: ClientActionsDone[];
  @Input() clientActions: ClientAction[];

  hasDelegateRole = false;
  datas = [];
  private currentActionTypeId: any;
  locale: string;

  constructor(
    private translateConfig: TranslationService,
    private actionsService: ActionsService,
    private jwtService: JWTService,
    private getDatasService: GetDatasService,
    private cookie: CookieService,
    private modal: ModalService,
    private translate: TranslationService,
    public error:ErrorService
  ) {
  }

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');
    this.jwtService.hasDelegateRole$.subscribe(isDelegate => this.hasDelegateRole==isDelegate)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.clientActionsDone?.firstChange === false) {
      this.updateDatas();
    }
    if(changes.clientActions?.currentValue?.length) {
      this.setDatas();
    }
  }

  setDatas() {
    for (const e of this.clientActions) {

      if(e.clientActionTypeId==14 && this.hasDelegateRole) continue

      let realisationDate = null;
      if (this.clientActionsDone) {
        this.clientActionsDone.forEach(f => {
          if (f.clientActionTypeId === e.clientActionTypeId) {
            realisationDate = f.doneDate;
          }
        });
      }
      this.datas.push({
          clientActionTypeId: e.clientActionTypeId,
          displayPatternId: e.displayPatternId,
          description: e.name,
          points: e.displayValue,
          type: e.clientActionTypeId,
          realisationDate
        }
      );
    }
  }

  updateDatas() {
    const el = this.datas.filter(x => x.clientActionTypeId === this.currentActionTypeId);
    if(el.length) {
      const cad = this.clientActionsDone.filter(f => f.clientActionTypeId === this.currentActionTypeId);
      if(cad.length) {
        el[0].realisationDate = cad[0].doneDate;
      }
    }
  }

  getActionLink(type: number) {
    switch (type) {
      case 1:
        return this.translateConfig.getCollectionMenuLocalizedLink();
      case 11:
      case 10:
        return '';
      case 12:
        return '';
      case 13:
        return '/' + this.locale + '/club/profile/data';
      case 14:
        return '/' + this.locale + '/club/referral';
    }
  }

  checkIfDone(clientActionTypeId: number) {
    for (const e of this.clientActionsDone) {
      if (e.clientActionTypeId === clientActionTypeId) {
        return new Date(e.doneDate).toLocaleDateString();
      }
    }
    return null;
  }

  private openInstagram() {
    switch (this.translate.getContextId()) {
      case 1:
        window.open( "https://www.instagram.com/victoriafr_off/", "_blank");
        break;
      case 2:
        window.open( "https://www.instagram.com/victoria_benelux/", "_blank");
        break;
      case 3:
        window.open( "https://www.instagram.com/victoriadeutschland/", "_blank");
        break;
      default: window.open( "https://www.instagram.com/victoriafr_off/", "_blank");
    }
  }

  private openFacebook() {
    switch (this.translate.getContextId()) {
      case 1:
        window.open( "https://www.facebook.com/victoriafranceofficiel/", "_blank");
        break;
      case 2:
        window.open( "https://www.facebook.com/victoriabenelux/", "_blank");
        break;
      case 3:
        window.open( "https://www.facebook.com/victoriaschmuck.deutschland", "_blank");
        break;
      default: window.open( "https://www.facebook.com/victoriafranceofficiel/", "_blank");
    }
  }

  sendAction(action: any) {

    const actionsType = {
      12: 'subscribe_newsletter',
      11: 'follow_facebook',
      10: 'follow_instagram'
    };

    if(action.type === 10) {
      this.openInstagram();
    }

    if(action.type === 11) {
      this.openFacebook();
    }

    this.currentActionTypeId = action.clientActionTypeId;
    this.actionsService.postAddAction(this.jwtService.getSubFromToken(), actionsType[action.type]).subscribe(res => {

      if (res) {
        this.getDatasService.updateSummaryInternaly(res.data, true)
        //this.getDatasService.getSummary(this.jwtService.getSubFromToken());
      }
    });
  }

  openBirthDateModal() {
    this.currentActionTypeId = 13;
    this.modal.open("#birthDateModal");
  }
}
