<div class="delegate-card">
  <div *ngIf="delegateData && delegateData.delegateId">
    <div class="header">
      <div class="card-title flex column ac-center ai-center">
        <span class="status-text">{{'GLOBAL.DELEGATE' | translate}}</span>
        <span class="status-value">{{delegateData.displayName}}</span>
      </div>
    </div>
    <div class="body body-full-height body-padding-no-footer column">
      <div class="image">
        <div class="card-img-container flex center-all">
          <img loading="lazy" class="thumb-image" src="{{delegateData.profilePath ?? delegateData.profileImagePath}}">
        </div>
      </div>
      <div class="certification" *ngIf="delegateData.myVictoriaCertificationLevel && delegateData.myVictoriaCertificationLevel==3">
        <span class="v_certified">
          <svg viewBox="0 0 37 36" class="icon peach">
            <use xlink:href="#victoria-certification"></use>
          </svg>{{"DEMO.DELEGATE.CERTIFIED" | translate}}
        </span>
      </div>
      <div class="content">
        <div class="row flex j-center" *ngIf="delegateData.city">
          <svg viewBox="0 0 32 32" class="icon peach height100">
            <use xlink:href="#icon-location"></use>
          </svg> &nbsp; {{delegateData.city}}
        </div>
        <div class="row flex j-center" *ngIf="delegateData.phone">
          <svg viewBox="0 0 32 32" class="icon peach height100">
            <use xlink:href="#icon-telephone"></use>
          </svg> &nbsp; {{delegateData.phone}}
        </div>
        <div class="row flex j-center" *ngIf="delegateData.email">
          <svg viewBox="0 0 32 32" class="icon peach height100">
            <use xlink:href="#icon-newsletter"></use>
          </svg> &nbsp; {{delegateData.email}}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!delegateData.delegateId && resources" class="no-delegate flex column ai-center">
    <div class="card-title flex column ac-center ai-center">
      <span class="status-text">{{resources.profileConsultant.labelNoConsultants}}</span>
    </div>
    <div class="flex center-all icon-no-delegate">
      <svg viewBox="0 0 26 32" class="icon">
        <use xlink:href="#icon-delegate"></use>
      </svg>
    </div>
    <div class="description" [innerHTML]="'FIDELITY.DELEGATE.NODELEGATE' | translate">
    </div>
  </div>
</div>
