
import { Injectable, NgModule } from '@angular/core';
import { AbstractSecurityStorage, AuthModule, LogLevel} from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { AuthStorageService } from './AuthStorageService.service';


@NgModule({
  imports: [
    AuthModule.forRoot({
      config: [{
        configId: 'desktop',
        authority: environment.identity_url,
        authWellknownEndpointUrl: environment.identity_url,
        triggerAuthorizationResultEvent: true,
        redirectUrl: window.location.origin + '/authCallback',
        postLogoutRedirectUri: window.location.origin,
        // clientId: '213a54b2-a602-4b33-a757-7eca964bfcc5', //[TEST-APP1] fusion Auth Test - Node - Agora
        clientId: '1ba11db4-a6ee-43d7-a896-a7dc27c81e8e', //Client APP - NOT REGISTERED
        // clientId: 'e6c8802f-47aa-4d06-ad20-12ff65efdb6e', //Delegate APP - REGISTERED
        scope: 'openid profile offline_access',
        responseType: 'code',
        autoUserInfo: true,
        ignoreNonceAfterRefresh : true,
        silentRenew: true,
        //silentRenewUrl: window.location.origin + '/silent-renew.html',
        useRefreshToken: true,
        renewUserInfoAfterTokenRenew: true,
        renewTimeBeforeTokenExpiresInSeconds: 60,
        silentRenewTimeoutInSeconds: 3,
        logLevel: LogLevel.None,
        maxIdTokenIatOffsetAllowedInSeconds:82400,
        historyCleanupOff: false,
        storage: new AuthStorageService(),
        eagerLoadAuthWellKnownEndpoints: true,
        startCheckSession: true,
        autoCleanStateAfterAuthentication: true,

      },{
        configId: 'android',
        authority: environment.identity_url,
        triggerAuthorizationResultEvent: true,
        redirectUrl: "victoria-app://agora",
        postLogoutRedirectUri: "victoria-app://agora/logout-android",
        // clientId: '213a54b2-a602-4b33-a757-7eca964bfcc5', //[TEST-APP1] fusion Auth Test - Node - Agora
        clientId: '1ba11db4-a6ee-43d7-a896-a7dc27c81e8e', //Client APP - NOT REGISTERED
        // clientId: 'e6c8802f-47aa-4d06-ad20-12ff65efdb6e', //Delegate APP - REGISTERED
        scope: 'openid profile offline_access',
        responseType: 'code',
        autoUserInfo: false,
        silentRenew: false,   //TO TEST
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        refreshTokenRetryInSeconds:20,
        logLevel: LogLevel.None,
        maxIdTokenIatOffsetAllowedInSeconds:82400,
        eagerLoadAuthWellKnownEndpoints: false,
        storage: new AuthStorageService(),
      },{
        configId: 'ios',
        authority: environment.identity_url,
        triggerAuthorizationResultEvent: true,
        redirectUrl: "capacitor://localhost",
        postLogoutRedirectUri: window.location.origin,
        // clientId: '213a54b2-a602-4b33-a757-7eca964bfcc5', //[TEST-APP1] fusion Auth Test - Node - Agora
        clientId: '1ba11db4-a6ee-43d7-a896-a7dc27c81e8e', //Client APP - NOT REGISTERED
        // clientId: 'e6c8802f-47aa-4d06-ad20-12ff65efdb6e', //Delegate APP - REGISTERED
        scope: 'openid profile offline_access',
        responseType: 'code',
        autoUserInfo: false,
        silentRenew: false,   //TO TEST
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        refreshTokenRetryInSeconds:20,
        logLevel: LogLevel.None,
        maxIdTokenIatOffsetAllowedInSeconds:82400,
        eagerLoadAuthWellKnownEndpoints: false,
        storage: new AuthStorageService(),
      }]
    }),
  ],
  exports: [AuthModule],
  providers: [
    { provide: AbstractSecurityStorage, useClass: AuthStorageService },
  ]
})
export class AuthConfigModule {
}
