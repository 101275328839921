<div class="cart-recap-info-container">
  <div class="cart-recap-head">
    <div class="heading-block">
      {{resources.shoppingBag.label}}
    </div>
    <div class="cart-validation-waiting waiting" *ngIf="this.demo?.mustValidateCart && isDemoCart && demoCode && isCartValidationWaiting">
      <div class="infos-icon">
        <svg>
          <use xlink:href="#icon-help-info-solid"></use>
        </svg>
      </div>
      <p class="p-s">
        {{'DEMO.TUNNEL.VALIDATION.ACTIVE' | translate}}
        <br>
        {{'DEMO.TUNNEL.INFO.SENDCART' | translate}}
      </p>
    </div>
    <div class="cart-validation-waiting pending" *ngIf="this.demo?.mustValidateCart && isDemoCart && demoCode && isClientValidationDone">
      <div class="valid-icon">
        <svg>
          <use xlink:href="#icon-check"></use>
        </svg>
      </div>
      <p class="p-s">
        {{'DEMO.TUNNEL.INFO.CARTSENT' | translate:{'X': getTimes()} }}
      </p>
    </div>
    <div class="cart-validation-waiting valid" *ngIf="this.demo?.mustValidateCart && isDemoCart && demoCode && isCartValid && (order.flowStatuses.payment.status != 11)">
      <div class="valid-icon">
        <svg>
          <use xlink:href="#icon-check"></use>
        </svg>
      </div>
      <p class="p-s">
        {{'DEMO.TUNNEL.VALIDATION.DONE' | translate}}
      </p>
    </div>
    <div class="recap-description" *ngIf="isDemoCart && !demoCode && isCartValidationWaiting">
      <p>{{resources.shoppingBag.text | removeHtmlTag}}</p>
    </div>
    <div class="recap-description" *ngIf="isDemoCart && demoCode && isCartValidationWaiting && isCartValid">
      <p>{{resources.shoppingBag.text | removeHtmlTag}}</p>
    </div>
    <!-- <div class="recap-description" *ngIf="isDemoCart &&  demoCode && isCartValidationWaiting && !isCartValid">
      <p>{{'DEMO.TUNNEL.INFO.SENDCART' | translate}}</p>
    </div> -->
  </div>
  <div class="cart-recap-vouchers">
    <p>{{'CART.SUMMARY.REDUCTIONSCOMESNEXT' | translate }}</p>
  </div>
  <div class="cart-recap-info flex column">
    <div class="recap-row flex row j-space-between">
      <div>{{resources.shoppingBag.subtotal}} :</div>
      <div class="amount right">{{order.itemsTotal | agoraCurrency}}</div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="order.vouchers.length>0">
      <div>{{resources.shoppingBag.birthdayLabelError}} :</div>
      <div class="amount right">- {{order.vouchersTotal | agoraCurrency}}</div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="order.walletAmount>0">
      <div>{{'CART.SUMMARY.TOTALWALLET' | translate }}</div>
      <div class="amount right">- {{order.walletAmount | agoraCurrency}}</div>
    </div>
    <div class="recap-row flex row j-space-between">
      <div>{{resources.shoppingBag.shippingLabel}} :</div>
      <div *ngIf="order?.deliveryTypeId==1"><a (click)="modal.open('#detailsModal')">{{resources.shoppingBag.shippingButtonLabel}}</a></div>
      <div *ngIf="order?.deliveryTypeId==10"><a (click)="modal.open('#detailsModal')">{{order?.deliveryFee | agoraCurrency}}</a></div>
    </div>
    <div class="recap-row flex row j-space-between" *ngIf="order">
      <div>{{resources.shoppingBag.categoriesLabel}} :</div>
      <div class="amount right">{{order.totalToPay | agoraCurrency}}</div>
    </div>
    <div class="cta-container cart-actions">
      <button class="btn btn-primary secondary j-center" *ngIf="this.demo?.mustValidateCart && isDemoCart && demoCode && !isCartValid" (click)="sendOrderValidation()" [disabled]="operationPending">
        <div *ngIf="operationPending" class="loader loader-mini"></div>
        <label>{{'DEMO.TUNNEL.ACTION.SENDCART' | translate}}</label>
      </button>
      <button (click)="validateOrder()" [disabled]="this.demo?.mustValidateCart && (operationPending || (!isCartValid && isDemoCart))">
        <div *ngIf="operationPending" class="loader loader-mini"></div>
        <label>{{resources.shoppingBag.checkoutButtonLabel}}</label>
      </button>
    </div>

    <p *ngIf="order.totalToPay<=0 && order.orderItemsCount>0 && (order.vouchersTotal - order.itemsTotal)>0">{{resources.checkoutPayment.addressLabel2 |translateWihParam: {'surplus': (order.vouchersTotal - order.itemsTotal | agoraCurrency)} }}</p>

    <div class="flex ai-center j-center cart-recap-providers " *ngIf="(loader.loaded$ | async)">
      <div class="provider" *ngFor="let item of paymentMethods?.items">
        <img loading="lazy" [src]="item.image.size1x" alt="" *ngIf="item.image.svg">
        <img loading="lazy" src="/assets/images/cb.svg" class="lyf"  *ngIf="!item.image.svg && item.id != '3X'"/>
      </div>
    </div>
  </div>
</div>
