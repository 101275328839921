<div class="flex column order-contact" *ngIf="loaded && resources && !(error.delegateProfileError$ | async)">
  <div class="order-demo-code flex ai-center j-center ac-center" *ngIf="!demoId || !hasDemoCookie">
    <div class="label">
      {{resources.checkoutConsultant.ctaMessage | removeHtmlTag}}
    </div>
    <div class="link">
      <a class="ctaLink" (click)="modal.open('#orderSelectCode')">{{resources.checkoutConsultant.buttonLabel}}</a>
    </div>
  </div>

  <div class="order-contact-container" *ngIf="delegate && !(error.lookupError$ | async)">
    <app-order-contact-box (updateDemoCode)="updateDemoCode($event)" [delegate]="delegate" [order]="order" [contextId]="contextId" [profile]="profile" [demoCode]="demoId ?? demoCode ?? null" (refreshDelegatesToSelect)="updateDelegates($event)"></app-order-contact-box>
  </div>

  <div class="order-contact-container" *ngIf="(error.lookupError$ | async)">
    <h1 class="canela">{{resources.checkoutConsultant.secondTitle}}</h1>
    <div class="flex column">
      <p class="subtitle">{{resources.checkoutConsultant.addressLabelError }}</p>
      <div class="order-contact-info flex column ai-center">
        {{'ORDER_TUNNEL.CONTACT.MISSING_INFO' | translate}}
        <div class="flex j-center rowCTA">
          <app-wavedlink [linkText]="resources.checkoutConfirmation.loginButtonLabel" [linkUrl]="'/' + locale + '/club/profile/data'" ></app-wavedlink>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex loader-container center-all" *ngIf="!loaded">
  <div class="loader"></div>
</div>

<app-error-box  *ngIf="error.delegateProfileError$ | async" ></app-error-box>

<app-modal *ngIf="resources" id="orderSelectCode" [title]="resources.popupFindHomeparty.mainTitle" template="addCode"></app-modal>
<app-modal *ngIf="resources"  id="orderSearchDelegate" [title]="resources.popupFindConsultant.mainTitle" template="searchDelegate"></app-modal>
<app-modal *ngIf="resources"  id="orderSelectDelegate" [title]="resources.popupFindConsultant.chooseConsultantTitle" template="selectDelegate" [item]="delegatesToSelect"></app-modal>
