import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from 'src/app/core/services/storage.service';
import {TranslationService} from 'src/app/core/services/translation.service';
import {CommandeComponent} from './components/commande/commande.component';
import {PanierComponent} from './components/panier/panier.component';
import {WishlistComponent} from './components/wishlist/wishlist.component';
import {ProgressDemoComponent} from './components/demo/progress-demo/progress-demo.component';
import {CommunicationsComponent} from "./components/communications/communications.component";
import { OptInComponent } from './components/opt-in/opt-in.component';
import { PublicOrderPaymentComponent } from './components/public-order-payment/public-order-payment.component';
import { ESOLandingComponent } from './components/esolanding/esolanding.component';
import { LanguageResolver } from 'src/app/core/resolver/langFragmentResolver.service';

//ROUTING OPTION ---------------------------------------------------------------------
// breadcrumb : value  -- value to display in breadcrumb
// showBreadCrumb: false -- request to hide breadcrumb
// showJumbotron: false -- request to hide jumbo
// showNewsletter: false -- request to hide newsletter subscribtion frame
// showFooterMenu: false -- request to hide footer menu
// showFooterDisclaimer= false -- reqiest to hide footer disclaimer section
// showHeader: false -- switch header to header-light
// usePinkColor: true -- will switch main page + breadcrumb color to victoria pink
//------------------------------------------------------------------------------------
const routes: Routes = [
  {
    path: '',
    redirectTo: 'cart',
    pathMatch: 'full'
  },
  {
    path: 'cart',
    component: PanierComponent,
    data: {
      breadcrumb: 'GLOBAL.CART',
      showJumbotron: false,
      usePinkColor: true,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      showDemoBanner: true,
    },
  }, {
    path: 'checkout',
    component: CommandeComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
      enlargeScreen: true,
    }
  },
  {
    path: 'order/:orderId/client/:clientGuid/checkout',
    component: PublicOrderPaymentComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
      enlargeScreen: true,
    }
  },
  {
    path: 'wishlist',
    component: WishlistComponent,
    data: {
      breadcrumb: 'GLOBAL.WISHLIST',
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      enlargeScreen: true,
      showDemoBanner: true,
    },
  },
  {
    path: 'demo/:delegateId',
    resolve: [LanguageResolver],
    component: ESOLandingComponent,
    data: {
      breadcrumb: 'GLOBAL.DEMO',
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      showHeader: false,
      showFooterDisclaimer: false,
      enlargeScreenMobile: false,
      maxScreen: true,
      usePinkColor: false,
    },
  },
  {
    path: 'demo/:delegateId/:demoCode',
    resolve: [LanguageResolver],
    component: ProgressDemoComponent,
    data: {
      breadcrumb: 'GLOBAL.DEMO',
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      showHeader: false,
      showFooterDisclaimer: false,
      enlargeScreenMobile: false,
      maxScreen: true,
      usePinkColor: true,
    },
  },
  {
    path: 'demo/:delegateId/:demoCode/',
    resolve: [LanguageResolver],
    component: ProgressDemoComponent,
    data: {
      breadcrumb: 'GLOBAL.DEMO',
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      showHeader: false,
      showFooterDisclaimer: false,
      enlargeScreenMobile: false,
      maxScreen: true,
      usePinkColor: true,
    },
  },
  {
    path: 'demo/:delegateId/:demoCode/:hostessName',
    resolve: [LanguageResolver],
    component: ProgressDemoComponent,
    data: {
      breadcrumb: 'GLOBAL.DEMO',
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      showHeader: false,
      showFooterDisclaimer: false,
      enlargeScreenMobile: false,
      maxScreen: true,
      usePinkColor: true,
    },
  },
  {
    path: 'communications',
    component: CommunicationsComponent,
    data: {
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      enlargeScreenMobile: true,
      enlargeScreen: true,
      showHeader: false,
      noContainer: true,
    }
  },
  {
    path: 'communications/optin',
    component: OptInComponent,
    data: {
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      enlargeScreenMobile: true,
      enlargeScreen: true,
      showHeader: false,
      noContainer: true,
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicPagesRoutingModule {

  constructor(private translate: TranslateService, private translateConfig: TranslationService, private storage: StorageService) {
    //   routes.push(
    //     {
    //       path: this.translate.instant("PATH.HOME"),
    //       data: {breadcrumb: 'MENU.HOME', infoRoute: 'PATH.HOME'},
    //       component: HomeComponent,
    //     },
    //     {
    //       path: this.translate.instant("PATH.STATUS"),
    //       data: {breadcrumb: 'MENU.STATUS', infoRoute: 'PATH.STATUS'},
    //       component: StatusComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.WALLET"),
    //       data: {breadcrumb: 'MENU.WALLET', infoRoute: 'PATH.WALLET'},
    //       component: WalletComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.DELEGATE"),
    //       data: {breadcrumb: 'MENU.DELEGATE', infoRoute: 'PATH.DELEGATE'},
    //       component: DelegateComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.POINTENCODING"),
    //       data: {breadcrumb: 'MENU.POINTENCODING', infoRoute: 'PATH.POINTENCODING'},
    //       component: PointEncodingComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.POINTEVOLUTION"),
    //       data: {breadcrumb: 'MENU.POINTS', infoRoute: 'PATH.POINTEVOLUTION'},
    //       component: PointEvolutionComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.ORDERS"),
    //       data: {breadcrumb: 'MENU.ORDERS.ORDERS', infoRoute: 'PATH.ORDERS'},
    //       component: OrdersListComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.ORDER") + '/:id',
    //       data: {breadcrumb: 'MENU.ORDERS.ORDERS', infoRoute: 'PATH.ORDER'},
    //       component: OrderComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.PROFILE"),
    //       data: {breadcrumb: 'MENU.PROFILE', infoRoute: 'PATH.PROFILE'},
    //       component: ProfilComponent,
    //       canActivate: [AuthorizationGuard],
    //       children: [
    //         {
    //           path: '', redirectTo: this.translate.instant("PATH.PROFILE.DATAS")
    //         },
    //         {
    //           data: {breadcrumb: 'MENU.PROFILE.INFORMATIONS', infoRoute: 'PATH.PROFILE.DATAS'},
    //           path: this.translate.instant("PATH.PROFILE.DATAS"), component: MydatasComponent,

    //         },
    //         {
    //           data: {breadcrumb: 'MENU.PROFILE.ACCES', infoRoute: 'PATH.PROFILE.ACCES'},
    //           path: this.translate.instant("PATH.PROFILE.ACCES"), component: MyaccesComponent
    //         },
    //         {
    //           data: {breadcrumb: 'MENU.PROFILE.COMMUNICATION', infoRoute: 'PATH.PROFILE.COMMUNICATION'},
    //           path: this.translate.instant("PATH.PROFILE.COMMUNICATION"), component: MycommunicationsComponent
    //         }
    //       ]
    //     },
    //     {
    //       path: this.translate.instant("PATH.ERROR"),
    //       component: ErrorComponent,
    //   })

    // this.restoreDefaultLanguage();
  }

  private restoreDefaultLanguage() {
    if (!this.storage.languageCode || this.storage.languageCode == null) {
      this.storage.languageCode = 'fr_be';
    }
    this.translate.use(this.storage.languageCode);
  }
}
