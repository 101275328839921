import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { dateNow } from '@datadog/browser-core';
import { TouchSequence } from 'selenium-webdriver';
import { ClientOrder } from 'src/app/core/models/clientOrder.model';
import { FidelityDelegate } from 'src/app/core/models/fidelityDelegate.model';
import { OrderDeliveryAddress } from 'src/app/core/models/orderDeliveryAddress.model';
import { FidelityService } from 'src/app/core/services/fidelity.service';
import { FormsService } from 'src/app/core/services/forms.service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { OrderService } from 'src/app/core/services/order.service';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { OrderStateEnum } from '../../../../../agora/demo/enums/order-state.enum';
import { ActivatedRoute } from '@angular/router';
import { DemoService } from '../../../../../agora/demo/services/demo.service';
import { OrderActionEnum } from '../../../../../agora/demo/enums/order-action.enum';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-order-delivery',
  templateUrl: './order-delivery.component.html',
  styleUrls: ['./order-delivery.component.scss'],
})
export class OrderDeliveryComponent implements OnInit, OnChanges {
  @Input() profile: any;
  @Input() order: ClientOrder;
  @Input() clientInfos: any;
  @Input() contextId: number;
  @Input() deliveryId: number;
  @Input() demoCode: string;
  @Input() isOrderValidation = false;

  @Output() changeDelivery: EventEmitter<number> = new EventEmitter();

  delegate: FidelityDelegate;

  canEditDeliveryAdress = true;
  saveAsDefaultAdress = false;

  deliveryOptions: any[] = [];

  deliveryForm: FormGroup;
  resources: any;
  orderActionEnum = OrderActionEnum;

  public isBusy = false;
  private orderState = OrderStateEnum;

  get df() {
    return this.deliveryForm.controls;
  }

  @Output() changeStep: EventEmitter<any> = new EventEmitter();

  constructor(
    private orderService: OrderService,
    private fidelityService: FidelityService,
    private dataService: GetDatasService,
    private fb: FormBuilder,
    public form: FormsService,
    public translate: TranslationService,
    private route: ActivatedRoute,
    private demoService: DemoService,
    private loaderService: LoaderService
  ) {
    fidelityService.delegate$.subscribe((res) => {
      if (res) this.delegate = res;
    });
    dataService.clientData$.subscribe((res) => {
      this.profile = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.order) {
      const order = changes.order;

      if (
        order.currentValue != undefined &&
        !order.currentValue.deliveryAddress
      ) {
        if (!this.isOrderValidation)
          order.currentValue.deliveryAddress = new OrderDeliveryAddress(
            this.profile
          );
        else if (this.clientInfos)
          order.currentValue.deliveryAddress = new OrderDeliveryAddress(
            this.clientInfos
          );
      }

      this.deliveryForm = this.form.getDeliveryForm(
        this.fb,
        this.order.deliveryAddress
      );
    }
  }

  ngOnInit() {
    //console.log('Order Delivery Init');

    this.loaderService.loaded$.subscribe((res: boolean) => {
      if (res) {
        this.isBusy = false;
      }
    });

    this.isBusy = false;
    this.deliveryOptions = [];

    if (this.deliveryId == 10) {
      this.canEditDeliveryAdress = false;
    }
    if (this.order.isOnlyEGifts) {
      this.deliveryId = 1;
      this.changeDelivery.emit(1);
    }

    this.translate.localesLoaded.subscribe((res) => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });

    this.deliveryOptions = this.getDeliveryOptions(
      this.order.deliveryAddress?.countryCode
    );

    if (
      !this.delegate &&
      this.order.delegateId &&
      this.order.delegateId != null &&
      this.order.delegateId != ''
    )
      this.fidelityService.getDelegateSiteProfile(
        this.contextId,
        this.order.delegateId,
        null,
        true
      );
  }

  switchOption(option: number) {
    if (this.order.isOnlyEGifts) {
      this.changeDelivery.emit(1);
      return;
    }

    if (option == this.deliveryId) {
      return;
    } else {
      this.changeDelivery.emit(option);
    }

    this.order.deliveryFee = this.getDeliveryFee(option);
    const calculatedTotal = this.order.itemsTotal - this.order.vouchersTotal - this.order.walletAmount

    this.order.totalToPay = (calculatedTotal<0 ? 0 : calculatedTotal) + this.order.deliveryFee
  }

  deliveryIsAvailable(deliveryTypeId) {
    return this.deliveryOptions.find((x) => x.deliveryTypeId == deliveryTypeId);
  }

  getDeliveryFee(deliveryTypeId) {
    return this.deliveryOptions.find((x) => x.deliveryTypeId == deliveryTypeId)
      ?.deliveryFeeAmount;
  }

  confirmDelivery() {
    if (this.isBusy) return;
    else {
      this.isBusy = true;
      if (this.isOrderValidation && this.demoCode) {
        this.orderService.postClientOrderDelivery(
          {
            deliveryTypeId: this.deliveryId,
            deliveryAddress: this.order.deliveryAddress,
          },
          false,
          null,
          this.demoCode,
          this.route.snapshot.params?.clientGuid ?? this.order.clientSub,
          this.order.resolvedId
        );
      } else {
        this.orderService.postGuestOrderDelivery(
          {
            deliveryTypeId: this.deliveryId,
            deliveryAddress: this.order.deliveryAddress,
          },
          true,
          3,
          true
        );
      }

      if (this.isOrderValidation) {
        this.changeStep.emit(this.orderState.IS_DIP);
      }
      // this.changeStep.emit(3);
    }
  }

  saveEditAdresse() {
    if (this.isBusy) return;
    else if (this.deliveryForm.valid) {
      this.isBusy = true;

      const address = new OrderDeliveryAddress();
      address.firstName = this.isOrderValidation
        ? this.clientInfos.firstName
        : this.profile.firstName;
      address.lastName = this.isOrderValidation
        ? this.clientInfos.lastName
        : this.profile.lastName;
      address.email = this.isOrderValidation
        ? this.clientInfos.email
        : this.profile.email;
      address.address1 = this.deliveryForm.get('street').value;
      address.city = this.deliveryForm.get('city').value;
      address.zipCode = this.deliveryForm.get('zipCode').value;
      address.mobile = this.deliveryForm.get('phone').value;
      address.countryCode = this.deliveryForm.get('country').value;

      if (this.isOrderValidation && this.demoCode) {
        this.orderService.postClientOrderDelivery(
          { deliveryTypeId: this.deliveryId, deliveryAddress: address },
          false,
          null,
          this.demoCode,
          this.route.snapshot.params?.clientGuid,
          this.order.resolvedId,
          false
        );
        this.isBusy = false;
      } else {
        this.orderService.postGuestOrderDelivery({
          deliveryTypeId: this.deliveryId,
          deliveryAddress: address,
        });
        this.isBusy = false;
      }
      this.canEditDeliveryAdress = false;
    }
  }

  editDeliveryAdress() {
    this.canEditDeliveryAdress = true;
  }

  changeCountry(event) {
    this.deliveryOptions = this.getDeliveryOptions(event.target.value);
    this.order.deliveryFee = this.getDeliveryFee(this.deliveryId);
  }

  private getDeliveryOptions(country = undefined) {
    const deliveryParameters = this.order.params;
    let result = [];

    if (!country || country == null)
      result = deliveryParameters?.deliveryOptions?.filter(
        (x) => x.country == '*'
      );
    else {
      result = deliveryParameters?.deliveryOptions?.filter(
        (x) => x.country == country
      );

      if (!result.find((x) => x.deliveryTypeId == 1))
        result.push(
          ...deliveryParameters?.deliveryOptions?.filter(
            (x) => x.country == '*' && x.deliveryTypeId == 1
          )
        );
      if (!result.find((x) => x.deliveryTypeId == 10))
        result.push(
          ...deliveryParameters?.deliveryOptions?.filter(
            (x) => x.country == '*' && x.deliveryTypeId == 10
          )
        );
    }

    return result;
  }

  getPhonePlaceHolder(){

    return "";
    switch(this.contextId){
      case 1: return "0320735124";
      case 2: return "023446088";
      case 3: return "021143636881"
    }
  }
}
