import {Component, Input, OnInit} from '@angular/core';
import {ClientOrder} from '../../../../core/models/clientOrder.model';
import {ClientOrderItem} from "../../../../core/models/clientOrderItem.model";
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/core/services/translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-articles',
  templateUrl: './cart-articles.component.html',
  styleUrls: ['./cart-articles.component.scss'],
})
export class CartArticlesComponent implements OnInit {

  @Input() order: ClientOrder;
  @Input() wishlist: ClientOrder;
  @Input() resources: any;

  locale:string;

  constructor(private cookie:CookieService, private translate:TranslationService, private router:Router) {
  }

  ngOnInit() {
    this.locale = this.cookie.get("v_locale")?.replace("_","-");
  }

  checkInWishlist(item: ClientOrderItem) {
    return this.wishlist.orderItems.filter(e => e.sku === item.sku).length > 0;
  }

  displayArticle() {
    document.getElementById('cart-articles-main').style.display = 'block';
    document.getElementById('expand-cart').style.display = 'none';
  }

  goToCollection(){

    if(this.order && this.order.demoStatusId ==2 ){
      this.router.navigateByUrl(`/${this.locale}/myvictoria/demo/${this.order.demoCode}/jewelry`)

    } else window.location.href=this.translate.getCollectionMenuLocalizedLink();
  }

}
