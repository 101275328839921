<a *ngIf="fragmentUrl && linkUrl" class="link-wave" (click)="navigate()">
  <div class="flex row link">
    {{linkText}}
    <svg *ngIf="hasArrow" viewBox="0 0 32 32">
      <use xlink:href="#icon-arrow-right-long"></use>
    </svg>
  </div>
  <svg class="line" viewBox="0 0 82 4" preserveAspectRatio="none">
    <use xlink:href="#icon-line"></use>
  </svg>
</a>

<a *ngIf="externalUrl" class="link-wave" [attr.href]="externalUrl" >
  <div class="flex row link">
    {{linkText}}
    <svg *ngIf="hasArrow" viewBox="0 0 32 32">
      <use xlink:href="#icon-arrow-right-long"></use>
    </svg>
  </div>
  <svg class="line" viewBox="0 0 82 4" preserveAspectRatio="none">
    <use xlink:href="#icon-line"></use>
  </svg>
</a>

<a *ngIf="linkUrl && !fragmentUrl" class="link-wave" [routerLink]="[linkUrl]"  >
  <div class="flex row link">
    {{linkText}}
    <svg *ngIf="hasArrow" viewBox="0 0 32 32">
      <use xlink:href="#icon-arrow-right-long"></use>
    </svg>
  </div>
  <svg class="line" viewBox="0 0 82 4" preserveAspectRatio="none">
    <use xlink:href="#icon-line"></use>
  </svg>
</a>

<div *ngIf="isCTA" class="link-wave cursor">
  <div class="flex row link">
    {{linkText}}
    <svg *ngIf="hasArrow" viewBox="0 0 32 32">
      <use xlink:href="#icon-arrow-right-long"></use>
    </svg>
  </div>
  <svg class="line" viewBox="0 0 82 4" preserveAspectRatio="none">
    <use xlink:href="#icon-line"></use>
  </svg>
</div>
