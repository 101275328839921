import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { getVictoriaUrl } from 'src/app/core/utils/filter.utils';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(private authService: OidcSecurityService, private storage: StorageService, private jwtService:JWTService, private dataServices:GetDatasService) { }

  ngOnInit() {

    let language = this.storage.languageCode?.replace("_","-");

    switch(language){
      case "nl-be":
      case "fr-be": this.authService.getConfiguration("desktop").postLogoutRedirectUri = getVictoriaUrl(this.storage.languageCode) + `/${language}`; break;
      default: this.authService.getConfiguration("desktop").postLogoutRedirectUri = getVictoriaUrl(this.storage.languageCode)
    }

    this.dataServices.deleteClientStatusCookie();
    this.jwtService.deleteTokenCookie();
    this.authService.logoff("desktop");
    this.jwtService.cleanState();
  }

}
