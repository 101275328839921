<div class="header-wrapper" [class.pink-container]="breadcrumb.usePinkColor$ | async">
  <header class="desktop" [class.pink-container]="breadcrumb.usePinkColor$ | async">
    <div class="header-content">
      <div class="header-lv1">
        <div class="logo-container">
          <div class="logo">
            <a [href]="getLogoUrl()"><img loading="lazy" src="/assets/images/logo.svg" alt="Logo Victoria"></a>
          </div>
        </div>
        <div class="menu-links-container">
          <div class="menu-links-top">
            <div class="nav-container">
            </div>
          </div>
          <div class="menu-links-bottom">
            <nav>
              <ul>
                <li>
                  <div *ngIf="(breadcrumb.showBackToCart$ | async)===false" class="select-lang-area">
                    <div class="select-lang" id="#currentCountry" (click)="openCountries()">
                      <div class="country-area">
                        <div class="flag">
                          <svg viewBox="0 0 32 32">
                            <use id="flag" [attr.xlink:href]="langSettings.flag"></use>
                          </svg>
                        </div>
                        <div class="country-name">
                          {{langSettings.country}}
                        </div>
                      </div>
                      <div class="carret-area">
                        <svg viewBox="0 0 32 32">
                          <use xlink:href="#icon-arrow-right"></use>
                        </svg>
                      </div>
                    </div>
                    <div class="lang-options">
                      <div class="select-lang" (click)="changeLangage('nl_be')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-be"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('nl_be')}">
                            Belgïe (NL)
                          </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('nl_be','nl_nl')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-nl"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('nl_nl')}">
                            Nederland (NL)
                          </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('fr_be')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-be"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('fr_be')}">
                            Belgique (FR)
                          </div>
                        </div>
                      </div>

                      <div class="select-lang" (click)="changeLangage('fr_fr')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-fr"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('fr_fr')}">
                            France (FR)
                          </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('de_de')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-de"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('de_de')}">
                            Deutschland (DE)
                          </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('de_de', 'de_at')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-at"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('de_at')}">
                            Österreich (DE)
                          </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('fr_be', 'fr_lu')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-lu"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('fr_lu')}">
                            Luxemburg (FR)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="backToCart" *ngIf="(breadcrumb.showBackToCart$ | async)" (click)="goToCart()">
          <svg viewbox="0 0 32 32">
            <use xlink:href="#icon-close"></use>
          </svg>
        </div>
      </div>
    </div>
  </header>
  <header class="mobile" [class.pink-container]="breadcrumb.usePinkColor$ | async">
    <div class="header-content">
      <div class="header-lv1">
        <div class="logo-container">
          <div class="logo">
            <a [href]="getLogoUrl()">
              <img loading="lazy" src="/assets/images/m-logo.svg" alt="Logo Victoria">
            </a>
          </div>
        </div>
        <div class="buttons-container">
        </div>
        <div class="backToCart" *ngIf="(breadcrumb.showBackToCart$ | async)" (click)="goToCart()">
          <svg viewbox="0 0 32 32">
            <use xlink:href="#icon-close"></use>
          </svg>
        </div>
      </div>
    </div>
  </header>
</div>
