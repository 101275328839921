import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ErrorType} from '../enums/errorType.enum';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {StorageService} from './storage.service';
import {JWTService} from './jwt.service';
import {LoaderService} from './loader.service';
import {ErrorService} from './error.service';
import {CookieService} from 'ngx-cookie-service';
import {TranslationService} from './translation.service';
import {ClientCommunications} from "../models/clientCommunications.model";
import { getApiUrlFromOrigin } from '../utils/filter.utils';

const BASE_URL: string = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root'
})
export class RGPDService {

  private headers: HttpHeaders;

  private _communications$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public communications$: Observable<any> = this._communications$.asObservable();
  private _noCommunications$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public noCommunications$: Observable<boolean> = this._noCommunications$.asObservable();

  public _optinDone$:BehaviorSubject<any> = new BehaviorSubject(undefined);
  public optinDone$:Observable<any> = this._optinDone$.asObservable();


  //INTERNAL
  private profileLoading: boolean = false;

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public jwtService: JWTService,
    public loader: LoaderService,
    public error: ErrorService,
    private translate: TranslationService
  ) {
    this.communications$ = this._communications$.asObservable();
  }

  public getClientPublicCommunication(contextId:number, email, hash:string, hash2:string, delegateId:string=undefined){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ContextId', contextId.toString());
    this.headers = this.headers.append('X-Hash', hash);
    this.headers = this.headers.append('X-Hash2', hash2);

    if(delegateId){
      this.headers = this.headers.append('X-DelegateId', delegateId);
    }

    if (!this.profileLoading) {
      this.profileLoading = true;
      this.http.get<any>(BASE_URL + `/RGPD/${email}`, {headers: this.headers}).subscribe(res => {
          if (res.data) {
            this._communications$.next(res.data);
          }
          this.error.dropError(ErrorType.PROFILE);
          this.triggerLoad(true);
          this.profileLoading = false;
        },
        err => {
          this._noCommunications$.next(true);
          this.triggerLoad(true);
          this.error.raiseError(ErrorType.PROFILE);
          this.profileLoading = false;
        });
    }
  }

  

  public postClientPublicCommunication(com: ClientCommunications, target: number, operation: number, contextId: number, email: string, preference: any, hash:string, hash2:string): void {

    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ContextId', contextId.toString());
    this.headers = this.headers.append('X-Hash', hash);
    this.headers = this.headers.append('X-Hash2', hash2);

    const body = {
      operation,
      target,
      addressId: com ? com.addressId : 0,
      email
    };

    if(target === 500) {
      body['detailsId'] = preference.detailsId;
      body['delegateId'] = preference.delegateId;
    }

      this.http.post<any>(BASE_URL + `/RGPD/${email}`, body, {headers: this.headers}).subscribe(res => {
         if(res!==undefined){
          this._optinDone$.next(res);
          this.error.dropError(ErrorType.PROFILE);
         }
        },
        err => {
          this.error.raiseError(ErrorType.PROFILE);
        });
  }

  public postClientPublicCommunicationOptin(com: ClientCommunications, target: number, operation: number, contextId: number, email: string, preference: any, hash:string, hash2:string): void {

    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ContextId', contextId.toString());
    this.headers = this.headers.append('X-Hash', hash);
    this.headers = this.headers.append('X-Hash2', hash2);

    const body = {
      operation,
      target,
      addressId: com ? com.addressId : 0,
      email
    };

    if(target === 500) {
      body['detailsId'] = preference.detailsId;
      body['delegateId'] = preference.delegateId;
    }

      this.http.post<any>(BASE_URL + `/RGPD/${email}/optin`, body, {headers: this.headers}).subscribe(res => {
          if (res.data) {
            this._communications$.next(res.data);
            this._noCommunications$.next(false);
          }
          this.error.dropError(ErrorType.PROFILE);
        },
        err => {
          this.error.raiseError(ErrorType.PROFILE);
        });
  }

  public getClientCommunications(contextId: number, sub: string, email: string, notifyLoaded = false): void {

    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwtService.getToken());
    this.headers = this.headers.append('X-ContextId', contextId.toString());

    if (!this.profileLoading) {
      this.profileLoading = true;
      this.http.get<any>(BASE_URL + `/RGPD/${email}`, {headers: this.headers}).subscribe(res => {
          if (res.data) {
            this._communications$.next(res.data);
          }
          this.error.dropError(ErrorType.PROFILE);
          this.triggerLoad(notifyLoaded);
          this.profileLoading = false;
        },
        err => {
          this._noCommunications$.next(true);
          this.triggerLoad(notifyLoaded);
          this.error.raiseError(ErrorType.PROFILE);
          this.profileLoading = false;
        });
    }
  }

  public postClientCommunication(com: ClientCommunications, target: number, operation: number, contextId: number, sub: string, email: string, preference: any): void {

    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwtService.getToken());
    this.headers = this.headers.append('X-ContextId', contextId.toString());

    const body = {
      operation,
      target,
      addressId: com ? com.addressId : 0,
      email
    };

    if(target === 500) {
      body['detailsId'] = preference.detailsId;
      body['delegateId'] = preference.delegateId;
    }

      this.http.post<any>(BASE_URL + `/RGPD/${email}`, body, {withCredentials: true, headers: this.headers}).subscribe(res => {
          if (res.data) {
            this._communications$.next(res.data);
            this._noCommunications$.next(false);
          }
          this.error.dropError(ErrorType.PROFILE);
        },
        err => {
          this.error.raiseError(ErrorType.PROFILE);
        });
  }

  private triggerLoad(active) {
    if (active) this.loader.loaded();
  }
}
