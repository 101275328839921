import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {

  @Input() modalTitle;
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Input() isBusy = false;

  @Input() okButton:string;
  @Input() cancelButton:string;
  @Input() titleIconRight:string;
  @Input() titleIconLeft:string;

  isConfirmation = true;

  constructor() { }

  ngOnInit() {
    this.isBusy = false;
    this.isConfirmation = true;
  }

  confirmAction(b: boolean) {
    if(this.isBusy) return;
    else {
      this.isConfirmation = b;
      this.isBusy = true;
      this.confirm.emit(b);
      this.isBusy = false;
    }
  }
}
