<app-page-title
  mainTitle="{{'DELEGATE.TITLE'| translate}}"
  subTitle="{{'DELEGATE.SUBTITLE'| translate}}">
</app-page-title>

<div class="advisor-container flex j-center">
  <div class="advisor" *ngIf="delegate">
    <app-delegate-infos-card [delegate]="delegate"></app-delegate-infos-card>
  </div>
</div>
