<div class="delivered-orders-list-datas-container" *ngIf="getDatas">
  <p *ngIf="!deliveredOrders.length && !(error.getOrdersError$ | async)" class="no-data-title">{{'DATATABLE.NODATA' | translate}}</p>
  <div class="error-box" *ngIf="(error.getOrdersError$ | async)">
    <div class="flex row ai-center">
      <svg viewBox="0 0 28 28" class="icon-warning">
        <use xlink:href="#icon-warning"></use>
      </svg>
      <div>
        <span>{{'ERROR.TITLE' | translate }}</span>
      </div>
    </div>
  </div>
  <div *ngFor="let deliveredOrder of deliveredOrders">
    <app-order-short [order]="deliveredOrder" [delivered]="true" [border]="'bottom'" [actionLink]="'/' + locale + '/club/orders/'"></app-order-short>
  </div>
</div>
