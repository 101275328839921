import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from "../../../core/services/translation.service";

@Component({
  selector: 'app-delegate-infos-card',
  templateUrl: './delegate-infos-card.component.html',
  styleUrls: ['./delegate-infos-card.component.scss'],
})
export class DelegateInfosCardComponent implements OnInit {

  @Input() delegate: any;
  resources: any;

  constructor(
    private translate: TranslationService
  ) { }

  ngOnInit() {
    this.translate.localesLoaded.subscribe(res => {
      if(res==true){
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });
  }

}
