<div class="back-to-top">
  <div class="content" id="back-to-top-button" (click)="scrollToTop()">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
      <circle cx="15" cy="15" r="14.5" fill="white" stroke="currentColor"></circle>
      <path
        d="M10.0044 12.3975C9.95166 12.4492 9.90978 12.511 9.8812 12.5791C9.85261 12.6472 9.83789 12.7204 9.83789 12.7942C9.83789 12.8681 9.85261 12.9413 9.8812 13.0094C9.90978 13.0775 9.95166 13.1392 10.0044 13.191C10.1091 13.2941 10.2502 13.3518 10.3971 13.3518C10.5441 13.3518 10.6851 13.2941 10.7899 13.191L14.8134 9.16749V22.4445C14.813 22.5176 14.8272 22.59 14.855 22.6576C14.8829 22.7252 14.9238 22.7866 14.9755 22.8382C15.0272 22.8899 15.0886 22.9308 15.1562 22.9585C15.2238 22.9863 15.2963 23.0004 15.3694 23C15.6789 23 15.9329 22.754 15.9329 22.4445V9.16749L19.9489 13.191C20.1709 13.4055 20.5284 13.4055 20.7424 13.191C20.7951 13.1392 20.837 13.0775 20.8655 13.0094C20.8941 12.9413 20.9089 12.8681 20.9089 12.7942C20.9089 12.7204 20.8941 12.6472 20.8655 12.5791C20.837 12.511 20.7951 12.4492 20.7424 12.3975L15.7664 7.42149C15.7154 7.36883 15.6544 7.32695 15.5869 7.29834C15.5194 7.26974 15.4469 7.255 15.3736 7.255C15.3003 7.255 15.2278 7.26974 15.1603 7.29834C15.0929 7.32695 15.0318 7.36883 14.9809 7.42149L10.0044 12.3975Z"
        fill="currentColor"></path>
    </svg>
  </div>
</div>
