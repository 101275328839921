import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LoaderService} from 'src/app/core/services/loader.service';
import {PaymentService} from 'src/app/core/services/payment.service';
import {StorageService} from 'src/app/core/services/storage.service';
import {ClientOrder} from "../../../../core/models/clientOrder.model";

@Component({
  selector: 'app-payment-mode-selection',
  templateUrl: './payment-mode-selection.component.html',
  styleUrls: ['./payment-mode-selection.component.scss'],
})
export class PaymentModeSelectionComponent implements OnInit, OnChanges {
  //////////////////////////////////////////////////////
  //INPUT VALUES////////////////////////////////////////
  @Input() resources: any;
  @Input() order: ClientOrder;
  @Input() contextId: number;
  @Input() isDemoClient = false;
  @Input() showPaymentToDelegate = true;
  //OUTPUT ACTIONS//////////////////////////////////////
  @Output() changeSelectedMethod: EventEmitter<string> = new EventEmitter();
  //INTERNAL MEMBERS////////////////////////////////////
  paymentMethods: any;
  selectedMethod: any;
  loaded: any = false;

  //////////////////////////////////////////////////////
  constructor(private paymentService: PaymentService, private storage: StorageService, private loader: LoaderService) {
  }

  ngOnInit() {
    //console.log("Payment Mode Selection Init");

    this.loader.loaded$.subscribe(_ => this.loaded = true);

    this.paymentService.paymentOptions$.subscribe(res => {
      if (res) {
        this.paymentMethods = res;
        this.selectPaymentMethod(this.paymentMethods.items[0].id);
        this.loader.loaded();
      }
    });

    if(this.order && this.order.flowStatuses.payment.status === 11) {
      this.selectedMethod = 'delivery';
      this.changeSelectedMethod.emit(this.selectedMethod);
    }

    if(this.paymentMethods && !this.isDemoClient){
        this.selectPaymentMethod(this.paymentMethods.items[0].id);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contextId || changes.lang || changes.order) {
      this.paymentService.getPaymentsOptions(this.contextId, this.storage.languageCode);

      if(this.contextId==1 && this.selectedMethod=="3X" && this.order.totalToPay<90){
        this.selectPaymentMethod(this.paymentMethods.items[0].id);
      }

      if(changes.order && this.isDemoClient && (this.order.demoStatusId<2 || this.order.demoStatusId>3)){
        if(this.selectedMethod=="delivery") this.selectPaymentMethod(this.paymentMethods.items[0].id);
        this.showPaymentToDelegate = false
      }
    }
  }

  selectPaymentMethod(method) {
    if(method=="3X" && this.order.totalToPay<90) return;

    this.selectedMethod = method;
    this.changeSelectedMethod.emit(this.selectedMethod);
  }

}
