<div class="discount-box">
  <div class="voucher-encoding-container">
    <div class="header" *ngIf="!isOrderValidation">{{'ORDER_TUNNEL.REDUCTIONS.VOUCHER' | translate }}</div>
    <div *ngIf="isOrderValidation">
      <h1 class="canela">{{'DEMO.TUNNEL.REDUCTIONS.TITLE' | translate}}</h1>
      <div class="header order-validation">{{'DEMO.TUNNEL.CLIENT.VOUCHERS' | translate}}</div>
    </div>
    <div class="form-control flex row j-space-between ai-center input-box m-gap-20" [class.error]="hasError">
      <input data-testId="voucher-encoding" [placeholder]="'ORDER_TUNNEL.REDUCTIONS.VOUCHER.PLACEHOLDER' | translate" class="voucher-input" [attr.disabled]="(order?.totalToPay - order.deliveryFee) > 0 ? null : ''" [(ngModel)]="promoCode">
      <button data-testId="voucher-encoding-action"
        class="btn btn-primary btn-animated {{(order?.totalToPay - order.deliveryFee) > 0 && promoCode ? '' : 'disabled'}} encode-voucher-done"
        [ngClass]="demoCode ? 'secondary' : ''"
        (click)="validatePromoCode()" [disabled]="isBusy">
        <span *ngIf="!used">{{'GLOBAL.ACTION.USE' | translate }}</span>
        <span *ngIf="used">{{'GLOBAL.ACTION.USED' | translate }}</span>
        <svg viewBox="0 0 32 32" class="icon encode-voucher-check" >
          <use xlink:href="#icon-checkmark"></use>
        </svg>
        <div *ngIf="isBusy" class="loader loader-mini"></div>
      </button>
    </div>
    <div data-testId="voucher-encoding-errors" class="error-container" [class.error]="hasError">
      <label *ngIf="hasError && errorCode==12">{{resources.shoppingBag.bagLabel}}</label>
      <label *ngIf="hasError && errorCode==13">{{resources.shoppingBag.backToTopLabel}}</label>
      <label *ngIf="hasError && errorCode==14">{{resources.shoppingBag.backLabel}}</label>
      <label *ngIf="hasError && errorCode==15">{{resources.shoppingBag.backToTopLabel}}</label>
    </div>
  </div>
</div>
