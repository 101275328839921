<div class="open-payment">
    <app-page-title  *ngIf="!isConfirmation && resources && loaded" [mainTitle]="resources.checkout.mainTitle">
    </app-page-title>

    <div class="order-container" *ngIf="!isConfirmation && resources && loaded">
      <div>
        <div class="frame-container flex j-center ai-start flex-step-3">
          <div  class="order-content flex-2 fullwidth-only">
            <app-optin-dip-box *ngIf="isDip==true" [isPublicAccess]="true" [resources]="resources" [demoCode]="demoCode" [order]="order" (setIsDipCheck)="setIsDipCheck($event)" [selectedMethod]="selectedMethod"></app-optin-dip-box>

            <div class="payment-mode-selection-box" [ngClass]="{'signed': isDip==false}">
              <div *ngIf="error!=0 || errors.length" class="order-payment-error">
                <div class="flex row ai-center" *ngIf="error!=0">
                  <svg viewBox="0 0 28 28" class="icon-warning">
                    <use xlink:href="#icon-warning"></use>
                  </svg>
                  <div>
                    <span *ngIf="error==3">{{resources.checkoutPayment.messageLabelError}}</span>
                    <span *ngIf="error==5">{{resources.checkoutPayment.topicLabelError}}</span>
                    <span *ngIf="error==99">{{resources.resetpassword.shortDescription}}</span>
                    <span *ngIf="error!=3 && error!=99 && error!=5">{{'ALERT.ERROR.GLOBAL' | translate}}</span>

                  </div>
                </div>
                <p class="error-box flex column error-bottom-space" *ngIf="errors?.length">
                  <span *ngFor="let err of errors">{{err.message}}</span>
                </p>
              </div>

              <app-payment-mode-selection
                [order]="order"
                [resources]="resources" [contextId]="translate.contextId$ | async"
                (changeSelectedMethod)="selectPaymentMethod($event)"
                [showPaymentToDelegate]="isDip==true"
                [isDemoClient]="true"></app-payment-mode-selection>
            </div>
          </div>
          <div *ngIf="order.orderItems" class="order-summary-content flex-1 fullwidth-only">
            <app-order-summary [deliveryId]="order.deliveryTypeId"
                              [order]="order"
                              [selectedMethod]="selectedMethod"
                              [isDipCheck]="isDipChecked"
                              [demoCode]="demoCode"
                              [disableShopButton]="(!isDipChecked || !selectedMethod)"
                              [isDipCheck]="isDipChecked"
                              (triggerPaymentByDelegate)="paymentByDelegate($event)"
                              [noProduct]="false"
                              [hiddeDeliveryButton]="true"
                              [disableDeleteVouchersWallet]="true"
                              [contextId]="translate.contextId$ | async"
                              [step]="3"
                              (triggerError)="setError($event)"
                              (errors)="updateErrors($event)"
                              [isPublicAccess]="true"></app-order-summary>
          </div>
        </div>
      </div>

      <div class="demo-order-actions" *ngIf="orderSummaryComponent && screen.isMobile">
        <div class="demo-order-actions-container">
          <div class="flex row j-space-between">
            <p class="bold">{{'DEMO.TUNNEL.TOTALTOPAY' | translate}}</p>
            <p class="bold">{{order.totalToPay | agoraCurrency}}</p>
          </div>
          <div *ngIf="!isDipChecked || !this.selectedMethod" class="validation-text">
            <p class="p-s" *ngIf="!isDipChecked">{{'DEMO.TUNNEL.VALIDATION.DIP' | translate}}</p>
            <p class="p-s" *ngIf="isDipChecked && !selectedMethod">{{'DEMO.TUNNEL.VALIDATION.PAYMENT_METHOD' | translate}}</p>
          </div>

          <div class="cta-container">
            <button class="primary" (click)="orderSummaryComponent.saveTunnelDatas()" [disabled]="orderSummaryComponent.operationPending || orderSummaryComponent.disableShopButton || !selectedMethod">
              <div *ngIf="orderSummaryComponent.operationPending" class="loader loader-mini"></div>
              <label>{{'DEMO.TUNNEL.ACTION.SHOPNOW' | translate}}</label>
            </button>
          </div>
        </div>
      </div>
    </div>


    <app-order-payment-status *ngIf="isConfirmation && resources && loaded" [order]="order"  [demoCode]="demoCode" [resources]="resources.checkoutConfirmation"></app-order-payment-status>

  </div>
