import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Voucher } from 'src/app/core/models/voucher.model';
import { JWTService } from 'src/app/core/services/jwt.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ScreenService } from 'src/app/core/services/screen.service';

@Component({
  selector: 'app-voucher-selection',
  templateUrl: './voucher-selection.component.html',
  styleUrls: ['./voucher-selection.component.scss'],
})
export class VoucherSelectionComponent implements OnInit, OnChanges {
  //////////////////////////////////////////////////////
  //INPUT VALUES////////////////////////////////////////
  @Input() resources:any;
  @Input() order:any;
  @Input() contextId: number;
  @Input() demoCode: string;
  @Input() isOrderValidation = false;
  @Input() clientVouchers;
  @Input() redirect = true;
  //OUTPUT ACTIONS//////////////////////////////////////
  //INTERNAL MEMBERS////////////////////////////////////
  vouchers: any[]
  voucherForm: FormGroup;
  availableVouchers: Voucher[];
  voucherCount = -1;
  usedVouchers: any[];
  enableVoucherButton = false;
  used = false;
  isBusy = false;
  //////////////////////////////////////////////////////
  constructor(
    private formBuilder: FormBuilder,
    private orderService:OrderService,
    private jwtService:JWTService,
    private route: ActivatedRoute,
    private screen: ScreenService,
    private loader: LoaderService
  ) { }

  ngOnInit() {
    this.voucherCount = -1;

    //console.log("Voucher Selection Init");
    this.loader.loaded$.subscribe(res => {
      if(res) this.isBusy = false;
    })

    this.initVoucherForm();

    if(!this.isOrderValidation)
      this.orderService.getValidVouchers(this.jwtService.getSubFromToken()).subscribe((res: any) => {
        if(res.data) {
          this.vouchers = res.data;
          this.updateVouchers();
        }
      })
    else {
      this.vouchers = this.clientVouchers;
      this.updateVouchers();
    }

    this.orderService.panierOrder$.subscribe(res => {
      if(res && !this.isOrderValidation){
        this.usedVouchers = res.vouchers;
        this.updateVouchers();
      }
    });

    this.orderService.demoSingleClient$.subscribe(res => {
      if(res && this.isOrderValidation){
        this.usedVouchers = this.order.vouchers;
        this.updateVouchers();
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes && changes.clientVouchers && changes.clientVouchers.currentValue && this.isOrderValidation){
        this.voucherCount = -1;
        this.vouchers = changes.clientVouchers.currentValue;
        this.updateVouchers();
      }
  }

  useVoucher() {
    if(this.isBusy) return;
    else if(this.voucherForm.get('voucher').value !== 'default' && this.isOrderValidation && this.demoCode) {
        this.isBusy = true;
        const clientGuid = this.route.snapshot.params?.clientGuid ?? this.order.clientSub;
        this.orderService.postVoucherClientOrder(this.demoCode, clientGuid, this.voucherForm.get('voucher').value, this.contextId, this.order.resolvedId, this.redirect);
        this.notifyAsDone();
    }
    else if(this.voucherForm.get('voucher').value !== 'default'){
      this.isBusy = true;
      this.orderService.postVoucherOrderGuest(this.contextId, this.voucherForm.get('voucher').value);
      this.notifyAsDone();
    }
  }

  notifyAsDone(){
    if(this.screen.isMobile){
      this.used = true;
      document.getElementsByClassName("add-voucher-done")[0]?.classList.toggle('done')
      document.getElementsByClassName('add-voucher-check')[0]?.classList.toggle('display');
      setTimeout(() => {
        this.used = false;
        document.getElementsByClassName('add-voucher-check')[0]?.classList.toggle('display');
        document.getElementsByClassName("add-voucher-done")[0]?.classList.toggle('done')
      }, 3500);
    }
  }

  private initVoucherForm(){
    this.voucherForm = this.formBuilder.group({
      voucher: ['default']
    });

    this.voucherForm.get('voucher').valueChanges.subscribe(v => {
      this.enableVoucherButton = true;
    });
  }

  private updateVouchers() {
    this.availableVouchers = this.vouchers?.filter(voucher => !this.usedVouchers?.find(uV => uV.code === voucher.code) );
    if(this.voucherCount==-1 || this.voucherCount==0 || this.voucherCount==undefined) this.voucherCount = this.availableVouchers?.length??0
    this.voucherForm?.get('voucher').setValue('default');
    this.enableVoucherButton = false;
  }
}
