<div class="optin-dip-box-container">
  <h1 class="canela">{{'DEMO.TUNNEL.DIP.VALIDATION.TITLE' | translate}}</h1>
  <div class="flex row ai-start">
    <label class="flex row checkbox path checkbox-optin">
      <input type="checkbox" [(ngModel)]="isDipChecked" (ngModelChange)="setIsCheck($event)"  [disabled]="selectedMethod=='delivery' && isDipChecked">
      <svg viewBox="0 0 28 28">
        <use xlink:href="#custom-checkbox"></use>
      </svg>
    </label>
    <p [innerHTML]="'DEMO.TUNNEL.DIP.VALIDATION.OPTIN' | translate"></p>
  </div>
</div>

