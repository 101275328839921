import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthenticatedResult,
  EventTypes,
  LoginResponse,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from './core/services/breadcrumb.service';
import { StorageService } from './core/services/storage.service';
import { TriggerService } from './core/services/trigger.service';
import { GetDatasService } from './core/services/get-datas.service';
import { Client } from './core/models/client.model';
import { OrderService } from './core/services/order.service';
import { JWTService } from './core/services/jwt.service';
import {
  getCookieDomainByLang,
  getDefaultLangByUrl,
  getEshopUrlByContext,
  getEshopUrlByLang,
  getSSOLocale,
} from './core/utils/filter.utils';
import { TranslationService } from './core/services/translation.service';
import { datadogRum } from '@datadog/browser-rum';
import { DOCUMENT } from '@angular/common';
import { GoogleAnalyticService } from './core/services/google-analytic.service';
import { Alert } from './core/models/alert.model';
import { AlertService } from './core/services/alert.service';
import { ModalService } from './core/services/modal.service';
import { ErrorService } from './core/services/error.service';
import { LoggerService } from './core/services/logger.service';
import { FidelityService } from './core/services/fidelity.service';
import { map, buffer, distinctUntilChanged } from 'rxjs/operators';
import { ScreenService } from './core/services/screen.service';
import { TrackingService } from './core/services/tracking.service';
import { SocketService } from './core/services/sockets.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  private firstLoad = true;
  public reduceDesign: boolean = undefined;
  public noLayout: boolean = undefined;
  public pinkBreadcrumb = false;
  public whiteLayout = false;
  public largeScreen = false;
  public alert: Alert = new Alert();
  public loginLogoutRequested = false;
  public isNotProd = !environment.production;

  victoriaToken = 'Not Provided';

  configId = 'desktop';
  checkSub: Subscription;
  authSub: Subscription;
  isLogin = false;

  isMenuOpen = false;
  isUserMenuOpen = false;
  showVersion = environment.showVersion;
  version = environment.version;

  //INTERNAL
  currentClient: Client;
  currentUrl: string;
  clientId: string;
  connectionCalled = false;
  fullPath = '/';

  constructor(
    private getDatasService: GetDatasService,
    private trigger: TriggerService,
    public breadcrumb: BreadcrumbService,
    private cookie: CookieService,
    private oidcEventService: PublicEventsService,
    private translate: TranslateService,
    public translation: TranslationService,
    private storage: StorageService,
    private authService: OidcSecurityService,
    public orderService: OrderService,
    public jwtService: JWTService,
    private route: Router,
    private active: ActivatedRoute,
    private _rederer2: Renderer2,
    private gA: GoogleAnalyticService,
    private alertService: AlertService,
    @Inject(DOCUMENT) private _document: Document,
    private modalService: ModalService,
    public errorService: ErrorService,
    private logger: LoggerService,
    private fidelityService: FidelityService,
    public screen: ScreenService,
    private tracking: TrackingService,
    private socketServices: SocketService,
  ) {
    const localeCookie = this.cookie.get('v_locale');
    const demoCode = this.cookie.get('demoId') ?? '0';

    const delegateId = this.cookie.get('delegateId') ?? '0';
    const profile = this.cookie.get('victoria_profile') ?? '{}';

    // this.route.events.subscribe(event => {

    //   if (event instanceof NavigationEnd) {
    //     (window as any).rudderanalytics.page();
    //   }
    // });

    this.clientId = this.cookie.get('v_gi') ?? '';

    this.alertService.triggerAlert$.subscribe((data) => {
      if (data) {
        this.alert.message = data.message;
        this.alert.icon = data.icon;
        this.alert.position = data.position;
        this.alert.type = data.type;
        this.alert.urlLink = data.urlLink;
        this.alertService.display(data.id, data.duration);
      }
    });

    if (localeCookie && localeCookie != null && localeCookie != '')
      this.storage.languageCode = localeCookie;
    else if (!this.storage.languageCode || this.storage.languageCode == null) {
      this.storage.languageCode = getDefaultLangByUrl(window.location.origin);
      this.cookie.set(
        'v_locale',
        this.storage.languageCode,
        365,
        '/',
        getCookieDomainByLang(this.storage.languageCode),
      );
    }

    this.translate.use(this.storage.languageCode).subscribe((_) => {
      this.translation.initContextId(this.storage.languageCode);
      this.translation.loadJsonFiles();
      this.orderService.getGuestOrder(delegateId, demoCode, true);
    });

    this.checkAuthentication();

    this.oidcEventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.NewAuthenticationResult ||
            notification.type === EventTypes.IdTokenExpired ||
            notification.type === EventTypes.TokenExpired,
        ),
      )
      .subscribe(
        (data) => {
          const token = this.jwtService.getToken();

          if (
            data.type === EventTypes.IdTokenExpired ||
            data.type === EventTypes.TokenExpired
          ) {
            const validCookie = this.jwtService.hasTokenCookie();

            if (!validCookie) {
              this.authService.logoffLocal('desktop');
              this.authService.revokeAccessToken(token);
              this.jwtService.deleteTokenCookie();
              this.route.navigate(['/']);
            }
          } else if (data.type === EventTypes.NewAuthenticationResult) {
            if (data.value.isAuthenticated) {
              //SET APPROPRIATED COOKIE DURATION
              let storedValues: any = localStorage.getItem(this.configId);
              storedValues =
                storedValues != null ? JSON.parse(storedValues) : null;

              if (storedValues) {
                const accessTokenExpirationDate = new Date(
                  storedValues.access_token_expires_at,
                );
                this.jwtService.setTokenCookie(
                  this.translate.currentLang,
                  accessTokenExpirationDate,
                );
              }

              if (this.firstLoad) {
                this.firstLoad = false;
                //this.route.navigate(['/club/home']);
              }
            } else if (data.value.isRenewProcess) {
              this.route.navigate(['/']);
            }

            if (environment.datadog_active) {
              datadogRum.setUserProperty('clientId', this.clientId);
              datadogRum.setUserProperty('delegateId', delegateId);
              datadogRum.setUserProperty(
                'isConnected',
                (token != undefined).toString(),
              );
            }

            if (profile) {
              const jsonProfile = JSON.parse(profile) || '{}';
              if (jsonProfile.firstName)
                datadogRum.setUserProperty(
                  'name',
                  (jsonProfile.firstName ?? '') +
                    ' ' +
                    (jsonProfile.lastName ?? ''),
                );
              if (jsonProfile.email)
                datadogRum.setUserProperty('email', jsonProfile.email ?? '');
              if (jsonProfile.delegateId)
                datadogRum.setUserProperty(
                  'linkedDelegateId',
                  jsonProfile.delegateId,
                );
              if (jsonProfile.linkedDelegateId)
                datadogRum.setUserProperty(
                  'linkedDelegateId',
                  jsonProfile.linkedDelegateId,
                );

              // (window as any).posthog.identify(
              //   jsonProfile.id,
              //   { email: jsonProfile.email ?? "", name:  (jsonProfile.firstName ?? "") + " " + (jsonProfile.lastName ?? "") }
              // );

              // (window as any).rudderanalytics.identify(
              //   jsonProfile.email,
              //   {
              //      email: jsonProfile.email ,
              //      first_name:  jsonProfile.firstName ,
              //      last_name: jsonProfile.lastName  ,
              //      delegate_id : jsonProfile.delegateId
              //     }
              // );

              // (window as any)._cio.identify(
              //   {
              //      id: jsonProfile.email ,
              //      email: jsonProfile.email ,
              //      first_name:  jsonProfile.firstName ,
              //      last_name: jsonProfile.lastName  ,
              //      // eslint-disable-next-line @typescript-eslint/naming-convention
              //      delegate_id : jsonProfile.linkedDelegateId,
              //      cookie_delegate_id : delegateId,
              //      cookie_demo_id : demoCode
              //     }
              // );
            }
          }
        },
        (err) => {},
      );
  }

  ngOnInit() {
    // this.tracking.track_demoId_delegateId()
    const activationEnd$ = this.route.events.pipe(
      filter<ActivationEnd>((event) => event instanceof ActivationEnd),
    );
    const navigationEnd$ = this.route.events.pipe(
      filter<NavigationEnd>((event) => event instanceof NavigationEnd),
    );
    activationEnd$
      .pipe(
        map((event) => event.snapshot.routeConfig.path),
        buffer(navigationEnd$),
        map((paths) => paths.reverse().join('/')),
        distinctUntilChanged(),
      )
      .subscribe((path) => {
        console.log('Will add pageview : ', window.location.href);
        this.gA.GAAddPageView(window.location.href);
        //console.log("Navigation done on path : ", window.location.href);
      });

    this.orderService.cartLoaded$.subscribe((order) => {
      if (
        this.jwtService.isAuthenticated() &&
        order &&
        order.demoCode &&
        order.demoStatusId == 2
      )
        this.socketServices.connect(
          this.jwtService.getSubFromToken(),
          order.demoCode,
        );
    });

    this.currentUrl = window.location.pathname;

    this.active.queryParams.subscribe((params) => {
      if (params.redirectTo) {
        this.storage.redirectTo = params.redirectTo;
      }
    });

    this.trigger.requestLogin$.subscribe((d) => {
      if (d == true) {
        this.login();
      }
    });

    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.fullPath = event.url;
        if (event.url == '/logout') this.loginLogoutRequested = true;
        else this.loginLogoutRequested = false;
      }
    });

    this.getDatasService.clientData$.subscribe((data) => {
      if (data) {
        this.currentClient = data;
        this.storage.clientFirstName = this.currentClient.firstName;
        this.storage.registeredStatusId = this.currentClient.statusId;

        if (this.currentClient?.statusId) {
          this.getDatasService.updateClientStatusCookies(
            this.currentClient.statusId,
          );
        }

        // if (
        //   this.currentClient.contextId != this.translation.getContextId() &&
        //   this.translation.getContextId() != 0
        // )
        //   this.openRedirectionModal();
        //this.cookie.set("currentStatus", this.currentClient.statusId.toString(), 1/24, '/', getCookieDomainByLang(this.storage.languageCode));
      }
    });

    this.authService.isAuthenticated$.pipe(distinctUntilChanged()).subscribe(
      (d: AuthenticatedResult) => {
        const currentConfig = d.allConfigsAuthenticated.find(
          (x) => x.isAuthenticated == true,
        );
        if (!currentConfig) {
          this.jwtService.deleteTokenCookie();
          this.jwtService.resetRoles();
        } else if (!this.connectionCalled) {
          this.jwtService.initConnectedUserRoles();
          // this.fidelityService.syncCartWishlist(this.clientId, this.jwtService.getToken(), this.jwtService.getSubFromToken());
          // this.socketServices.connect(this.jwtService.getSubFromToken());
          this.getDatasService.getClientDatas(
            this.jwtService.getSubFromToken(),
            true,
          );
          this.getDatasService.getSummary(this.jwtService.getSubFromToken());
        }
      },
      (error) => {},
    );

    this.authService.userData$.subscribe(
      (d) => {
        // let userData = d.allUserData.find(x => x.configId == "desktop")?.userData;
        // if (userData && !this.getDatasService.summaryHasDatas()) {
        //   this.getDatasService.getClientDatas(userData.sub, true);
        //   this.getDatasService.getSummary(userData.sub);
        // }
      },
      (error) => {},
    );

    this.translation.contextId$.subscribe((res) => {
      if (res != 0)
        this.gA.createGAElements(res, this._rederer2, this._document);
    });

    this.modalService.displayCongratModal$.subscribe((value) => {
      if (value && value == true) {
        setTimeout((_) => {
          this.modalService.open('#congratulationsModal');
          this.storage.showCongratulation = false;
          this.modalService.setDisplayCongratulationModale(false);
        }, 5000);
      }
    });

    if (this.storage.showCongratulation == 'true')
      this.modalService.setDisplayCongratulationModale(true);
  }

  public ngAfterViewInit() {
    if (!this.storage.sandboxModalDismissed && this.isNotProd)
      this.modalService.openAgoraModal('sandbox-modal');
  }

  public login() {
    this.authService.authorize(this.configId, {
      customParams: {
        locale: getSSOLocale(this.storage.languageCode),
        back: getEshopUrlByLang(this.storage.languageCode),
      },
    });
  }

  private checkAuthentication() {
    this.storage.setTokenRenewing(true);
    if (this.authSub) this.authSub.unsubscribe();
    if (this.checkSub) this.checkSub.unsubscribe();

    let storedValues: any = localStorage.getItem(this.configId);
    if (storedValues && storedValues != null && storedValues != '') {
      storedValues = JSON.parse(storedValues);
      this.logger.warnData(
        '==> Identity Environment Match : ',
        storedValues?.authWellKnownEndPoints?.issuer.startsWith(
          environment.identity_url.substring(0, 20),
        ),
      );
      this.logger.warnData(
        '==> Global evaluation result : ',
        storedValues &&
          storedValues != null &&
          storedValues != '' &&
          storedValues?.authWellKnownEndPoints?.issuer &&
          !storedValues?.authWellKnownEndPoints?.issuer.startsWith(
            environment.identity_url.substring(0, 20),
          ),
      );
    }

    //Check Configuration
    if (
      storedValues &&
      storedValues != null &&
      storedValues != '' &&
      storedValues?.authWellKnownEndPoints?.issuer &&
      !storedValues?.authWellKnownEndPoints?.issuer.startsWith(
        environment.identity_url.substring(0, 20),
      )
    ) {
      this.logger.warn('==> Configuration Mismatch, cleaning state...');
      this.jwtService.cleanState();
      this.jwtService.deleteTokenCookie();
      window.location.reload();
    }

    this.checkSub = this.authService.checkAuth('desktop').subscribe(
      (auth: LoginResponse) => {
        this.storage.setTokenRenewing(false);
        if (auth && auth.isAuthenticated) {
          //prevent multiple call, call only once if connected
          this.jwtService.initConnectedUserRoles();
          // this.fidelityService.syncCartWishlist(this.clientId, this.jwtService.getToken(), this.jwtService.getSubFromToken());
          // this.socketServices.connect(this.jwtService.getSubFromToken());
          this.getDatasService.getClientDatas(
            this.jwtService.getSubFromToken(),
            true,
          );
          this.getDatasService.getSummary(this.jwtService.getSubFromToken());
          this.connectionCalled = true;
          //

          this.isLogin = true;
          storedValues = localStorage.getItem(this.configId);
          storedValues = storedValues != null ? JSON.parse(storedValues) : null;

          if (storedValues != null && storedValues.authnResult != undefined) {
            storedValues.authnResult.access_token = '';
            localStorage.setItem(this.configId, JSON.stringify(storedValues));
            const accessTokenExpirationDate = new Date(
              storedValues.access_token_expires_at,
            );
            this.jwtService.setTokenCookie(
              this.translate.currentLang,
              accessTokenExpirationDate,
            );
          }

          //Create access_token cookie HTTP only
          //STILL USEFULL??
          // this.cookie.set('Token', this.authService.getAccessToken(), 1 / 24, '/', getCookieDomainByLang(this.translate.currentLang), true);
          //this.route.navigate(['/club/home']);
        } else {
          this.isLogin = false;
          //STILL USEFULL??
          // this.cookie.delete('Token');
          this.jwtService.deleteTokenCookie();
        }
      },
      (error) => {},
    );
  }

  registerListeners() {
    // document.addEventListener('click', (e) => this.clickHandler(e));

    const clubElemList = Array.from(document.getElementsByClassName('club'));
    const megaMenuElems = Array.from(
      document.getElementsByClassName('mega-menu-content'),
    );

    clubElemList.forEach((elem) => {
      elem.addEventListener('mouseover', (e) => this.hoverMegaMenu(e));
    });

    megaMenuElems.forEach((megaElem) => {
      megaElem.addEventListener('mouseout', () =>
        this.hideAllMegaMenu(undefined, undefined),
      );
    });
  }

  @HostListener('document:click', ['$event'])
  clickHandler(e) {
    const menu = document.getElementsByClassName('menu-visible')[0];
    const menuButtonClicked = e.target.closest('.btn-menu');
    const menuMobileButtonClicked = e.target.closest('.menu-action');
    const menuDropDownLangueClicked = e.target.closest('.select-lang-area');
    const menuNavigable = e.target.closest('.navigable');
    const menuButtonClicked2 = e.target.closest('.dropbtn');

    const langMenu = document.getElementsByClassName('lang-options open')[0];
    const langMenuMobile = document.getElementsByClassName(
      'lang-options-mobile open',
    )[0];

    if (langMenu && !menuDropDownLangueClicked)
      langMenu.classList.remove('open');
    if (langMenuMobile && !menuDropDownLangueClicked)
      langMenuMobile.classList.remove('open');

    //DropDownHandler
    this.clickDropDownHandler(e);

    //close modal
    if (e.target.id === 'myModal' && e.target.style.display === 'flex') {
      e.target.style.display = 'none';
    }

    if (
      menuButtonClicked == null &&
      menuMobileButtonClicked == null &&
      menu &&
      !menuDropDownLangueClicked &&
      !menuNavigable
    ) {
      menu.classList.toggle('menu-visible');
      document.getElementsByTagName('main')[0].classList.toggle('fixedHeight');
      this.isMenuOpen = !this.isMenuOpen;
    } else if (
      menuButtonClicked == null &&
      menuMobileButtonClicked == null &&
      menu &&
      (menuDropDownLangueClicked || menuNavigable)
    ) {
    }
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   //location.reload()
  // }

  @HostListener('window:beforeunload', ['$event'])
  onleave(event) {
    console.log('clean connect to socket');
    this.storage.connectedToSocket = '';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.screen.isMobile) this.hideAllMegaMenu(undefined, undefined);
  }

  // @HostListener('window:load', ['$event'])
  // eventOnLoad(event) {
  //   // let menuBottom = document.getElementsByClassName('menu-links-bottom')[0];
  //   // if(menuBottom) menuBottom.addEventListener('mouseout', (e:any) => {
  //   //     this.hideAllMegaMenu(undefined,undefined);
  //   // });
  // }

  public updateMenuState($value) {
    this.isMenuOpen = $value;
  }

  public updateUserMenuState($value) {
    this.isUserMenuOpen = $value;
  }

  private clickDropDownHandler(e) {
    const dropdownClicked = e.target.closest('.actions-dropdown');

    if (dropdownClicked) {
      this.closeDropDown(dropdownClicked);
      dropdownClicked
        .getElementsByClassName('dropdown-content')[0]
        ?.classList.add('show');
      dropdownClicked
        .getElementsByTagName('svg')[0]
        ?.classList.add('drop-rotate');
    } else this.closeDropDown(undefined);
  }

  private closeDropDown(current) {
    const dropdowns = document.getElementsByClassName('actions-dropdown');
    Array.from(dropdowns).forEach((x) => {
      x.getElementsByClassName('dropdown-content')[0]?.classList.remove('show');
      x.getElementsByTagName('svg')[0]?.classList.remove('drop-rotate');
    });
  }

  private hoverMegaMenu(e) {
    const menuButtonClicked2 = e.target.parentElement.closest('.club');

    if (menuButtonClicked2) {
      const menu = menuButtonClicked2.children[1];
      const carret = menuButtonClicked2.children[0].children[1];
      const jumbo = document.getElementsByClassName('jumbo-shadow')[0];

      this.hideAllMegaMenu(menu, carret);

      if (menu && menu.classList.contains('block-menu')) {
      } //menu.classList.add('block-menu');
      else if (menu) {
        if (carret) carret.classList.add('rotate-carret');
        if (jumbo) jumbo.classList.add('visible');
        menu.classList.add('block-menu');
      }
    } else this.hideAllMegaMenu(undefined, undefined);
  }

  private hideAllMegaMenu(current, currentCarret) {
    const megaMenus = document.getElementsByClassName('mega-menu-content');
    const openCarrets = document.getElementsByClassName('carret-area');

    Array.from(megaMenus).forEach((item) => {
      if (item != current) item.classList.remove('block-menu');
    });

    Array.from(openCarrets).forEach((item) => {
      if (item != currentCarret) item.classList.remove('rotate-carret');
    });

    if (!current && !currentCarret) {
      const jumbo = document.getElementsByClassName('jumbo-shadow')[0];
      if (jumbo) jumbo.classList.remove('visible');
    }
  }

  private openRedirectionModal() {
    this.modalService.open('#redirectionModal');
    setTimeout((_) => {
      this.jwtService.deleteTokenCookie();
      this.authService.logoff('desktop');
      window.location.href =
        getEshopUrlByContext(this.currentClient.contextId) +
        '/' +
        this.currentClient.locale;
    }, 5000);
  }
}
