<app-page-title [mainTitle]="'BOOSTER.TITLE' | translate" [subTitle]="'BOOSTER.SUBTITLE' | translate"></app-page-title>
<div class="booster-container">
    <div class="share-link-area">
        <div class="share-link-card">
            <h1>{{'BOOSTER.SHARE.TITLE' | translate}}</h1>
            <p>{{'BOOSTER.SHARE.SUBTITLE' | translate}}</p>
            <div class="share-link-form">
              <label class="input-label">{{'BOOSTER.SHARE.LABEL' | translate}}</label>
              <div class="wrapped-input">
                <input type="text" placeholder="https://xxxxxxxxxxxxxx..." [(ngModel)]="referralLink" readonly>
                <svg  id="icon-copy-booster" class="display" (click)="copy()">
                  <use xlink:href="#icon-copy"></use>
                </svg>
                <svg viewBox="0 0 32 32" class="icon" id="icon-copy-booster-check">
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
              </div>
              <div class="cta-container">
                <button (click)="share()">
                  <label>{{'BOOSTER.SHARE.ACTION' | translate}}</label>
                  <svg>
                    <use xlink:href="#icon-share"></use>
                  </svg>
                </button>
              </div>
            </div>
        </div>
    </div>
    <div class="booster-followup">
      <div class="content">
        <h1>{{'BOOSTER.FOLLOWUP.TITLE' | translate}}</h1>
        <p *ngIf="!hasBoosters">{{'BOOSTER.FOLLOWUP.NODATA' | translate}}</p>
        <ng-container *ngIf="hasBoosters">
          <div class="cards">
            <app-booster-success [rank]="referralsData?.level1?.referralType ?? 1" [text]="'BOOSTER.FOLLOWUP.ACTION1' | translate" [total]="referralsData?.level1?.quantity??0" [points]="referralsData?.level1?.points??0"></app-booster-success>
            <app-booster-success [rank]="referralsData?.level2?.referralType ?? 2" [text]="'BOOSTER.FOLLOWUP.ACTION2' | translate" [total]="referralsData?.level2?.quantity??0" [points]="referralsData?.level2?.points??0"></app-booster-success>
            <app-booster-success [rank]="referralsData?.level3?.referralType ?? 3" [text]="'BOOSTER.FOLLOWUP.ACTION3' | translate" [total]="referralsData?.level3?.quantity??0" [points]="referralsData?.level3?.points??0" [amount]="referralsData?.level3?.wallet??0"></app-booster-success>
          </div>
          <app-booster-success [text]="'BOOSTER.FOLLOWUP.SUMMARY' | translate" [points]="referralsData?.totalPoints??0" [amount]="referralsData?.totalWallet??0"></app-booster-success>
        </ng-container>
      </div>
    </div>
    <div class="booster-advantages">
      <div class="content">
        <h1>{{'BOOSTER.ADVANTAGES.TITLE' | translate}}</h1>
        <div class="cards">
          <app-booster-objectif [rank]="1" [text]="'BOOSTER.ADVANTAGES.ACTION1' | translate" [bonus]="'BOOSTER.ADVANTAGES.XPOINTS' | translate: {X:25}"></app-booster-objectif>
          <app-booster-objectif [rank]="2" [text]="'BOOSTER.ADVANTAGES.ACTION2' | translate" [bonus]="'BOOSTER.ADVANTAGES.XPOINTS' | translate: {X:100}"></app-booster-objectif>
          <app-booster-objectif [rank]="3" [text]="'BOOSTER.ADVANTAGES.ACTION3' | translate" [bonus]="'BOOSTER.ADVANTAGES.XPOINTYJEWELS' | translate: {X:150, Y: 100 | agoraCurrencyInt}"></app-booster-objectif>
        </div>
        <a class="booster-conditions"  (click)="displayConditions()">{{"BOOSTER.ADVANTAGES.CONDITIONS" | translate}}</a>
        <div #conditions class="booster-conditions-box" [innerHTML]="'REFERRAL.PROGRAM' | translate"></div>
      </div>
    </div>
</div>
