import {ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ModalAction } from '../models/modalAction.model';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private _triggerAction$:Subject<ModalAction> = new Subject();
  public triggerAction$:Observable<ModalAction> = this._triggerAction$.asObservable();

  private _displayCongratModal$:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public displayCongratModal$:Observable<any> = this._displayCongratModal$.asObservable();

  //ON/OFF Error
  private _hasError$:Subject<boolean> = new Subject();
  public hasError$:Observable<boolean> = this._hasError$.asObservable();
  //Internal Related Code
  private _errorCode$:Subject<number> = new Subject();
  public errorCode$:Observable<number> = this._errorCode$.asObservable();

  private _reset$:Subject<any> = new Subject();
  public reset$:Observable<any> = this._reset$.asObservable();

  private _visibleModal$:BehaviorSubject<string> = new BehaviorSubject("");
  public visibleModal$:Observable<string> = this._visibleModal$.asObservable();



  private viewContainerRef: ViewContainerRef;
  modalRef: ComponentRef<any>[] = [];

  constructor(private resolver: ComponentFactoryResolver) {
  }


  public open(id){
    const modal = document.getElementById(id);
    if(modal) modal.style.display = 'flex';

    this.notifyModalIsOpening(id);
  }

  public close(id){
    const modal = document.getElementById(id);
    if(modal){
      modal.style.display = 'none';
      this.resetModal();
    }
  }

  public setDisplayCongratulationModale(value:any){
    this._displayCongratModal$.next(value);
  }

  public executeAction(action){
    this._triggerAction$.next(action);
  }

  public raiseError(code:number = 0){
    this._hasError$.next(true);
    //Code Nomemclature
    // - 1 - Demo code expired
    // - 3 - Demo code doesn't exists
    // - 12 - Voucher Used
    // - 13 - Voucher Expired
    // - 14 - Voucher Not Found
    if(code!=0) this._errorCode$.next(code);
  }

  public openActionsSheet(id:string = "actionsSheet"){
    const actionsSheet = document.getElementById(id);
    if(actionsSheet) {
      actionsSheet.style.display = 'flex';

      const child = actionsSheet.children[0] as HTMLElement;

      if(child) child.style.display = 'flex';
    }
  }

  public closeActionsSheet(id:string = "actionsSheet"){
    const actionsSheet = document.getElementById(id);
    if(actionsSheet) {
      actionsSheet.style.display = 'none';

      const child = actionsSheet.children[0] as HTMLElement;

      if(child) child.style.display = 'none';
    }
  }


  public openAgoraModal(id: string){
    /*const modal = document.getElementById('agora-modal-container');
    if(id) {
      const modalBody = document.getElementById(id) as any;
      if(modalBody) {
        modalBody.children[0].style.display = 'flex';
      }
    }
    modal.style.display = 'flex';*/

    const modal = document.getElementById(id);
    if(modal) {
      modal.style.display = 'flex';
      const child = modal.children[0] as HTMLElement;
      child.style.display = 'flex';

      this.notifyModalIsOpening(id);
    }



    // Add scroll icon
    const modalBlock = document.querySelector('.modal') as HTMLElement;
    const icon = document.querySelector('.scroll-icon') as HTMLElement;

    if (icon && (modalBlock.scrollTop + modalBlock.clientHeight) + 50 < modalBlock.scrollHeight) {
      icon.classList.add('show');
    } else if (icon) {
      icon.classList.remove('show');
    }
  }

  public closeAgoraModal(el: MouseEvent | string){
    if(typeof el === 'string'){
      const modal = document.getElementById(el);
      if(modal) {
        modal.style.display = 'none';
      }
    } else {
      const b = el.target as any;
      b.closest('#agora-modal-container').style.display = 'none';
    }

    this.notifyModalIsOpening('');
  }

  public checkAgoraModalIsOpen(id: string): boolean {
    const modalBody = document.getElementById(id) as any;
    return modalBody?.children[0].style.display === 'flex';

  }

  private resetModal(){
    this._reset$.next(true);
  }

  private notifyModalIsOpening(id){
    this._visibleModal$.next("");
    this._visibleModal$.next(id);
  }

}
