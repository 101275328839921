import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { CookieModule } from 'ngx-cookie';
import { BreadcrumComponent } from './breadcrum/breadcrum.component';
import { HeaderComponent } from './header/header.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { FooterComponent } from './footer/footer.component';
import { ComponentsModule } from '../components/components.module';
import { HeaderLightComponent } from './header-light/header-light.component';
import { TranslateModule } from '@ngx-translate/core';
import { CsrhighlightComponent } from './csrhighlight/csrhighlight.component';
import { GoToDemoBannerComponent } from './go-to-demo-banner/go-to-demo-banner.component';
import { PipeModule } from 'src/app/core/pipes/pipe.module';
import { SandboxBarComponent } from './sandbox-bar/sandbox-bar.component';

@NgModule({
    declarations: [
        BreadcrumComponent,
        HeaderComponent,
        HeaderLightComponent,
        JumbotronComponent,
        FooterComponent,
        GoToDemoBannerComponent,
        SandboxBarComponent,
        CsrhighlightComponent
    ],
    entryComponents: [],
    imports: [
        HttpClientModule,
        CookieModule.withOptions(),
        BrowserModule,
        RouterModule,
        ComponentsModule,
        TranslateModule,
        PipeModule
    ],
    exports: [
        BreadcrumComponent,
        HeaderComponent,
        HeaderLightComponent,
        JumbotronComponent,
        FooterComponent,
        GoToDemoBannerComponent,
        SandboxBarComponent,
        CsrhighlightComponent
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        }
    ],
    bootstrap: [],


})
export class ThemeModule {
    constructor() { }
}
