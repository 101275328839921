import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdressForm } from '../models/adressForm.model';
import { DataForm } from '../models/dataForm.model';
import { FidelityLogin } from '../models/fidelityLogin.model';
import { FidelityRegistration } from '../models/fidelityRegistration.model';
import { LocalOrderClient } from '../models/localOrderClient.model';
import { OrderDeliveryAddress } from '../models/orderDeliveryAddress.model';
import { CustomValidators } from '../utils/customValidators';
import { TranslationService } from './translation.service';
import { UserService } from './user.service';
import {AddBirthDate} from "../models/addBirthDate.model";

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  //TranslationService grant access to ContextId using locales +
  constructor(private translate: TranslationService, private userService:UserService) {
  }

  public getGenders() {
    return [
      { id: 1, value: 'GLOBAL.GENDER.MADAME' },
      { id: 2, value: 'GLOBAL.GENDER.MISTER' }
    ];
  }

  public getCountries() {

    const countries = [];
    switch (this.translate.getContextId()) {
      // case 1: countries.push({ id: 'FR', value: 'France', selected:true }); break;
      // case 2: countries.push({ id: 'BE', value: 'Belgique/België', selected:true }, { id: 'NL', value: 'Nederland' }, { id: 'LU', value: 'Luxembourg' }); break;
      // case 3: countries.push({ id: 'DE', value: 'Deutschland', selected:true }, { id: 'AT', value: 'Österreich' }); break;
      default: countries.push(
        { id: 'BE', value: 'Belgique/België', selected: this.translate.getContextId()==2 },
        { id: 'NL', value: 'Nederland' },
        { id: 'LU', value: 'Luxembourg' },
        { id: 'FR', value: 'France', selected: this.translate.getContextId()==1  },
        { id: 'DE', value: 'Deutschland', selected: this.translate.getContextId()==3 },
        { id: 'AT', value: 'Österreich' },
      ); break;
    }

    return countries;
  }

  public getLanguages() {

    const languages = [];

    switch (this.translate.getContextId()) {
      case 1: languages.push( { id: 'fr-fr', value: 'Français' }); break;
      case 2: languages.push({ id: 'fr-be', value: 'Français' },{ id: 'nl-be', value: 'Nederlands' }); break;
      case 3: languages.push({ id: 'de-de', value: 'Deutsch' },); break;
      default: languages.push(
        { id: 'fr-fr', value: 'Français (France)' },
        { id: 'fr-be', value: 'Français (Belgique)' },
        { id: 'nl-be', value: 'Nederlands' },
        { id: 'de-de', value: 'Deutsch' },
      ); break;
    }

    return languages;
  }

  public getDataForms(fb: FormBuilder, data: DataForm) {
    const form: FormGroup = fb.group({
      defaultAddressId: [data.defaultAddressId],
      gender: [data.gender],
      firstName: [data.firstName, [Validators.required]],
      lastName: [data.lastName, [Validators.required]],
      address1: [data.address1, [Validators.required]],
      address2: [data.address2],
      zipCode: [data.zipCode, [Validators.required]],
      city: [data.city, [Validators.required]],
      country: [data.country, [Validators.required]],
      mobile: [data.mobile, [Validators.required]],
      phone: [data.phone],
      langage: [data.langage],
      email: [data.email, [Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      birthDate: [data.birthDate],
    });

    return form;
  }

  public getAdressForms(fb: FormBuilder, data: AdressForm) {
    const form: FormGroup = fb.group({
      enterprise: [data.isEnterprise],
      adressName: [data.adresseName],
      gender: [data.gender],
      firstname: [data.firstname, [Validators.required]],
      lastname: [data.lastname, [Validators.required]],
      adresse1: [data.adresse1, [Validators.required]],
      adresse2: [data.adresse2],
      zip: [data.zip, [Validators.required]],
      city: [data.city, [Validators.required]],
      country: [data.country, [Validators.required]],
      gsm: [data.gsm, [Validators.required]],
      phone: [data.phone],
    });

    return form;
  }

  public getChangeEmailForms(fb: FormBuilder, userEmail) {
    const form: FormGroup = fb.group({
      email: [userEmail, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      newemail: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
    });

    return form;
  }

  public getFidelityRegistrationFormStep1(fb: FormBuilder, data: FidelityRegistration) {
    const form: FormGroup = fb.group({
      gender: [data.gender, [Validators.required]],
      firstName: [data.firstName, [Validators.required]],
      lastName: [data.lastName, [Validators.required]],
      birthDate: [data.birthDate, [Validators.required, CustomValidators.dateMinimum(new Date("1920-01-01").toDateString()), CustomValidators.dateMaximum((new Date()).toDateString())]],
      email: [data.email, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      password: [data.password, [Validators.required, Validators.pattern('^(?=(.*[0-9]){2,})(?=.*[a-z])(?=.*[A-Z])(.{8,})$')]],
    })

    return form;
  }

  public getFidelityRegistrationFormStep2(fb: FormBuilder, data: FidelityRegistration) {
    const form: FormGroup = fb.group({
      street: [data.street, [Validators.required]],
      zipCode: [data.zipCode, [Validators.required]],
      locality: [data.locality, [Validators.required]],
      countryCode: [data.countryCode, [Validators.required]],
      phone: [data.phone, [Validators.required]],
      optin: [data.optin, [Validators.requiredTrue]],
      newsletter: [data.newsletter],
    })

    return form;
  }

  public getFidelityLoginForm(fb: FormBuilder, data: FidelityLogin) {
    const form: FormGroup = fb.group({
      fidLoginEmail: [data.email, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      fidLoginPassword: [data.password, [Validators.required]],
    })

    return form;
  }

  public getDeliveryForm(fb: FormBuilder, data: OrderDeliveryAddress) {
    const form: FormGroup = fb.group({
      street: [data?.address1 || null, [Validators.required]],
      zipCode: [data?.zipCode|| null, [Validators.required]],
      city: [data?.city|| null, [Validators.required]],
      country: [data?.countryCode|| null, [Validators.required]],
      phone: [data?.mobile|| null, [Validators.pattern]]
    });

    return form;
  }

  public getBirthDateForm(fb: FormBuilder, data: AddBirthDate) {
    const form: FormGroup = fb.group({
      birthDate: [data?.birthDate, [Validators.required,, CustomValidators.dateMinimum(new Date("1920-01-01").toDateString()), CustomValidators.dateMaximum((new Date()).toDateString())]],
    });

    return form;
  }

  public checkFormAndDisplayError(formToCheck: FormGroup) {
    formToCheck.markAllAsTouched();
  }
}
