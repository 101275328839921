<div class="order-summary" *ngIf="resources">
  <h1 *ngIf="noProduct && !isReceipt">{{'DEMO.TUNNEL.AMOUNTTOPAY' | translate}}</h1>
  <h1 *ngIf="noProduct && isReceipt">{{'DEMO.TUNNEL.BILL' | translate}}</h1>
  <app-order-cart [orderItems]="order.orderItems" *ngIf="!noProduct"></app-order-cart>

  <div class="order-summary-footer">
    <div class="flex row j-space-between ai-center tr data-row">
      <div>{{resources.shoppingBag.subtotal}}:</div>
      <div class="amount">{{order.itemsTotal | agoraCurrency}}</div>
    </div>
    <div class="flex column" *ngIf="order.vouchersTotal>=0 && order.vouchers.length > 0">
      <div *ngIf="order.vouchers.length>0" class="flex column voucher-block vouchers">
        <ng-template ngFor let-voucher [ngForOf]="order.vouchers">
          <div class="voucher-container">
            <div class="flex row j-space-between ai-center tr lightbold">
              <div>{{'ORDER_TUNNEL.SUMMARY.VOUCHERS' | translate}}</div>
              <div class="amount">-{{voucher.amount | agoraCurrency}}</div>
            </div>
            <app-cart-voucher
              [voucher]="voucher"
              [demoCode]="demoCode"
              [isReceipt]="isReceipt"
              [order]="order"
              [disableDeleteVouchersWallet]="disableDeleteVouchersWallet"
              [isOrderValidation]="isOrderValidation"></app-cart-voucher>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="flex column data-row" *ngIf="order.walletAmount>0">
      <div class="flex row j-space-between ai-center tr lightbold">
        <div>{{'ORDER_TUNNEL.SUMMARY.WALLET' | translate}}</div>
        <div class="amount">&nbsp; - {{order.walletAmount | agoraCurrency}}</div>
      </div>
      <div *ngIf="order.walletAmount>0 && !isReceipt && !disableDeleteVouchersWallet" class="flex column voucher-block">
        <div class="cart-voucher flex ai center j-space-between">
          <div class="cart-voucher-code flex ai-center">
            {{'ORDER_TUNNEL.SUMMARY.WALLET.CANCEL' | translate}}
          </div>
          <div class="cart-voucher-action flex ai-center" (click)="removeWalletUsageFromOrder()">
            <svg viewBox="0 0 32 32" class="icon trash">
              <use xlink:href="#icon-trash"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>


    <div class="flex row j-space-between ai-center tr data-row">
      <div *ngIf="!isPublicAccess && !isOrderValidation">{{resources.shoppingBag.shippingLabel}}:</div>
      <div *ngIf="isPublicAccess || isOrderValidation">{{"DEMO.ORDER.SUMMARY.DELIVERY" + deliveryId | translate}}:</div>

      <div *ngIf="deliveryId==1 && step>=2"><a data-testId="delivery-fees-amount" (click)="modal.open('#orderDelivery')">{{ 0 | agoraCurrency}}</a></div>
      <div *ngIf="deliveryId==1 && step<2"><a (click)="modal.open('#orderDelivery')">{{resources.shoppingBag.shippingButtonLabel}}</a></div>
      <div *ngIf="deliveryId==10 && step"><a data-testId="delivery-fees-amount" (click)="modal.open('#orderDelivery')">{{order.deliveryFee | agoraCurrency}}</a></div>
      <div *ngIf="noProduct && !step && deliveryId==1"><a data-testId="delivery-fees-amount" (click)="modal.open('#orderDelivery')">{{ 0 | agoraCurrency}}</a></div>
      <div *ngIf="noProduct && !step && deliveryId==10"><a data-testId="delivery-fees-amount" (click)="modal.open('#orderDelivery')">{{order.deliveryFee | agoraCurrency}}</a></div>
    </div>
    <div>
      <div class="flex row j-space-between ai-center tr">
        <div class="total">{{resources.shoppingBag.categoriesLabel}}:</div>
        <div class="amount">{{order.totalToPay | agoraCurrency}}</div>
      </div>

      <div class="order-actions" *ngIf="(step === 3) && selectedMethod && footerLocales">
        <div class="free-amount" *ngIf="order.orderItemsCount > 0 && (order.vouchersTotal - (order.itemsTotal - order.walletAmount) > 0)">
          <div class="flex row j-space-between">
            <div>
              <div class="icon-circle flex center-all">
                <svg viewBox="0 0 32 32" class="icon billet">
                  <use xlink:href="#icon-bank-billet"></use>
                </svg>
              </div>
            </div>
            <p [innerHTML]="'ORDER_TUNNEL.SUMMARY.VOUCHERXGENERATED' | translate: {'X': order.voucherAmountToGenerate | agoraCurrency}"></p>
          </div>
        </div>

        <div>
          <div class="payment-description warning flex row gap-10" *ngIf="!isDipCheck">
            <svg viewBox="0 0 32 32" class="icon billet">
              <use xlink:href="#icon-warning"></use>
            </svg>
            <p (click)="goToDIPBox()" [innerHTML]="'ORDER_TUNNEL.SUMMARY.DIPCHECK' | translate:{'X': dataService.filter(footerLocales.footerBottomNav[2].navLink), 'Y': dataService.filter(footerLocales.footerBottomNav[1].navLink)} "></p>
          </div>
        </div>
        <div class="" *ngIf="order.totalToPay>0">
          <button class="btn btn-primary j-center save-tunnel-button amount"
                  *ngIf="!demoCode"
                  (click)="saveTunnelDatas()" [disabled]="operationPending || disableShopButton">
            <div *ngIf="operationPending" class="loader loader-mini"></div>
            {{'ORDER_TUNNEL.SUMMARY.BUYNOW' | translate}}
          </button>
          <button class="btn btn-primary j-center save-tunnel-button amount"
                  *ngIf="demoCode && (this.screen.isDesktop || (this.screen.isTablet && this.screen.isLandscape))"
                  (click)="saveTunnelDatas()" [disabled]="operationPending || disableShopButton">
            <div *ngIf="operationPending" class="loader loader-mini"></div>
            {{'ORDER_TUNNEL.SUMMARY.BUYNOW' | translate}}
          </button>
          <div>
            <div class="payment-description">
              <p [innerHTML]="'ORDER_TUNNEL.SUMMARY.CGV' | translate:{'X': dataService.filter(footerLocales.footerBottomNav[2].navLink), 'Y': dataService.filter(footerLocales.footerBottomNav[1].navLink)} "></p>
            </div>
          </div>
        </div>
        <div class="" *ngIf="order.orderItemsCount>0">
          <div>
            <div class="order-actions" *ngIf="step==3 && selectedMethod && (order.totalToPay <= 0) && (this.screen.isDesktop || (this.screen.isTablet && this.screen.isLandscape))">
              <button class="btn btn-primary j-center save-tunnel-button"
                      (click)="saveTunnelDatasWithoutPayment()" [disabled]="operationPending || disableShopButton">
                {{'ORDER_TUNNEL.SUMMARY.VALIDATEORDER' | translate}}
                <div *ngIf="operationPending" class="loader loader-mini"></div>
              </button>
              <div>
                <div class="payment-description">
                  <p [innerHTML]="'ORDER_TUNNEL.SUMMARY.CGV' | translate:{'X': dataService.filter(footerLocales.footerBottomNav[2].navLink), 'Y': dataService.filter(footerLocales.footerBottomNav[1].navLink)} "></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex row j-center" *ngIf="(step === 1) && demoCode && isDemoCart && !isOrderManage && !isOrderValidation">
        <button class="btn btn-primary j-center save-tunnel-button width100"
                (click)="nextStep()" [disabled]="operationPending">
          <label *ngIf="step === 1">{{'DEMO.TUNNEL.ACTION.VALIDATEVOUCHERS' | translate}}</label>
          <div *ngIf="operationPending" class="loader loader-mini"></div>
        </button>
      </div>
      <div class="flex row j-center" *ngIf="(step === 1) && !isDemoCart && order.delegateId && order.delegateId!=''">
        <button class="btn btn-primary j-center save-tunnel-button width100"
                (click)="nextStep()" [disabled]="operationPending">
          <label *ngIf="step === 1">{{'DEMO.TUNNEL.ACTION.VALIDATEVOUCHERS' | translate}}</label>
          <div *ngIf="operationPending" class="loader loader-mini"></div>
        </button>
      </div>

      <div class="flex row j-center test" *ngIf="noProduct && !isReceipt && !isOrderManage && !hiddeDeliveryButton && !hiddeButton">
        <button class="btn btn-primary j-center save-tunnel-button width100"
                (click)="goToDelivery()" [disabled]="operationPending || this.order.orderItemsCount==0">
          <label *ngIf="validationStep === validationStepEnum.IS_VOUCHER">
            {{'DEMO.TUNNEL.ACTION.VALIDATE' | translate}}
          </label>
          <label *ngIf="validationStep === validationStepEnum.IS_DELIVERY || validationStep === validationStepEnum.IS_DIP">
            {{'DEMO.TUNNEL.ACTION.CONFIRMCHOICE' | translate}}
          </label>
          <label *ngIf="validationStep === validationStepEnum.IS_CONFIRMATION">
            {{'DEMO.TUNNEL.ACTION.CONFIRMORDER' | translate}}
          </label>
          <div *ngIf="operationPending" class="loader loader-mini"></div>
        </button>
      </div>
    </div>
  </div>

  <app-modal id="orderDelivery" [title]="resources.popupShippingOptions.mainTitle" template="info"></app-modal>
  <app-modal-delivery-payment-confirmation *ngIf="order" (confirm)="doConfirm($event)" ></app-modal-delivery-payment-confirmation>
</div>
