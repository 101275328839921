import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { DemoService } from '../../../../features/agora/demo/services/demo.service';
import { OrderActionEnum } from '../../../../features/agora/demo/enums/order-action.enum';
import { ClientOrder } from "../../../../core/models/clientOrder.model";
import { PublicOrderService } from 'src/app/core/services/publicOrder.service';
import { ScreenService } from 'src/app/core/services/screen.service';
import { environment } from 'src/environments/environment';
import { getApiUrlFromOrigin } from 'src/app/core/utils/filter.utils';

@Component({
  selector: 'app-optin-dip-box',
  templateUrl: './optin-dip-box.component.html',
  styleUrls: ['./optin-dip-box.component.scss'],
})
export class OptinDipBoxComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() resources: any;
  @Input() order: ClientOrder;
  @Input() contextId: number;
  @Input() demoCode: string;
  @Input() selectedMethod: string;
  @Input() isPublicAccess = false;
  @Output() setIsDipCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  orderActionEnum = OrderActionEnum;

  isDipChecked = false;

  constructor(
    private demoService: DemoService,
    private publicOrderService: PublicOrderService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private screen:ScreenService
  ) {
  }

  ngOnInit() {

    //console.log("Optin Dip Box Init");

    if (this.order && this.order.flowStatuses?.dip.status === 10 || this.order && this.order.flowStatuses?.dip.status === 11) {
      this.isDipChecked = true;
    } else {
      this.isDipChecked = false;
    }

    this.setIsDipCheck.emit(this.isDipChecked);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.order && changes.order?.currentValue?.flowStatuses?.dip.status == 0) {
      this.isDipChecked = false;
      this.setIsDipCheck.emit(this.isDipChecked);
    } else if(changes && changes.order && (changes.order?.currentValue?.flowStatuses?.dip.status == 10 || changes.order?.currentValue?.flowStatuses?.dip.status == 11)){
      this.isDipChecked = true;
      this.setIsDipCheck.emit(this.isDipChecked);
    }
  }


  ngAfterViewInit() {
    // const element = this.elementRef.nativeElement.querySelector('a');
    const jsElement = this.elementRef.nativeElement.querySelector('a');
    const url = this.getDipUrl(this.demoCode, this.order.clientSub, this.order.resolvedId);

    if(this.screen.isMobile) jsElement?.setAttribute("download", "victoria-dip-" + this.order.resolvedId + ".pdf");
    if(this.screen.isDesktop) jsElement?.setAttribute("target", "_blank");
    jsElement?.setAttribute("href", url);
  }

  private getDipUrl(demoCode:string, clientGuid:string, resolvedId:string){
    return `${getApiUrlFromOrigin()}/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/dip`;
  }

  setIsCheck(value: boolean) {
    if (this.demoCode && this.order && !this.isPublicAccess) {
      this.demoService.postClientGuestOrderAction(value ? this.orderActionEnum.DIP_VALIDATED_BY_CLIENT : this.orderActionEnum.DIP_UNVALIDATE, this.demoCode, this.order.clientSub).subscribe(res => {
        if (res) {
          this.setIsDipCheck.emit(value);
        }
      });
    } else if (this.demoCode && this.order && this.isPublicAccess) {
      this.publicOrderService.sendClientAction(this.order.resolvedId, this.order.clientSub, value ? this.orderActionEnum.DIP_VALIDATED_BY_CLIENT : this.orderActionEnum.DIP_UNVALIDATE).subscribe(result => {
        if (result) this.setIsDipCheck.emit(value);
      });
    }


  }
}
