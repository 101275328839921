<div *ngIf="screen.isDesktop" class="jumbo-shadow"></div>
<div class="banner-wrapper">
  <ng-container *ngFor="let item of craftedHighlight, let i = index">
    <app-csrhighlight [highlight]="item.displayText" [highlighticon]="item.displayIcon ? item.displayIcon[0]?.url : undefined" [highlightlink]="item.clickLinks && item.clickLinks.length ? item.clickLinks[0].url : undefined" [current]="currentElement==i && craftedHighlight.length>1" [alone]="craftedHighlight.length==1"></app-csrhighlight>
  </ng-container>
</div>
<div class="header-wrapper">
  <header class="desktop">
    <div class="header-content">
      <div class="header-lv1">
        <div class="logo-container">
          <div class="logo"> <a [href]="getLogoUrl()"><img loading="lazy" src="/assets/images/logo.svg"
                alt="Logo Victoria"></a> </div>
        </div>
        <div class="menu-links-container">
          <div class="menu-links-top">
            <div class="nav-container">
              <nav>
                <ul> <ng-template *ngIf="headerLocales" ngFor let-link [ngForOf]="headerLocales.topNav">
                    <li><a href="{{dataService.filter(link.navLink)}}">{{link.navLabel}}</a>
                    </li>
                  </ng-template>
                  <li>
                    <div class="select-lang-area">
                      <div class="select-lang" id="#currentCountry" (click)="openCountries()">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use id="flag" [attr.xlink:href]="langSettings.flag">
                              </use>
                            </svg>
                          </div>
                          <div class="country-name">
                            {{langSettings.country}}
                          </div>
                        </div>
                        <div class="carret-area">
                          <svg viewBox="0 0 32 32">
                            <use xlink:href="#icon-arrow-right">
                            </use>
                          </svg>
                        </div>
                      </div>
                      <div class="lang-options">
                        <div class="select-lang" (click)="changeLangage('nl_be')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-be">
                                </use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{'current':currentRegion('nl_be')}">
                              Belgïe
                              (NL)
                            </div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('nl_be', 'nl_nl')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-nl">
                                </use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{'current':currentRegion('nl_nl')}">
                              Nederland
                              (NL)
                            </div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('fr_be')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-be">
                                </use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{'current':currentRegion('fr_be')}">
                              Belgique
                              (FR)
                            </div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('fr_fr')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-fr">
                                </use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{'current':currentRegion('fr_fr')}">
                              France
                              (FR)
                            </div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('de_de')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-de">
                                </use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{'current':currentRegion('de_de')}">
                              Deutschland
                              (DE)
                            </div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('de_de', 'de_at')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-at">
                                </use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{'current':currentRegion('de_at')}">
                              Österreich
                              (DE)
                            </div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('fr_be', 'fr_lu')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-lu">
                                </use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{'current':currentRegion('fr_lu')}">
                              Luxemburg
                              (FR)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="menu-links-bottom">
            <div class="nav-container">
              <nav>
                <ul> <ng-template *ngIf="headerLocales" ngFor let-item [ngForOf]="headerLocales.mainNav">
                    <li class="club"> <a class="dropbtn mega-menu"
                        [href]="dataService.filter(item.navLink??item.mainLink)">
                        <div class="jumbolink">
                          {{item.navLabel}}
                        </div>
                        <div class="carret-area">
                          <svg viewBox="0 0 32 32">
                            <use xlink:href="#icon-arrow-right">
                            </use>
                          </svg>
                        </div>
                      </a>
                      <div class="content no-d mega-menu-content" id="mega-menu-club-content">
                        <div class="container">
                          <div class="row flex" [class.mega-menu-limits]="!item.dropdownImage">
                            <div *ngIf="item.dropdownTitle"
                              class="flex-1 flex column center-all mega-content-card-title">
                              <div class="flex column">
                                <h1 class="mega-title">{{item.dropdownTitle}}
                                </h1>
                                <div class="fit">
                                  <app-wavedlink *ngIf="item.mainLink" [externalUrl]="dataService.filter(item.mainLink)"
                                    linkText="{{item.buttonLabel}}">
                                  </app-wavedlink>
                                </div>
                              </div>
                            </div>
                            <ng-template *ngIf="item.children" ngFor let-child [ngForOf]="item.children">
                              <div class="flex-1 flex column mega-content-card lastOption extraMargin">
                                <p class="lightbold menu-title">
                                  {{child.columnTitle}}
                                </p>
                                <ul class="flex column">
                                  <ng-template *ngIf="child.children" ngFor let-link [ngForOf]="child.children">
                                    <li class="bottom-gap">
                                      <a href="{{dataService.filter(link.navLink)}}">{{link.navLabel}}</a>
                                    </li>
                                  </ng-template>
                                </ul>
                              </div>
                            </ng-template>
                            <div *ngIf="item.dropdownImage" class="flex-2 img-container">
                              <img loading="lazy" src="{{item.dropdownImage[0].url}}" width="80%"
                                title="{{item.dropdownImage[0].filename}}" alt="{{item.dropdownImage[0].filename}}">
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ng-template> </ul>
              </nav>
            </div>
            <div class="buttons-container" *ngIf="headerLocales">
              <nav>
                <ul>
                  <li>
                    <div class="icon-button" (click)="search()"> <svg viewBox="0 0 32 32" class="icon">
                        <use attr.xlink:href="#icon-{{headerLocales.iconsNav[0].iconId}}">
                        </use>
                      </svg>
                      <span class="nowrap">{{headerLocales.iconsNav[0].label}}</span>
                    </div>
                  </li>
                  <li [class.club]="isUserLogged">
                    <div class="icon-button dropbtn mega-menu">
                      <svg *ngIf="!isUserLogged" (click)="checkEnvironnementAndLogin()" viewBox="0 0 32 32"
                        class="icon">
                        <use attr.xlink:href="#icon-{{headerLocales.iconsNav[1].iconId}}">
                        </use>
                      </svg>
                      <app-avatar data-testId="avatar-desktop" *ngIf="isUserLogged"
                        [firstname]="currentClient?.firstName??''" [lastname]="currentClient?.lastName??''"
                        (click)="navigateToHome()">
                      </app-avatar>
                      <span class="nowrap" *ngIf="!isUserLogged">{{headerLocales.iconsNav[1].label}}</span>
                      <span class="nowrap" *ngIf="isUserLogged">{{headerLocales.iconsNav[2].label}}</span>
                    </div>
                    <div *ngIf="isUserLogged" class="content no-d mega-menu-content mega-menu-icon"
                      id="mega-menu-club-content">
                      <div class="container">
                        <div class="row flex">
                          <div class="flex-1 flex column mega-content-card-title-login center-all ">
                            <div class="flex column">
                              <h1 class="welcome_user">
                                {{'GLOBAL.WELCOMEX'
                                |
                                translate:{'X':
                                storage.clientFirstName}
                                }}
                              </h1>
                              <h1 class="welcome_message">{{'MENU.FIDELITY.TITLE'
                                |
                                translate}}
                              </h1>
                              <div *ngIf="(demoService.hasDemo$ | async) && demoStatusId==2 && isDemoClient"
                                class="cta-container primary flex row ">
                                <button (click)="goToDemo()" class="btn btn-primary flex party-button j-center gap-10">
                                  <svg viewBox="0 0 32 32" class="icon">
                                    <use xlink:href="#icon-party-rock">
                                    </use>
                                  </svg>
                                  {{"DEMO.JOINUS" | translate}}
                                  <svg viewBox="0 0 32 32" class="icon">
                                    <use xlink:href="#icon-arrow-right-long">
                                    </use>
                                  </svg>
                                </button>
                              </div>
                              <ul class="flex column">
                                <li (click)="logout()">
                                  <a class="block">
                                    <div class="flex ai-center">
                                      <svg viewBox="0 0 32 32" class="icon">
                                        <use xlink:href="#disconnect">
                                        </use>
                                      </svg>
                                    </div>
                                    <div class="flex ai-center">
                                      <p> {{'GLOBAL.DISCONNECT'
                                        |
                                        translate}}
                                      </p>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="flex-2 flex column mega-content-card lastOption">
                            <p class="lightbold menu-title">
                              {{'MENU.FIDELITY.SUBTITLE'
                              |
                              translate}}
                            </p>
                            <div class="flex row">
                              <ul class="flex column auto-height">
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" data-testId="home-link"
                                    [routerLink]="'/' + locale + '/club/home'">{{'MENU.HOME'
                                    |
                                    translate}}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/club/point-encoding'">{{'MENU.POINTENCODING'
                                    |
                                    translate}}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/club/status'">{{'MENU.STATUS'
                                    |
                                    translate}}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/club/wallet'">{{'MENU.WALLET'
                                    |
                                    translate}}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/club/orders'">{{'MENU.ORDERS.ORDERS'
                                    |
                                    translate}}</a>
                                </li>
                              </ul>
                              <ul class="flex column auto-height">
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/myvictoria/demos'">{{'MENU.DEMO'
                                    |
                                    translate}}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/club/delegate'">{{'MENU.DELEGATE'
                                    |
                                    translate}}</a>
                                </li>
                                <!-- <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/club/point-evolution'">{{'MENU.POINTS'
                                    |
                                    translate}}</a>
                                </li> -->
                                <!-- <li
                                                                                                                                        class="bottom-gap">
                                                                                                                                        <a (click)="hideAllMegaMenu(null, null)"
                                                                                                                                                [routerLink]="'/' + locale + '/club/client-code'">{{'MENU.CLIENT_CODE'
                                                                                                                                                |
                                                                                                                                                translate}}</a>
                                                                                                                                </li> -->
                                <li class="bottom-gap" *ngIf="(jwt.hasDelegateRole$ | async) === false">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/club/referral'">{{'MENU.BOOSTER' | translate}}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + locale + '/club/profile'">{{'MENU.PROFILE' | translate}}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="icon-button" [routerLink]="['/' + locale + '/public/wishlist']">
                      <div *ngIf="wishlistItemsCount>0" class="cart-badge">
                        {{wishlistItemsCount}}
                      </div> <svg viewBox="0 0 32 32" class="icon">
                        <use attr.xlink:href="#icon-{{headerLocales.iconsNav[3]?.iconId}}">
                        </use>
                      </svg>
                      <span class="nowrap">{{headerLocales.iconsNav[3]?.label}}</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-button" [routerLink]="['/' + locale + '/public/cart']">
                      <div *ngIf="orderItemsCount>0" class="cart-badge">
                        {{orderItemsCount}}
                      </div> <svg viewBox="0 0 32 32" class="icon">
                        <use attr.xlink:href="#icon-{{headerLocales.iconsNav[4]?.iconId}}">
                        </use>
                      </svg>
                      <span class="nowrap">{{headerLocales.iconsNav[4]?.label}}</span>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <header class="mobile">
    <div class="header-content">
      <div class="header-lv1">
        <div class="logo-container">
          <div class="logo"> <a [href]="getLogoUrl()"> <img loading="lazy" src="/assets/images/m-logo.svg"
                alt="Logo Victoria"></a> </div>
        </div>
        <div class="buttons-container"> <a class="d-only" (click)="search()">
            <div class="icon-button"> <svg viewBox="0 0 21 21" class="icon">
                <use xlink:href="#icon-search"></use>
              </svg> </div>
          </a>
          <div data-testId="avatar-mobile" class="more-button" (click)="showUserMenu()">
            <div class="icon-button"> <svg *ngIf="!isUserLogged" viewBox="0 0 32 32" class="icon">
                <use xlink:href="#icon-members"></use>
              </svg> <app-avatar *ngIf="isUserLogged" [firstname]="currentClient?.firstName??''"
                [lastname]="currentClient?.lastName??''"></app-avatar>
            </div>
          </div> <a [routerLink]="['/' + locale + '/public/wishlist']">
            <div class="icon-button">
              <div *ngIf="wishlistItemsCount>0" class="cart-badge">
                {{wishlistItemsCount}} </div> <svg viewBox="0 0 31 29" class="icon peach">
                <use xlink:href="#icon-wishlist"></use>
              </svg>
            </div>
          </a><a [routerLink]="['/' + locale + '/public/cart']">
            <div class="icon-button">
              <div *ngIf="orderItemsCount>0" class="cart-badge">
                {{orderItemsCount}} </div> <svg viewBox="0 0 21 21" class="icon peach">
                <use xlink:href="#icon-bag"></use>
              </svg>
            </div>
          </a>
          <div class="more-button" (click)="showSideMenu()">
            <div *ngIf="!isMenuOpen" class="icon-button menu-action icon-menu"> <svg viewBox="0 0 32 32"
                class="icon peach">
                <use xlink:href="#icon-menu"></use>
              </svg> </div>
            <div *ngIf="isMenuOpen" class="icon-button menu-action icon-close"> <svg viewBox="0 0 20 20"
                class="icon peach">
                <use xlink:href="#icon-close"></use>
              </svg> </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</div> <!-- SIDE MENU -->
<div class="side-menu">
  <!-- <div *ngIf="isUserLogged" class="flex column club-info">
                <h1 class="peach">{{'GLOBAL.WELCOMEX' | translate:{'X': storage.clientFirstName} }}</h1>
                <h1>{{'MENU.FIDELITY.TITLE' | translate}}</h1>

                <div class="cta-container primary flex row ">
                        <button class="btn btn-primary flex party-button">
                                <svg viewBox="0 0 32 32" class="icon">
                                        <use xlink:href="#icon-party-rock"></use>
                                </svg>
                                Démo en cours! Rejoins-nous...
                                <svg viewBox="0 0 32 32" class="icon">
                                        <use href="#icon-arrow-right-long"></use>
                                </svg>
                        </button>
                </div>

                <ul class="flex column club-disconnect height-auto center">
                        <li (click)="logout()"> <a [routerLink]="'/' + locale" class="block"> <svg viewBox="0 0 32 32"
                                                class="icon">
                                                <use xlink:href="#disconnect"></use>
                                        </svg> {{'GLOBAL.DISCONNECT' | translate}} </a> </li>
                </ul>
        </div> -->
  <ul class="navigable noPadding padding25">
    <!-- <li *ngIf="isUserLogged">
                        <a class="flex j-space-between ai-center club-section" (click)="displayMenu(99)">
                                <div>{{'MENU.FIDELITY.SUBTITLE' | translate}}</div>
                                <div> <svg viewBox="0 0 32 32">
                                                <use xlink:href="#icon-arrow-right-long"></use>
                                        </svg> </div>
                        </a>
                        <div [ngClass]="'mega-mobile mega_99 white-container'">
                                <div class="back_menu">
                                        <div class="arrow_link">
                                                <div class="arrow_container peach" (click)="closeMenu(99)"> <svg
                                                                viewBox="0 0 32 32">
                                                                <use xlink:href="#icon-arrow-left-long"></use>
                                                        </svg> </div>
                                        </div>
                                        <div class="back_title">{{'MENU.FIDELITY.SUBTITLE' | translate}}</div>
                                </div>
                                <div class="container">
                                        <div class="column flex">
                                                <div class="flex-1 flex column mega-content-card">
                                                        <ul class="flex column" (click)="showSideMenu()">
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/home'">
                                                                                <div>{{'MENU.HOME' | translate}}</div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/point-encoding'">
                                                                                <div>{{'MENU.POINTENCODING' |
                                                                                        translate}}</div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/status'">
                                                                                <div>{{'MENU.STATUS' | translate}}</div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/wallet'">
                                                                                <div>{{'MENU.WALLET' | translate}}</div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/orders'">
                                                                                <div>{{'MENU.ORDERS.ORDERS' |
                                                                                        translate}}</div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/delegate'">
                                                                                <div>{{'MENU.DELEGATE' | translate}}
                                                                                </div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/point-evolution'">
                                                                                <div>{{'MENU.POINTS' | translate}}</div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/profile'">
                                                                                <div>{{'MENU.PROFILE' | translate}}
                                                                                </div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/myvictoria/demos'">
                                                                                <div>{{'MENU.DEMO' | translate}} </div>
                                                                        </a> </li>
                                                                <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/client-code'">
                                                                                <div>{{'MENU.CLIENT_CODE' | translate}}
                                                                                </div>
                                                                        </a> </li>
                                                        </ul>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </li>  -->
    <ng-template *ngIf="headerLocales" ngFor let-item let-i="index" [ngForOf]="headerLocales.mainNav">
      <li> <a class="flex j-space-between ai-center" (click)="displayMenu(i)">
          <div>{{item.navLabel}}</div>
          <div> <svg viewBox="0 0 32 32">
              <use xlink:href="#icon-arrow-right-long"></use>
            </svg> </div>
        </a>
        <div [ngClass]="'mega-mobile mega_' + i" [class.white-container]="item.children && item.children.length>1">
          <div class="back_menu">
            <div class="arrow_link">
              <div class="arrow_container peach" (click)="closeMenu(i)"> <svg viewBox="0 0 32 32">
                  <use xlink:href="#icon-arrow-left-long"></use>
                </svg> </div>
            </div>
            <div class="back_title">{{item.navLabel}}</div>
          </div>
          <div class="container">
            <div class="column flex">
              <div *ngIf="item.dropdownTitle"
                class="flex-1 flex column ac-center j-center mega-content-card-title white-container ai-stretch">
                <div class="flex column">
                  <div *ngIf="item.dropdownImage" class="mobile-img-container"> <a
                      [href]="dataService.filter(item.mainLink)">
                      <figure> <img loading="lazy" src="{{item.dropdownImage[0].url}}" width="100%"
                          title="{{item.dropdownImage[0].filename}}" alt="{{item.dropdownImage[0].filename}}">
                      </figure>
                    </a> </div>
                  <h1 class="descriptif">{{item.dropdownTitle}}
                  </h1>
                  <div class="fit"> <app-wavedlink *ngIf="item.mainLink"
                      [externalUrl]="dataService.filter(item.mainLink)" linkText="{{item.buttonLabel}}">
                    </app-wavedlink> </div>
                </div>
              </div>
              <div *ngIf="item.navLink" class="flex-1 flex column mega-content-card">
                <ul class="flex column navLink">
                  <li> <a class="flex j-space-between ai-center" href="{{dataService.filter(item.navLink)}}">
                      <div>{{item.navLabel}}</div>
                    </a> </li>
                </ul>
              </div> <ng-template *ngIf="item.children" ngFor let-child [ngForOf]="item.children">
                <div class="flex-1 flex column mega-content-card"
                  [class.pink-container]="item.children && item.children.length==1">
                  <p class="lightbold menu-title">
                    {{child.columnTitle}}</p>
                  <ul class="flex column"> <ng-template *ngIf="child.children" ngFor let-link
                      [ngForOf]="child.children">
                      <li> <a class="flex j-space-between ai-center" href="{{dataService.filter(link.navLink)}}">
                          <div>{{link.navLabel}}
                          </div>
                        </a> </li>
                    </ng-template> </ul>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </li>
    </ng-template>
  </ul>
  <div class="eshop-mobile flex column navigable">
    <div class="link-container flex row">
      <div class="flex column flex-1">
        <ul> <ng-template *ngIf="headerLocales" ngFor let-link [ngForOf]="headerLocales.topNav">
            <li><a href="{{dataService.filter(link.navLink)}}">{{link.navLabel}}</a>
            </li>
          </ng-template>
          <li>
            <div class="lang-container">
              <ul>
                <li>
                  <div class="select-lang-area">
                    <div class="select-lang" id="#currentCountry" (click)="openCountries(true)">
                      <div class="country-area">
                        <div class="flag"> <svg viewBox="0 0 32 32">
                            <use id="flag-mobile" [attr.xlink:href]="langSettings.flag">
                            </use>
                          </svg> </div>
                        <div class="country-name"> {{langSettings.country}}
                        </div>
                      </div>
                      <div class="carret-area"> <svg viewBox="0 0 32 32">
                          <use xlink:href="#icon-arrow-right"></use>
                        </svg> </div>
                    </div>
                    <div class="lang-options-mobile">
                      <div class="select-lang" (click)="changeLangage('nl_be')">
                        <div class="country-area">
                          <div class="flag"> <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-be"></use>
                            </svg> </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('nl_be')}">
                            Belgïe (NL) </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('nl_be','nl_nl')">
                        <div class="country-area">
                          <div class="flag"> <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-nl"></use>
                            </svg> </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('nl_nl')}">
                            Nederland (NL) </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('fr_be')">
                        <div class="country-area">
                          <div class="flag"> <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-be"></use>
                            </svg> </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('fr_be')}">
                            Belgique (FR) </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('fr_fr')">
                        <div class="country-area">
                          <div class="flag"> <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-fr"></use>
                            </svg> </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('fr_fr')}">
                            France (FR) </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('de_de')">
                        <div class="country-area">
                          <div class="flag"> <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-de"></use>
                            </svg> </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('de_de')}">
                            Deutschland (DE) </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('de_de', 'de_at')">
                        <div class="country-area">
                          <div class="flag"> <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-at"></use>
                            </svg> </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('de_at')}">
                            Österreich (DE) </div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('fr_be','fr_lu')">
                        <div class="country-area">
                          <div class="flag"> <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-lu"></use>
                            </svg> </div>
                          <div class="country-name" [ngClass]="{'current':currentRegion('fr_lu')}">
                            Luxemburg (FR) </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="user-menu">
  <ul class="navigable" [class.noPadding]="isUserLogged">
    <li>

      <div [ngClass]="'mega-user  white-container'">
        <div class="container">
          <div class="container-top menu-welcome-title"
            [class.hasDemo]="(demoService.hasDemo$ | async) && demoStatusId==2 && isDemoClient">
            <h1>{{'GLOBAL.WELCOMEX' | translate:{'X': storage.clientFirstName} }}</h1>
            <h2>{{'MENU.FIDELITY.TITLE' | translate}}</h2>

            <div *ngIf="(demoService.hasDemo$ | async)  && demoStatusId==2 && isDemoClient"
              class="cta-container primary flex row ">
              <button (click)="goToDemo()" class="btn btn-primary flex party-button j-center gap-10">
                <svg viewBox="0 0 32 32" class="icon">
                  <use xlink:href="#icon-party-rock">
                  </use>
                </svg>
                {{"DEMO.JOINUS" | translate}}
                <svg viewBox="0 0 32 32" class="icon">
                  <use xlink:href="#icon-arrow-right-long">
                  </use>
                </svg>
              </button>
            </div>

            <div class="arrow_container peach" (click)="showUserMenu()">
              <svg viewBox="0 0 32 32">
                <use xlink:href="#icon-close"></use>
              </svg>
            </div>
          </div>
          <div class="menus-container column flex">
            <div class="flex-1 flex column mega-content-card">
              <ul class="flex column" (click)="showUserMenu()">
                <li> <a class="flex j-space-between ai-center" data-testId="home-link-mobile" [routerLink]="'/' + locale + '/club/home'">
                    <div>{{'MENU.HOME' | translate}}</div>
                  </a> </li>
                <li> <a class="flex j-space-between ai-center" [routerLink]="'/' + locale + '/club/point-encoding'">
                    <div>{{'MENU.POINTENCODING' |
                      translate}}</div>
                  </a> </li>
                <li> <a class="flex j-space-between ai-center" [routerLink]="'/' + locale + '/club/status'">
                    <div>{{'MENU.STATUS' | translate}}</div>
                  </a> </li>
                <li> <a class="flex j-space-between ai-center" [routerLink]="'/' + locale + '/club/wallet'">
                    <div>{{'MENU.WALLET' | translate}}</div>
                  </a> </li>
                <li> <a class="flex j-space-between ai-center" [routerLink]="'/' + locale + '/club/orders'">
                    <div>{{'MENU.ORDERS.ORDERS' |
                      translate}}</div>
                  </a> </li>
                <li> <a class="flex j-space-between ai-center" [routerLink]="'/' + locale + '/myvictoria/demos'">
                    <div>{{'MENU.DEMO' | translate}} </div>
                  </a> </li>
                <li> <a class="flex j-space-between ai-center" [routerLink]="'/' + locale + '/club/delegate'">
                    <div>{{'MENU.DELEGATE' | translate}}
                    </div>
                  </a> </li>
                <!-- <li> <a class="flex j-space-between ai-center" [routerLink]="'/' + locale + '/club/point-evolution'">
                    <div>{{'MENU.POINTS' | translate}}</div>
                  </a> </li> -->
                <!-- <li> <a class="flex j-space-between ai-center"
                                                                                [routerLink]="'/' + locale + '/club/client-code'">
                                                                                <div>{{'MENU.CLIENT_CODE' | translate}}
                                                                                </div>
                                                                        </a> </li> -->
                <li>
                  <a class="flex j-space-between ai-center"
                    [routerLink]="'/' + locale + '/club/referral'">{{'MENU.BOOSTER' | translate}}</a>
                </li>
                <li class="last-menu"> <a class="flex j-space-between ai-center"
                    [routerLink]="'/' + locale + '/club/profile'">
                    <div>{{'MENU.PROFILE' | translate}}
                    </div>
                  </a> </li>
                <li (click)="logout()" class="disconnect">
                  <a [routerLink]="'/' + locale" class="block flex ai-center">
                    <svg viewBox="0 0 32 32" class="icon">
                      <use xlink:href="#disconnect"></use>
                    </svg>
                    {{'GLOBAL.DISCONNECT' | translate}}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div> <!-- END SIDE MENU -->
