<div class="wishlist-container" [class.hasBanner]="(breadcrumb.goToDemoBanner$ | async) && (orderService.panierOrder$ | async)?.demoStatusId == 2 && (orderService.panierOrder$ | async)?.accessLevel > 0" *ngIf="resources && loaded">
  <div data-testId="wishlist-visible" *ngIf="wishlist.length">
    <app-page-title
      [mainTitle]="'WISHLIST.TITLE' | translate"
      [subTitle]="'WISHLIST.SUBTITLE' | translate">
    </app-page-title>
    <div class="wishlist-item-list flex wrap">
      <div class="wishlist-item" *ngFor="let item of wishlist; index as i">
        <app-wishlist-item [order]="order" [wishlistItem]="item" [index]="i"></app-wishlist-item>
      </div>
    </div>
    <hr class="victoria-cart-separator" />
    <div class="cta-container d-width-fit">
      <button data-testId="go-to-collection" class="btn secondary" (click)="goToCollection()">
        <label>{{'WISHLIST.CONTINUE.SHOPPING' | translate}}</label>
      </button>
    </div>
  </div>
  <div data-testId="wishlist-not-visible" *ngIf="!wishlist.length">
    <app-page-title
      [mainTitle]="'WISHLIST.TITLE' | translate"
      [subTitle]="'WISHLIST.NOITEMS' | translate">
    </app-page-title>
    <div class="no-wishlist-body flex column center-all">
      <div class="heart-icon">
        <svg viewBox="0 0 31 29" class="icon">
          <use xlink:href="#icon-wishlist"></use>
        </svg>
      </div>
      <div class="description flex j-center">
        <p>{{'WISHLIST.DESCRIPTION' | translate}}</p>
      </div>
      <div class="link-container">
        <app-wavedlink data-testId="go-to-collection-internal" *ngIf="isInternalNavigation()" [linkUrl]="goToInternalCollection()" [linkText]="resources.shoppingBag.discoverButtonLabel"></app-wavedlink>
        <app-wavedlink data-testId="go-to-collection-external" *ngIf="!isInternalNavigation()" [externalUrl]="goToExternalCollection()" [linkText]="resources.shoppingBag.discoverButtonLabel"></app-wavedlink>
      </div>
    </div>
  </div>
</div>
<div class="panier-container flex j-center ai-center" *ngIf="!resources || !loaded">
  <div class="loader"></div>
</div>
