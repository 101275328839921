import { getLocaleExtraDayPeriodRules } from '@angular/common';
import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientOrder } from '../models/clientOrder.model';
import { CookieService } from 'ngx-cookie-service';
import { getCookieDomainByUrl } from '../utils/filter.utils';
import { ActivatedRoute } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private cookie:CookieService){}


  public get languageCode(): string {
    return localStorage.getItem('lc');
  }

  public set languageCode(value: string) {
    localStorage.setItem('lc', value);
  }

  public get token(): string {
    return localStorage.getItem('victoria_jwt_token');
  }

  public set token(value: string) {
    localStorage.setItem('victoria_jwt_token', value);
  }

  public get clientFirstName(): string {
    return localStorage.getItem('cft');
  }

  public set clientFirstName(value: string) {
    localStorage.setItem('cft', value);
  }

  public get tempDelegateId(): string {
    return localStorage.getItem('tdid');
  }

  public set tempDelegateId(value:string) {
     localStorage.setItem('tdid', value);
  }

  public get tempOrder(): ClientOrder {
    const value = localStorage.getItem('tco');
    if(value && value!=null && value!='') return JSON.parse(value)
    else return undefined;
  }

  public set tempOrder(value:ClientOrder) {
     localStorage.setItem('tco', JSON.stringify(value));
  }

  public set performedStep(value:number){
    //COS - Checkout Step
    localStorage.setItem('cos', value.toString());
  }

  //Unused : let you follow the current validate step from the checkout
  public get performedStep():number{
    //COS - Checkout Step
    const value = localStorage.getItem('cos');
    if(!value || value==null || value=='') return 0;
    else return parseInt(value,10);
  }

  public set redirectTo(value:string) {
    localStorage.setItem('rt', value.toString());
  }

  public get redirectTo() {
    return localStorage.getItem('rt');
  }

  public deleteRedirectTo(){
    localStorage.removeItem('rt');
  }

  public set registeredStatusId(status:any){
    sessionStorage.setItem('rid', status);
  }

  public get registeredStatusId():string {
    const value = sessionStorage.getItem('rid');
    return value;
  }

  public set doUpdateCookie(status:any){
    localStorage.setItem('duc', status);
  }

  public get doUpdateCookie():string {
    const value = localStorage.getItem('duc');
    return value;
  }

  public set showCongratulation(value:any){
    localStorage.setItem("dcm", value);
  }

  public get showCongratulation(){
    return localStorage.getItem("dcm");
  }

  public set demoOrderId(value: number){
    sessionStorage.setItem('doid', value.toString());
  }

  public get demoOrderId(){
    return parseInt(sessionStorage.getItem('doid'), 10);
  }

  public set demoCode(value: string){
    localStorage.setItem('demoCode', value);
  }

  public get demoCode(){
    return localStorage.getItem('demoCode');
  }

  public set currentTray(value:any){
    localStorage.setItem("currentTray", value)
  }

  public get currentTray(){
    return localStorage.getItem("currentTray") ?? 0
  }

  public setTempDemoComment(demoCode:any, value:string,){
    localStorage.setItem("tempCommentBackup" + demoCode, value);
  }

  public getTempDemoComment(demoCode:any){
    return localStorage.getItem("tempCommentBackup" + demoCode) ?? undefined;
  }

  public removeTempDemoComment(demoCode){
    localStorage.removeItem("tempCommentBackup" + demoCode);
  }

  public removeCurrentTray(){
    localStorage.removeItem("currentTray");
  }

  public removeDemoOrderId(){
    localStorage.removeItem('doid');
  }

  public get sandboxBannerDismissed(){
    return sessionStorage.getItem("hideBanner") !== null;
  }

  public set sandboxBannerDismissed(value:boolean){
    sessionStorage.setItem("hideBanner", value.toString())
  }

  public get sandboxModalDismissed(){
    return sessionStorage.getItem("hideModal") !== null;
  }

  public set sandboxModalDismissed(value:boolean){
    sessionStorage.setItem("hideModal", value.toString())
  }

  /*public set demoRole(role: number){
    sessionStorage.setItem('demoRole', role.toString());
  }
  public get demoRole(){
    return sessionStorage.getItem('demoRole');
  }*/

  public mustReloadConfiguration(){

    const config = localStorage.getItem("desktop");
    if(config==null || config=='') return false;
    const jsonConfig = JSON.parse(config);

    if(jsonConfig.authWellKnownEndPoints?.issuer != environment.identity_url) return true;
  }

  public setCurrentDemoCookies(demo:any){
    this.cookie.set("currentDelegateId", demo.delegateId,null, "/", getCookieDomainByUrl(window.location.origin));
    this.cookie.set("currentDemoId",  demo.id,  null, "/", getCookieDomainByUrl(window.location.origin));
    this.cookie.set("currentDemoOrderId",  demo.demoOrderId,  null, "/", getCookieDomainByUrl(window.location.origin));
    this.cookie.set("currentDemoCode", demo.demoCode, null, "/", getCookieDomainByUrl(window.location.origin))
    this.cookie.set(demo.demoCode, demo.alertLevel, null, "/", getCookieDomainByUrl(window.location.origin))
  }

  public setDemoLogCookie(demo:any){
    this.cookie.set(demo.demoCode, demo.alertLevel, null, "/", getCookieDomainByUrl(window.location.origin))
  }

  public isTokenRenewing(){
    return sessionStorage.getItem("renewing")!==null;
  }

  public setTokenRenewing(active:boolean){
    if(active) sessionStorage.setItem("renewing","true");
    else sessionStorage.removeItem("renewing");
  }

  public setCurrentDemoCode(value:string){
    this.cookie.set("currentDemoCode", value, null, "/", getCookieDomainByUrl(window.location.origin))
  }

  public currentDemoCode(){
    const demoCode = this.cookie.get("currentDemoCode");

    if(demoCode=="undefined" || demoCode==null || demoCode=="") return this.demoId;
    else return demoCode;
  }

  public currentDemoId(){
    const demoId =  this.cookie.get("currentDemoId");

    if(demoId==null || demoId=="") return undefined;
    else return demoId;
  }

  public set demoId(value:string){
    this.cookie.set("demoId", value, 30, "/", getCookieDomainByUrl(window.location.origin))
  }

  public get demoId(){
    const demoId = this.cookie.get("demoId");

    if(demoId=="undefined" || demoId==null || demoId=="") return "";
    else return demoId;
  }

  public removeDemoId(){
    console.log("demoId Removed")
    this.cookie.delete("demoId","/", getCookieDomainByUrl(window.location.origin))
  }

  public set delegateId(value:string){
    this.cookie.set("delegateId", value, 30, "/", getCookieDomainByUrl(window.location.origin))
  }

  public get delegateId(){
    const delegateId = this.cookie.get("delegateId")

    if(delegateId=="undefined" || delegateId==null || delegateId=="") return "";
    else return delegateId;
  }

  public removeDelegateId(){
    this.cookie.delete("delegateId","/", getCookieDomainByUrl(window.location.origin))
  }

  public currentDelegateId(){
    const delegateId =  this.cookie.get("currentDelegateId");

    if(delegateId==null || delegateId=="") return undefined;
    else return delegateId;
  }

  public currentDemoOrderId(){
    const orderId =  this.cookie.get("currentDemoOrderId");

    if(orderId==null || orderId=="") return undefined;
    else return orderId;
  }

  public get currentRole(){
    const role = sessionStorage.getItem("cr") ?? undefined
    return role;
  }

  public set currentRole(value){
    sessionStorage.setItem("cr",value.toString());
  }

  public currentAlertLevel(demoCode){
    const level =  this.cookie.get(demoCode);
    if(level==null || level=="") return undefined;
    else return level;
  }

  public updateAlertLevel(demoCode, alertLevel){
    this.cookie.set(demoCode, alertLevel, null, "/", getCookieDomainByUrl(window.location.origin))
  }

  public getDismissAlert(demoCode){
    const value = this.cookie.get("alert_" + demoCode);
    if(value==null || value=="") return {start: true, end: true};
    else return JSON.parse(value);
  }

  public setDismissAlert(demoCode, obj){
    this.cookie.set("alert_" + demoCode, JSON.stringify(obj), null, "/", getCookieDomainByUrl(window.location.origin))
  }

  public getTrackingListing(){
    const value = this.cookie.get(`tracking_lising_demo`);
    if(value==null || value=="") return false
    else return true
  }

  public setTrackingListing(){
    this.cookie.set(`tracking_lising_demo`, "true", null, "/", getCookieDomainByUrl(window.location.origin));
  }

  public getTrackingDemo(demoCode){
    const value = this.cookie.get(`tracking_${demoCode}_home`);
    if(value==null || value=="") return false
    else return true
  }

  public setTrackingDemo(demoCode:string){
    this.cookie.set(`tracking_${demoCode}_home`, "true", null, "/", getCookieDomainByUrl(window.location.origin))
  }

  public getTrackingJewels(demoCode){
    const value = this.cookie.get(`tracking_${demoCode}_jewels`);
    if(value==null || value=="") return false
    else return true
  }

  public setTrackingJewels(demoCode:string){
    this.cookie.set(`tracking_${demoCode}_jewels`, "true", null, "/", getCookieDomainByUrl(window.location.origin))
  }

  public getTrackingUnivers(demoCode){
    const value = this.cookie.get(`tracking_${demoCode}_univers`);
    if(value==null || value=="") return false
    else return true
  }

  public setTrackingUnivers(demoCode:string){
    this.cookie.set(`tracking_${demoCode}_univers`, "true", null, "/", getCookieDomainByUrl(window.location.origin))
  }

  public get connectedToSocket(){
    const value = this.cookie.get(`current_ws_connection`);
    if(value==null || value=="") return ""
    else return value;
  }

  public set connectedToSocket(demoCode:string){
    this.cookie.set(`current_ws_connection`, demoCode, null, "/", getCookieDomainByUrl(window.location.origin))
  }
}
