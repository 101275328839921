<div class="cart-item-wrapper {{classCondition}}" *ngIf="resources && item.productGroup">
  <div class="cart-item" [class.border-bottom]="bottomBorder">
    <div class="cart-item-container flex ai-start">
      <div class="cart-item-thumb">
        <figure class="image-cover">
          <img loading="lazy" [src]="dataService.getProductImagesPathForThumbs(item.productGroup.productImage)" alt="photo de l'article"/>
        </figure>
      </div>
      <div class="cart-item-content" [class.error]="item.isOnError">
        <div class="cart-item-head">
          <div class="flex row cart-item-price-label">
            <div class="cart-item-name" (click)="goToProduct()">
              {{item.productGroup.name }}
            </div>
            <div class="cart-item-price">
              {{item.unitPrice | agoraCurrency}}
            </div>
          </div>
          <div class="cart-item-remove" (click)="deleteItem()" *ngIf="!isReadOnly && !removeBin">
            <svg viewBox="0 0 32 32" class="icon">
              <use xlink:href="#icon-trash"></use>
            </svg>
          </div>
        </div>
        <div class="cart-item-controls flex row j-space-between">
          <div class="cart-item-control order2" *ngIf="!isReadOnly">
            <div class="control-label" *ngIf="sizes.length>1 && !wishlistElement">{{resources.shoppingBag.sizesLabels}}</div>
            <div class="control-content">
              <select *ngIf="sizes.length>1 && !wishlistElement" class="control-select limited-size" (change)="updateItemSize($event.target)" [disabled]="isReadOnly">
                <option *ngFor="let opt of sizes" [value]="opt.id" [selected]="opt.id==item.sku" [disabled]="!opt.canSelect" [class.barre]="!opt.canSelect">{{opt.value}}</option>
              </select>
              <select *ngIf="false && sizes.length==1" class="control-select" disabled>
                <option selected>{{resources.shoppingBag.detailsLabel}}</option>
              </select>
            </div>
          </div>          

          <div class="cart-item-control" *ngIf="!wishlistElement && !isReadOnly">
            <div class="control-label">{{resources.shoppingBag.quantityLabel}}</div>
            <div class="control-content">
              <select class="control-select" (change)="updateItemQuantity($event.target)">
                <option *ngFor="let opt of getQuantity(item.quantity)" [value]="opt.id" [selected]="opt.id==item.quantity">{{opt.value}}</option>
              </select>
            </div>
          </div>
        </div>

        <div *ngIf="isReadOnly" class="flex row j-space-between read-only-datas">
          <p class="p-s bold">{{resources.shoppingBag.quantityLabel}}: {{item.quantity}}</p>
          <p class="p-s bold" *ngIf="item.size && sizes?.length>1">{{resources.shoppingBag.sizesLabels}}: {{item.size ?? '/'}}</p>
        </div>

        <div *ngIf="sizes.length>1 && !wishlistElement && !isReadOnly" class="cart-add-size" (click)="modal.open('#sizeModal' + index)">
          <svg viewBox="0 0 32 32" class="icon">
            <use xlink:href="#icon-plus"></use>
          </svg>
          <span class="no-red">{{resources.shoppingBag.addToBagLabel}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="!isReadOnly && wishlistElement && isDemo" class="d-width-fit right move-to-cart flex row ai-center" (click)="addItemToCart(item)">
      <svg viewBox="0 0 32 32" class="icon icon-block">
        <use xlink:href="#icon-bag"></use>
      </svg>
      <label>{{'DEMO.ORDER.MOVEWISHLISTTOCART' | translate }}</label>
    </div>
  </div>
  <div class="add-to-wishlist flex ai-center" *ngIf="!wishlistElement && !isDemoDelegate">
    <button class="heart-button flex center-all" (click)="item.isInWishlist ? removeItemFromWishlist(item) : addItemToWishlist(item)">
      <svg viewBox="0 0 32 32"
           class="icon" [class.heart-animated]="item.isInWishlist">
        <use [attr.xlink:href]="'#' + (item.isInWishlist ? 'icon-heart-filled' : 'icon-wishlist')"></use>
      </svg>
    </button>
    <p>{{item.isInWishlist ? ('CART.WISHLIST.ITEM' | translate) : ('CART.WISHLIST.ADD' | translate)}}</p>
  </div>
</div>
<app-modal *ngIf="resources"
           [id]="'sizeModal' + index"
           [title]="resources.popupPickSize.mainTitle"
           [isDemo]="isDemo"
           [clientGuid]="clientGuid"
           [resolvedId]="order?.resolvedId"
           [titleNextStep]="resources.sizeGuide.mainTitle"
           template="size" [selectedTab]="'0'" [item]="item"></app-modal>

<app-modal [id]="'confirmation' + index"
          [title]="'CART.MODAL.CONFIRMATION' | translate"
          [isDemo]="isDemo"
          [clientGuid]="clientGuid"
          [item]="item"
          [resolvedId]="order?.resolvedId"
          [wishlistElement]="wishlistElement"
          template="confirmation"
></app-modal>

