<div class="carousel m-only">
  <div class="row" (scroll)="onScroll($event)">
    <ng-content></ng-content>
    <div *ngIf="!loaded" class="item-loading flex ai-center">
      <div class="flew row loader-bullet">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
