import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
})
export class BackToTopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //Todo, use hostListener!
    window.onscroll = () => this.scrollFunction();
  }

  private scrollFunction() {
    const backToTopButton = document.getElementById('back-to-top-button');
    //Ensure button exist
    if (backToTopButton) {
      if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
        backToTopButton.style.visibility = 'visible';
        backToTopButton.style.opacity = '1';
      } else {
        backToTopButton.style.visibility = 'hidden';
        backToTopButton.style.opacity = '0';
      }
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
