import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { TranslateLoader, TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { filterURL, getApiUrlFromOrigin, getContextByUrl, getVictoriaUrl } from '../utils/filter.utils';
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

const BASE_URL: string = getApiUrlFromOrigin();

const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')

@Injectable({
    providedIn: 'root'
})
export class TranslationService implements TranslateLoader {

    public langHasChanded: Subject<boolean> = new Subject<boolean>();

    private _localesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public localesLoaded: Observable<boolean> = this._localesLoaded.asObservable();

    private _contextId$: BehaviorSubject<number> = new BehaviorSubject(0);
    public contextId$: Observable<number> = this._contextId$.asObservable();

    //INTERNAL
    private jsonDatas: any = []
    private localesLoading = false;

    constructor(private http: HttpClient,
        private storage: StorageService,
        private translate: TranslateService) {

        this.langHasChanded.subscribe(res => {
            if (res == true) {
                this._localesLoaded.next(false);
                this._contextId$.next(this.getContextId());
                this.loadJsonFiles()
            }
        });

        // this.loadJsonFiles();
        // this._contextId$.next(this.getContextId());
    }

    public getLanguage(){
        return this.translate.currentLang;
    }

    public getCodeLangueFromUserLocale(locale){
        let contextLanguages = []
        let error = false;

        let contextId = this.getContextId();
        if(contextId == 0) contextId = getContextByUrl(window.location.origin)
        switch(contextId){
            case 1: contextLanguages = environment.supportedLocales.fr; break;
            case 2: contextLanguages = environment.supportedLocales.be; break;
            case 3: contextLanguages = environment.supportedLocales.de; break;
            default: error = true;
        }

        if(error){

        }


        else if(contextLanguages.includes(locale.replace("_","-"))) return locale;
        else return contextLanguages[0].replace("-","_");
    }

    public getTranslation(lang: string): Observable<any> {
        lang = this.getCodeLangueFromUserLocale(lang);

        return this.http.get<any>(BASE_URL + "/admin/resources/" + lang, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    public getJsonLocales() {
        const lang = this.translate.currentLang || this.storage.languageCode || "fr_be";

        switch (lang) {
            case "fr_fr": return this.jsonDatas.find(x => x.lang == "fr-fr");
            case "fr_be": return this.jsonDatas.find(x => x.lang == "fr-be");
            case "nl_be": return this.jsonDatas.find(x => x.lang == "nl-be");
            case "nl_nl": return this.jsonDatas.find(x => x.lang == "nl-be");
            case "de_de": return this.jsonDatas.find(x => x.lang == "de-de");
            default: return this.jsonDatas.find(x => x.lang == "fr-fr");
        }
    }

    public getCollectionMenuLocalizedLink() {
        return this.filter(this.getJsonLocales()?.header.mainNav[0].navLink);
    }

    public filter(url) {
        return getVictoriaUrl(this.storage.languageCode, true) + filterURL(url);
    }

    public loadJsonFiles() {
        if(!this.localesLoading) this.loadLangue(this.storage.languageCode || "fr_fr");
    }

    private loadLangue(lang: string) {
        this.localesLoading = true;
        this._localesLoaded.next(false);
        let data;

        switch (lang) {
            case "fr_fr": data = this.jsonDatas.find(x => x.lang == "fr-fr"); break;
            case "fr_be": data = this.jsonDatas.find(x => x.lang == "fr-be"); break
            case "nl_be": data = this.jsonDatas.find(x => x.lang == "nl-be"); break
            case "nl_nl": data = this.jsonDatas.find(x => x.lang == "nl-be"); break
            case "de_de": data = this.jsonDatas.find(x => x.lang == "de-de"); break
        }

        if (data && data!=null) {
            this._localesLoaded.next(true);
            this.localesLoading = false;
        } else {

            let jsonUrl = '';
            switch (lang) {
                case "nl_nl":
                case "fr_be":
                case "nl_be": jsonUrl= `${environment.base_url_benelux}/data/data_${lang}.json`; break;
                case "de_de": jsonUrl= `${environment.base_url_allemagne}/data/data_${lang}.json`; break;
                case "fr_fr": jsonUrl= `${environment.base_url_france}/data/data_${lang}.json`; break;
                default: jsonUrl= `${environment.base_url_france}/data_fr_fr.json`; break;
            }

            this.http.get(jsonUrl).subscribe(res => {
                this.jsonDatas.push(res);
                this._localesLoaded.next(true);
                this.localesLoading = false;
            }, err => {
                console.warn(`Json Locale Loading for language ${lang} failed`)
                this.localesLoading = false;
            });
        }
    }

    public getContextId() {

        const lang = this.storage.languageCode || this.translate?.currentLang  || "fr_be";

        switch (lang) {
            case "nl_nl":
            case "fr_be":
            case "nl_be": return 2;
            case "de_de": return 3;
            case "fr_fr": return 1;
            default: return 0;
        }
    }

    public initContextId(lang){
        switch (lang) {
            case "nl_nl":
            case "fr_be":
            case "nl_be": this._contextId$.next(2); break;
            case "de_de": this._contextId$.next(3); break;
            case "fr_fr": this._contextId$.next(1); break;
            default: this._contextId$.next(0); break;
        }
    }

    //ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Erreur Client / Reseau:', error.error.message);
        } else {
            console.error(
                `Erreur depuis le back :  ${error.status}, ` +
                `Corps de l'erreur : ${error.error}`);
        }
        return throwError(
            error.message);
    }



    public getTranslationMultiple(client:string, delegate:string, isDelegateView:boolean = false, clientParams?:any,  delegateParams?:any, isClientFromResources:boolean = false, isDelegateFromResources:boolean = false){
            if(isDelegateView && delegateParams && !isDelegateFromResources) return this.translate.instant(delegate, delegateParams);
            else if(isDelegateView && !isDelegateFromResources) return this.translate.instant(delegate);
            else if(isDelegateFromResources) return delegate;
            else if(!isDelegateView && clientParams && !isClientFromResources) return this.translate.instant(client, clientParams);
            else if(!isDelegateView && !isClientFromResources) return this.translate.instant(client);
            else if(isClientFromResources) return client;
            else return "translation error";
    }

}
