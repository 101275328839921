import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../../../core/services/modal.service';

@Component({
  selector: 'app-agora-modal',
  templateUrl: './agora-modal.component.html',
  styleUrls: ['./agora-modal.component.scss'],
})
export class AgoraModalComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() closable = true;
  @Input() id: string;
  @Input() type = 'default';
  @Input() classes: string;

  @Output() closed:EventEmitter<any> = new EventEmitter<any>()

  private isMobile = false;

  constructor(
    private modalService: ModalService
  ) {
  }

  ngOnInit() {

    this.isMobile = window.innerWidth <= 1181;

    const modal = document.querySelector('.modal') as HTMLElement;
    const icon = document.querySelector('.scroll-icon') as HTMLElement;

    modal.addEventListener('scroll', () => {
      if ((modal.scrollTop + modal.clientHeight) + 50 < modal.scrollHeight) {
        icon?.classList.add('show');
      } else {
        icon?.classList.remove('show');
      }
    });
  }

  closeModal(el: MouseEvent) {
    this.modalService.closeAgoraModal(el);
    this.closed.emit();
  }

  closeModalFromOutside(el: MouseEvent) {
    if(!this.closable) return;

    const m = el.target as HTMLElement;
    if (m.id === 'agora-modal-container' && !this.isMobile) {
      this.modalService.closeAgoraModal(el);
    }

    this.closed.emit();
  }
}

