import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss'],
})
export class FormStepperComponent implements OnInit {

  @Input() steps:any[]
  @Input() currentStep:number
  @Input() withBackground = false;
  @Input() isDemo = false;

  @Output() triggerStepBack:EventEmitter<number> = new EventEmitter()

  constructor(private orderService : OrderService) { }

  ngOnInit() {}

  activateStepBack(item:any){
    if(item.step < this.currentStep && item.canNavigate){
      if(this.isDemo)
      this.orderService.updateCartNavigationStatus(item.step);
      this.triggerStepBack.emit(item.step);

    }
  }

}
