import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ClientOrder } from 'src/app/core/models/clientOrder.model';
import { ModalService } from 'src/app/core/services/modal.service';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { OrderService } from '../../../../../../core/services/order.service';
import { GoogleAnalyticService } from '../../../../../../core/services/google-analytic.service';
import { StorageService } from '../../../../../../core/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MollieGetLinkLoad } from '../../../../../../core/models/mollieGetLinkLoad.model';
import {
  getCookieDomainByContext,
  getEshopUrlByContext,
} from '../../../../../../core/utils/filter.utils';
import { PaymentService } from '../../../../../../core/services/payment.service';
import { CookieService } from 'ngx-cookie-service';
import { GetDatasService } from '../../../../../../core/services/get-datas.service';
import { TriggerService } from '../../../../../../core/services/trigger.service';
import { DemoService } from '../../../../../agora/demo/services/demo.service';
import { OrderActionEnum } from '../../../../../agora/demo/enums/order-action.enum';
import { OrderStateEnum } from '../../../../../agora/demo/enums/order-state.enum';
import { ScreenService } from 'src/app/core/services/screen.service';
import { PublicOrderService } from 'src/app/core/services/publicOrder.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit, OnChanges {
  @Input() order: ClientOrder;
  @Input() deliveryId = 1;

  @Input() selectedMethod: string;
  @Input() isDipCheck: boolean;

  @Input() contextId: number;
  @Input() step: number;
  @Input() isDemo = false;
  @Input() demoCode: string;
  @Input() disableShopButton = false;
  @Input() noProduct = false;
  @Input() isOrderValidation = false;
  @Input() isReceipt = false;
  @Input() isOrderManage = false;
  @Input() validationStep: string;
  @Input() hiddeDeliveryButton = false;
  @Input() disableDeleteVouchersWallet = false;
  @Input() hiddeButton = false;

  @Input() isDemoCart = false;

  @Input() isPublicAccess = false;

  @Output() errors: EventEmitter<any[]> = new EventEmitter();
  @Output() triggerError: EventEmitter<number> = new EventEmitter();
  @Output() goToNextStep: EventEmitter<number> = new EventEmitter();
  @Output() goToDeliveryEmit: EventEmitter<any> = new EventEmitter();
  @Output() triggerPaymentByDelegate: EventEmitter<boolean> =
    new EventEmitter();

  resources: any;
  footerLocales: any;
  operationPending = false;

  orderActionEnum = OrderActionEnum;
  validationStepEnum = OrderStateEnum;
  isDemoClient = false;

  constructor(
    public modal: ModalService,
    private translate: TranslationService,
    private translator: TranslateService,
    private orderService: OrderService,
    private gA: GoogleAnalyticService,
    public storage: StorageService,
    public paymentService: PaymentService,
    public cookie: CookieService,
    private router: Router,
    private translateConfig: TranslationService,
    public dataService: GetDatasService,
    private trigger: TriggerService,
    private route: ActivatedRoute,
    private demoService: DemoService,
    private publicOrderService: PublicOrderService,
    public screen: ScreenService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    //console.log("Order Summary Init");

    this.demoService.demo$.subscribe((res) => {
      if (res) this.isDemoClient = res.accessLevel > 0;
    });

    if (this.order) {
      if (this.order.flowStatuses.payment.status === 11) {
        this.disableShopButton = true;
      }

      if (!this.isDemoCart)
        this.isDemoCart = this.order.demoStatusId == 2 && this.isDemoClient;
    }

    this.translate.localesLoaded.subscribe((res) => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
        const datas = this.translateConfig.getJsonLocales();
        this.footerLocales = datas?.footer;
      }
    });

    if (this.route.snapshot.queryParams.hasError == 'true') {
      this.triggerError.emit(99);
    }

    switch (this.validationStep) {
      case this.validationStepEnum.IS_VOUCHER:
        this.step = 1;
        break;
      case this.validationStepEnum.IS_DELIVERY:
        this.step = 2;
        break;
      case this.validationStepEnum.IS_DIP:
        this.step = 3;
        break;
      case this.validationStepEnum.IS_CONFIRMATION:
        this.step = 4;
        break;
    }

    this.loader.loaded$.subscribe((res) => {
      if (res) this.operationPending = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);

    if (changes.selectedMethod?.currentValue && this.isDipCheck) {
      this.disableShopButton = false;
    } else if (changes.isDipCheck?.currentValue && this.selectedMethod) {
      this.disableShopButton = false;
    }

    if(changes.order && this.order?.orderItemsCount==0){
      this.errors.emit([{code:400, message: this.translator.instant("DEMO.VALIDATION.NOARTICLES")}])
    } else if(changes.order && this.order?.orderItemsCount>0){
      this.errors.emit([])
    }
  }

  removeWalletUsageFromOrder() {
    if (this.isOrderValidation && this.demoCode) {
      const clientGuid =
        this.route.snapshot.params?.clientGuid ?? this.order.clientSub;
      this.orderService.postWalletClientOrder(
        0,
        this.demoCode,
        clientGuid,
        this.order.resolvedId,
        false
      );
    } else {
      this.orderService.postWalletOrderGuest(0);
    }
  }

  checkDeliveryAndPaymentThenSave() {
    if (
      this.order.deliveryTypeId == 10 &&
      (this.order.flowStatuses.payment.status == 0 ||
        this.order.flowStatuses.payment.status == 11)
    ) {
      this.modal.openAgoraModal('payment-delivery-confirmation');
    } else {
      this.confirmeOrder(this.operationPending);
    }
  }

  doConfirm(value) {
    this.modal.closeAgoraModal('payment-delivery-confirmation');

    if (value) {
      this.operationPending = true;
      this.confirmeOrder(this.operationPending);
    } else {
      this.operationPending = false;
    }
  }

  confirmeOrder(isPending = false) {
    if (this.operationPending && !isPending) return;
    else {
      this.operationPending = true;
      this.demoService
        .postClientOrderAction(
          this.orderActionEnum.ORDER_CONFIRMED,
          this.demoCode,
          this.order.clientSub,
          undefined,
          this.order.resolvedId
        )
        .subscribe(
          (res) => {
            if (res) {
              if (res.data.hasErrors) {
                this.operationPending = false;
                this.errors.emit(res.data.errors);
              } else {
                this.orderService.updateSingleClientOrder(res.data);
                this.router.navigateByUrl(
                  '/' +
                    this.storage.languageCode?.replace('_', '-') +
                    '/myvictoria/demo/' +
                    this.demoCode +
                    '/manage/clients?orderId=' +
                    res.data.resolvedId +
                    '&withPayment=true'
                );
              }
            }
          },
          (err) => {
            this.operationPending = false;
            this.triggerError.emit(99);
          }
        );
    }
  }

  saveTunnelDatas() {
    if (this.operationPending) {
      return;
    } else {
      this.operationPending = true;

      if (!this.isPublicAccess) {
        if (
          this.demoCode &&
          !this.isOrderValidation &&
          this.selectedMethod === 'delivery'
        ) {
          this.demoService
            .postClientGuestOrderAction(
              this.orderActionEnum.PAYMENT_HANDLED_BY_DELEGATE,
              this.demoCode,
              this.order.clientSub
            )
            .subscribe(
              (res) => {
                if (res) {
                  this.triggerPaymentByDelegate.emit(true);
                  this.operationPending = false;
                  this.disableShopButton = true;

                  //Temp store delId
                  this.storage.tempDelegateId = res.data.delegateId;
                  this.storage.tempOrder = res.data;

                  window.location.href =
                    window.origin +
                    '/' +
                    this.storage.languageCode?.replace('_', '-') +
                    '/public/checkout?step=4&confirmation=true&toDelegate=true';
                  // this.router.navigate(['/' + this.storage.languageCode?.replace('_', '-') + '/public/checkout'], {queryParams: {step: 4, confirmation: true, toDelegate: true}});
                }
              },
              (err) => {
                this.operationPending = false;
                this.triggerError.emit(99);
              }
            );
          return;
        }

        this.orderService.postGuestOrder().subscribe(
          (res) => {
            this.orderService.updateGuestOrderInternaly(res.data.guestOrder);

            // Work around no id in GuestOrder
            // let tempOrder = this.storage.tempOrder;
            // if(tempOrder && tempOrder!=null && tempOrder.id) res.data.guestOrder.id = tempOrder.id;

            if (
              res.data &&
              res.data.guestOrder &&
              res.data.guestOrder.orderItemsCount > 0 &&
              !res.data.guestOrder.hasErrors
            ) {
              this.gA.GACheckout(3, this.selectedMethod, res.data.guestOrder);
              //Temp store delId
              this.storage.tempDelegateId = res.data.guestOrder.delegateId;
              this.storage.tempOrder = res.data.guestOrder;

              // let paymentRef = this.cookie.get("v_upl");

              // //- TODO - : CHECK IF USER HAS ASKED A LINK
              // if (paymentRef) {
              //   //PAYMENT ALREADY ASKED : TRY TO GET IT BACK
              //   this.mollieService.getExistingPayment(this.contextId, paymentRef).subscribe(linkRes => {
              //     if (linkRes.data && linkRes.data.checkoutLink != null) window.location.href = linkRes.data.checkoutLink
              //     else {
              //       //Invalidate Payment Cookie
              //       this.cookie.delete("v_upl");
              //       //Request New Payment
              //       this.requestMollieLink(res);
              //     }
              //   })
              // } else {
              //   this.requestMollieLink(res);
              // }

              if (this.selectedMethod === 'delivery' && this.demoCode) {
                this.demoService
                  .postClientGuestOrderAction(
                    this.orderActionEnum.PAYMENT_HANDLED_BY_DELEGATE,
                    this.demoCode,
                    this.order.clientSub
                  )
                  .subscribe(
                    (resV) => {
                      if (resV) {
                        this.orderService.updateSingleClientOrder(
                          resV.data,
                          false
                        );
                        this.operationPending = false;

                        //Temp store delId
                        this.storage.tempDelegateId = res.data.delegateId;
                        this.storage.tempOrder = res.data;

                        window.location.href =
                          window.origin +
                          '/' +
                          this.storage.languageCode?.replace('_', '-') +
                          '/public/checkout?step=4&confirmation=true&toDelegate=true';

                        // this.router.navigate(['/' + this.storage.languageCode?.replace('_', '-') + '/public/checkout'], {queryParams: {step: 4, confirmation: true, toDelegate:true}});
                      }
                    },
                    (err) => {
                      this.operationPending = false;
                      this.triggerError.emit(99);
                    }
                  );

                //this.router.navigate(['/' + this.storage.languageCode?.replace('_', '-') + '/public/checkout'], {queryParams: {step: 4, confirmation: true}});
              } else if (this.order.contextId == 1) {
                //FOR PRESENTATION STEERCO ONLY
                if (
                  this.selectedMethod != 'Direct' &&
                  this.selectedMethod != '3X'
                ) {
                  this.requestMollieLink(res.data.guestOrder);
                } else {
                  this.requestLyfpayLink(res.data.guestOrder);
                }
              } else {
                this.requestMollieLink(res.data.guestOrder);
              }
            } else if (
              res.data &&
              res.data.guestOrder &&
              res.data.guestOrder.hasErrors
            ) {
              this.operationPending = false;
              this.router.navigate([
                '/' +
                  this.storage.languageCode?.replace('_', '-') +
                  '/public/cart',
              ]);
            }
          },
          (err) => {
            this.operationPending = false;
            this.triggerError.emit(99);
          }
        );
      } else if (this.isPublicAccess) {
        if (
          this.demoCode &&
          !this.isOrderValidation &&
          this.selectedMethod === 'delivery'
        ) {
          this.publicOrderService
            .sendClientAction(
              this.order.resolvedId,
              this.order.clientSub,
              this.orderActionEnum.PAYMENT_HANDLED_BY_DELEGATE
            )
            .subscribe(
              (result) => {
                if (result) {
                  this.triggerPaymentByDelegate.emit(true);
                  this.operationPending = false;
                  this.disableShopButton = true;

                  //Temp store delId
                  this.storage.tempDelegateId = result.data.delegateId;
                  this.storage.tempOrder = result.data;

                  window.location.href =
                    window.origin +
                    '/' +
                    this.storage.languageCode?.replace('_', '-') +
                    '/public/order/' +
                    this.order.resolvedId +
                    '/client/' +
                    this.order.clientSub +
                    '/checkout?confirmation=true&toDelegate=true';
                  // this.router.navigate(['/' + this.storage.languageCode?.replace('_', '-') + '/public/order/' + this.order.resolvedId + "/client/" + this.order.clientSub + "/checkout"], {queryParams: {confirmation: true, toDelegate: true}});
                }
              },
              (err) => {
                this.operationPending = false;
                this.triggerError.emit(99);
              }
            );
          return;
        }

        this.publicOrderService
          .confirmOrder(this.order.resolvedId, this.order.clientSub)
          .subscribe(
            (res: any) => {
              this.publicOrderService.updateClientOrderInternaly(res.data);

              if (
                res.data &&
                res.data.orderItemsCount > 0 &&
                !res.data.hasErrors
              ) {
                this.gA.GACheckout(3, this.selectedMethod, res.data);
                //Temp store delId
                this.storage.tempDelegateId = res.data.delegateId;
                this.storage.tempOrder = res.data;

                if (this.order.contextId == 1) {
                  //FOR PRESENTATION STEERCO ONLY
                  if (
                    this.selectedMethod != 'Direct' &&
                    this.selectedMethod != '3X'
                  ) {
                    this.requestMollieLink(res.data);
                  } else {
                    this.requestLyfpayLink(res.data);
                  }
                } else {
                  this.requestMollieLink(res.data);
                }
              }
            },
            (err) => {
              this.operationPending = false;
              this.triggerError.emit(99);
            }
          );
      }
    }
  }

  saveTunnelDatasWithoutPayment() {
    if (this.operationPending) {
      return;
    } else {
      this.operationPending = true;

      this.orderService.postGuestOrder(true).subscribe(
        (res) => {
          if (
            res.data &&
            res.data.guestOrder &&
            !res.data.guestOrder.hasErrors
          ) {
            this.cookie.set(
              'delegateId',
              res.data.guestOrder.delegateId,
              365,
              '/',
              getCookieDomainByContext(this.contextId)
            );
            const profile = this.cookie.get('victoria_profile');

            if (profile && profile != null && profile != '') {
              const jsonProfile = JSON.parse(profile);
              jsonProfile.delegateId = res.data.delegateId;
              this.cookie.set(
                'victoria_profile',
                JSON.stringify(jsonProfile),
                365,
                '/',
                getCookieDomainByContext(this.contextId)
              );
            }

            this.storage.tempOrder = res.data.guestOrder;
            this.storage.tempDelegateId = res.data.guestOrder.delegateId;
            this.storage.doUpdateCookie = true;

            this.orderService.updateGuestOrderInternaly(res.data.guestOrder);

            this.operationPending = false;

            const redirectionUrl =
              window.origin +
              '/' +
              this.storage.languageCode?.replace('_', '-') +
              '/public/checkout?step=4&confirmation=true';

            window.location.href = redirectionUrl;
          } else if (
            res.data &&
            res.data.guestOrder &&
            res.data.guestOrder.hasErrors
          ) {
            this.operationPending = false;
            this.router.navigate([
              '/' +
                this.storage.languageCode?.replace('_', '-') +
                '/public/cart',
            ]);
          }
        },
        (err) => {
          this.operationPending = false;
          this.triggerError.emit(99);
        }
      );
    }
  }

  nextStep() {
    if (this.operationPending) return;
    else {
      this.operationPending = true;

      if (this.step === 1 && this.demoCode) {
        this.demoService
          .postClientGuestOrderAction(
            this.orderActionEnum.VOUCHERS_VALIDATED,
            this.demoCode,
            this.order.clientSub
          )
          .subscribe(
            (res) => {
              if (res.data.hasErrors) {
                this.operationPending = false;
                this.errors.emit(res.data.errors);
              } else {
                this.trigger.requestOrderNextStep(this.step + 1);
                this.operationPending = false;
              }
            },
            (err) => (this.operationPending = false)
          );
      } else {
        this.trigger.requestOrderNextStep(this.step + 1);
        this.operationPending = false;
      }
    }
  }

  goToDelivery() {
    if (this.operationPending || this.order?.orderItemsCount==0) return;
    else {
      this.errors.emit([]);
      this.operationPending = true;

      if (this.validationStep === this.validationStepEnum.IS_VOUCHER) {
        this.demoService
          .postClientOrderAction(
            this.orderActionEnum.VOUCHERS_VALIDATED,
            this.demoCode,
            this.order.clientSub,
            undefined,
            this.order.resolvedId
          )
          .subscribe(
            (res) => {
              if (res) {
                if (res.data.hasErrors) {
                  this.operationPending = false;
                  this.errors.emit(res.data.errors);
                } else {
                  this.orderService.updateSingleClientOrder(res.data);
                  this.operationPending = false;
                }
              }
            },
            (err) => (this.operationPending = false)
          );
      } else {
        this.checkDeliveryAndPaymentThenSave();
      }
    }
  }

  private requestMollieLink(res) {
    //PAYEMENT DO NOT EXISTS : GET MOLLIE PAYMENT LINK
    const load = new MollieGetLinkLoad();
    load.country = res.countryCode;
    load.locale = this.storage.languageCode;
    load.method = this.selectedMethod;
    load.orderAmount = res.totalToPay;
    load.orderId = res.id;
    load.orderGuid = res.resolvedId;

    if (!this.isPublicAccess)
      load.redirectUrl = `${getEshopUrlByContext(
        this.contextId
      )}/${this.storage.languageCode?.replace(
        '_',
        '-'
      )}/public/checkout?step=4`;
    else
      load.redirectUrl = `${getEshopUrlByContext(
        this.contextId
      )}/${this.storage.languageCode?.replace('_', '-')}/public/order/${
        this.order.resolvedId
      }/client/${this.order.clientSub}/checkout`;

    if (this.demoCode && !this.isPublicAccess) {
      load.redirectUrl += `&demoCode=${this.demoCode}`;
    }

    this.paymentService.getMolliePaymentLink(this.contextId, load).subscribe(
      (link) => {
        if (link.data) {
          //PAYMENT LINK RECEIVED : TEMPORARY SAVE PAYMENT REF
          this.cookie.set(
            'v_upl',
            link.data.id,
            1 / 48,
            '/',
            getCookieDomainByContext(this.contextId)
          );

          //SAVE ORDER PAYMENT : UPDATE ORDER
          this.orderService
            .postMolliePaymentForOrderUpdate(
              load.orderGuid,
              { paymentRef: link.data.id, amount: load.orderAmount },
              this.order.clientSub,
              this.isPublicAccess
            )
            .subscribe(
              (update) => {
                if (update.data) {
                  this.operationPending = false;
                  //REDIRECT USER TO MOLLIE
                  window.location.href = link.data.links.checkout.href;
                }
              },
              (err) => {
                this.operationPending = false;
                this.triggerError.emit(99);
              }
            );
        }
      },
      (err) => {
        this.operationPending = false;
        this.triggerError.emit(99);
      }
    );
  }

  private requestLyfpayLink(res) {
    const load = new MollieGetLinkLoad();
    load.country = res.countryCode;
    load.locale = this.storage.languageCode;
    load.method = this.selectedMethod;
    load.orderAmount = res.totalToPay;
    load.orderId = res.id;
    load.redirectUrl = `${getEshopUrlByContext(
      this.contextId
    )}/${this.storage.languageCode?.replace('_', '-')}/public/checkout?step=${
      this.demoCode ? '5' : '4'
    }&isLyfpay=true`;
    load.failedRedirectUrl = `${getEshopUrlByContext(
      this.contextId
    )}/${this.storage.languageCode?.replace(
      '_',
      '-'
    )}/public/checkout?hasError=true`;

    if (this.demoCode) {
      load.redirectUrl += `&demoCode=${this.demoCode}`;
    }

    this.paymentService.getLyfPayPaymentLink(load).subscribe(
      (link) => {
        if (link.data) {
          this.operationPending = false;
          window.location.href = link.data;
        }
      },
      (err) => {
        this.operationPending = false;
        this.triggerError.emit(99);
      }
    );
  }

  public goToDIPBox() {
    const dipBox = document.getElementById('dipBox');
    dipBox.scrollIntoView({ behavior: 'smooth' });
  }
}
