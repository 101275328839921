<div class="wishlist-item-container flex column">
  <div class="wishlist-item-wrapper">
    <div class="" (mouseover)="hoverImage($event, true)" (mouseout)="hoverImage($event, false)"  [ngStyle]="{'width': width}">
      <div class="wishlist-item-image">
        <button class="order-button btn-primary flex center-all" (click)="addToCart(index)">
          <svg viewBox="0 0 21 21"
               class="icon">
            <use xlink:href="#icon-bag"></use>
          </svg> &nbsp; <label class=" flex center-all">{{'GLOBAL.ACTION.BUY' | translate}}</label>
        </button>
        <button class="heart-button btn-primary flex center-all" (click)="confirmDelete(index)">
          <svg viewBox="0 0 32 32"
               class="icon heart-animated">
            <use xlink:href="#icon-heart-filled"></use>
          </svg>
        </button>
        <div #container class="image-container">
         <img loading="lazy" class="thumb-image" [src]="isMouseOver?itemHoverImage:itemImage" (load)="showElem($event)" (click)="goToProduct()"/>
        </div>
      </div>
    </div>
    <div class="flex row j-space-between wishlist-datas">
      <p>{{wishlistItem.name ?? wishlistItem.productGroup.name}}</p>
      <p class="wishlist-amount">{{wishlistItem.unitPrice | agoraCurrency }}</p>
    </div>
  </div>
</div>

<app-modal [id]="'sizeModal' + index" [isWishlist]="true" [title]="resources.popupPickSize.mainTitle" [titleNextStep]="resources.sizeGuide.mainTitle"  template="size" [selectedTab]="'0'" [item]="wishlistItem" ></app-modal>
<app-modal [id]="'confirmDeleteWishlistItem' + index" [modalClass]="'center'" width="42rem" [item]="wishlistItem" [title]="'WISHLIST.DELETE.TITLE' | translate" template="confirmDeleteWishlistItem"></app-modal>

