import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { FidelityService } from 'src/app/core/services/fidelity.service';
import { GoogleAnalyticService } from 'src/app/core/services/google-analytic.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { getCookieDomainByLang } from 'src/app/core/utils/filter.utils';

@Component({
  selector: 'app-authcallback',
  templateUrl: './authcallback.component.html',
  styleUrls: ['./authcallback.component.scss'],
})
export class AuthcallbackComponent implements OnInit {

  isInitialAuthentication = true;

  constructor(private fidelityService: FidelityService, private translate: TranslateService, private trackingService:TrackingService, public authService : OidcSecurityService, private route : Router, private cookie:CookieService, private storage:StorageService, private jwtService: JWTService, private gA: GoogleAnalyticService,) {

  }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe( (d:AuthenticatedResult) => {
          let authenticated = d.allConfigsAuthenticated.find(x => x.isAuthenticated);
          if(authenticated) {
            let locale = this.cookie.get("v_locale")?.replace("_","-");

            //SET APPROPRIATED COOKIE DURATION
            let storedValues: any = localStorage.getItem("desktop");
            storedValues = storedValues != null ? JSON.parse(storedValues) : null;

            if(storedValues){
              let accessTokenExpirationDate = new Date(storedValues.access_token_expires_at);
              this.jwtService.setTokenCookie(this.translate.currentLang, accessTokenExpirationDate);
            }

            if(this.storage.redirectTo){
              this.isInitialAuthentication = false;
              let redirectFragment = this.storage.redirectTo;
              this.storage.deleteRedirectTo();


              this.route.navigateByUrl(redirectFragment);
            }
            else if(this.isInitialAuthentication) {
              this.isInitialAuthentication = false;

            //fac
            if(this.jwtService._connectedAsDelegate$.value == true) this.trackingService.track_login_delegate();
            else this.trackingService.track_login_client();

            if(this.jwtService._connectedAsDelegate$.value == true) this.route.navigateByUrl('/' + locale + '/myvictoria/demos');
            else this.route.navigateByUrl('/' + locale + '/club/home');
            }
          } else this.isInitialAuthentication = true;
    })

    this.authService.checkAuthIncludingServer().subscribe(auth => {

      if (auth && auth.isAuthenticated) {

        let storedValues: any = localStorage.getItem("desktop");
        storedValues = storedValues != null ? JSON.parse(storedValues) : null;

        if (storedValues.authnResult != undefined) {
          storedValues.authnResult.access_token = "";
          localStorage.setItem("desktop", JSON.stringify(storedValues))
        }

        const clientId = this.cookie.get('v_gi') ?? "";
        this.fidelityService.syncCartWishlist(clientId, this.jwtService.getToken(), this.jwtService.getSubFromToken());

        //Create access_token cookie HTTP only
        //STILL USEFULL??
        // this.cookie.set('Token', this.authService.getAccessToken(), 1 / 24, '/', getCookieDomainByLang(this.translate.currentLang), true);
        //this.route.navigate(['/club/home']);
      } else {
        //STILL USEFULL??
        // this.cookie.delete('Token');
        this.jwtService.deleteTokenCookie();
      }
    }, err => {
      //console.log("authCallback error, redirect to root");
      this.jwtService.deleteTokenCookie();
      this.route.navigate(["/"]);

    })
  }

}
