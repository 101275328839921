import { Injectable } from '@angular/core';
import { CRUDService } from './crud.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { filterURL, getApiUrlFromOrigin, getContextByUrl, getCookieDomainByContext, getCookieDomainByLang, getCookieDomainByUrl, getVictoriaUrl } from '../utils/filter.utils';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoaderService } from "./loader.service";
import { CookieService } from "ngx-cookie-service";
import { TriggerService } from "./trigger.service";
import { TranslationService } from "./translation.service";
import { JWTService } from "./jwt.service";
import { ErrorService } from "./error.service";
import { GoogleAnalyticService } from "./google-analytic.service";
import { AlertService } from "./alert.service";
import { ModalService } from "./modal.service";
import { Router } from '@angular/router';
import { ErrorType } from '../enums/errorType.enum';
import { registerPlugin } from '@capacitor/core/types/global';

const BASE_URL = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root'
})
export class GetDatasService extends CRUDService<any> {


  //Summary Infos
  private _summary$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public summary$: Observable<any> = this._summary$.asObservable();
  private _forecast$: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
  public forecast$: Observable<any[]> = this._forecast$.asObservable();
  //Client Profile
  private _clientData$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public clientData$: Observable<any> = this._clientData$.asObservable();
  //Client Referrals
  private _clientReferrals$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public clientReferrals$ : Observable<any> = this._clientReferrals$.asObservable();


  //INTERNAL
  private profileLoading: boolean = false;
  public summaryLoading: boolean = false;
  private httpHeaders: HttpHeaders;

  constructor(
    httpClient: HttpClient,
    jwtService: JWTService,
    private loader: LoaderService,
    private storage: StorageService,
    private http: HttpClient,
    private cookie: CookieService,
    private trigger: TriggerService,
    private translate: TranslationService,
    private jwt: JWTService,
    private modal: ModalService,
    private router: Router,
    private errorService: ErrorService
  ) {
    super(httpClient, jwtService);
    this.API_URL = getApiUrlFromOrigin();
  }

  public getSummary(clientSub: any) {
    if (!this.summaryLoading) {
      this.summaryLoading = true;
      this.getOneWithAutentification(`clients/${clientSub}/summary`).subscribe(res => {
        this._summary$.next(res?.data);
        this.errorService.dropError(ErrorType.SUMMARY);
        this.errorService.dropError(ErrorType.DATATABLE)
        this.summaryLoading = false;

        // let registeredStatus = this.cookie.get("currentStatus");
        const registeredStatus = this.storage.registeredStatusId
        const summaryStatus = res?.data?.status?.statusId;

        if(registeredStatus && registeredStatus!=null && registeredStatus!='' && summaryStatus && registeredStatus < summaryStatus) {
          this.storage.showCongratulation = true;
          this.modal.setDisplayCongratulationModale(true);
        }

      }, err => {
        this.errorService.raiseError(ErrorType.SUMMARY);
        this.errorService.raiseError(ErrorType.DATATABLE)
        this.summaryLoading = false;
      });
    }
  }

  public updateSummaryInternaly(data: any, withAlert = false) {
    this._summary$.next(data);
    if (withAlert) {
      if (data.status.hasUpgradedStatus) {
        this.modal.open("#congratulationsModal");
        this.storage.showCongratulation = false;
        this.modal.setDisplayCongratulationModale(false);
        //this.cookie.set("currentStatus", data.status.statusId, 1/24, '/', getCookieDomainByLang(this.storage.languageCode));
      }
    }
  }

  public summaryHasDatas(){
    return this._summary$.getValue()!==undefined;
  }

  public getFidelityClientActions() {
    return this.getOne(`fidelity/clientactions/${2}`);
  }

  public getActions(clientSub: number, param: { showPurchases: boolean; showVouchers: boolean; showOthers: boolean }, pageSize: number) {
    return this.getOneWithAutentification(`clients/${clientSub}/actions`, { ...param, pageIndex: 0, pageSize });
  }

  public getVouchers(clientSub: any, pageSize: number, filter: any) {

    return this.getOneWithAutentification(`clients/${clientSub}/vouchers`, { pageIndex: 0, pageSize, ...filter });
  }

  public getReferralsInfos(clientSub:any, clientId:any) {
      this.httpHeaders = new HttpHeaders();
      this.httpHeaders = this.httpHeaders.append("X-ClientId", clientId);
      this.httpHeaders = this.httpHeaders.append("Authorization", "Bearer " + this.jwt.getToken());

      this.http.get<any>(`${BASE_URL}/clients/${clientSub}/referrals`, {headers: this.httpHeaders}).subscribe(result => {
        if(result.data){
          this._clientReferrals$.next(result.data);
        }
      })
  }

  public getClientDatas(clientSub, notifyLoaded = false) {
    if (!this.profileLoading) {
      this.profileLoading = true;
      this.getOneWithAutentification(`clients/${clientSub}/profile`).subscribe(res => {
        let mustUpdateCookie = this._clientData$.getValue()!=undefined || this.storage.doUpdateCookie == "true";

        this._clientData$.next(res?.data);
        this.updateDelegateCookies(mustUpdateCookie, res.data.delegateId, res.data);
        this.triggerLoad(notifyLoaded);
        this.profileLoading = false;
        this.storage.registeredStatusId = res?.data?.statusId;

      }, err => {
        this.triggerLoad(notifyLoaded);
        this.profileLoading = false;
      });
    }
  }

  public mustLoadProfile(): boolean {
    return this._clientData$.getValue() == undefined;
  }

  public updateClientDataInternaly(clientData) {
    this._clientData$.next(clientData);
  }

  public filter(url: any) {
    if(url.includes("cloudfront")) return url;
    else return getVictoriaUrl(this.storage.languageCode, true) + filterURL(url);
  }

  public getProductImagesPathForThumbs(fileName: string) {
    if (!fileName) fileName = "empty.jpg";
    return "https://d16xcbgaysva9k.cloudfront.net/200x200/" + fileName.toLocaleLowerCase();

  }

  public getProductImagesPathForHigh(fileName: string) {
    if (!fileName) fileName = "empty.jpg";
    return "https://d16xcbgaysva9k.cloudfront.net/400x400/" + fileName.toLocaleLowerCase();

  }

  public getProductImagesPathForAmbiance(fileName: string) {
    if (!fileName) fileName = "empty.jpg";
    return "https://d16xcbgaysva9k.cloudfront.net/1200x1200/" + fileName.toLocaleLowerCase();

  }

  public getVoucher(contextId: number, voucherCode: string) {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders = this.httpHeaders.append("Authorization", "Bearer " + this.jwt.getToken());
    this.httpHeaders = this.httpHeaders.append("Accept", "application/pdf");
    const locale = this.cookie.get("v_locale");
    return this.http.get(BASE_URL + '/vouchers/download/' + voucherCode + '/' + locale, { headers: this.httpHeaders, responseType: 'blob' });
  }

  private triggerLoad(active) {
    if (active) this.loader.loaded();
  }

  public updateDelegateCookies(must, delegateId: string, profile) {

    if (must && delegateId) {

      this.storage.doUpdateCookie = false;

      let delegateCountryCode = delegateId?.substring(0, 2);
      let delegateContextId = delegateCountryCode == "BE" ? 2 : delegateCountryCode == "FR" ? 1 : delegateCountryCode == "DE" ? 3 : 0;

      const contextId = getContextByUrl(window.origin);
      const storedDelegateId =  this.cookie.get("delegateId");

      if(contextId == delegateContextId){
        this.cookie.set("delegateId", delegateId, 365, "/", getCookieDomainByUrl(window.origin));
      }
      //CHEK EXISTING delegateId Validity
      else if(storedDelegateId && storedDelegateId!=null && storedDelegateId!=''){
          delegateCountryCode = storedDelegateId?.substring(0, 2);
          delegateContextId = delegateCountryCode == "BE" ? 2 : delegateCountryCode == "FR" ? 1 : delegateCountryCode == "DE" ? 3 : 0;

          if(delegateContextId!=contextId) {
            this.cookie.delete("delegateId", "/", getCookieDomainByUrl(window.origin));
          }

      }

      this.storage.doUpdateCookie = false;
    }

    this.cookie.set("victoria_profile", JSON.stringify(profile), 365, "/", getCookieDomainByUrl(window.origin));

  }

  public updateClientStatusCookies(statusId:any){
    this.cookie.set("v_cstatus", statusId, null, "/", getCookieDomainByUrl(window.origin));
  }

  public deleteClientStatusCookie(){
    this.cookie.delete("v_cstatus","/",getCookieDomainByUrl(window.origin))
  }
}
