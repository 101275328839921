<div class="order-item flex" [ngClass]="border ? 'border' : ''" *ngIf="resources">
  <div class="item-image flex">
    <figure>
      <img loading="lazy" *ngIf="item.productGroup" [src]="getDataService.getProductImagesPathForThumbs(item.productGroup?.productImage)" />
      <img loading="lazy" *ngIf="item.imageUrl" [src]="item.imageUrl" />
      <img loading="lazy" *ngIf="item.imageFilename" [src]="getDataService.getProductImagesPathForThumbs(item.imageFilename)" />
    </figure>
  </div>
  <div class="item-info flex column flex-3">
    <div class="item-name">{{item.productGroup?.name ??  item.name}}</div>
    <div class="item-price">{{item.unitPrice | agoraCurrency}}</div>
    <div class="item-specs flex j-start ai-center">
      <div *ngIf="false && item.productGroup && item.productGroup.products.length==1">{{resources.profileOrders.sizesLabels}}: {{resources.shoppingBag.detailsLabel}}</div>
      <div *ngIf="item.productGroup && item.productGroup.products.length>1">{{resources.profileOrders.sizesLabels}}: {{getItemSize()}}</div>
      <div *ngIf="item.size && !item.productGroup">{{resources.profileOrders.sizesLabels}}: {{item.size}}</div>
      <div *ngIf="false && !item.size && !item.productGroup">{{resources.profileOrders.sizesLabels}}: {{resources.shoppingBag.detailsLabel}}</div>
      <div>{{resources.profileOrders.quantityLabel}}: {{item.quantity}}</div>
    </div>
  </div>
</div>
