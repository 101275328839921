import {Component, Input, OnInit} from '@angular/core';
import { ErrorService } from 'src/app/core/services/error.service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import {CardType} from "../../../core/enums/card-type.enum";
import {Forecast} from "../../../core/models/forecast.model";

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {

  @Input() cardType: CardType;
  @Input() data: any;
  @Input() forecasts: Forecast[];
  @Input() footerInformationTooltipText: string;
  @Input() footerInformationTooltipPosition: string;
  @Input() footerInformationTooltipWidth: string;
  @Input() footerInformationTooltipHeight: string;
  @Input() showTooltip: boolean;
  @Input() showWalletButton: boolean;
  @Input() showStatusLink: boolean;
  @Input() paddingBottom = true;
  @Input() hiddeFooterAction = false;
  @Input() dropPadding = false;

  cardTypeEnum = CardType;

  constructor(public errorService:ErrorService, public dataService:GetDatasService) { }

  ngOnInit() {}

}
