import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {

  @Input() mainTitle: string;
  @Input() subTitle: string;
  @Input() shortMarginTop = false;
  @Input() classes:string = ''

  constructor() { }

  ngOnInit() {}

}
