import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { OrderService } from 'src/app/core/services/order.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-nocart',
  templateUrl: './nocart.component.html',
  styleUrls: ['./nocart.component.scss'],
})
export class NocartComponent implements OnInit {

  @Input() isAuthenticated:boolean
  resources: any;
  locale:string;
  order:any;

  constructor(private orderService:OrderService, public translateConfig : TranslationService, public translate:TranslationService, public jwt : JWTService, private cookie:CookieService, public storage:StorageService, private router:Router) { }

  ngOnInit() {
    this.locale = this.cookie.get("v_locale")?.replace("_","-");

    this.orderService.panierOrder$.subscribe(panier => {
      if(panier) this.order = panier;
    })

    this.translate.localesLoaded.subscribe(res => {
      if(res==true){
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    })
  }

  goToCollection(){

    if(this.jwt.isAuthenticated() && this.order && this.order.demoStatusId ==2 ){
      return `/${this.locale}/myvictoria/demo/${this.order.demoCode}/jewelry`

    } else return this.translateConfig.getCollectionMenuLocalizedLink();
  }

  goToAuthentication(){
    this.storage.redirectTo = "/" + this.locale + "/public/cart";
    this.router.navigateByUrl("/" + this.locale);
  }

}
