import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { getCookieDomainByLang, getRootUrl, getVictoriaUrl } from 'src/app/core/utils/filter.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-light',
  templateUrl: './header-light.component.html',
  styleUrls: ['./header-light.component.scss'],
})
export class HeaderLightComponent implements OnInit, AfterViewInit {

  @Output() requestRegistration: EventEmitter<any> = new EventEmitter();
  @Input() color: string;

  langSettings = { country: "Belgique (FR)", flag: "#icon-flag-be" }
  headerLocales: any

  isMobileMenu = false;
  locale:string;

  constructor(
    public dataService: GetDatasService,
    public storage: StorageService,
    private translate: TranslateService,
    private translateConfig: TranslationService,
    private cookie: CookieService,
    public breadcrumb: BreadcrumbService,
    private authService: OidcSecurityService,
    private jwtService: JWTService,
    private router:Router
  ) {

  }

  ngOnInit() {
    this.translateConfig.localesLoaded.subscribe(d => {
      if (d == true) {
        this.headerLocales = this.translateConfig.getJsonLocales()?.header;
      }
    })
  }

  ngAfterViewInit(): void {
    this.initLangage(this.storage.languageCode, this.cookie.get('active_language'));
  }

  public openCountries(mobile = false) {

    this.isMobileMenu = mobile;

    if (mobile) document.getElementsByClassName("lang-options-mobile")[0]?.classList.toggle("open");
    else document.getElementsByClassName("lang-options")[0]?.classList.toggle("open");
  }

  public currentLangage(code) {
    return this.storage.languageCode === code;
  }

  public currentRegion(code) {
    return this.cookie.get('active_language')?.replace('-', '_') === code;
  }

  public initLangage(code: string, region?: string) {
    region = (region || code).replace('-', '_');

    this.translate.use(code).toPromise().then(_ => {
      this.storage.languageCode = code;
      this.updateLangageInfo(region);
      this.translateConfig.langHasChanded.next(true);

      this.cookie.set("v_locale", code, 365, '/', getCookieDomainByLang(code));
      if (!environment.production && region && region != null && region != '') this.cookie.set("active_language", region.replace('_', '-'), 365, '/', getCookieDomainByLang(code));
      //if(environment.production) window.location.href = getRootUrl(code, region);
      this.locale = this.locale = code.replace('_', '-');;
    });
  }

  public goToCart(){
    this.router.navigate([`/${this.locale}/public/cart`])
  }

  public changeLangage(code: string, region?: string) {

    const previousCode = this.storage.languageCode;

    region = (region || code).replace('-', '_');

    this.translate.use(code).toPromise().then(_ => {
      this.storage.languageCode = code;
      this.updateLangageInfo(region);
      this.openCountries(this.isMobileMenu);
      this.translateConfig.langHasChanded.next(true);

      this.cookie.set("v_locale", code, 365, '/', getCookieDomainByLang(code));
      if (region && region != null && region != '') this.cookie.set("active_language", region.replace('_', '-'), 365, '/', getCookieDomainByLang(code));

      const isSameCountry = (previousCode.endsWith("be") && this.storage.languageCode.endsWith("be")) || (previousCode.endsWith("de") && this.storage.languageCode.endsWith("de"));

      if (this.jwtService.isAuthenticated() && !isSameCountry) {
        this.jwtService.deleteTokenCookieUsingLang(previousCode);
        this.authService.logoff('desktop');

         window.location.href = getRootUrl(code, region);


      } else {
         window.location.href = getRootUrl(code, region);
      }
    });
  }

  public registerListeners() {
    this.requestRegistration.emit();
  }

  private updateLangageInfo(code) {
    switch (code) {
      case "fr_be":
        this.langSettings.country = "Belgique (FR)"
        this.langSettings.flag = "#icon-flag-be"
        break;
      case "nl_be":
        this.langSettings.country = "België (NL)"
        this.langSettings.flag = "#icon-flag-be"
        break;
      case "fr_fr":
        this.langSettings.country = "France (FR)"
        this.langSettings.flag = "#icon-flag-fr"
        break;
      case "de_de":
        this.langSettings.country = "Deutschland (DE)"
        this.langSettings.flag = "#icon-flag-de"
        break;
      case "de_at":
        this.langSettings.country = "Österreich (DE)"
        this.langSettings.flag = "#icon-flag-at"
        break;
      case "nl_nl":
        this.langSettings.country = "Nederland (NL)"
        this.langSettings.flag = "#icon-flag-nl"
        break;
      case "fr_lu":
        this.langSettings.country = "Luxemburg (FR)"
        this.langSettings.flag = "#icon-flag-lu"
        break;
    }

    // document.getElementById("flag").setAttribute("href", this.langSettings.flag)
  }

  public getLogoUrl() {
    return getVictoriaUrl(this.translate.currentLang, true) + '/' + this.translateConfig.getJsonLocales()?.lang;
  }
}
