<div class="progress-demo-container" *ngIf="!goToDemo">

  <div class="container">
    <div class="demo-image-container background-image">
      <img loading="lazy" src="assets/images/demo.png">
    </div>
    <div class="demo-content-container text-container">
      <div class="demo-participation-container">
        <img loading="lazy" class="logo" src="/assets/images/logo.svg" alt="Logo Victoria">
        <div class="demo-participation">
          <div *ngIf="demo" class="has-demo">
            <div class="flex column ai-center">
              <h2 class="bold">{{'DEMO.ONBOARDING.DATE' | translate:{'X': demo.date | date} }}</h2>
              <p class="p-s">{{'DEMO.LIST.ITEM.HOSTESS' | translate:{'hostessName': demo.hostessDisplayName} }}</p>
            </div>
            <div class="content-box">
              <p class="p-s">{{'DEMO.ONBOARDING.DELEGATE' | translate:{'X': demo.delegateDisplayName} }}</p>
            </div>
            <div *ngIf="demo.statusId === 1">
              <ng-container *ngTemplateOutlet="status1"></ng-container>
            </div>
            <div *ngIf="demo.statusId === 2">
              <ng-container *ngTemplateOutlet="status2"></ng-container>
            </div>
            <div *ngIf="demo.statusId === 3">
              <ng-container *ngTemplateOutlet="status3"></ng-container>
            </div>
            <div *ngIf="demo.statusId === 4">
              <ng-container *ngTemplateOutlet="status4"></ng-container>
            </div>
          </div>
          <div data-testId="noDemoToJoin" *ngIf="error.getDemoError$ | async" class="no-demo">
            <div class="oops-block">
              <svg viewBox="0 0 32 32" class="peach">
                <use xlink:href="#icon-oops"></use>
              </svg>
              <p>Oops!</p>
            </div>
            <p class="wrong_link">{{'DEMO.ONBOARDING.WRONG_LINK' | translate}}</p>
            <p>{{'DEMO.ONBOARDING.WRONG_LINK.DETAILS' | translate}}</p>
            <hr>
            <p>{{'DEMO.ONBOARDING.WEBSITE' | translate}}</p>
            <div class="link">
              <app-wavedlink data-testId="noDemoRedirectionLink" [linkText]="'DEMO.ONBOARDING.VICTORIA' | translate" [externalUrl]="getSiteUrl()"></app-wavedlink>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="progress-demo-container loading flex center-all" *ngIf="goToDemo" >
  <div class="loader"></div>
</div>

<ng-template #status1>
  <div *ngIf="!registrationDone">
    <p>{{'DEMO.ONBOARDING.WELCOME' | translate: {'X': demo.hostessDisplayName, 'Y': demo.date | date} }}</p>
    <p *ngIf="!isAuthenticated">{{'DEMO.ONBOARDING.CONNECT' | translate}}</p>
    <label class="flex row checkbox path checkbox-optin">
      <input data-testId="privacyConsent" type="checkbox" [(ngModel)]="isOptin">
      <svg viewBox="0 0 28 28">
        <use xlink:href="#custom-checkbox"></use>
      </svg>
      <p class="p-s">{{'DEMO.ONBOARDING.OPTIN.LABEL' | translate}}</p>
    </label>
    <div class="cta-container">
      <button data-testId="joinFuturDemo" (click)="authenticate('state=registered')" [disabled]="!isOptin">
        <label>{{'DEMO.ONBOARDING.INSCRIPTION' | translate}}</label>
      </button>
    </div>
    <div class="demo-no-present">
      <p>{{'DEMO.ONBOARDING.NO_PRESENT' | translate}}</p>
      <div class="cta-container">
        <button data-testId="discoverCollection" class="secondary" (click)="navigateUrl(this.translateConfig.getCollectionMenuLocalizedLink())">
          <label>{{'DEMO.ONBOARDING.DISCOVER' | translate}}</label>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="registrationDone">
    <p>{{'DEMO.ONBOARDING.THANKS_INSCRIPTION' | translate}}</p>
    <p>{{'DEMO.ONBOARDING.DELEGATE_KNOWS' | translate}}</p>
    <p class="lightbold">{{'DEMO.ONBOARDING.REMAINING_DAYS' | translate:{'X': getRemainingDays(demo.date)} }}</p>
    <p>{{'DEMO.ONBOARDING.COLLECTION' | translate}}</p>
    <div class="cta-container">
      <button data-testId="demoViewCollection" (click)="navigateUrl(this.translateConfig.getCollectionMenuLocalizedLink())">
        <label>{{'DEMO.ONBOARDING.WATCH_COLLECTION' | translate}}</label>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #status2>
  <p>{{'DEMO.ONBOARDING.WELCOME2' | translate:{'X': demo.hostessDisplayName} }}</p>
  <div *ngIf="!isAuthenticated">
    <p>{{'DEMO.ONBOARDING.JOIN' | translate}}</p>
    <div class="cta-container">
      <button data-testId="joinDemoAuthenticateFirst"(click)="authenticate('state=joined')">
        <label>{{'DEMO.ONBOARDING.PARTICIPATE2' | translate}}</label>
      </button>
    </div>
  </div>
  <div *ngIf="isAuthenticated">
    <div class="cta-container">
      <button data-testId="joinDemo" (click)="join()">
        <label>{{'DEMO.ONBOARDING.PARTICIPATE2' | translate}}</label>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #status3>
  <p>{{'DEMO.ONBOARDING.WELCOME3' | translate:{'X': demo.hostessDisplayName, 'Y': demo.date | date} }}</p>
  <div>
    <div class="cta-container">
      <button (click)="goToEshop()">
        <label>{{'DEMO.ONBOARDING.ORDER' | translate}}</label>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #status4>
  <p>{{'DEMO.ONBOARDING.CLOSED' | translate}}</p>
  <div>
    <div class="cta-container">
      <button (click)="navigateUrl(this.translateConfig.getCollectionMenuLocalizedLink())">
        <label>{{'DEMO.ONBOARDING.WISHLIST' | translate}}</label>
      </button>
    </div>
  </div>
</ng-template>
