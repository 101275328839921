import {Injectable} from '@angular/core';
import {LoaderButtonService} from '../../../../core/services/loader-button.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, empty, Observable, of, Subject} from 'rxjs';
import {DemoProductItemModel} from '../models/demo-product-item.model';
import {TranslationService} from '../../../../core/services/translation.service';
import {LoaderService} from '../../../../core/services/loader.service';
import {DemoAddClientNewDTO} from '../dto/demo-add-client-new.dto';
import {DemoOrdersModel} from '../models/demo-orders.model';
import {JWTService} from '../../../../core/services/jwt.service';
import {DemoModel, DemosResponse} from '../models/demo.model';
import {DemoClientModel} from '../models/demo-client.model';
import {getApiUrlFromOrigin, getCookieDomainByContext} from '../../../../core/utils/filter.utils';
import {v4 as uuidv4} from 'uuid';
import {CookieService} from 'ngx-cookie-service';
import {ErrorType} from "../../../../core/enums/errorType.enum";
import {ErrorService} from "../../../../core/services/error.service";
import { StorageService } from 'src/app/core/services/storage.service';
import { Router } from '@angular/router';
import {OrderActionEnum} from "../enums/order-action.enum";
import { DemoTypesEnum } from 'src/app/core/enums/demo-type.enum';
import { TrackingService } from 'src/app/core/services/tracking.service';


const BASE_URL = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root'
})
export class DemoService extends LoaderButtonService {

  private orderActionEnum = OrderActionEnum;

  private headers: HttpHeaders;

  //has 1 demo available
  private _hasDemo$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public hasDemo$: Observable<string> = this._hasDemo$.asObservable();

  //get demos
  private _demos$: BehaviorSubject<DemosResponse> = new BehaviorSubject(undefined);
  public demos$: Observable<DemosResponse> = this._demos$.asObservable();

  private _isClientListing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isClientListing$: Observable<boolean> = this._isClientListing$.asObservable();

  //get demo
  public _demo$: BehaviorSubject<DemoModel> = new BehaviorSubject(undefined);
  public demo$: Observable<DemoModel> = this._demo$.asObservable();

  //get progress demo
  public _progressDemo$: BehaviorSubject<DemoModel> = new BehaviorSubject(undefined);
  public progressDemo$: Observable<DemoModel> = this._progressDemo$.asObservable();

  //get demoOrders
  private _demoOrders$: BehaviorSubject<DemoOrdersModel> = new BehaviorSubject(undefined);
  public demoOrders$: Observable<DemoOrdersModel> = this._demoOrders$.asObservable();

  //get demoOrderClients
  private _demoClients$: BehaviorSubject<DemoClientModel[]> = new BehaviorSubject(undefined);
  public demoClients$: Observable<DemoClientModel[]> = this._demoClients$.asObservable();

  //get clients
  private _clients$: BehaviorSubject<DemoClientModel[]> = new BehaviorSubject(undefined);
  public clients$: Observable<DemoClientModel[]> = this._clients$.asObservable();

  //client Order Refresher
  private _orderRefresher$: Subject<any> = new Subject()
  public orderRefresher$:Observable<any> = this._orderRefresher$.asObservable();

  //trigger hide overlay
  private _hideOverlay$: Subject<boolean> = new BehaviorSubject(false);
  public hideOverlay$:Observable<boolean> = this._hideOverlay$.asObservable();

  //get jewelry
  private _jewelry$: BehaviorSubject<DemoProductItemModel> = new BehaviorSubject(undefined);
  public jewelry$: Observable<DemoProductItemModel> = this._jewelry$.asObservable();

  //get jewelry
  private _createClientByDemoCode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public createClientByDemoCode$: Observable<boolean> = this._createClientByDemoCode$.asObservable();

  //get clientOrderInfo
  private _clientOrderInfos$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public clientOrderInfos$: Observable<any> = this._clientOrderInfos$.asObservable();

  private _clientLinkLight$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public clientLinkLight$: Observable<any> = this._clientLinkLight$.asObservable();

  private _clientLinkLightTemp$: Subject<any> = new Subject();
  public clientLinkLightTemp$: Observable<any> = this._clientLinkLightTemp$.asObservable();

  private _demosSummary$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public demosSummary$: Observable<any> = this._demosSummary$.asObservable();


  //Internal
  private reloadOrdersPending = false;

  constructor(
    private http: HttpClient,
    private translate: TranslationService,
    public loader: LoaderService,
    private jwt: JWTService,
    private cookie: CookieService,
    private error: ErrorService,
    private storage: StorageService,
    private router: Router,
    private tracking: TrackingService
  ) {
    super();
  }

  public updateInternalDemo(demo){
    //Work around to keep current access level
    const currentAccessLevel = this._demo$.getValue().accessLevel;
    demo.accessLevel = currentAccessLevel;
    //

    this._demo$.next(demo);
  }

  public setHasDemo(demoCode:string){
    this._hasDemo$.next(demoCode);
  }

  public setDemoClosed(){
    const demo = this._demo$.value;
    demo.statusId = 4;

    this._demo$.next(demo);
  }

  public getDemoListingSummary(){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    this.http.get<any>(BASE_URL + `/demos/summary`, {headers: this.headers}).subscribe(res => {
      if (res && res!=null && res.data) {
       this._demosSummary$.next(res.data);
      }
    });
  }

  public getAllDemos(isClient:boolean, demoType=DemoTypesEnum.OPEN, pageIndex=0, pageSize=20 ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    this.http.get<any>(BASE_URL + `/demos?isClient=${isClient}&pageIndex=${pageIndex}&pageSize=${pageSize}&typeId=${demoType}`, {headers: this.headers}).subscribe(res => {
      if (res.data) {
        this._demos$.next(res.data);
        this._isClientListing$.next(res.data.isClientListing);
      }
    });
  }

  public refreshClient(client:DemoClientModel){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    return this.http.get<any>(BASE_URL + `/demos/${demoCode}/clients/ByCode/${client.clientCode}`, {headers: this.headers});
  }


  public getDemo(demoCode: string, forceCall = false, notifyLoaded = false, redirectIfnoAccess = true) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());
    if(!this._demo$.value || forceCall) {

      this.http.get<any>(BASE_URL + `/demos/` + demoCode, {headers: this.headers}).subscribe(res => {
        if (res.data) {

          if(res.data.accessLevel==0){
            this.triggerLoad(notifyLoaded);

            if(redirectIfnoAccess){
              const navigateTo = `/${this.cookie.get("v_locale").replace("_","-")}/public/demo/${res.data.delegateId}/${res.data.demoCode}`
              this.router.navigate([navigateTo]);
            }
          } else {

            //setCurrentDemoCookiesthis.storage.setCurrentDemoCookies(res.data);
            this.storage.setDemoLogCookie(res.data)
            this._hasDemo$.next(res.data.demoCode);

            this._demo$.next( res.data);
          }
        }

        this.triggerLoad(notifyLoaded);
      }, err => {
        this.triggerLoad(notifyLoaded);
        this.error.raiseError(ErrorType.DEMO)
        // let navigateToErrorOnBoarding = `/${this.cookie.get("v_locale").replace("_","-")}/public/demo/XX12345/${demoCode}`
        // this.router.navigate([navigateToErrorOnBoarding]);
      });
    }
  }


  public getDemoByCode(demoCode: any, delegateId: any) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());
    this.http.get<any>(BASE_URL + `/demos/` + delegateId + '/' + demoCode, {headers: this.headers}).subscribe(res => {
      if (res.data) {
        this._progressDemo$.next(res.data);
      }
    }, error => {
      this.error.raiseError(ErrorType.DEMO)
    });
  }


  public getDemoOrders(forceCall = false, notifyLoaded = false, redirect = true) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    if((!this._demoOrders$.value || forceCall) && !this.reloadOrdersPending) {
      this.reloadOrdersPending =  true;
      this.http.get<any>(BASE_URL + `/demos/` + demoCode + '/demoorder', {headers: this.headers}).subscribe(res => {
        if (res.data) {

          res.data.clientLinks?.forEach(c => c.orders?.forEach(x => x.changeStep = redirect));

          this._demoOrders$.next(res.data);
          this._demoClients$.next(res.data.clientLinks);
        }

        this.triggerLoad(notifyLoaded);
        this.reloadOrdersPending = false;
      } ,error =>
        this.triggerLoad(notifyLoaded));
        this.reloadOrdersPending = false;
    }
  }

  public getOrderInfos(demoCode:string, clientGuid:string){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    this.http.get<any>(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orderinfos`, {headers: this.headers}).subscribe( res => {
      if(res && res.data){
        this._clientOrderInfos$.next(res.data);
      }
    })
  }


  public updateSingleDemoOrder(clientGuid) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    if(demoCode)
      this.http.get<any>(BASE_URL + `/demos/` + demoCode + '/demoorder', {headers: this.headers}).subscribe(res => {
          if (res.data) {
              const client = res.data.clientLinks.find(x => x.clientGuid == clientGuid);
              if(client && client.orders[0]){
                const currentOrder = client.orders[0];
                currentOrder.changeStep = true;
                this.refreshClientSubject(currentOrder);
              }
          }
        } ,error => console.log("Error Update demo Order : ", error));
  }

  private refreshClientSubject(order){

    const datas = this._demoClients$.value;
    if(datas) {
      for(const i of datas){
        for(const o of i.orders){
          if(o.resolvedId == order.resolvedId)  {
            o.changeStep = order.changeStep ?? false;
            o.flowStatuses = order.flowStatuses;
            o.vouchersTotal = order.vouchersTotal;
            o.walletAmount = order.walletAmount;
            o.maxWalletAllowed = order.maxWalletAllowed;
            o.vouchers = order.vouchers;
            o.total = order.total;
            o.totalToPay = order.totalToPay;
            o.totalPaid = order.totalPaid;
            o.paidTotal = order.paidTotal;
            o.turnover = order.turnover;
            o.deliveryFee = order.deliveryFee;
            o.deliveryTypeId = order.deliveryTypeId;
            o.deliveryAddress = order.deliveryAddress;
            o.deliveryFeeAmount = order.deliveryFeeAmount;
          }
        }
      }

      this._demoClients$.next([...datas]);
    }
  }


  public submitDemo() {
    this._loaderButton$.next(true);
    setTimeout(() => {
      this._loaderButton$.next(false);
    }, 3000);
  }

  public getDelegateDatas(delegateCode: string,) {
     return this.http.get<any>(`${BASE_URL}/delegates/${delegateCode}/siteprofile`)
  }

  public getJewelryDatas(productId: string, notifyLoaded = false,) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());
    this.headers = this.headers.append('related', 'true');

    this.http.get(BASE_URL + `/Catalog/eshop/products/${productId}`, {headers: this.headers, params: {languageCode: this.cookie.get('v_locale'), related: true}}).subscribe((res: any) => {
        if (res.data) {
          this._jewelry$.next(res.data);
        }
        this.triggerLoad(notifyLoaded);
      }, err => {
        this.triggerLoad(notifyLoaded);
      });

    // this.http.get(BASE_URL + `/Catalog/products/${productId}/${this.cookie.get('v_locale')}`, {headers: this.headers, params: {related: true}}).subscribe((res: any) => {
    //   if (res.data) {
    //     this._jewelry$.next(res.data);
    //   }
    //   this.triggerLoad(notifyLoaded);
    // }, err => {
    //   this.triggerLoad(notifyLoaded);
    // });

  }

  public createClientByCode(demoCode, clientCode): Observable<any>  {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());
    if(!this._createClientByDemoCode$.value) {
      //TODO HANDLE EXCEPTIONS
      this._createClientByDemoCode$.next(true);
      return this.http.post(`${BASE_URL}/demos/${demoCode}/clients/ByCode`, {clientCode:clientCode.toString()}, {headers: this.headers});
    } else {
      return empty();
    }
  }

  public addClientByCode(value: any): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    return this.http.post(BASE_URL + `/demos/${demoCode}/clients/ByCode`, {clientCode: value.clientId}, {headers: this.headers});
  }

  public addClientByForm(addClientFormDTO: DemoAddClientNewDTO): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    return this.http.post(BASE_URL + `/demos/${demoCode}/clients/ByForm`, {...addClientFormDTO},{headers: this.headers});
  }

  public updateClientByForm(addClientFormDTO: DemoAddClientNewDTO): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    return this.http.post(BASE_URL + `/demos/${demoCode}/clients/ByForm`, {...addClientFormDTO},{headers: this.headers});
  }

  public getClientByCode(clientCode: string): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    return this.http.get(BASE_URL + `/demos/${demoCode}/clients/ByCode/` + clientCode, {headers: this.headers});
  }

  public deleteOrderItem(sku: string, clientGuid: string, resolvedId: string) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();


    return this.http.delete(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/cart/${sku}`, {withCredentials: true, headers: this.headers}).subscribe(res => {
      if (res) {
        this.getDemoOrders(true);
      }
    });
  }

  public putOrderItem(sku: string, newsku: string, quantity: number, clientGuid: string, resolvedId?:string) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    return this.http.put(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/cart/${sku}`, {sku :newsku, quantity}, {withCredentials: true, headers: this.headers}).subscribe(res => {
      if (res) {
        this.getDemoOrders(true);
      }
    });
  }

  public postOrderItem(sku: string, quantity: number, clientGuid: string, resolvedId:string, remove=false, itemSku=undefined, forceCall=true) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    this.http.post(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/cart/${sku}`, {sku, quantity}, {withCredentials: true, headers: this.headers}).subscribe(res => {
      if (res) {
        if(remove && itemSku) this.deleteWishlistItem(itemSku, clientGuid, resolvedId)
        this.getDemoOrders(forceCall);
      }
    });

  }

  public postWishlistItem(sku: string, clientGuid: string, resolvedId?:string, forceCall = true) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode = this.storage.currentDemoCode();

    return this.http.post(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/wishlist/${sku}`, {sku, quantity: 1}, {withCredentials: true, headers: this.headers}).subscribe(res => {
      if (res) {
        this.getDemoOrders(forceCall);
      }
    });

  }

  public deleteWishlistItem(sku: string, clientGuid: string, resolvedId?:string) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const demoCode =  this.storage.currentDemoCode();

    return this.http.delete(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/wishlist/${sku}`, {withCredentials: true, headers: this.headers}).subscribe(res => {
      if (res) {
        this.getDemoOrders(true);
      }
    });
  }

  public getClientLinkLight(demoCode:string, clientGuid:string){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    return this.http.get(`${BASE_URL}/demos/${demoCode}/order/client/${clientGuid}`, {headers: this.headers}).subscribe((res:any) => {
      if(res && res.data){
        this._clientLinkLight$.next(res.data);
        this._clientLinkLightTemp$.next(res.data);
      }
    }, err => {

    })
  }

  public updateClientDatas(value: any, type: number, clientGuid: string, demoCode:string = undefined) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    if(!demoCode) demoCode =  this.storage.currentDemoCode() ?? this.storage.demoId;

    if(type!=4) return this.http.put(BASE_URL + `/demos/${demoCode}/universe/${clientGuid}/actions`, {option: type, value}, {withCredentials: true, headers: this.headers});
    else return this.http.put(BASE_URL + `/demos/${demoCode}/universe/${clientGuid}/actions`, {option: type, demoRequestDate: value}, {withCredentials: true, headers: this.headers});
  }

  public updateJewelryWishlist(value: boolean) {
    if(this._jewelry$) {
      const el = this._jewelry$;
      el.value.isInWishlist = value;
      this._jewelry$.next(el.value);
    }
  }

  public putDelegateComment(demoCode:string, comments:string){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());
    this.headers = this.headers.append('content-type', 'application/json');


    return this.http.put(BASE_URL + `/demos/${demoCode}`, {comments}, {withCredentials: true, headers: this.headers});
  }

  public postClientOrderAction(actionId: number, demoCode: string, clientGuid: string, paymentMethod?: number, resolvedId?: string): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());
    this.headers = this.headers.append('content-type', 'application/json');

    if(this.canSendAction(actionId, clientGuid, resolvedId)) {
      return this.http.post(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/actions/${actionId}`, {paymentTypeId: paymentMethod}, {withCredentials: true, headers: this.headers});
    } else {
      const clientOrder = this._demoOrders$.value.clientLinks.find(x => x.clientGuid === clientGuid)?.orders.find(x => x.resolvedId == resolvedId);
      return of({data: clientOrder});
    }
  }

  public postClientGuestOrderAction(actionId: number, demoCode: string, clientGuid: string): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-ContextId", this.translate.getContextId().toString());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    this.headers = this.headers.append('content-type', 'application/json');

    return this.http.post(BASE_URL + `/orders/guestorder/actions/${actionId}`, null, {withCredentials: true, headers: this.headers});
  }

  public postDipSignature(demoCode: string, clientGuid: string, signature: string, resolvedId?:string){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    this.headers = this.headers.append('content-type', 'application/json');
    const body = {
      base64Payload: signature.replace('data:image/png;base64,', ''),
      'content-type': 'image/png'
    }

    return this.http.post(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/dip/signature`,  body, { headers: this.headers });
  }

  public getDipSignature(demoCode: string, clientGuid: string, resolvedId: string) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    this.headers = this.headers.append('content-type', 'application/json');

    return this.http.get(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/dip/signature`,  { headers: this.headers });
  }

  public getDipPDF(demoCode: string, clientGuid: string, resolvedId: string) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    this.headers = this.headers.append("Accept", "application/pdf");

    return this.http.get(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolvedId}/dip`,  { headers: this.headers , responseType: 'blob'});
  }

  public updateSingleClientLinks(clientLink: any, getDemoOrders = true) {
    const demoOrders = this._demoOrders$.value;
    if(demoOrders) {
      demoOrders.clientLinks.forEach(x => {
        if(x.clientGuid === clientLink.clientGuid) {
          Object.assign(x, clientLink);
          this._demoOrders$.next(demoOrders);
        }
      });
    }
    if(getDemoOrders) {
      this.getDemoOrders(true);
    }
  }

  public updateDemoInternaly(demo:DemoModel){
    this._demo$.next(demo)
  }

  public getDemoSettings(demoCode: any) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    return this.http.get(BASE_URL + `/demos/${demoCode}/parameters`,  { headers: this.headers});

  }

  public saveDemoSettings(demoCode: any, values =  {}) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    return this.http.put(BASE_URL + `/demos/${demoCode}/parameters/save`,values,  { headers: this.headers});

  }

  public openDemo(demoCode: any, values = {}) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    return this.http.put(BASE_URL + `/demos/${demoCode}/parameters/open`,values,  { headers: this.headers});

  }


  public closeDemo(demoCode: any, values =  {}) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    return this.http.put(BASE_URL + `/demos/${demoCode}/parameters/close`,values,  { headers: this.headers});

  }

  public checkIfDemoOrderExists(demoCode:string){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());
    return this.http.get<any>(BASE_URL + `/demos/${demoCode}/parameters/checkdelcomorder`, {headers: this.headers});

  }


  public transferDemoToIntranet(demoCode:string, ordersToTransfert:any = null){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-ClientId', this.getClientId());
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());
    return this.http.post<any>(BASE_URL + `/demos/${demoCode}/demoorder`,ordersToTransfert, {headers: this.headers});
  }


  public triggerHideOverlay() {

    setTimeout(() => {
      this._hideOverlay$.next(true);
      this._hideOverlay$.next(false);
    }, 1);
  }

  private triggerLoad(active) {
    if (active) {
      this.loader.loaded();
    }
  }

  private getClientId() {

    const currentDate = new Date();
    //Current Date Add 1 Year with object mutation
    currentDate.setFullYear(currentDate.getFullYear() + 1);

    let id = this.cookie.get('v_gi');

    if (!id || id === '') {
      id = uuidv4();
    }

    //Update Cookie Expiration Time
    this.cookie.set('v_gi', id, currentDate, '/', getCookieDomainByContext(this.translate.getContextId()));
    return id;
  }

  private canSendAction(actionId: number, clientGuid: string, resolvedId?: string) {

    let clientOrder = null;

    if(!resolvedId) clientOrder = this._demoOrders$.value.clientLinks.find(x => x.clientGuid === clientGuid).orders[0];
    else clientOrder = this._demoOrders$.value.clientLinks.find(x => x.clientGuid === clientGuid).orders.find(x => x.resolvedId == resolvedId);

    if((actionId === this.orderActionEnum.CART_VALIDATED_BY_DELEGATE ||
      actionId === this.orderActionEnum.CART_SENT_BY_CLIENT ||
      actionId === this.orderActionEnum.CART_VALIDATED_BY_CLIENT
      )
      && clientOrder.flowStatuses.cart.status < 10) {
      return true;
    } else if (actionId === this.orderActionEnum.VOUCHERS_VALIDATED && clientOrder.flowStatuses.vouchers.status <= 10) {
      return true;
    }  else if (actionId === this.orderActionEnum.DELIVERY_VALIDATED && clientOrder.flowStatuses.delivery.status < 10) {
      return true;
    } else if ((actionId === this.orderActionEnum.DIP_VALIDATED_BY_CLIENT
      || actionId === this.orderActionEnum.DIP_SENT
      || actionId === this.orderActionEnum.DIP_VALIDATED_BY_DELEGATE
      || actionId === this.orderActionEnum.DIP_UNVALIDATE
    ) && clientOrder.flowStatuses.dip.status < 10 ) {
      return true;
    } else if (actionId === this.orderActionEnum.ORDER_CONFIRMED && clientOrder.flowStatuses.confirmation.status < 10) {
      return true;
    } else if (actionId === this.orderActionEnum.PAYMENT_PAID ||
      actionId === this.orderActionEnum.PAYMENT_SENT ||
      actionId === this.orderActionEnum.PAYMENT_HANDLED_BY_DELEGATE ||
      actionId === this.orderActionEnum.PAYMENT_UNVALIDATE ||
      actionId === this.orderActionEnum.PAYMENT_SET_TYPE_BY_DELEGATE
    ) {
      return true;
    } else if(actionId === this.orderActionEnum.ORDER_CONFIRMATION_CANCELED && clientOrder.flowStatuses.confirmation.status >= 10) {
      return true;
    }

    return false;
  }

  public createFakeDemo(){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
    return this.http.post(BASE_URL + '/demos/dummy', null, { headers: this.headers})
  }

  public resetDemo(demoCode:string){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

    this.http.delete(BASE_URL + `/demos/${demoCode}/reset`, { headers: this.headers}).subscribe(result => {
      console.log(`Demo ${demoCode} has been reset!`);
      this.getDemoOrders(true);
    });
  }
}
