import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import {ClientSummary} from '../../../core/models/client-summary.model';
import {GetDatasService} from '../../../core/services/get-datas.service';
import {Router} from "@angular/router";
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';
import { OrderService } from 'src/app/core/services/order.service';
import { JWTService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss'],
})
export class JumbotronComponent implements OnInit, AfterViewInit {

  @Input() reduce = false;

  currentClientSummary: ClientSummary;
  headerLocales:any;
  langueChangedSubscription : Subscription;

  demoCode:string;
  isDemoClient = false;

  langSettings = {country:"Belgique (FR)", flag:"#icon-flag-be"};
  locale:string;

  status = {
    1: 'rubis',
    2: 'emeraude',
    3: 'saphire',
    4: 'diamant'
  };

  constructor(
    private getDatasService: GetDatasService,
    public storage: StorageService,
    private translateConfig:TranslationService,
    private cookie:CookieService,
    public demoService:DemoService,
    public orderService:OrderService,
    public jwt:JWTService,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.locale = this.cookie.get("v_locale")?.replace("_","-");
    this.getDatasService.summary$.subscribe(data => {
      if(data) {
        this.currentClientSummary = data;
      }
    })

    this.orderService.panierOrder$.subscribe(v => {
      if(v && v.demoCode){
        this.demoCode = v.demoCode;
        this.isDemoClient = v.accessLevel > 0;
      }
    })

  //   this.authService.userData$.subscribe( d=> {
  //     let userData = d.allUserData.find(x => x.configId=="desktop")?.userData;
  //     if(userData && !this.getDatasService.summaryLoaded()){
  //       this.getDatasService.getSummary(userData.sub);
  //     }
  // })

    // this.updateLangageInfo(this.storage.languageCode);
    this.translateConfig.localesLoaded.subscribe( d => {
      if(d==true) {
        this.headerLocales = this.translateConfig.getJsonLocales()?.header;
      }
    })
  }

  ngAfterViewInit(){
    // if(this.langueChangedSubscription) this.langueChangedSubscription.unsubscribe();
    // this.langueChangedSubscription = this.translateConfig.langHasChanded.subscribe((value)=> {
    //   if(value===true) {
    //     this.headerLocales = this.getDatasService.getJsonLocales()?.header;
    //   }
    // })
  }

  // public showSideMenu() {
  //   document.getElementsByClassName('side-menu')[0].classList.toggle('menu-visible');
  //   document.getElementsByTagName('main')[0].classList.toggle('fixedHeight');
  // }

  // public openCountries() {
  //   document.getElementsByClassName("lang-options-mobile")[0].classList.toggle("open");
  // }

  // public currentLangage(code) {
  //   return this.storage.languageCode === code;
  // }

  // public changeLangage(code){
  //   this.translate.use(code);
  //   this.storage.languageCode = code;
  //   this.updateLangageInfo(code);
  //   this.openCountries();
  //   this.translateConfig.langHasChanded.next(true);
  // }

  // private updateLangageInfo(code){
  //   switch(code){
  //     case "fr_be":
  //       this.langSettings.country="Belgique (FR)"
  //       this.langSettings.flag="#icon-flag-be"
  //       break;
  //     case "nl_be":
  //       this.langSettings.country="Belgïe (NL)"
  //       this.langSettings.flag="#icon-flag-be"
  //       break;
  //     case "fr_fr":
  //       this.langSettings.country="France (FR)"
  //       this.langSettings.flag="#icon-flag-fr"
  //       break;
  //     case "de_de":
  //       this.langSettings.country="Deutschland (DE)"
  //       this.langSettings.flag="#icon-flag-de"
  //       break;
  //   }
  // }

  goToDemo(){
    this.router.navigate([`/${this.locale}/myvictoria/demo/${this.demoCode}`]);
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;

    if(!document.getElementsByClassName('jumbotron')[0]) return;

    if (verticalOffset > 375) {
      document.getElementsByClassName('jumbotron')[0]?.classList.add('scrolled');
    } else {
      document.getElementsByClassName('jumbotron')[0]?.classList.remove('scrolled');
    }
  }
}
