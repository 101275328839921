import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { registerPlugin } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, of } from 'rxjs';
import { ErrorType } from 'src/app/core/enums/errorType.enum';
import { ErrorService } from 'src/app/core/services/error.service';
import { FidelityService } from 'src/app/core/services/fidelity.service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { GoogleAnalyticService } from 'src/app/core/services/google-analytic.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { OrderService } from 'src/app/core/services/order.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { getCookieDomainByContext, getCookieDomainByUrl, getVictoriaUrl } from 'src/app/core/utils/filter.utils';
import {DemoService} from "../../../../../agora/demo/services/demo.service";
import {OrderActionEnum} from "../../../../../agora/demo/enums/order-action.enum";
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-order-payment-status',
  templateUrl: './order-payment-status.component.html',
  styleUrls: ['./order-payment-status.component.scss'],
})
export class OrderPaymentStatusComponent implements OnInit, OnChanges, OnDestroy {

  @Input() order;
  @Input() profile;
  @Input() resources;
  @Input() demoCode: string;
  @Input() status = 0;
  @Input() isDemo = false;
  @Output() changeStep: EventEmitter<number> = new EventEmitter()
  @Output() setErrorCode: EventEmitter<number> = new EventEmitter()

  locale:string;
  toDelegate = false;
  expired = false;;

  orderActionEnum = OrderActionEnum;

  //NG Life Cycle
  subscriptions: Subscription[] = []

  constructor(private orderService:OrderService,
    private jwt:JWTService,
    private getDataService:GetDatasService,
    private storage:StorageService,
    public translate:TranslationService,
    private translation:TranslateService,
    private cookie:CookieService,
    private trigger:TriggerService,
    public error:ErrorService,
    private router:Router,
    private demoService: DemoService,
    private gA:GoogleAnalyticService,
    private activated:ActivatedRoute,
    private modalService:ModalService) { }

  ngOnInit() {

    //console.log("order-payment-status component init");

    this.locale = this.cookie.get("v_locale")?.replace("_","-");

    this.activated.queryParams.subscribe(q => {
        if((q.toDelegate && q.toDelegate=='true')) this.toDelegate = true;
        else this.toDelegate = false;

        if(q.expired && q.expired=='true'){
          this.expired = true;
          return;
        }

        if((q.confirmation && q.confirmation=='true') || (q.isLyfpay && q.isLyfpay=='true')){
          this.status = 2;
          const order = this.storage.tempOrder;
          if(order){
            const profile = this.cookie.get("victoria_profile");
            if(profile && profile!=null && profile!=''){
              const jsonProfile = JSON.parse(profile);
              jsonProfile.delegateId = this.storage.tempDelegateId;

              this.getDataService.updateDelegateCookies(true, this.storage.tempDelegateId, jsonProfile);
            }

            if(this.isDemo) this.demoService.getClientLinkLight(this.demoCode, this.jwt.getSubFromToken())
            this.gA.GAOrderConfirmed(order);
            localStorage.removeItem("tdid");
            localStorage.removeItem("tco");
          }
        }else {
          this.error.dropError(ErrorType.GETPAYMENTINFO);
          const paymentRef = this.cookie.get("v_upl");
          if(paymentRef && paymentRef!=null && paymentRef!='') this.checkOrder(paymentRef);
          else this.router.navigate([ '/' + this.locale + '/public/cart']);
        }
      }
    )

    this.subscriptions.push(this.demoService.clientLinkLightTemp$.subscribe((client) => {
      if(!this.jwt.isDelegate()){
        if(client && (client.isDemoRequest==false || client.isDelegateRequest==false)) this.openBecomeDelegateHostessModal();
      }
    }));
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes.isDemo && changes.isDemo.currentValue!=undefined && changes.isDemo.currentValue && this.status==2){
      this.demoService.getClientLinkLight(this.demoCode, this.jwt.getSubFromToken())
    }
  }

  private checkOrder(paymentRef, isCallback = false){
    this.order = this.storage.tempOrder;

    if(this.status == 0 || isCallback) {
    this.orderService.getPaymentStatus(this.order?.resolvedId, paymentRef).subscribe(res => {

      this.status = res.data;
      this.handlePaymentStatus(isCallback, paymentRef);

    }, err => {
      this.error.raiseError(ErrorType.GETPAYMENTINFO);
    })
    } else {
      this.handlePaymentStatus(isCallback, paymentRef);
    }
  }

  public getLogoUrl(){
    return getVictoriaUrl(this.translation.currentLang, true) + '/' + this.translate.getJsonLocales()?.lang;
  }

  private handlePaymentStatus(isCallback, paymentRef){

    if(this.status!=2 && this.status!=0 ) {
      this.setErrorCode.emit(this.status)
      this.trigger.requestOrderNextStep(3);
    } else if(this.status!=2 && !isCallback){
      setTimeout(_ => {
        this.checkOrder(paymentRef, true)
      },1000);
    } else if(this.status==2 && this.storage.tempDelegateId){

      // this.cookie.set("delegateId", this.storage.tempDelegateId,365,"/", getCookieDomainByContext(this.order.contextId));
      const profile = this.cookie.get("victoria_profile");

      const order = this.storage.tempOrder;
      if(order)this.gA.GAOrderConfirmed(order);

      if(profile && profile!=null && profile!=''){
        const jsonProfile = JSON.parse(profile);
        jsonProfile.delegateId = this.storage.tempDelegateId;

        this.getDataService.updateDelegateCookies(true, this.storage.tempDelegateId, jsonProfile);
        // this.cookie.set("victoria_profile", JSON.stringify(jsonProfile), 365,"/", getCookieDomainByContext(this.order.contextId));
      }

      if(this.isDemo) this.demoService.getClientLinkLight(this.demoCode, this.jwt.getSubFromToken())
      localStorage.removeItem("tco");
      localStorage.removeItem("tdid");
      this.cookie.delete("v_upl", "/", getCookieDomainByUrl(window.origin));

    } else if(this.status==0 && isCallback){
      this.trigger.requestOrderNextStep(3);
    }
  }

  private openBecomeDelegateHostessModal() {
    window.setTimeout( () => this.modalService.openAgoraModal("become-delegate-modal"), 3000);
  }

  ngOnDestroy(){
    //console.log("Order Payment Status component destroyed")
    this.subscriptions.forEach(x => x.unsubscribe())
  }
}
