<main
  *ngIf="(breadcrumb.loaded$ | async) && (translation.localesLoaded | async)"
  [class.pink-container]="breadcrumb.usePinkColor$ | async"
>
  <!-- MENU TOP -->
  <app-header
    class="fixed-header"
    *ngIf="
      (breadcrumb.showHeader$ | async) ||
      ((breadcrumb.showHeaderMobileOnly$ | async) && screen.isMobile)
    "
    (openCloseUserMenu)="updateUserMenuState($event)"
    (openCloseMenu)="updateMenuState($event)"
    (requestRegistration)="registerListeners()"
    [isMenuOpen]="isMenuOpen"
    [isUserMenuOpen]="isUserMenuOpen"
  ></app-header>
  <app-header-light
    *ngIf="
      (breadcrumb.showHeader$ | async) === false &&
      (breadcrumb.showHeaderMobileOnly$ | async) && screen.isMobile
    "
    (requestRegistration)="registerListeners()"
  ></app-header-light>
  <!-- END MENU TOP -->

  <!-- BANNER -->
  <app-jumbotron
    class="fixed-jumbo"
    *ngIf="breadcrumb.showJumbotron$ | async"
  ></app-jumbotron>

  <!-- MAIN CONTAINER -->
  <section
    class="main-container"
    [ngClass]="
      currentUrl.includes('agora') || currentUrl.includes('public/demo')
        ? 'agora-template'
        : ''
    "
    [class.enlarge_screen]="breadcrumb.enlargeScreen$ | async"
    [class.max_screen]="breadcrumb.maxScreen$ | async"
    [class.enlarge_screen_mobile]="breadcrumb.enlargeScreenMobile$ | async"
    [class.drop-jumbo-height]="
      (breadcrumb.showJumbotron$ | async) === false &&
      (breadcrumb.showBackToCart$ | async) === false
    "
    [class.pink-container]="breadcrumb.usePinkColor$ | async"
    [class.marginTop]="breadcrumb.marginTop$ | async"
    [class.no-container]="breadcrumb.noContainer$ | async"
    [class.no-header]="
      !(
        (breadcrumb.showHeader$ | async) === false &&
        (breadcrumb.showHeaderMobileOnly$ | async) && screen.isMobile
      ) &&
      !(
        (breadcrumb.showHeader$ | async) ||
        ((breadcrumb.showHeaderMobileOnly$ | async) && screen.isMobile)
      )
    "
  >
    <!-- BANNER -->
    <!-- <app-jumbotron  *ngIf="breadcrumb.showJumbotron$ | async"></app-jumbotron> -->

    <app-go-to-demo-banner
      *ngIf="
        (breadcrumb.goToDemoBanner$ | async) &&
        (orderService.panierOrder$ | async)?.demoStatusId == 2
      "
    ></app-go-to-demo-banner>
    <app-breadcrum *ngIf="breadcrumb.showBreadcrumb$ | async"></app-breadcrum>
    <!-- END BANNER -->

    <!-- GLOBAL ERROR MESSAGE  -->
    <div
      *ngIf="(errorService.summaryError$ | async) && !loginLogoutRequested"
      class="error-box margin-top-50 center font-XXL"
      [innerHTML]="'GLOBAL.GENERAL.ERROR' | translate"
    ></div>
    <!-- END GLOBAL ERROR MESSAGE -->
    <div
      *ngIf="
        (errorService.summaryError$ | async) === false || loginLogoutRequested
      "
    >
      <router-outlet></router-outlet>
    </div>
  </section>
  <!-- END MAIN CONTAINER -->

  <!-- BACK TO TOP -->
  <app-back-to-top *ngIf="!noLayout"></app-back-to-top>
  <!-- END BACK TO TOP -->

  <!-- FOOTER -->
  <app-footer></app-footer>
  <!-- END FOOTER -->

  <!-- GENERAL USER ALERT -->
  <app-alert
    [id]="'display-alert-toast'"
    [message]="alert.message"
    [icon]="alert.icon"
    [urlLink]="alert.urlLink"
    [type]="alert.type"
    [position]="alert.position"
  ></app-alert>
  <!-- END GENERAL USER ALERT -->
</main>
<app-sandbox-bar *ngIf="isNotProd"></app-sandbox-bar>

<div *ngIf="showVersion" class="version">
  <span *ngIf="jwtService.connectedAsDelegate$ | async">Delegate</span>
  <span *ngIf="(jwtService.connectedAsDelegate$ | async) === false"
    >Client</span
  >
  <br />{{ version }}<br />
  <button
    *ngIf="jwtService.hasDelegateRole$ | async"
    (click)="jwtService.switchRole()"
  >
    switchRole
  </button>
</div>
<!-- CONTEXT REDIRECTION MODALE -->
<app-modal
  id="redirectionModal"
  [title]="'REDIRECTION.TITLE' | translate"
  template="contextRedirectionModal"
  [isClosable]="false"
></app-modal>
<!-- END CONTEXT REDIRECTION MODALE -->
<!-- CONGRATULATION MODALE -->
<app-modal
  [id]="'congratulationsModal'"
  template="congratulationsModal"
  modalClass="congratulationsModal"
></app-modal>
<!-- END CONGRATULATION MODALE -->
<app-modal-sandbox-alert></app-modal-sandbox-alert>
