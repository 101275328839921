<div class="profile-order-container" *ngIf="resources">
  <app-page-title mainTitle="{{'ORDER.TITLE'| translate: {'NumCommande': currentOrderId} }}">
  </app-page-title>
  <app-order-details
    *ngIf="order && order.id"
    [orderItems]="order.orderItems"
    [displayBackToOrders]="{title: resources.profileOrders.backButtonLabel, link: '/club/orders'}"
    [order]="order">
  </app-order-details>
  <app-error-box [withFrame]="false" *ngIf="(error.getOrderByIdError$ | async)"></app-error-box>
</div>
