import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'src/app/core/services/storage.service';
import { getAreaByUrl, getCookieDomainByUrl } from 'src/app/core/utils/filter.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss'],
})
export class RedirectionComponent implements OnInit {

  constructor(private cookie:CookieService, private router:Router, private storage:StorageService, private translate:TranslateService) { }


  ngOnInit() {

    let translationLang = this.cookie.get("v_locale");
    let locale = this.cookie.get("v_locale")?.replace("_","-");
    if(locale && locale!=null && locale!='') {
      this.translate.use(translationLang);
      this.router.navigate(["/" + locale]);
    }
    else {
      const area = getAreaByUrl(window.location.origin);
      const availableLanguages = environment.allowedLocales[area];

        locale = availableLanguages[0];

        this.storage.languageCode = availableLanguages[0]?.replace("-","_");
        this.cookie.set("v_locale", availableLanguages[0]?.replace("-","_"),365, '/', getCookieDomainByUrl(window.location.origin));

        const lang = this.cookie.get("active_language");

        if(!lang || lang==null || lang=='') this.cookie.set("active_language", locale, 365, '/', getCookieDomainByUrl(window.location.origin));

        if(locale=="nl-be" && (lang=="fr-lu" || lang=="fr-be"))  this.cookie.set("active_language", "nl-be", 365, '/', getCookieDomainByUrl(window.location.origin));
        else if(locale=="fr-be" &&  (lang=="nl-be")) this.cookie.set("active_language", "fr-be", 365, '/', getCookieDomainByUrl(window.location.origin));

        this.translate.use(this.storage.languageCode);
         this.router.navigate(["/" + locale]);
    }
  }
}
