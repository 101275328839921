<div class="modal-confirm-container">
  <div class="modal-title gap-10">
    <svg *ngIf="titleIconLeft">
        <use [attr.xlink:href]="titleIconLeft"></use>
    </svg>
    <p>{{modalTitle}}</p>
    <svg *ngIf="titleIconRight">
      <use [attr.xlink:href]="titleIconRight"></use>
    </svg>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="cta-container">
    <button data-testId="confirm-button" (click)="confirmAction(true)" [disabled]="isBusy">
      <label *ngIf="!okButton">{{'GLOBAL.ACTIONS.CONFIRM' | translate}}</label>
      <label *ngIf="okButton">{{ okButton }}</label>
      <div *ngIf="isBusy && isConfirmation" class="loader-icon loader loader-mini"></div>
    </button>
    <button class="secondary" (click)="confirmAction(false)" [disabled]="isBusy">
      <label *ngIf="!cancelButton">{{'GLOBAL.ACTIONS.CANCEL' | translate}}</label>
      <label *ngIf="cancelButton">{{ cancelButton }}</label>
      <div *ngIf="isBusy && !isConfirmation" class="loader-icon loader loader-mini"></div>
    </button>
  </div>
</div>
