<div class="discount-box" *ngIf="voucherCount != - 1 && voucherCount>0">
  <div class="voucher-selection-container">
    <div class="header">{{'ORDER_TUNNEL.REDUCTIONS.CLUB' | translate }}</div>
    <div [innerHTML]="'ORDER_TUNNEL.REDUCTIONS.XVOUCHERSAVAILABLE' | translate: {'X': availableVouchers.length ?? 0}"></div>
    <div class="form-control flex row j-space-between ai-center input-box m-gap-20">
      <form [formGroup]="voucherForm">
        <select data-testId="voucher-selection" placeholder="Choisis ton Chèque Club" class="voucher-input" formControlName="voucher" [attr.disabled]="(order?.totalToPay - order.deliveryFee) > 0 ? null : ''">
          <option disabled selected value="default">{{'ORDER_TUNNEL.REDUCTIONS.CLUB.PLACEHOLDER' | translate }}</option>
          <option *ngFor="let voucher of availableVouchers" [value]="voucher.code">{{voucher.amount | agoraCurrency}} - {{'GLOBAL.VOUCHER.TYPE_' + voucher.typeId | translate}} - {{voucher.code}}</option>
        </select>
      </form>
      <button
        data-testId="voucher-selection-action"
        class="btn btn-primary btn-animated {{(order?.totalToPay - order.deliveryFee) > 0 && enableVoucherButton ? '' : 'disabled'}} add-voucher-done"
        [ngClass]="demoCode ? 'secondary' : ''"
        (click)="useVoucher()"
        [disabled]="isBusy">
        <span *ngIf="!used">{{'GLOBAL.ACTION.USE' | translate }}</span>
        <span *ngIf="used">{{'GLOBAL.ACTION.USED' | translate }}</span>
        <svg viewBox="0 0 32 32" class="icon add-voucher-check" >
          <use xlink:href="#icon-checkmark"></use>
        </svg>
      </button>
    </div>
  </div>
</div>
