import { getCookieDomainByUrl } from 'src/app/core/utils/filter.utils';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ClientOrder } from 'src/app/core/models/clientOrder.model';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { OrderService } from 'src/app/core/services/order.service';
import { PublicOrderService } from 'src/app/core/services/publicOrder.service';
import { ScreenService } from 'src/app/core/services/screen.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { OrderSummaryComponent } from '../commande/components/order-summary/order-summary.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-public-order-payment',
  templateUrl: './public-order-payment.component.html',
  styleUrls: ['./public-order-payment.component.scss'],
})
export class PublicOrderPaymentComponent implements OnInit, OnDestroy {

  public resources:any;
  public order:ClientOrder;
  public demoCode:string;

  public selectedMethod:string;
  public error:Number = 0;
  public errors:any[] = [];
  public isDipChecked = false;
  public isDip;
  public isPaymentDelegate = false;

  public isConfirmation = false;
  public isExpired = false;

  public loaded = false;

  public locale:string
  public paymentStatus:any = 0;

  @ViewChild(OrderSummaryComponent) orderSummaryComponent: any;

  //NG Life Cycle
  subscriptions: Subscription[] = []

  constructor(
    public translate:TranslationService,
    private activeRoute:ActivatedRoute,
    public jwt:JWTService,
    private publicOrderService:PublicOrderService,
    private router: Router,
    private orderService:OrderService,
    private dataService:GetDatasService,
    private cookie:CookieService,
    public screen:ScreenService
  ) { }

  ngOnInit() {

    //console.log("Init Order public");

    this.locale = this.cookie.get('v_locale')?.replace('_', '-');
    const paymentRef = this.cookie.get("v_upl");

    this.subscriptions.push(this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    }));

    this.subscriptions.push(this.activeRoute.params.subscribe(params => {
      if(params.orderId && params.clientGuid){
        const id  = params.orderId;
        const client = params.clientGuid;

        this.publicOrderService.getOrder(id, client);
      }
    }))

    this.subscriptions.push(this.activeRoute.queryParams.subscribe(params => {
      if(params.confirmation && params.confirmation=="true"){
        this.isConfirmation = true;
      }

      if(params.expired && params.expired=="true"){
          this.isConfirmation = true;
          this.isExpired = true;
      }
    }))

    this.subscriptions.push(this.publicOrderService.clientOrder$.subscribe(clientOrder => {
      if(clientOrder){
        this.order = clientOrder;
        this.demoCode = this.order.demoCode

        this.isDipChecked = this.order.flowStatuses.dip.status >= 10;
        const isPayment = (this.order.flowStatuses.confirmation.status>=10 && (this.order.flowStatuses.payment.status == 1 || this.order.flowStatuses.payment.status == 11));

        if(this.isDip==undefined) this.isDip = (this.order.flowStatuses.confirmation.status < 10);

        if(this.order?.id && this.order?.id!=0 && paymentRef && paymentRef!=null && this.jwt.isAuthenticated()) {
          this.orderService.getPaymentStatus(this.order.resolvedId, paymentRef).subscribe(res => {
              if(res && res.data!=0 && res.data!=2){
                this.paymentStatus = 0;
                this.setError(res.data);
              } else this.cookie.delete("v_upl", "/", getCookieDomainByUrl(window.origin))
          });
        } else if(this.order?.id && this.order?.id!=0 && paymentRef && paymentRef!=null) {
          this.orderService.getPublicPaymentStatus(this.order.resolvedId, this.order.clientSub, paymentRef).subscribe(res => {
            if(res && res.data!=0 && res.data!=2){
              this.paymentStatus = 0;
              this.setError(res.data);
            } else this.cookie.delete("v_upl", "/", getCookieDomainByUrl(window.origin))
        });
        }

        if(this.order.statusId>=2 && (paymentRef)) this.router.navigate([`/${this.locale}/public/order/${this.order.resolvedId}/client/${this.order.clientSub}/checkout`], {queryParams:{confirmation:'true'}});
        else if((!isPayment && !this.isDip) || (this.order.statusId>=2 && (!paymentRef))) this.router.navigate([`/${this.locale}/public/order/${this.order.resolvedId}/client/${this.order.clientSub}/checkout`], {queryParams:{expired:'true'}});


        if(!this.loaded) this.loaded = true;
      }
    }))
  }

  setIsDipCheck(value){
    this.isDipChecked = value;
  }

  setError(error){
    this.error = error;
  }

  updateErrors(error){
    this.errors = error;
  }

  selectPaymentMethod(value){
    this.selectedMethod = value;
  }

  paymentByDelegate(value: boolean) {
    if(value) {
      this.isPaymentDelegate = true;
      window.scrollTo({top: 0, behavior: "smooth"});
    }
  }

  @HostListener("unload")
  ngOnDestroy(){
    //console.log("public order payment component destroyed")
    this.subscriptions.forEach(x => x.unsubscribe())
  }

}
