import { environment } from "src/environments/environment"

export class MollieGetLinkLoad{
    public orderAmount:number
    public orderId:string
    public orderGuid:string
    public locale:string

    //Application Context : 1 = Intranet | 2 = eShop
    public applicationId:number = 2
    public country:string
    
    //Payment Method ID
    public method:string 

    //DEV ONLY - PROD DEACTIVATED
    public redirectUrl:string = `${environment.base_url}/public/checkout?step=4`

    //LYFPAY ONLY
    public failedRedirectUrl:string
}