<app-agora-modal classes="sandbox-modal" id="sandbox-modal" type="pop-up" [closable]="false">
<div class="modal-sandbox-container">
  <div class="modal-body">
    <div class="flex column center-all center">
      <h1>{{'GLOBAL.WARNING.SANDBOX.MODAL.TITLE' | translate}}</h1>
    </div>
    <div class="message-container flex m-column">
      <p [innerHTML]="'GLOBAL.WARNING.SANDBOX.MODAL' | translate"></p>
    </div>
  </div>
  <div class="cta-container">
    <button data-testId="confirm-button" (click)="close()" >
      <label>{{'GLOBAL.OK' | translate}}</label>
    </button>
  </div>
</div>
</app-agora-modal>
