import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient, HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { CookieService } from 'ngx-cookie-service';
import { from } from 'rxjs';
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';
import { environment } from 'src/environments/environment';
import { JWTService } from './jwt.service';
import { OrderService } from './order.service';
import { StorageService } from './storage.service';
import { TriggerService } from './trigger.service';
import { AlertService } from './alert.service';
import { Alert } from '../models/alert.model';
import { DemoClientModel } from 'src/app/features/agora/demo/models/demo-client.model';
import { MissingTranslationFromJSONHandler } from '../utils/missing-translation-handlet';
import { TranslateService } from '@ngx-translate/core';
import { getSocketUrlFromOrigin } from '../utils/filter.utils';

const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Credentials', 'true')

const connectionUrl = environment.sockets_url + "/agoraHub";
// const connectionUrl = getSocketUrlFromOrigin();

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private hubConnection: HubConnection
    private clientGuid:string;
    private demoCode:string;

    constructor(private jwtService:JWTService,
        private orderServices:OrderService,
        private demoServices:DemoService,
        private storage:StorageService,
        private cookie:CookieService,
        private trigger: TriggerService,
        private alert: AlertService,
        private translation:TranslateService
    ) {

    }

    public connect = (clientGuid, demoCode) => {

        this.clientGuid = clientGuid;
        this.demoCode = demoCode;

        //Check if user is connected
        const hasSocketConnection = this.storage.connectedToSocket!=''
        //Check if user has connection to current demoCode
        const connectedToCurrentDemo = (!hasSocketConnection) ? false : this.storage.connectedToSocket == demoCode

        if (!hasSocketConnection || !connectedToCurrentDemo) {
            console.log(`Client ${clientGuid} try to join Room with demoCode ${demoCode}`)
            this.startConnection(clientGuid, demoCode);
            this.addListeners();
            this.storage.connectedToSocket = demoCode
        }
    }

    private startConnection(clientGuid, demoCode) {
        this.hubConnection = this.getConnection();
        //Keep Connection Alive every 29 sec
        //Set Connection timeout to 59 sec
        this.hubConnection.keepAliveIntervalInMilliseconds = 29000;
        this.hubConnection.serverTimeoutInMilliseconds=59000;
        //

        this.hubConnection.start()
            .then(() => {
                this.joinRoom(clientGuid, demoCode)
            })
            .catch((err) => { })
    }

    private getConnection(): HubConnection {


        return new HubConnectionBuilder()
            .withUrl(connectionUrl, {
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Error)
            .build();
    }

    public joinRoom(clientGuid, demoCode) {
      if(this.hubConnection && this.hubConnection.state==HubConnectionState.Connected){
        const promise = this.hubConnection.invoke("Connect",  clientGuid.toString(), demoCode)
        .then(() => {
            console.log("Join Room Ok");
        })
        .catch((err) => {
            console.error("Error Join Room - Error Given : ", err)
        });

        return from(promise);
      }
    }

    private addListeners() {

        this.hubConnection.onreconnected(_ => {
            console.log("====> SIGNAL R RECONNECTION CALLBACK <====")
            this.joinRoom(this.clientGuid, this.demoCode);
        })

        this.hubConnection.on("Connected", (data: any) => {
            console.log("Socket Connection : ", data);
        })

        this.hubConnection.on("MessageToGroup", (data: any) => {
            console.log("Group Socket datas : ", data);

            const demoCode = this.cookie.get("currentDemoCode");
            const logLevel = this.cookie.get(demoCode) ?? 2;
            if(data.level <= logLevel && data.message) {
                const popup = new Alert();
                popup.message = this.createMessage(data.message, data.messageParameters);
                popup.duration = 6000;

                this.alert.raiseAlert(popup);
            }

            console.log("Group socket received, reload GuestOrder to get potential updates");


            this.orderServices.getGuestOrder();
            this.demoServices.getDemo(data.demoCode, true);

            this.trigger.socketReload(true);
        })

        this.hubConnection.on("RefreshOrder", (data: any) => {
            console.log("Socket datas : ", data);

            const demoCode = data.delegateReload? this.storage.currentDemoCode() : this.storage.demoId
            const delegateId = this.storage.delegateId
            const logLevel = this.cookie.get(demoCode) ?? 2;

            if(data.clientOrderReload){

                if(data.sender != data.receiver && this.jwtService.isDelegate() && this.cookie.get("currentDemoCode")==data.demoCode){    //vérifier expressement currentDemoCode propre à la conseillère demo
                    this.demoServices.updateSingleDemoOrder(data.sender);
                    this.trigger.socketReload(true);
                }
                else if(data.sender != data.receiver){
                    this.orderServices.getGuestOrder(delegateId, demoCode);
                    this.trigger.socketReload(true);
                }

                if(data.level <= logLevel && data.message) {
                    const popup = new Alert();
                    popup.message = this.createMessage(data.message, data.messageParameters);
                    popup.duration = 6000;

                    this.alert.raiseAlert(popup);
                }
            }
            else if(data.delegateReload && demoCode && demoCode != null && demoCode == data.demoCode) {

                if(data.level <= logLevel && data.message) {
                    const popup = new Alert();
                    popup.message = this.createMessage(data.message, data.messageParameters);
                    popup.duration = 6000;


                    this.alert.raiseAlert(popup);
                }

                // this.demoServices.getDemoOrders(true);
                this.trigger.socketReload(true);

            }
            else if(!data.delegateReload && !data.clientOrderReload && demoCode && demoCode != null && demoCode == data.demoCode){

                //On applique le filtre aux clients également? A discuter
                //Actuellement: OUI
                if(data.level <= logLevel && data.message) {
                    const popup = new Alert();
                    popup.message = this.createMessage(data.message, data.messageParameters);
                    popup.duration = 6000;

                    this.alert.raiseAlert(popup);
                }

                this.orderServices.getGuestOrder(delegateId, demoCode);
                this.trigger.socketReload(true);
            } else if(!data.delegateReload && !data.clientOrderReload){

                //On applique le filtre aux clients également? A discuter
                //Actuellement: OUI
                if(data.level <= logLevel && data.message) {
                    const popup = new Alert();
                    popup.message = this.createMessage(data.message, data.messageParameters);
                    popup.duration = 6000;

                    this.alert.raiseAlert(popup);
                }

                this.orderServices.getGuestOrder(delegateId);
                this.trigger.socketReload(true);
            }

        })
    }

    private createMessage(key, parameters){

        const objectParameters = {};

        if(parameters?.X) objectParameters["X"] = parameters.X;
        if(parameters?.Y) objectParameters["Y"] = parameters.Y;

        return this.translation.instant(key, objectParameters);
    }
}
