<div class="panier-container"  [class.hasBanner]="(breadcrumb.goToDemoBanner$ | async) && (orderService.panierOrder$ | async)?.demoStatusId == 2 && (orderService.panierOrder$ | async)?.accessLevel > 0" *ngIf="resources && loaded">
  <app-page-title
    [mainTitle]="resources.shoppingBag.mainTitle"
    [subTitle]="resources.shoppingBag.description | removeHtmlTag">
  </app-page-title>

  <app-nocart *ngIf="hasCart==false"  [isAuthenticated]="this.jwtService.isAuthenticated()"> </app-nocart>
  <app-cart-content *ngIf="hasCart==true" [wishlist]="orderService.wishlistOrder$ | async" [order]="orderService.panierOrder$ | async" [contextId]="translate.contextId$ | async"> </app-cart-content>
</div>
<div class="panier-container flex j-center ai-center" *ngIf="!resources || !loaded">
  <div class="loader"></div>
</div>
