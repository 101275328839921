import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    //ALL GET CALL EXCLUDED SITE RESOURCES AND SSO
    if (req.method === 'GET' && !req.url.endsWith(".json") && !req.url.startsWith(environment.identity_url)) {
      const httpRequest = req.clone({
        headers: req.headers.set('Cache-Control','no-cache').set("Pragma","no-cache"),
      });

      return next.handle(httpRequest);
    } else return next.handle(req);
  }
}
