import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableOptions} from '../../../../../shared/components/datatable/datatable.options';
import {ColumnType} from '../../../../../shared/components/datatable/enum/column.enum';
import {Router} from '@angular/router';
import {LoaderService} from "../../../../../core/services/loader.service";
import {OrderService} from "../../../../../core/services/order.service";
import {TranslationService} from "../../../../../core/services/translation.service";

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit {
  constructor(
    public orderService: OrderService,
    private translate: TranslationService,
  ) {
  }

  ngOnInit() {

    this.orderService.getOrders(this.translate.getContextId(),0, true);
  }
}
