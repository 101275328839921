import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClientOrderItem} from 'src/app/core/models/clientOrderItem.model';
import {GetDatasService} from 'src/app/core/services/get-datas.service';
import {ModalService} from 'src/app/core/services/modal.service';
import {OrderService} from 'src/app/core/services/order.service';
import {TranslationService} from '../../../../core/services/translation.service';
import {getVictoriaUrl, slugify} from '../../../../core/utils/filter.utils';
import {TranslateService} from '@ngx-translate/core';
import {DemoService} from '../../../../features/agora/demo/services/demo.service';
import {ClientOrder} from '../../../../core/models/clientOrder.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { StockService } from 'src/app/core/services/stock.service';
import { RoleEnum } from 'src/app/features/agora/demo/enums/role.enum';
import { DemoModel } from 'src/app/features/agora/demo/models/demo.model';
import { AgoraCurrency } from 'src/app/core/pipes/agoraCurrency.pipe';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ProductDisplayStatus } from '../../../../core/enums/product-display-status.enum';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent implements OnInit, OnChanges {

  @Input() item: ClientOrderItem;
  resources: any;
  @Input() index;
  @Input() classCondition: string;
  @Input() isInWishlist = false;
  @Input() demo:DemoModel;
  @Input() isDemo = false;
  @Input() isDemoDelegate = false;
  @Input() clientGuid: string;
  @Input() order: ClientOrder;
  @Input() wishlistElement = false;
  @Input() isReadOnly = false;
  @Input() bottomBorder = false;
  @Input() removeBin = false;

  //quantity select options
  public quantities: any[];
  //size selection options
  public sizes: any[] = [];
  public role:RoleEnum = RoleEnum.CLIENT;
  public currentDemo:DemoModel;

  public locale:string;


  constructor(
    public modal: ModalService,
    public dataService: GetDatasService,
    private orderService: OrderService,
    private translate: TranslationService,
    private translation: TranslateService,
    private demoService: DemoService,
    private storage: StorageService,
    private stockService: StockService,
    private agoraCurreny:AgoraCurrency,
    private cookie:CookieService,
    private router:Router
  ) {
  }

  ngOnInit() {
    this.locale =  this.cookie.get('v_locale')?.replace('_', '-');

    // this.demoService.demo$.subscribe(demo => {
    //   if (demo) {
    //     this.role = demo.accessLevel;
    //     this.currentDemo = demo;
    //   }
    //   else this.role = RoleEnum.CLIENT;

    //   this.getStockInfo();
    // })

    this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });

    if(this.demo){
      this.role = this.demo.accessLevel;
    }else this.role = RoleEnum.CLIENT;

    this.getStockInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.demo && changes.demo?.currentValue){
      this.role = this.demo.accessLevel;
      this.getStockInfo();
    }
  }

  getStockInfo(){
    this.sizes = [];

    if(this.item.productGroup && (this.isDemo || (this.demo && (this.demo?.statusId >=2 &&  this.demo?.statusId < 4 )))) this.item.productGroup.products.forEach(x => {
      const currentProductDisplayStatus = this.stockService.getArticleStatus(x, this.role == RoleEnum.DELEGATE);
      const displaySalesButton = (currentProductDisplayStatus==ProductDisplayStatus.available || currentProductDisplayStatus==ProductDisplayStatus.backorderForDelegate);

      const showRalMessage = currentProductDisplayStatus==ProductDisplayStatus.backorderForCustomer;
      const showRalCV = currentProductDisplayStatus==ProductDisplayStatus.backorderForDelegate;

      this.sizes.push({id: x.sku, value: x.size + ' - ' + this.agoraCurreny.transform(x.price) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : '')  + (showRalCV ? ' | RAL' : ''), stock: x.stock, canSelect: displaySalesButton });
    });
    else if(this.item.productGroup && !this.isDemo || (this.demo && (this.demo?.statusId < 2 || this.demo?.statusId > 3 ))) this.item.productGroup.products.forEach(x => this.sizes.push({id: x.sku, value: x.size + ' - ' + this.agoraCurreny.transform(x.price), stock: x.stock, canSelect: x.stock > 0}));
  }

   deleteItem() {
    this.modal.open("#confirmation" + this.index)
  }

  updateItemSize(e: EventTarget) {
    const htmle = e as HTMLInputElement;

    if (htmle && !this.isDemo) {
        this.orderService.putGuestOrderItem(this.item.sku, htmle.value, this.item.quantity);
    } else if(htmle) {
        this.demoService.putOrderItem(this.item.sku, htmle.value, this.item.quantity, this.clientGuid, this.order.resolvedId);
    }
  }

  updateItemQuantity(e: EventTarget) {
    const htmle = e as HTMLInputElement;
    if (htmle) {
      if (!this.isDemo) {
        this.orderService.putGuestOrderItem(this.item.sku, this.item.sku, parseInt(htmle.value));
      } else {
        this.demoService.putOrderItem(this.item.sku, this.item.sku, parseInt(htmle.value, 10), this.clientGuid, this.order.resolvedId);
      }
    }
  }

  getQuantity(quantity) {

    const stock = this.item.productGroup.products.find(x => x.sku == this.item.sku)?.stock;
    const max = stock >= 10 ? 10 : stock;
    this.quantities = [];

    if (quantity == 0) {
      this.quantities.push({id: 0, value: 0});
    }

    for (let i = 1; i <= max; i++) {
      this.quantities.push({id: i, value: i});
    }

    if (max < quantity) {
      this.quantities.push({id: quantity, value: quantity});
    }

    return this.quantities;
  }

  goToProduct() {

    let link = "";
    if(!this.isDemo){
     link = getVictoriaUrl(this.translation.currentLang, true) +
      '/' + this.translate.getJsonLocales()?.lang +
      '/' + slugify(this.item.productGroup.category.name) +
      '/' + this.item.productGroup.nameOnly.toLowerCase() +
      '-' + this.item.productGroup.code.toLowerCase();

      window.open(link);
    } else {
      link = `/${this.locale}/myvictoria/demo/${this.demo?.demoCode}/jewelry/${this.item.productGroup?.code}`
      this.router.navigate([link]);
    }
  }

  addItemToCart(item: ClientOrderItem){
    if(this.isDemo && this.item.productGroup?.products?.length==1){
      this.demoService.postOrderItem(this.item.sku, 1, this.clientGuid, this.order.resolvedId);
      this.removeItemFromWishlist(item);
    } else if(this.isDemo && this.sizes.length>1){
      this.modal.open('#sizeModal' + this.index)
    }

  }

  addItemToWishlist(item: ClientOrderItem) {
    if (!this.isDemo) {
      this.orderService.postWishlistItem(this.item.sku);
    } else {
      this.demoService.postWishlistItem(this.item.sku, this.clientGuid, this.order.resolvedId);
    }
  }

  removeItemFromWishlist(item: ClientOrderItem) {
    if (!this.isDemo) {
      this.orderService.deleteWishlistItem(this.item.sku, this.item);
    } else {
      this.demoService.deleteWishlistItem(this.item.sku, this.clientGuid, this.order.resolvedId);
    }
  }
}
