import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ClientOrder } from 'src/app/core/models/clientOrder.model';
import { ModalAction } from 'src/app/core/models/modalAction.model';
import { FidelityService } from 'src/app/core/services/fidelity.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { OrderService } from 'src/app/core/services/order.service';
import {TranslationService} from "../../../../../../core/services/translation.service";
import {ClientOrderItem} from "../../../../../../core/models/clientOrderItem.model";
import { JWTService } from 'src/app/core/services/jwt.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SocketService } from 'src/app/core/services/sockets.service';

@Component({
  selector: 'app-cart-content',
  templateUrl: './cart-content.component.html',
  styleUrls: ['./cart-content.component.scss'],
})
export class CartContentComponent implements OnInit, OnChanges {

  @Input() contextId:number;
  @Input() order:ClientOrder;
  @Input() wishlist:ClientOrder;

  resources: any;
  currentItem:any;
  locale:string;
  operationPending:boolean = false;

  constructor(
    public modal:ModalService,
    public orderService:OrderService,
    public translation:TranslateService,
    public translate:TranslationService,
    private fidelity:FidelityService,
    private jwtService:JWTService,
    private paymentService:PaymentService,
    public loader:LoaderService,
    public cookie:CookieService,
    public storage:StorageService,
    private socketServices:SocketService
  ) {
    this.modal.triggerAction$.subscribe((data:ModalAction) => {
      if(data && data.actionName=="validateCode"){
          this.validatePromoCode(data.value);
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contextId || changes.lang) {
      this.paymentService.getPaymentsOptions(this.contextId, this.translation.currentLang);
    }
  }

  ngOnInit() {
    this.locale = this.cookie.get("v_locale")?.replace("_","-");
    //if(!this.paymentService.hasPaymentsOptions()) this.paymentService.getPaymentsOptions(this.contextId, this.translation.currentLang);

    this.translate.localesLoaded.subscribe(res => {
      if(res==true){
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    })

    if(this.jwtService.isAuthenticated() && this.order.demoCode && this.order.demoStatusId==2){
      this.socketServices.connect(this.jwtService.getSubFromToken(), this.order.demoCode)
    }
  }

  private validatePromoCode(promoCode){
    if(promoCode){
      this.orderService.checkVoucherValidity(this.contextId, promoCode).then(res => {
        if(res && res.data){
          switch(res.data.statusId){
            case 1:
              this.orderService.postVoucherOrderGuest(this.contextId, promoCode);
              this.modal.close('#codeModal')
            break;
            case 2: //Handle Error Message
            case 3:
            case 4:
            case 5:
              this.modal.raiseError(res.data.statusId + 10);
             break;
          }
        }
      });
    }
  }
}
