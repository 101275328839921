import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Action} from '../models/action.model';
import {LoaderService} from "./loader.service";
import {Status} from "../models/status.model";
import {ClientActionsDone} from "../models/clientActionsDone.model";
import {ClientAction} from "../models/clientAction.model";
import {Voucher} from "../models/voucher.model";
import { JWTService } from './jwt.service';
import { ErrorService } from './error.service';
import { ErrorType } from '../enums/errorType.enum';
import { getApiUrlFromOrigin } from '../utils/filter.utils';

const BASE_URL = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root'
})
export class DatatableService {

  // STATUS DATATABLE
  private _statusDatas$: BehaviorSubject<Status> = new BehaviorSubject(null);
  public statusDatas$: Observable<Status> = this._statusDatas$.asObservable();
  private _actionsDoneDatas$: BehaviorSubject<ClientActionsDone[]> = new BehaviorSubject([]);
  public actionsDoneDatas$: Observable<ClientActionsDone[]> = this._actionsDoneDatas$.asObservable();
  private _clientActionDatas$: BehaviorSubject<ClientAction[]> = new BehaviorSubject([]);
  public clientActionDatas$: Observable<ClientAction[]> = this._clientActionDatas$.asObservable();

  //ACTIONS HISTORY LIST
  private _actionDatas$: BehaviorSubject<Action[]> = new BehaviorSubject([]);
  public actionDatas$: Observable<Action[]> = this._actionDatas$.asObservable();
  private _actionDatasHasMore$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public actionDatasHasMore$: Observable<boolean> = this._actionDatasHasMore$.asObservable();

  //VOUCHERS LIST
  private _voucherDatas$: BehaviorSubject<Voucher[]> = new BehaviorSubject([]);
  public voucherDatas$: Observable<Voucher[]> = this._voucherDatas$.asObservable();
  private _voucherDatasHasMore$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public voucherDatasHasMore$: Observable<boolean> = this._voucherDatasHasMore$.asObservable();

  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private jwtService: JWTService,
    private loader: LoaderService,
    private error:ErrorService
  ) {
  }

  public getActions(clientSub: number, param: { showPurchases: boolean; showVouchers: boolean; showOthers: boolean }, pageIndex: number, triggerLoaded: boolean = false) {
    this.http.get(BASE_URL + `/clients/${clientSub}/actions`, {withCredentials: true, headers: {'Authorization': `Bearer ${this.jwtService.getToken()}`}, params: { ...param, pageIndex, pageSize: 5 }})
      .subscribe((res: any) => {
        if(res.data) {
          this._actionDatas$.next(res.data.data);
          this._actionDatasHasMore$.next(res.data.hasMore);

          this.error.dropError(ErrorType.DATATABLE)
          this.triggerLoad(triggerLoaded);
        }
      }, err => {
        this.triggerLoad(triggerLoaded);
        this.error.raiseError(ErrorType.DATATABLE)
      });
  }

  public getClientActions(triggerLoaded: boolean = false, contextId){
    this.http.get(BASE_URL + `/fidelity/clientactions/` + contextId, {withCredentials: true, headers: {'Authorization': `Bearer ${this.jwtService.getToken()}`}})
      .subscribe((res: any) => {
        if(res.data) {
          this._clientActionDatas$.next(res.data);
          this.error.dropError(ErrorType.CLIENTACTIONS)
          this.triggerLoad(triggerLoaded);
        }
      }, err => {
        this.triggerLoad(triggerLoaded);
        this.error.raiseError(ErrorType.CLIENTACTIONS)
      });
  }

  public getVouchers(clientSub: number, param: { showPurchases: boolean; showVouchers: boolean; showOthers: boolean }, pageIndex: number, triggerLoaded: boolean = false) {
    this.http.get(BASE_URL + `/clients/${clientSub}/vouchers`, {withCredentials: true, headers: {'Authorization': `Bearer ${this.jwtService.getToken()}`}, params: { ...param, pageIndex, pageSize: 5 }})
      .subscribe((res: any) => {
        if(res.data) {
          this._voucherDatas$.next(res.data.data);
          this._voucherDatasHasMore$.next(res.data.hasMore);
          this.error.dropError(ErrorType.VOUCHERS)
          this.triggerLoad(triggerLoaded);
        }
      }, err => {
        this.triggerLoad(triggerLoaded);
        this.error.raiseError(ErrorType.VOUCHERS)
      });
  }

  private triggerLoad(active) {
    if (active) this.loader.loaded();
  }
}
