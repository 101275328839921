import {Component, Input, OnInit} from '@angular/core';
import {LocalOrderVoucher} from 'src/app/core/models/localOrderVoucher.model';
import {OrderService} from 'src/app/core/services/order.service';
import {ActivatedRoute} from '@angular/router';
import {ClientOrder} from '../../../../../../core/models/clientOrder.model';

@Component({
  selector: 'app-cart-voucher',
  templateUrl: './cart-voucher.component.html',
  styleUrls: ['./cart-voucher.component.scss'],
})
export class CartVoucherComponent implements OnInit {

  @Input() voucher: LocalOrderVoucher;
  @Input() demoCode: string;
  @Input() isOrderValidation = false;
  @Input() isReceipt = false;
  @Input() order: ClientOrder;
  @Input() disableDeleteVouchersWallet = false;

  constructor(
    public orderService: OrderService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
  }


  deleteVoucher(code: string) {
    if (this.demoCode && this.isOrderValidation) {
      const clientGuid = this.route.snapshot.params?.clientGuid ?? this.order.clientSub;
      this.orderService.deleteVoucherClientOrder(this.demoCode, clientGuid, code, this.order.resolvedId);
    } else {
      this.orderService.deleteVoucherOrderGuest(code);
    }
  }
}
