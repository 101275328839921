<div class="cart-articles-container">
  <p class="error-box flex column error-bottom-space" *ngIf="order.hasErrors">
    <span *ngFor="let err of order.errors">{{err.message}}</span>
  </p>

  <div class="cart-head-mobile m-only">
    <div class="cart-head-mobile-part">
      <div class="cart-head-mobile-heading">
        <div class="label label_default">{{resources.shoppingBag.mainTitle}}</div>
      </div>
      <div class="cart-head-mobile-badge">{{order.orderItemsCount}}</div>
    </div>
    <div class="cart-head-mobile-part">
      <div class="cart-head-mobile-price">
        <div class="label label_default">{{order.total | agoraCurrency}}</div>
      </div>
    </div>
  </div>
  <div id="cart-articles-main">
    <p>{{resources.shoppingBag.itemsFound}} <span class="art-qty">{{order.orderItemsCount}} {{resources.shoppingBag.itemsLabel}}</span> {{resources.shoppingBag.itemsFoundSecond}}</p>
    <div class="item-list" *ngIf="resources.shoppingBag">
      <ng-template ngFor let-item [ngForOf]="order.orderItems" let-i="index">
        <app-cart-item [item]="item" [index]="i" [isInWishlist]="checkInWishlist(item)" [classCondition]="i+1 !== order.orderItems.length ? 'border-bottom' : ''"></app-cart-item>
      </ng-template>

      <hr class="victoria-cart-separator" />
      <div class="cta-container d-width-fit">
        <button class="btn secondary" (click)="goToCollection()">
          <label>{{'CART.CONTINUE.SHOPPING' | translate}}</label>
        </button>
      </div>
    </div>
  </div>
  <div class="cart-bottom-mobile" id="expand-cart">
    <div class="cart-bottom-mobile-button" (click)="displayArticle()">{{resources.shoppingBag.shippingButtonLabel}}</div>
  </div>
</div>
