<div class="waiting-orders-list-data-wrapper-container">
  <h2 class="table-title">{{'ORDERLIST.TITLE.WAITING'| translate}}</h2>
  <div *ngIf="loaded && orders">
    <app-waiting-orders-list-datas
      [waitingOrders]="orders"
    ></app-waiting-orders-list-datas>
    <div class="more">
      <div [ngClass]="loadedMore ? 'loading' : ''" (click)="getMoreDatas()" *ngIf="orderService.currentClientOrdersHasMore$ | async">
        <p class="uppercase">{{'GLOBAL.ACTION.MORE' | translate}}</p>
        <svg viewBox="0 0 32 32">
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
      </div>
    </div>
  </div>
  <div class="profile-main flex center-all loading" *ngIf="!loaded">
    <div class="loader"></div>
  </div>
</div>
