import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Status} from '../../../../core/models/status.model';
import {GetDatasService} from '../../../../core/services/get-datas.service';
import {DateHelper} from '../../../../shared/helpers/date.helper';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from 'src/app/core/services/translation.service';
import {Subscription} from 'rxjs';
import {StorageService} from 'src/app/core/services/storage.service';
import {CardType} from '../../../../core/enums/card-type.enum';
import {ClientActionsDone} from '../../../../core/models/clientActionsDone.model';
import {Forecast} from "../../../../core/models/forecast.model";

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnDestroy, AfterViewInit {

  currentStatus: Status;
  forecasts: Forecast[];
  langueChangedSubscription: Subscription;
  cardType = CardType;
  clientActionsDone: ClientActionsDone[];

  constructor(
    private getDatasService: GetDatasService,
    private translate: TranslateService,
    private translateConfig: TranslationService,
    private storage: StorageService,
  ) {

  }

  public ngOnDestroy(): void {
    if (this.langueChangedSubscription) {
      this.langueChangedSubscription.unsubscribe();
    }
  }

  public ngOnInit() {
    this.getDatasService.summary$.subscribe(data => {
      if (data) {
        this.clientActionsDone = data.actionsDone;
        this.currentStatus = data.status;
        this.forecasts = data.forecasts;
      }
    });

    //   this.authService.userData$.subscribe( d=> {
    //     let userData = d.allUserData.find(x => x.configId=="desktop")?.userData;
    //     if(userData && !this.getDatasService.summaryLoaded()){
    //       this.getDatasService.getSummary(userData.sub);
    //     }
    // })
  }

  public ngAfterViewInit() {
    if (this.langueChangedSubscription) {
      this.langueChangedSubscription.unsubscribe();
    }
    this.langueChangedSubscription = this.translateConfig.langHasChanded.subscribe((value) => {
      if (value === true) {
        this.translate.use(this.storage.languageCode);
      }
    });
  }

  public getRemainingPoints() {
    let remainingPoints = 0;
    if (this.currentStatus) {
      switch (this.currentStatus.statusId) {
        case 1:
          remainingPoints = 250 - this.currentStatus.totalPoints;
          break;
        case 2:
          remainingPoints = 500 - this.currentStatus.totalPoints;
          break;
        case 3:
          remainingPoints = 750 - this.currentStatus.totalPoints;
          break;
      }
    }
    return remainingPoints;
  }

}
