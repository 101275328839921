import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../../../../../core/services/translation.service';
import {JWTService} from '../../../../../../core/services/jwt.service';
import {GetDatasService} from '../../../../../../core/services/get-datas.service';
import {ClientCommunications} from '../../../../../../core/models/clientCommunications.model';
import {RGPDService} from '../../../../../../core/services/rgpd.service';
import {Client} from "../../../../../../core/models/client.model";

@Component({
  selector: 'app-mycommunications',
  templateUrl: './mycommunications.component.html',
  styleUrls: ['./mycommunications.component.scss'],
})
export class MycommunicationsComponent implements OnInit {

  communications: ClientCommunications;
  noCommunications = false;

  private client: Client;

  private operation = {
    optIn: 1,
    optOut: 2
  };
  private target = {
    victoria: 1,
    fidelity: 2,
    delegate: 500
  };

  constructor(
    private translateConfig: TranslationService,
    private jwtService: JWTService,
    private getDatasService: GetDatasService,
    private rgpdService: RGPDService
  ) {
  }

  ngOnInit() {
    this.getDatasService.clientData$.subscribe(res => {
      if (res) {
        this.client = res;
        this.rgpdService.getClientCommunications(this.translateConfig.getContextId(), this.jwtService.getSubFromToken(), res.email);
      }
    });

    this.rgpdService.communications$.subscribe(res => {
      if (res) {
        this.communications = res;
      }
    });

    this.rgpdService.noCommunications$.subscribe(res => {
      this.noCommunications = res;
    });
  }

  updateValue(value: boolean, target: string, pref?: any) {
    this.rgpdService.postClientCommunication(this.communications, this.target[target], value ? this.operation.optIn : this.operation.optOut, this.translateConfig.getContextId(), this.jwtService.getSubFromToken(), this.client.email, pref);
  }

  updateRGPD() {
    this.rgpdService.postClientCommunication(null, this.target.victoria, this.operation.optIn, this.translateConfig.getContextId(), this.jwtService.getSubFromToken(), this.client.email, null);
  }
}
