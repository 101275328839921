<div class="jumbotron" [class.withBanner]="(demoService.hasDemo$ | async) && isDemoClient" *ngIf="!reduce">
  <div *ngIf="(demoService.hasDemo$ | async) && isDemoClient" class="cta-container primary flex row joinUsBanner">
    <button class="btn btn-primary flex party-button d-gap-10" (click)="goToDemo()">
            <svg viewBox="0 0 32 32" class="icon">
                    <use xlink:href="#icon-party-rock"></use>
            </svg>
            {{"DEMO.JOINUS" | translate}}
            <svg viewBox="0 0 32 32" class="icon">
                    <use xlink:href="#icon-arrow-right-long"></use>
            </svg>
    </button>
</div>
  <div class="jumbotron-container flex row m-column ai-center m-j-space-between">
    <div class="jumbotron-title flex column j-start" [class.marginBanner]="(demoService.hasDemo$ | async) && isDemoClient" innerHTML="{{'JUMBO.TITLE' | translate: {'Client': storage.clientFirstName } }}"></div>
    <!-- MENU BUTTON -->
    <!-- <button class="btn btn-menu d-only futura" (click)="showSideMenu()">
      <div class="label">Mon Compte</div>
      <div class="icon peach icon-right">
        <svg viewBox="0 0 32 32">
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
      </div>
    </button> -->
    <!-- END BUTTON -->
    <div class="jumbotron-info flex j-end ai-end ac-end wrap">
      <button class="btn btn-primary encoding-button flex center-all" [routerLink]="['/' + locale + '/club/point-encoding']">
        <div class="label">
          {{'GLOBAL.POINTENCODING' | translate}}
        </div>
        <div class="icon white">
          <svg viewBox="0 0 32 32">
            <use xlink:href="#icon-diamond"></use>
          </svg>
        </div>
      </button>
      <!-- SUMMARY INFO -->
      <!--<div class="recap-container flex row nowrap j-space-around">
        <div class="recap-status flex ai-end j-center ac-center pointer" [routerLink]="['/' + locale + '/club/status']">
          <div class="recap-icon img-recap-icon flex ai-end">
            <img loading="lazy"  *ngIf="currentClientSummary" src="/assets/images/business/statut-{{currentClientSummary.status.statusId}}.svg">
            <img loading="lazy"  *ngIf="!currentClientSummary" src="/assets/images/business/statut-1.svg">
          </div>
          <div class="label-status status flex column ai-start">
            <span class="status-text">{{'GLOBAL.YOURSTATUS' | translate}}</span>
            <span *ngIf="currentClientSummary" class="status-value uppercase">{{'CLIENT.STATUS' + currentClientSummary.status.statusId | translate}}</span>
            <span *ngIf="!currentClientSummary" class="status-value uppercase">-&#45;&#45;</span>
          </div>
        </div>
        <div class="recap-status flex ai-end j-center ac-center pointer"  [routerLink]="['/' + locale + '/club/point-encoding']">
          <div class="recap-icon">
            <svg viewBox="0 0 32 32">
              <use xlink:href="#icon-diamond"></use>
            </svg>
          </div>
          <div class="label-status flex column ai-start">
            <span class="status-text">{{'GLOBAL.POINTS' | translate}}</span>
            <span *ngIf="currentClientSummary" class="status-value">{{currentClientSummary.status.totalPoints}} pts</span>
            <span *ngIf="!currentClientSummary" class="status-value">&#45;&#45; pts</span>
          </div>
        </div>
        <div class="recap-status flex ai-end j-center ac-center pointer" [routerLink]="['/' + locale + '/club/wallet']">
          <div class="recap-icon">
            <svg viewBox="0 0 32 32">
              <use xlink:href="#icon-wallet"></use>
            </svg>
          </div>
          <div class="label-status flex column ai-start">
            <span class="status-text">{{'GLOBAL.SOLDE' | translate}}</span>
            <span *ngIf="currentClientSummary" class="status-value">{{currentClientSummary.wallet.total | number: '.2' }} €</span>
            <span *ngIf="!currentClientSummary" class="status-value">-.&#45;&#45; €</span>

          </div>
        </div>
      </div>-->
      <div class="recap-infos-container">
        <div class="flex j-space-between ai-center">
          <div class="flex row ai-center j-space-between frame pointer" [routerLink]="['/' + locale + '/club/status']">
            <div class="flex" *ngIf="currentClientSummary">
              <svg viewBox="0 0 108 80" class="icon-status height100">
                <use [attr.xlink:href]="'#icon-status-' + currentClientSummary.status.statusId"></use>
              </svg>
            </div>
            <div class="flex" *ngIf="!currentClientSummary">
              <svg viewBox="0 0 108 80" class="icon-status height100">
                <use xlink:href="#icon-status-1"></use>
              </svg>
            </div>
            <div class="flex column">
              <p class="recap-title">{{'GLOBAL.YOURSTATUS' | translate}}</p>
              <p class="recap-value {{currentClientSummary.status.totalPoints >= 1000 ? 'decreaseSize' : ''}}" *ngIf="currentClientSummary" >{{'CLIENT.STATUS' + currentClientSummary.status.statusId | translate | uppercase}}</p>
              <p class="recap-value" *ngIf="!currentClientSummary" >-&#45;&#45;</p>
            </div>
          </div>
          <div class="flex row ai-center j-space-between frame pointer" [routerLink]="['/' + locale + '/club/point-encoding']">
            <div class="flex">
              <svg viewBox="0 0 32 32" class="icon-diamond height100">
                <use xlink:href="#icon-diamond"></use>
              </svg>
            </div>
            <div class="flex column">
              <p class="recap-title">{{'GLOBAL.POINTS' | translate}}</p>
              <p class="recap-value {{currentClientSummary.status.totalPoints >= 1000 ? 'decreaseSize' : ''}}" *ngIf="currentClientSummary">{{currentClientSummary.status.totalPoints}} pts</p>
              <p class="recap-value" *ngIf="!currentClientSummary">-&#45;&#45; pts</p>
            </div>
          </div>
          <div class="flex row ai-center j-space-between frame pointer" [routerLink]="['/' + locale + '/club/wallet']">
            <div class="flex">
              <svg viewBox="0 0 32 32" class="icon-wallet  height100">
                <use xlink:href="#icon-wallet"></use>
              </svg>
            </div>
            <div class="flex column">
              <p class="recap-title">{{'GLOBAL.SOLDE' | translate}}</p>
              <p class="recap-value {{currentClientSummary.status.totalPoints >= 1000 ? 'decreaseSize' : ''}}" *ngIf="currentClientSummary">{{currentClientSummary.wallet.total | agoraCurrency}}</p>
              <p class="recap-value" *ngIf="!currentClientSummary">-&#45;&#45; €</p>
            </div>
          </div>
        </div>
      </div>
      <!-- END SUMMARY INFO -->
    </div>

  </div>
</div>
