
<div class="booster-datas">
  <div class="avantage" *ngIf="rank && text">
    <div class="number">{{rank}}</div>
    <div>
      <span [innerHTML]="text"></span>
    </div>
  </div>
  <p *ngIf="!rank && text">{{text}}</p>

  <div class="datas-area">
    <div class="data" *ngIf="total!=undefined">
      <span>{{total}}</span>
      <svg class="data-icon">
        <use xlink:href="#booster-checked"></use>
      </svg>
    </div>
    <div class="data" *ngIf="points!=undefined">
      <span>{{"BOOSTER.FOLLOWUP.ADDPOINTS" | translate: {X:points} }}</span>
    </div>
    <div class="data" *ngIf="amount!=undefined">
      <span>{{"BOOSTER.ADVANTAGES.XJEWELS" | translate: {X: amount | agoraCurrencyInt} }}</span>
    </div>
  </div>
</div>
