import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientOrder } from 'src/app/core/models/clientOrder.model';
import { v4 as uuidv4 } from 'uuid'
import { CookieService } from 'ngx-cookie-service';
import { TranslationService } from './translation.service';
import { JWTService } from './jwt.service';
import { TouchSequence } from 'selenium-webdriver';
import { TriggerService } from './trigger.service';
import { AlertService } from './alert.service';
import { Alert } from 'src/app/core/models/alert.model';
import { ErrorService } from './error.service';
import { ErrorType } from '../enums/errorType.enum';
import { LoaderService } from './loader.service';
import { getApiUrlFromOrigin, getCookieDomainByContext } from '../utils/filter.utils';
import { GoogleAnalyticService } from './google-analytic.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertTypes } from '../enums/alert-type.enum';
import { StorageService } from './storage.service';
import {DemoService} from "../../features/agora/demo/services/demo.service";
import {Client} from "../models/client.model";
import {DemoClientModel} from "../../features/agora/demo/models/demo-client.model";
import {OrderActionEnum} from "../../features/agora/demo/enums/order-action.enum";
import { Track } from '../models/track.model';

const BASE_URL = getApiUrlFromOrigin();

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    //CART ORDER
    private _panierOrder$: BehaviorSubject<ClientOrder> = new BehaviorSubject(undefined);
    public panierOrder$: Observable<ClientOrder> = this._panierOrder$.asObservable();

    //ORDERS LIST
    private _clientOrders$: BehaviorSubject<ClientOrder[]> = new BehaviorSubject([]);
    public clientOrders$: Observable<ClientOrder[]> = this._clientOrders$.asObservable();

    //CURRENT ORDERS LIST
    private _currentClientOrders$: BehaviorSubject<ClientOrder[]> = new BehaviorSubject([]);
    public currentClientOrders$: Observable<ClientOrder[]> = this._currentClientOrders$.asObservable();
    private _currentClientOrdersHasMore$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public currentClientOrdersHasMore$: Observable<boolean> = this._currentClientOrdersHasMore$.asObservable();


  //CURRENT DELIVERED ORDERS LIST
    private _currentDeliveredClientOrders$: BehaviorSubject<ClientOrder[]> = new BehaviorSubject([]);
    public currentDeliveredClientOrders$: Observable<ClientOrder[]> = this._currentDeliveredClientOrders$.asObservable();
    private _currentDeliveredClientOrdersHasMore$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public currentDeliveredClientOrdersHasMore$: Observable<boolean> = this._currentDeliveredClientOrdersHasMore$.asObservable();

    //ORDER BY ID
    private _clientOrderDetails$: BehaviorSubject<ClientOrder> = new BehaviorSubject(undefined);
    public clientOrderDetails$: Observable<ClientOrder> = this._clientOrderDetails$.asObservable();

    //WISHLIST ORDER
    private _wishlistOrder$: BehaviorSubject<ClientOrder> = new BehaviorSubject(undefined);
    public wishlistOrder$: Observable<ClientOrder> = this._wishlistOrder$.asObservable();

    //DEMO SINGLE CLIENT
    private _demoSingleClient$: BehaviorSubject<DemoClientModel> = new BehaviorSubject(undefined);
    public demoSingleClient$: Observable<DemoClientModel> = this._demoSingleClient$.asObservable();


    //TRIGGER CART LOADED
    private _cartLoaded$: Subject<ClientOrder> = new Subject()
    public cartLoaded$: Observable<ClientOrder> = this._cartLoaded$.asObservable()

    private headers: HttpHeaders;
    orderActionEnum = OrderActionEnum;

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
        private trigger: TriggerService,
        private translate: TranslationService,
        private translation: TranslateService,
        private jwt: JWTService,
        private error: ErrorService,
        private loader: LoaderService,
        private gA: GoogleAnalyticService,
        private alertService:AlertService,
        private trad:TranslateService,
        private storage:StorageService,
        private demoService: DemoService,
    ) {

    }


    private updateOrderVersion() {
        this.cookie.set("v_oav", new Date().getTime().toString(), 84600 * 30, '/', getCookieDomainByContext(this.translate.getContextId()));
    }

    public updateGuestOrderInternaly(data: any) {
        this._panierOrder$.next(data);
    }

    public updateCartNavigationStatus(step){
      const order = this._panierOrder$.value;

      switch(step){
        case 1:
        case 2:
        case 3:  order.changeStep = false; break;
      }

      this._panierOrder$.next(order)
    }

    private getClientId() {

        const currentDate = new Date();
        //Current Date Add 1 Year with object mutation
        currentDate.setFullYear(currentDate.getFullYear() + 1)

        let id = this.cookie.get('v_gi');

        if (!id || id == "") id = uuidv4();

        //Update Cookie Expiration Time
        this.cookie.set('v_gi', id, currentDate, "/", getCookieDomainByContext(this.translate.getContextId()));
        return id;
    }

    //Order
    public getInternalOrder() {
        return this._panierOrder$.getValue();
    }

    public getGuestOrder(delegateId = "0", demoCode = null, withTriggerLoaded=false): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("X-ContextId", this.translate.getContextId().toString());
        this.headers = this.headers.append("X-Reference", this.cookie.get('v_ref'));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        if (this.storage.delegateId && this.storage.delegateId !="undefined" && delegateId != "0") this.headers = this.headers.append("X-DelegateId", this.storage.delegateId);
        if (this.storage.demoId &&  this.storage.demoId!="undefined" && demoCode != null && demoCode != "") this.headers = this.headers.append("X-DemoCode", this.storage.demoId);


        this.http.get(BASE_URL + "/orders/guestorder", { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {

                if(res.data.guestOrder?.demoStatusId == 2 && res.data.guestOrder?.demoCode) this.demoService.setHasDemo(res.data.guestOrder?.demoCode);
                if(withTriggerLoaded) this._cartLoaded$.next(res.data.guestOrder)

                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);
            }

        }, err => {

        });
    }

    public putGuestOrder(data: any, triggerNextStep: boolean = false, step: number = 0): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-ContextId", this.translate.getContextId().toString());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        this.http.put(BASE_URL + "/orders/guestorder/", data, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();

                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);

                this._panierOrder$.getValue().hasError = false;
                if (triggerNextStep) {
                    this.gA.GACheckout(step - 1, res.data.guestOrder.delegateId, res.data.guestOrder);
                    this.trigger.requestOrderNextStep(step);
                }
            }
        }, err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
        });
    }

    public postGuestOrder(confirm = false): Observable<any> {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-ContextId", this.translate.getContextId().toString());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));

        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        return this.http.post(BASE_URL + `/orders/guestorder?doConfirm=${confirm}`, null, { headers: this.headers })
    }

    public validateGuestOrder(contextId: number): Observable<any> {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-ContextId", contextId.toString());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        return this.http.get<any>(BASE_URL + "/orders/guestorder/validate", { headers: this.headers });
    }

    //Order Delivery
    public postGuestOrderDelivery(data: any, triggerNextStep: boolean = false, step: number = 0, postAction = false): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        this.http.post(BASE_URL + "/orders/guestorder/delivery", data, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();

                if(!triggerNextStep) res.data.guestOrder.changeStep = false;

                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);

                this._panierOrder$.getValue().hasError = false;

                if (triggerNextStep && !data.clientGuid) {
                    const deliveryTag = res.data.deliveryTypeId == 10 ? 'deliverAtYourPlace' : 'pickUpAtYourConsultant';
                    this.gA.GACheckout(step - 1, deliveryTag, res.data.guestOrder);
                    this.trigger.requestOrderNextStep(step);

                    if(!postAction) this.loader.loaded();
                }

                if(postAction) {
                  this.demoService.postClientGuestOrderAction(this.orderActionEnum.DELIVERY_VALIDATED, res.data.guestOrder.demoCode, res.data.guestOrder.clientSub).subscribe(_ => this.loader.loaded())
                }
            }
        }, err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
            this.loader.loaded();
        })
    }

    //Wishlist
    public postWishlistItem(sku: string, product: any = undefined, isDetails = false): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        let route;

        const demoCode = this.storage.demoId;

        if(false && demoCode && demoCode!=null && demoCode!="" && this.jwt.isAuthenticated()) {
          route = `/demos/${demoCode}/clients/${this.jwt.getSubFromToken()}/orders/${this.jwt.getSubFromToken()}/wishlist/${sku}`
        } else {
          route = '/orders/wishlist/items/' + sku;
        }

        this.http.post(BASE_URL + route, { quantity: 1, sku }, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();

                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);

                this._panierOrder$.getValue().hasError = false;

                const alertData = new Alert();
                alertData.message = this.trad.instant('ALERT.WISHLIST.ADD');
                alertData.duration = 6000;

                this.alertService.raiseAlert(alertData);

                if (product) this.gA.GAAddToCart(product, sku);

                if (isDetails) {
                  this.demoService.updateJewelryWishlist(true);
                }
            }
        }, err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");

            const alertData = new Alert();
            alertData.message = this.trad.instant('ALERT.ERROR.GLOBAL');
            alertData.duration = 6000;
            alertData.type = AlertTypes.DANGER;
            alertData.icon = '#icon-warning'

            this.alertService.raiseAlert(alertData);
        });
    }

    public deleteWishlistItem(sku: string, product: any = undefined, isDetails = false): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

      let route;

      const demoCode = this.storage.demoId

      if(false && demoCode && demoCode!=null && demoCode!="" && this.jwt.isAuthenticated()) {
        route = `/demos/${demoCode}/clients/${this.jwt.getSubFromToken()}/orders/${this.jwt.getSubFromToken()}/wishlist/${sku}`
      } else {
        route = '/orders/wishlist/items/' + sku;
      }

        this.http.delete(BASE_URL + route, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();

                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);

                this._panierOrder$.getValue().hasError = false;

                if (product) this.gA.GARemoveFromCart(product, sku, product.quantity);

              if (isDetails) {
                this.demoService.updateJewelryWishlist(false);
              }
            }
        }, err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");

            const alertData = new Alert();
            alertData.message = this.trad.instant('ALERT.ERROR.GLOBAL');
            alertData.duration = 6000;
            alertData.type = AlertTypes.DANGER;
            alertData.icon = '#icon-warning'

            this.alertService.raiseAlert(alertData);
        });
    }

    //Order Items
    public postGuestOrderItem(sku: string, product: any = undefined, displayAlert=false, remove=false, baseSku = undefined, reloadGuestOrder=false): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        let route;

        const demoCode = this.cookie.get("demoId");

        if(false && demoCode && demoCode!=null && demoCode!="" && this.jwt.isAuthenticated()) {
          route = `/demos/${demoCode}/clients/${this.jwt.getSubFromToken()}/orders/${this.jwt.getSubFromToken()}/cart/${sku}`
        } else {
          route = '/orders/guestorder/items/' + sku;
        }

        this.http.post(BASE_URL + route, { quantity: 1, sku }, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();
                if(res.data.guestOrder.demoCode != demoCode) this.getGuestOrder(undefined, demoCode);

                this._panierOrder$.next(res.data.guestOrder);

                this._panierOrder$.getValue().hasError = false;

                if(remove && !baseSku){
                  this.deleteWishlistItem(sku, product)
                }else if(remove && baseSku){
                  this.deleteWishlistItem(baseSku, product)
                }


                if(displayAlert){
                    const alertData = new Alert();
                    alertData.message = this.trad.instant('ALERT.ORDER.ADD');
                    alertData.duration = 4000;
                    alertData.type = AlertTypes.INFO;
                    alertData.urlLink = `/${this.storage.languageCode.replace("_","-")}/public/cart`

                    this.alertService.raiseAlert(alertData);
                }

                if (product) this.gA.GAAddToCart(product, sku);
            }
        }, err => {

            const alertData = new Alert();
            alertData.message = this.trad.instant('ALERT.ERROR.GLOBAL');
            alertData.duration = 4000;
            alertData.type = AlertTypes.DANGER;
            alertData.icon = '#icon-warning'

            this.alertService.raiseAlert(alertData);

            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
        });
    }

    public putGuestOrderItem(skuOriginal: string, newsku: string, qty: number): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        let route;

        const demoCode = this.storage.demoId

        if(false && demoCode && demoCode!=null && demoCode!="" && this.jwt.isAuthenticated()) {
          route = `/demos/${demoCode}/clients/${this.jwt.getSubFromToken()}/orders/${this.jwt.getSubFromToken()}/cart/${skuOriginal}`
        } else {
          route = '/orders/guestorder/items/' + skuOriginal;
        }

        this.http.put(BASE_URL + route, { quantity: qty, sku: newsku }, { headers: this.headers }).subscribe((res:any) => this.putGuestOrderItemCallBack(res), err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
        });
    }

    private putGuestOrderItemCallBack(res:any){
      if (res.data) {
        this.updateOrderVersion();

        this._panierOrder$.next(res.data.guestOrder);
        this._wishlistOrder$.next(res.data.wishList);

        this._panierOrder$.getValue().hasError = false;
      }
    }

    public deleteGuestOrderItem(sku: string, product: any = undefined): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        let route;

        const demoCode = this.storage.demoId

        if(false && demoCode && demoCode!=null && demoCode!="" && this.jwt.isAuthenticated()) {
          route = `/demos/${demoCode}/clients/${this.jwt.getSubFromToken()}/orders/${this.jwt.getSubFromToken()}/cart/${sku}`
        } else {
          route = '/orders/guestorder/items/' + sku;
        }

        this.http.delete(BASE_URL + route, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();

                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);

                this._panierOrder$.getValue().hasError = false;
                if (product) this.gA.GARemoveFromCart(product, sku, product.quantity);
            }
        }, err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
        });
    }

    //Order Vouchers
    public postVoucherOrderGuest(contextId: number, code: string): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        this.http.post(BASE_URL + "/orders/guestorder/vouchers", { code, contextId }, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();

                res.data.guestOrder.changeStep = false;
                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);

                this._panierOrder$.getValue().hasError = false;
                this.loader.loaded();
            }
        }, err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
            this.loader.loaded();
        });
    }

    //Order wallet
    public postWalletOrderGuest(amount: number): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        this.http.post(BASE_URL + '/orders/guestorder/wallet', { amount }, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();

                res.data.guestOrder.changeStep = false;

                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);

                this._panierOrder$.getValue().hasError = false;
                this.loader.loaded();
            }
        }, err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
            this.loader.loaded();
        });
    }

    public deleteVoucherOrderGuest(code: string): void {
        this.headers = new HttpHeaders();
      this.headers = this.headers.append("X-ClientId", this.getClientId());
        this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        this.http.delete(BASE_URL + "/orders/guestorder/vouchers/" + code, { headers: this.headers }).subscribe((res: any) => {
            if (res.data) {
                this.updateOrderVersion();

                res.data.guestOrder.changeStep = false;
                this._panierOrder$.next(res.data.guestOrder);
                this._wishlistOrder$.next(res.data.wishList);

                this._panierOrder$.getValue().hasError = false;

            }
        }, err => {
            this._panierOrder$.getValue().hasError = true;
            this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
        });
    }

    //Add Payment info to Order
    public postMolliePaymentForOrderUpdate(orderGuid: any, data: any, clientGuid?:string, isPublicAccess:boolean = false): Observable<any> {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        if(this.jwt.isAuthenticated() && !isPublicAccess) return this.http.post<any>(BASE_URL + `/orders/guestorder/${orderGuid}/payment`, data, { headers: this.headers });
        else return this.http.post<any>(BASE_URL + `/orders/${orderGuid}/${clientGuid}/payment`, data, { headers: this.headers });
      }

    //Get Order Payment Status
    public getPaymentStatus(orderGuid: any, paymentRef: string): Observable<any> {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        return this.http.get(`${BASE_URL}/orders/guestorder/${orderGuid ?? 0}/payment/${paymentRef}`, { headers: this.headers })
    }

    //Get Public Order Payment Status
    public getPublicPaymentStatus(orderGuid: any, clientGuid: any, paymentRef: string): Observable<any> {
      this.headers = new HttpHeaders();

      return this.http.get(`${BASE_URL}/orders/${orderGuid ?? 0}/${clientGuid}/payment/${paymentRef}`, { headers: this.headers })
  }

    //Get Orders List For Profile
    public getOrdersSummaryForDemo(pageIndex: number=0) {
      this.headers = new HttpHeaders();
      this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

      return this.http.get<any>(`${BASE_URL}/clients/${this.jwt.getSubFromToken()}/orders`, { headers: this.headers, params: { pageIndex, pageSize: 99 } })
  }

    //Get Orders List For Profile
    public getOrders(contextId: number, pageIndex: number, triggerLoaded: boolean = false): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        this.http.get<any>(`${BASE_URL}/clients/${this.jwt.getSubFromToken()}/orders`, { headers: this.headers, params: { pageIndex, pageSize: 5 } }).subscribe(res => {
            if (res.data && res.data.results) {
                this._clientOrders$.next(res.data.results);
                this._currentClientOrders$.next(res.data.results.filter(x => x.statusId > 0));
                this._currentClientOrdersHasMore$.next(res.data.hasMore);

                this.error.dropError(ErrorType.GETORDERS)
                this.triggerLoad(triggerLoaded);
            }
        }, err => {
            this.triggerLoad(triggerLoaded);
            this.error.raiseError(ErrorType.GETORDERS)
        })
    }

    //Get Orders List For Profile
    public getDeliveredOrders(contextId: number, pageIndex: number, triggerLoaded: boolean = false): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
      this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));

        this.http.get<any>(`${BASE_URL}/Gateway/${this.translate.getContextId()}/clients/${this.jwt.getSubFromToken()}/orders`, { headers: this.headers, params: { pageIndex, pageSize: 5 } }).subscribe(res => {
            if (res.data && res.data.results) {
                this._currentDeliveredClientOrders$.next(res.data.results);
                this._currentDeliveredClientOrdersHasMore$.next(res.data.hasMore);
              this._currentDeliveredClientOrdersHasMore$.next(res.data.hasMore);

                this.error.dropError(ErrorType.GETORDERS)
                this.triggerLoad(triggerLoaded);
            }
        }, err => {
            this.triggerLoad(triggerLoaded);
            this.error.raiseError(ErrorType.GETORDERS)
        })
    }

    //Get Order Details By Id For Profile
    public getOrderById(contextId: number, orderId: number): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

        this.http.get<any>(`${BASE_URL}/clients/${this.jwt.getSubFromToken()}/orders/${orderId}`, { headers: this.headers }).subscribe(res => {
            if (res.data) {
                this.error.dropError(ErrorType.GETORDER);
                this._clientOrderDetails$.next(res.data);
            }
        }, err => {
            this.error.raiseError(ErrorType.GETORDER);
        })
    }
    //Get Order Details By Id For Profile
    public getDeliveredOrderById(contextId: number, orderId: number): void {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());
      this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));

        this.http.get<any>(`${BASE_URL}/Gateway/${this.translate.getContextId()}/clients/${this.jwt.getSubFromToken()}/orders/${orderId}`, { headers: this.headers }).subscribe(res => {
            if (res.data) {
                this.error.dropError(ErrorType.GETORDER);
                this._clientOrderDetails$.next(res.data);
            }
        }, err => {
            this.error.raiseError(ErrorType.GETORDER);
        })
    }

  public getValidVouchers(clientSub: any, triggerLoaded: boolean = false) {
    return this.http.get(BASE_URL + `/clients/${clientSub}/vouchers/valid`, {withCredentials: true, headers: { authorization: `Bearer ${this.jwt.getToken()}`}});
  }

  public setDemoSingleClient(client:DemoClientModel){
    this._demoSingleClient$.next(client);
  }

  // public getSingleClient(clientGuid: string): void {
  //   this.headers = new HttpHeaders();
  //   this.headers = this.headers.append('X-ClientId', clientGuid);
  //   this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

  //   const demoCode =  this.storage.currentDemoCode();

  //   this.http.get<any>(BASE_URL + `/demos/${demoCode}/order/client/${clientGuid}`,  {headers: this.headers}).subscribe(res => {
  //     if(res.data) {
  //       this._demoSingleClient$.next(res.data);
  //     }
  //   });
  // }

    //Checkers
    public checkVoucherValidity(contextId: number, code: string): Promise<any> {
        return this.http.get(BASE_URL + "/vouchers/" + code + "/" + contextId).toPromise();
    }

    // public checkDemoCodeValidity(contextId: number, code: string): Observable<any> {
    //     return this.http.get(BASE_URL + "/gateway/" + contextId + "/demos/" + code + "/validation");
    // }

    public checkDemoCodeValidity(contextId: number, code: string): Observable<any> {
      this.headers = new HttpHeaders();
      this.headers = this.headers.append("X-ContextId", contextId?.toString());

      return this.http.get(BASE_URL + "/demos/" + code + "/validation", {headers : this.headers})
    }



    public setOrderAndWishList(guestOrder: any, wishList: any) {
      if(this._panierOrder$.getValue()!=undefined && guestOrder){
        guestOrder.changeStep = this._panierOrder$.getValue().changeStep;
      }

      this.updateGuestOrderInternaly(guestOrder);
      this._wishlistOrder$.next(wishList);
      this.updateOrderVersion();
    }

    public deleteVoucherClientOrder(demoCode: string, clientGuid: any, code: string, resolveId:string, redirect:boolean = true) {

      this.headers = new HttpHeaders();
      this.headers = this.headers.append("X-ClientId", this.getClientId());
      this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
      this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

      this.http.delete(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolveId}/vouchers/` + code, { headers: this.headers }).subscribe((res: any) => {
        if (res.data) {
          res.data.guestOrder.changeStep = redirect
          this.updateSingleClientOrder(res.data.guestOrder);
        }
      }, err => {
        this.loader.loaded();
      });
    }

    public postVoucherClientOrder(demoCode: string, clientGuid: any, promoCode: string, contextId: number, resolveId:string, redirect:boolean = true) {
      this.headers = new HttpHeaders();
      this.headers = this.headers.append("X-ClientId", this.getClientId());
      this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
      this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

      this.http.post(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolveId}/vouchers`, { code: promoCode, contextId }, { headers: this.headers }).subscribe((res: any) => {
        if (res.data) {
          res.data.guestOrder.changeStep = redirect
          this.updateSingleClientOrder(res.data.guestOrder);
        }
      }, err => {
        this.loader.loaded();
      });
    }


  public postClientOrderDelivery(data: any, triggerNextStep: boolean = false, step: number = 0, demoCode?: string, clientGuid?: string, resolveId?:string, redirect:boolean = true): void {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

    this.http.post(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolveId}/delivery`, data, { headers: this.headers }).subscribe((res: any) => {
      // if (res.data && deliveryStatus==0) {  //rollback
        if(res.data) {

          this.demoService.postClientOrderAction(this.orderActionEnum.DELIVERY_VALIDATED, demoCode, clientGuid, undefined, resolveId).subscribe(actionOrder => {
          if(actionOrder) {
            actionOrder.data.changeStep = redirect
            this.updateSingleClientOrder(actionOrder.data);
            this.loader.loaded();
          }
        })
      }
    }, err => {
      this._panierOrder$.getValue().hasError = true;
      this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
      this.loader.loaded();
    })
  }

  public postWalletClientOrder(amount: number, demoCode: string, clientGuid: string, resolveId:string, redirect:boolean = true) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("X-ClientId", this.getClientId());
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());


    this.http.post(BASE_URL + `/demos/${demoCode}/clients/${clientGuid}/orders/${resolveId}/wallet`, { amount }, { headers: this.headers }).subscribe((res: any) => {
      if (res.data) {

        res.data.guestOrder["changeStep"] = redirect
        this.updateSingleClientOrder(res.data.guestOrder);
      }
    }, err => {
      this._panierOrder$.getValue().hasError = true;
      this._panierOrder$.getValue().errorMessage = this.translation.instant("ERROR.TITLE");
      this.loader.loaded();
    });
  }


  public updateSingleClientOrder(order: ClientOrder, getDemoOrders = true, ) {
    const client = this._demoSingleClient$.value;

    if(client) {
      const idx = client.orders.map(x => x.resolvedId).indexOf(order.resolvedId);

      if(idx!=-1) client.orders[idx] = order;
      else {
        client.orders[0] = order;
      }

      this._demoSingleClient$.next(client);
    }

    if(getDemoOrders) {
      this.demoService.getDemoOrders(true, true, order.changeStep);
    }
  }

    private triggerLoad(active) {
        if (active) this.loader.loaded();
    }





}
