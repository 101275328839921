import {Component, Input, OnInit} from '@angular/core';
import {Delegate} from '../../../../core/models/delegate.model';
import {TranslationService} from "../../../../core/services/translation.service";
import {GetDatasService} from "../../../../core/services/get-datas.service";

@Component({
  selector: 'app-delegate-card',
  templateUrl: './delegate-card.component.html',
  styleUrls: ['./delegate-card.component.scss'],
})
export class DelegateCardComponent implements OnInit {

  @Input() delegateData: Delegate;
  @Input() showTooltip = false;
  @Input() footerInformationTooltipText: string;
  @Input() footerInformationTooltipPosition: string;
  @Input() footerInformationTooltipWidth: string;
  @Input() footerInformationTooltipHeight: string;
  resources: any;

  constructor(
    public translate: TranslationService,
    public getDatasService: GetDatasService
  ) {
  }

  ngOnInit() {
    this.translate.localesLoaded.subscribe(res => {
      if(res==true){
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    })
  }

}
