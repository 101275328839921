import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { getAreaByUrl, getCookieDomainByUrl } from '../utils/filter.utils';

@Injectable({
  providedIn: 'root'
})

export class LanguageResolver implements Resolve<any> {
  constructor(private storage:StorageService, private cookie:CookieService, private router:Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {


    let params = route.params;

    let area = getAreaByUrl(window.location.origin);
    let availableLanguages = environment.allowedLocales[area];

    if(params.id && availableLanguages.includes(params.id)) {
        this.storage.languageCode = params.id?.replace("-","_");
        this.cookie.set("v_locale", params.id?.replace("-","_"),365, '/', getCookieDomainByUrl(window.location.origin));

        let lang = this.cookie.get("active_language");

        if(!lang || lang==null || lang=='') this.cookie.set("active_language", params.id, 365, '/', getCookieDomainByUrl(window.location.origin));

        if(params.id=="nl-be" && (lang=="fr-lu" || lang=="fr-be"))  this.cookie.set("active_language", "nl-be", 365, '/', getCookieDomainByUrl(window.location.origin));
        else if(params.id=="fr-be" &&  (lang=="nl-be" || lang=="nl-nl")) this.cookie.set("active_language", "fr-be", 365, '/', getCookieDomainByUrl(window.location.origin));

        return true;
    }
    else {
      let locale = this.cookie.get("v_locale")?.replace("_","-");
      if(locale && locale!=null && locale!='') {

        this.router.navigateByUrl("/" + locale);
      }
      else {
        locale = availableLanguages[0];

        this.storage.languageCode = availableLanguages[0]?.replace("-","_");
        this.cookie.set("v_locale", availableLanguages[0]?.replace("-","_"),365, '/', getCookieDomainByUrl(window.location.origin));

        let lang = this.cookie.get("active_language");

        if(!lang || lang==null || lang=='') this.cookie.set("active_language",locale, 365, '/', getCookieDomainByUrl(window.location.origin));

        if(params.id=="nl-be" && (lang=="fr-lu" || lang=="fr-be"))  this.cookie.set("active_language", "nl-be", 365, '/', getCookieDomainByUrl(window.location.origin));
        else if(params.id=="fr-be" &&  (lang=="nl-be" || lang=="nl-nl")) this.cookie.set("active_language", "fr-be", 365, '/', getCookieDomainByUrl(window.location.origin));


        this.router.navigateByUrl("/" + locale);
      }
    }
  }
}
