<app-optin-dip-box *ngIf="isDemoClient && demoCode && demoCode!='undefined' && demoCode!=null" [resources]="resources" [demoCode]="demoCode" [order]="order" (setIsDipCheck)="setIsDipCheck($event)" [selectedMethod]="selectedMethod"></app-optin-dip-box>
<div class="order-payment" [ngClass]="{'hasDip': isDemoClient && demoCode && demoCode!='undefined' && demoCode!=null}" *ngIf="resources.checkoutPayment">
  <div class="pink-separator">

    <div *ngIf="error!=0" class="order-payment-error">
      <div class="flex row ai-center">
        <svg viewBox="0 0 28 28" class="icon-warning">
          <use xlink:href="#icon-warning"></use>
        </svg>
        <div>
          <span *ngIf="error==3">{{resources.checkoutPayment.messageLabelError}}</span>
          <span *ngIf="error==5">{{resources.checkoutPayment.topicLabelError}}</span>
          <span *ngIf="error==99">{{resources.resetpassword.shortDescription}}</span>
          <span *ngIf="error!=3 && error!=99 && error!=5">{{'ALERT.ERROR.GLOBAL' | translate}}</span>
        </div>
      </div>
    </div>

    <h1 class="canela">{{'ORDER_TUNNEL.REDUCTIONS.TITLE' | translate}}</h1>
    <p class="description">{{'ORDER_TUNNEL.REDUCTIONS.CAPTION' | translate}}</p>

    <div class="flex column gap-8 avantages-box">
      <app-voucher-encoding [order]="order" [resources]="resources" [contextId]="contextId"></app-voucher-encoding>
    </div>

    <div class="flex column gap-8 avantages-box" *ngIf="vouchers && vouchers?.length > 0">
      <app-voucher-selection [order]="order" [resources]="resources" [contextId]="contextId"></app-voucher-selection>
    </div>

    <div class="flex column gap-8 avantages-box" *ngIf="summary && summary?.wallet.amount > 0 && (order.walletAmount <= 0 ? order.vouchersTotal < order.itemsTotal : true)">
      <app-wallet-consumption [order]="order" [resources]="resources" [contextId]="contextId"></app-wallet-consumption>
    </div>
  </div>
  <div class="choice-of-payment" *ngIf="order.totalToPay>0">
    <app-payment-mode-selection [resources]="resources" [contextId]="contextId" [isDemoClient]="isDemoClient" [order]="order"  (changeSelectedMethod)="selectPaymentMethod($event)" ></app-payment-mode-selection>
  </div>
</div>
