import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService, } from 'angular-auth-oidc-client';
import { JWTService } from '../core/services/jwt.service';
import { SocketService } from '../core/services/sockets.service';
import { StorageService } from '../core/services/storage.service';


const STORAGE_KEY = 'redirect';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate, CanActivateChild { //extends AutoLoginPartialRoutesGuard {

  config = "desktop";

  constructor(private readonly router: Router, private authService: OidcSecurityService, private storage: StorageService, private socketsService:SocketService, private jwt:JWTService) { }


  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuth(state.url).then(data => {
      return data
    });
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuth(state.url).then(data => {
      return data
    });
  }


  private async checkAuth(url) {

    let auth = this.authService.isAuthenticated("desktop");

    if(auth){
      // this.socketsService.connect(this.jwt.getSubFromToken());
      return true;
    }


    let result = await this.authService.checkAuth("/", this.config).toPromise().catch(err => {
      //Go Home Page if error
      this.storage.redirectTo = url;
      return this.router.navigate(["/"]);
    });

    if (typeof result == "object" && result.isAuthenticated) {
      //Check Socket connection if userData loaded
      // if(result.userData) this.socketsService.connect(result.userData.sub);

      return true;
    }
    else {
      //Try Force Refresh if RToken Available
      try {
        if (this.authService.getRefreshToken()) {
          return this.authService.forceRefreshSession().toPromise().then(res => {
            //Continue if authenticated
            if (res.isAuthenticated){
              //Check Socket connection if userData loaded
              // if(res.userData) this.socketsService.connect(res.userData.sub);
              return true;
            }
            //Go Home Page if not
            else {
              this.storage.redirectTo = url;
              return this.router.navigate(["/"]);
            }
          }).catch(err => {
            //Go Home Page if error
            this.storage.redirectTo = url;
            return this.router.navigate(["/"]);
          });
        }
      } catch (err) {

      }

      this.storage.redirectTo = url;
      //Go Home Page if no RToken Available
      return this.router.navigate(["/"]);
    }
  }
}
