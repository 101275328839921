import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './components/error/error.component';
import {HomeComponent} from './components/home/home.component';
import {MyaccesComponent} from './components/profil/components/myacces/myacces.component';
import {MycommunicationsComponent} from './components/profil/components/mycommunications/mycommunications.component';
import {MydatasComponent} from './components/profil/components/mydatas/mydatas.component';
import {ProfilComponent} from './components/profil/profil.component';
import {StatusComponent} from './components/status/status.component';
import {WalletComponent} from './components/wallet/wallet.component';
import {PointEncodingComponent} from './components/point-encoding/point-encoding.component';
import {DelegateComponent} from './components/delegate/delegate.component';
import {OrdersListComponent} from './components/order/orders-list/orders-list.component';
import {OrderComponent} from './components/order/order/order.component';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from 'src/app/core/services/translation.service';
import {StorageService} from 'src/app/core/services/storage.service';
import {ClientCodeComponent} from "./components/client-code/client-code.component";
import { BoosterComponent } from './components/booster/booster.component';

//ROUTING OPTION ---------------------------------------------------------------------
// breadcrumb : value  -- value to display in breadcrumb
// showBreadCrumb: false -- request to hide breadcrumb
// showJumbotron: false -- request to hide jumbo
// showNewsletter: false -- request to hide newsletter subscribtion frame
// showFooterMenu: false -- request to hide footer menu
// showFooterDisclaimer= false -- reqiest to hide footer disclaimer section
// showHeader: false -- switch header to header-light
// showHeaderMobileOnly: true -- Allows you to switch header to header-light except for mobile
// usePinkColor: true -- will switch main page + breadcrumb color to victoria pink
//------------------------------------------------------------------------------------
const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'home',
    data: {breadcrumb: 'MENU.HOME', showBreadCrumb: false},
    component: HomeComponent,
  },
  {
    path: 'status',
    data: {breadcrumb: 'MENU.STATUS'},
    component: StatusComponent,
  },
  {
    path: 'wallet',
    data: {breadcrumb: 'MENU.WALLET'},
    component: WalletComponent,
  },
  {
    path: 'delegate',
    data: {breadcrumb: 'MENU.DELEGATE'},
    component: DelegateComponent,
  },
  {
    path: 'point-encoding',
    data: {breadcrumb: 'MENU.POINTENCODING'},
    component: PointEncodingComponent,
  },
  // {
  //   path: 'point-evolution',
  //   data: {breadcrumb: 'MENU.POINTS'},
  //   component: PointEvolutionComponent,
  // },
  {
    path: 'orders',
    data: {breadcrumb: 'MENU.ORDERS.ORDERS'},
    component: OrdersListComponent,
  },
  {
    path: 'orders/:value',
    data: {breadcrumb: 'MENU.ORDERS.ORDERS'},
    component: OrderComponent,
  },
  {
    path: 'referral',
    data: {breadcrumb: 'MENU.BOOSTER'},
    component: BoosterComponent,
  },
  {
    path: 'profile',
    data: {breadcrumb: 'MENU.PROFILE'},
    component: ProfilComponent,
    children: [
      {
        path: '', redirectTo: 'data', pathMatch: 'full'
      },
      {
        data: {breadcrumb: 'MENU.PROFILE.INFORMATIONS'},
        path: 'data', component: MydatasComponent,

      },
      {
        data: {breadcrumb: 'MENU.PROFILE.ACCES'},
        path: 'access', component: MyaccesComponent
      },
      {
        data: {breadcrumb: 'MENU.PROFILE.COMMUNICATION'},
        path: 'communication', component: MycommunicationsComponent
      }
    ]
  },
  {
    path: 'client-code',
    data: {breadcrumb: 'MENU.CLIENT_CODE'},
    component: ClientCodeComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      showBreadCrumb: false,
      showJumbotron: false,
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {

  constructor(private translate: TranslateService, private translateConfig: TranslationService, private storage: StorageService) {
    //   routes.push(
    //     {
    //       path: this.translate.instant("PATH.HOME"),
    //       data: {breadcrumb: 'MENU.HOME', infoRoute: 'PATH.HOME'},
    //       component: HomeComponent,
    //     },
    //     {
    //       path: this.translate.instant("PATH.STATUS"),
    //       data: {breadcrumb: 'MENU.STATUS', infoRoute: 'PATH.STATUS'},
    //       component: StatusComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.WALLET"),
    //       data: {breadcrumb: 'MENU.WALLET', infoRoute: 'PATH.WALLET'},
    //       component: WalletComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.DELEGATE"),
    //       data: {breadcrumb: 'MENU.DELEGATE', infoRoute: 'PATH.DELEGATE'},
    //       component: DelegateComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.POINTENCODING"),
    //       data: {breadcrumb: 'MENU.POINTENCODING', infoRoute: 'PATH.POINTENCODING'},
    //       component: PointEncodingComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.POINTEVOLUTION"),
    //       data: {breadcrumb: 'MENU.POINTS', infoRoute: 'PATH.POINTEVOLUTION'},
    //       component: PointEvolutionComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.ORDERS"),
    //       data: {breadcrumb: 'MENU.ORDERS.ORDERS', infoRoute: 'PATH.ORDERS'},
    //       component: OrdersListComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.ORDER") + '/:id',
    //       data: {breadcrumb: 'MENU.ORDERS.ORDERS', infoRoute: 'PATH.ORDER'},
    //       component: OrderComponent,
    //       canActivate: [AuthorizationGuard]
    //     },
    //     {
    //       path: this.translate.instant("PATH.PROFILE"),
    //       data: {breadcrumb: 'MENU.PROFILE', infoRoute: 'PATH.PROFILE'},
    //       component: ProfilComponent,
    //       canActivate: [AuthorizationGuard],
    //       children: [
    //         {
    //           path: '', redirectTo: this.translate.instant("PATH.PROFILE.DATAS")
    //         },
    //         {
    //           data: {breadcrumb: 'MENU.PROFILE.INFORMATIONS', infoRoute: 'PATH.PROFILE.DATAS'},
    //           path: this.translate.instant("PATH.PROFILE.DATAS"), component: MydatasComponent,

    //         },
    //         {
    //           data: {breadcrumb: 'MENU.PROFILE.ACCES', infoRoute: 'PATH.PROFILE.ACCES'},
    //           path: this.translate.instant("PATH.PROFILE.ACCES"), component: MyaccesComponent
    //         },
    //         {
    //           data: {breadcrumb: 'MENU.PROFILE.COMMUNICATION', infoRoute: 'PATH.PROFILE.COMMUNICATION'},
    //           path: this.translate.instant("PATH.PROFILE.COMMUNICATION"), component: MycommunicationsComponent
    //         }
    //       ]
    //     },
    //     {
    //       path: this.translate.instant("PATH.ERROR"),
    //       component: ErrorComponent,
    //   })

    // this.restoreDefaultLanguage();
  }
}
