import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JWTService } from '../core/services/jwt.service';
import { getAreaByUrl } from '../core/utils/filter.utils';
import { StorageService } from '../core/services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  configId = "desktop";
  constructor(private oidcSecurityService: OidcSecurityService, private router: Router, private plateform: Platform, private jwtService:JWTService, private storage:StorageService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {

    let params = route.params;
    let area = getAreaByUrl(window.location.origin);
    let availableLanguages = environment.allowedLocales[area];

    if (params.id && availableLanguages.includes(params.id)) {

      return this.oidcSecurityService.checkAuth("/", this.configId).toPromise().then(result => {

        if (result && result.isAuthenticated) {
          if(this.jwtService._connectedAsDelegate$.value == true) return this.router.navigateByUrl('/' + params.id + '/myvictoria/demos');
          else return this.router.navigateByUrl('/' + params.id + '/club/home');
        }
        else {
          if (this.oidcSecurityService.getRefreshToken() && !this.storage.isTokenRenewing()) {
              this.storage.setTokenRenewing(true);

             return this.oidcSecurityService.forceRefreshSession().toPromise().then(res => {
              this.storage.setTokenRenewing(false);
              if (res.isAuthenticated) {
                if(this.jwtService._connectedAsDelegate$.value == true) return this.router.navigateByUrl('/' + params.id + '/myvictoria/demos');
                else return this.router.navigateByUrl('/' + params.id + '/club/home');
              }
              else {
                return true;
              }

            }).catch(err => {
              this.storage.setTokenRenewing(false);
              return true
            });
          } else return true;
        }
      }).catch(err =>  true);
    } else this.router.navigate(['/']);
  }
}
