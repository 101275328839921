import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { rootCertificates } from 'tls';

@Injectable({
    providedIn:'root'
})
export class AuthStorageService implements AbstractSecurityStorage {

  public read(key: string) {
    return localStorage.getItem(key);
  }

  public  write(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    localStorage.clear();
  }
}