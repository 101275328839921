import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GetDatasService} from '../../../../core/services/get-datas.service';
import {Subject, Subscription} from 'rxjs';
import {Wallet} from '../../../../core/models/wallet.model';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from 'src/app/core/services/translation.service';
import {StorageService} from 'src/app/core/services/storage.service';
import {CardType} from '../../../../core/enums/card-type.enum';
import {CookieService} from 'ngx-cookie-service';
import {ModalService} from '../../../../core/services/modal.service';
import {TriggerService} from "../../../../core/services/trigger.service";

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {

  refreshTableDatas: Subject<void> = new Subject<void>();
  currentWallet: Wallet;
  displayCreationDialog: Subject<void> = new Subject<void>();
  displayCreatedDialog: Subject<void> = new Subject<void>();
  cardType = CardType;
  locale: string;

  public addVoucherAvailable = false;

  private langueChangedSubscription: Subscription;

  constructor(
    protected cd: ChangeDetectorRef,
    private getDatasService: GetDatasService,
    private router: Router,
    private translate: TranslateService,
    private translateConfig: TranslationService,
    private storage: StorageService,
    private cookie: CookieService,
    public modal: ModalService,
    private triggerService: TriggerService
  ) {
  }

  public ngOnDestroy(): void {
    if (this.langueChangedSubscription) {
      this.langueChangedSubscription.unsubscribe();
    }
  }


  public async ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');
    this.getDatasService.summary$.subscribe(data => {
      if (data) {
        this.currentWallet = data.wallet;
      }
    });

  }

  public ngAfterViewInit() {
    if (this.langueChangedSubscription) {
      this.langueChangedSubscription.unsubscribe();
    }
    this.langueChangedSubscription = this.translateConfig.langHasChanded.subscribe(async (value) => {
      if (value === true) {
        this.translate.use(this.storage.languageCode);
      }
    });
  }

  displayCreationDialogAction() {
    this.modal.open('#walletCreateVoucher');
    this.triggerService.resetDefaultValueAddVoucher(true);
  }

}
