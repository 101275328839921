<div class="wallet-data-wrapper-container">
  <h2 class="table-title table-filter">{{'WALLET.DATAS.TITLE' | translate}}</h2>
  <div class="filter no-line-height" *ngIf="loaded">
    <div class="d-only flex">
      <div class="form-group row m-column" [formGroup]="formFilter">
        <div class="form-group flex ai-center filter-option">
          <div class="flex row checkbox path">
            <input type="checkbox" class="checkbox-input" formControlName="showUsed">
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
          </div>
          <p>{{'VOUCHER.TABLE.VIEWUSED' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="m-only">
      <form [formGroup]="formFilter">
        <div class="filter no-line-height no-padding-bottom">
          <div class="row flex fullwidth-only j-space-between">
            <div class="filter-title flex ai-center">
              <p>{{'VOUCHER.TABLE.VIEWUSED' | translate}}</p>
            </div>
            <div class="filter-input">
              <label class="switch">
                <input type="checkbox" formControlName="showUsed">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="table">
    <app-wallet-datas
      *ngIf="loaded && vouchers"
      [vouchers]="vouchers"
      (triggerCall)="getMoreDatas();"
    ></app-wallet-datas>
    <div class="table-loader flex j-center ai-center" *ngIf="firstCall && loadedMore">
      <div class="loader-bullet">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <div class="profile-main flex center-all loading" *ngIf="!loaded">
    <div class="loader"></div>
  </div>
  <div class="more d-only">
    <div [ngClass]="loadedMore ? 'loading' : ''" (click)="getMoreDatas()" *ngIf="datatableService.voucherDatasHasMore$ | async">
      <p class="uppercase">{{'GLOBAL.ACTION.MORE' | translate}}</p>
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-arrow-right"></use>
      </svg>
    </div>
  </div>
</div>
