export class OrderDeliveryAddress {
    public guid: string
    public firstName: string
    public lastName: string
    public address1: string
    public address2: string
    public zipCode: string
    public city: string
    public countryCode: string
    public email: string
    public mobile: string


    constructor(profile: any = undefined) {
        if (profile) {
            this.firstName = profile.firstName
            this.lastName = profile.lastName
            this.address1 = profile.address1
            this.zipCode = profile.zipCode
            this.city = profile.city
            this.countryCode = profile.country ?? profile.countryCode
            this.mobile = profile.mobile
            this.email = profile.email
        }
    }
}
