<!-- <ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>
      Blank
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Agora</ion-title>
    </ion-toolbar>
  </ion-header>

  <div id="container">
  </div>
</ion-content> -->


<!--<div class="info-bulle flex row ai-center ac-center j-space-around">
  <div class="container">
    <div class="icon rounded icon-container flex center-all">
      <svg viewBox="0 0 29 38">
        <use xlink:href="#icon-tip"></use>
      </svg>
    </div>
    <div class="flex column center-all text">
      <p>
        Lors de ton prochain achat, tu recevras le "<span class="bold">goodies</span>" Victoria grâce à ton passage au statut <span class="bold title">SAPHIR</span>.
      </p>
    </div>
  </div>
</div>-->

<div *ngIf="clientCode" class="fullwidth client-identity">
  <div class="clientCodeArea"><p>{{'MENU.CLIENT_CODE' | translate}} : {{clientCode}}</p></div>
</div>

<div class="encoding-point-button m-only">
  <button class="btn btn-primary" [routerLink]="['/' + locale + '/club/point-encoding']">
    <div class="label">
      {{'GLOBAL.POINTENCODING' | translate}}
    </div>
    <div class="icon white">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-diamond"></use>
      </svg>
    </div>
  </button>
</div>

<app-booster-access *ngIf="(jwtService.hasDelegateRole$ | async)===false"></app-booster-access>

<div class="status-card-container flex row cards-area" *ngIf="currentClientSummary">
    <div class="card flex flex-1 no-margin-left status">
      <app-card class="flex flex-1 flex j-center" [cardType]="cardType.STATUS" [data]="currentClientSummary.status" [forecasts]="currentClientSummary.forecasts" [showStatusLink]="true"></app-card>
    </div>
    <div class="card flex flex-1">
      <app-card class="flex flex-1 cursor flex j-center" [cardType]="cardType.WALLET" [data]="currentClientSummary.wallet" [showWalletButton]="true">></app-card>
    </div>
    <div class="card flex flex-1">
      <app-card class="flex flex-1 cursor flex j-center" [routerLink]="['/' + locale + '/club/delegate']" [cardType]="cardType.DELEGATE" [data]="currentClientSummary.delegate">></app-card>
    </div>
</div>
