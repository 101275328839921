<div class="order-delivery" *ngIf="resources && order">
  <h1 class="canela">{{resources.checkoutDelivery.mainSteps[1].label}}</h1>
  <p>{{'FIDELITY.DELIVERY.OPTIONX' | translate: {'X': deliveryOptions.length} }}</p>

  <div class="order-delivery-options flex column j-start">
    <div class="delivery-option flex" *ngIf="deliveryIsAvailable(1)" [class.selected]="deliveryId==1" (click)="switchOption(1)">
      <label class="flex">
        <input type="radio" id="delivery" name="delivery" [checked]="deliveryId==1">
        <div>
          <div class="flex delivery-option-label">
            <div class="header">
              <!-- {{resources.checkoutDelivery.shippingOptions[0].label | translateWihParam:{'name': delegate?.displayName} }} -->
              {{translate.getTranslationMultiple(resources.checkoutDelivery.shippingOptions[0].label | translateWihParam:{'name': delegate?.displayName}, 'ORDER.DELIVERY.CHOICE.DELEGATE', isOrderValidation, undefined, {displayName: delegate?.displayName}, true) }}
            </div>
            <div class="option">{{resources.checkoutDelivery.shippingOptions[0].priceLabel}}</div>
          </div>
          <p class="subtitle">
            {{ translate.getTranslationMultiple(resources.checkoutDelivery.shippingOptions[0].description | removeHtmlTag |
            translateWihParam:{'zipCode': delegate?.zipCode, 'city': delegate?.city, 'countryCode': delegate?.countryCode}, 'ORDER.DELIVERY.CHOICE.DELEGATE.SUBTITLE', isOrderValidation, undefined, undefined, true)
            }}
          </p>
          <div class="delivery-info" [class.selected]="deliveryId==1">
            <p *ngIf="!order.isOnlyEGifts && !isOrderValidation">{{resources.checkoutDelivery.shippingOptions[0].descriptionSecond | removeHtmlTag
              | translateWihParam:{'delegateName': delegate?.displayName} }}</p>
            <p *ngIf="order.isOnlyEGifts">{{'FIDELITY.DELIVERY.EGIFTS' | translate}}</p>
            <button class="btn btn-primary j-center" [disabled]="isBusy"
              (click)="confirmDelivery()">
              {{translate.getTranslationMultiple(resources.checkoutDelivery.pickupButtonLabel,'GLOBAL.ACTION.CONFIRM', isOrderValidation, undefined, undefined, true)}}
              <div *ngIf="isBusy" class="loader loader-mini"></div>
            </button>

          </div>
        </div>
      </label>
    </div>
    <div class="delivery-option flex" *ngIf="deliveryIsAvailable(10)" [class.selected]="deliveryId==10" (click)="switchOption(10)">
      <label class="flex">
        <input type="radio" id="delivery" name="delivery" [checked]="deliveryId==10">
        <div>
          <div class="flex delivery-option-label align-to-bullet">
            <div class="header">{{resources.checkoutDelivery.shippingOptions[1].label}}</div>
            <div class="option" >{{resources.checkoutDelivery.shippingOptions[1].priceLabel | translateWihParam:{price:
              (getDeliveryFee(10) | agoraCurrency)} }}</div>
          </div>
          <!-- <p class="subtitle">{{resources.checkoutDelivery.shippingOptions[1].description | removeHtmlTag}}</p> -->
          <div class="delivery-info" [class.selected]="deliveryId==10">
            <p class="subheader">{{resources.checkoutDelivery.shippingOptions[1].descriptionSecond | removeHtmlTag}}</p>
            <form [formGroup]="deliveryForm" *ngIf="deliveryForm && canEditDeliveryAdress">
              <div class="form-block">
                <label>{{resources.checkout.addressLabel}}</label>
                <input type="text" formControlName="street" id="street">
                <div class="form-error-container" *ngIf="df.street.errors && df.street.errors.required">
                  {{translate.getTranslationMultiple(resources.popupFindConsultant.streetLabelError, 'INPUT.VALIDATION.REQUIRED', isOrderValidation, undefined, undefined, true) }}
                </div>
              </div>
              <div class="flex gap-15 address-input">
                <div class="form-block">
                  <label>{{resources.checkout.postalCodeLabel}}</label>
                  <input type="text" formControlName="zipCode" id="zipCode">
                  <div class="form-error-container" *ngIf="df.zipCode.errors && df.zipCode.errors.required">
                    {{translate.getTranslationMultiple(resources.popupFindConsultant.postalCodeLabelError, 'INPUT.VALIDATION.REQUIRED', isOrderValidation, undefined, undefined, true) }}
                  </div>
                </div>
                <div class="form-block">
                  <label>{{resources.checkout.cityLabel}}</label>
                  <input type="text" formControlName="city" id="city">
                  <div class="form-error-container" *ngIf="df.city.errors && df.city.errors.required">
                    {{translate.getTranslationMultiple(resources.popupFindConsultant.cityLabelError, 'INPUT.VALIDATION.REQUIRED', isOrderValidation, undefined, undefined, true) }}
                  </div>
                </div>
              </div>
              <div class="form-block">
                <label>{{resources.checkout.countryLabel}}</label>
                <select formControlName="country" id="country" (change)="changeCountry($event)">
                  <option *ngFor="let opt of this.form.getCountries()" [value]="opt.id">
                    {{opt.value}}</option>
                </select>
                <div class="form-error-container" *ngIf="df.country.errors && df.country.errors.required">
                  {{translate.getTranslationMultiple(resources.popupFindConsultant.countryLabelError, 'INPUT.VALIDATION.REQUIRED', isOrderValidation, undefined, undefined, true) }}
                </div>
              </div>
              <div class="form-block exceptionnal-margin">
                <label>{{resources.checkout.phoneLabel}}</label>
                <input digitOnly decimalSeparator="" type="text" pattern="[0-9]*" inputmode="numeric" formControlName="phone" id="phone" [placeholder]="getPhonePlaceHolder()">
                <div class="form-error-container" *ngIf="df.phone.errors && df.phone.errors.required">
                  {{translate.getTranslationMultiple(resources.register.phoneLabelError, 'INPUT.VALIDATION.REQUIRED', isOrderValidation, undefined, undefined, true) }}
                </div>
                <div class="form-error-container" *ngIf="df.phone.errors && df.phone.errors.pattern">
                  {{translate.getTranslationMultiple(resources.register.phoneLabelError, 'INPUT.VALIDATION.PHONEPATTERN', isOrderValidation, undefined, undefined, true) }}
                </div>
              </div>
              <div class="text-margin-top save-default-address">
                <!-- <label class="flex row checkbox path text-margin-bottom">
                        <input type="checkbox" [(ngModel)]="saveAsDefaultAdress"  [ngModelOptions]="{standalone:true}"  id="sav">
                        <svg viewBox="0 0 28 28">
                          <use xlink:href="#custom-checkbox"></use>
                        </svg>
                        Sauvegarder cette adresse par défaut
                      </label> -->
              </div>
            </form>
            <div class="adresse-container" *ngIf="!canEditDeliveryAdress">
              <p>{{order.deliveryAddress.firstName}} {{order.deliveryAddress.lastName}}</p>
              <p>{{order.deliveryAddress.address1}}</p>
              <p>{{order.deliveryAddress.zipCode}} - {{order.deliveryAddress.city}}</p>
              <p>{{order.deliveryAddress.mobile}}</p>
            </div>
            <div class="form-block flex margin-top-50 save-button" *ngIf="canEditDeliveryAdress">
              <button class="btn btn-primary j-center" aria-details="button1"
                (click)="saveEditAdresse()" [disabled]="isBusy">
                {{resources.checkoutDelivery.buttonLabel}}
                <div *ngIf="isBusy" class="loader loader-mini"></div>
              </button>
            </div>
            <div class="form-block flex margin-top-50 ai-center save-button" *ngIf="!canEditDeliveryAdress">
              <button class="btn btn-primary j-center" aria-details="button2"
                (click)="confirmDelivery()" [disabled]="isBusy">
                {{resources.checkoutDelivery.deliverButtonLabel}}
                <div *ngIf="isBusy" class="loader loader-mini"></div>
              </button>
              <a class="ctaLink" aria-details="button3" (click)="editDeliveryAdress()">{{resources.checkoutDelivery.editButtonLabel}}</a>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</div>
