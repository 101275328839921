import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-delivery-payment-confirmation',
  templateUrl: './modal-delivery-payment-confirmation.component.html',
  styleUrls: ['./modal-delivery-payment-confirmation.component.scss'],
})
export class ModalDeliveryPaymentConfirmationComponent implements OnInit {

  @Output() confirm:EventEmitter<boolean> = new EventEmitter<boolean>()
  private eventTrigged = false;


  constructor() { }

  ngOnInit() {}

  confirmEvent(value){
    if(!this.eventTrigged) {
      this.confirm.emit(value);
      this.eventTrigged=true;
    }

    setTimeout( _ => this.eventTrigged = false, 1000)
  }

}
