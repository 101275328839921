import { Injectable, Renderer2 } from "@angular/core";
import { LoggerService } from "./logger.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JWTService } from "./jwt.service";
import { getApiUrlFromOrigin } from "../utils/filter.utils";
import { Track } from "../models/track.model";
import { StorageService } from "./storage.service";
import { CookieService } from "ngx-cookie-service";

const BASE_URL = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient, private jwt: JWTService, private storage:StorageService, private cookie:CookieService) { }

  public track(data: Track) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getToken());

    this.http.post<any>(BASE_URL + `/track`, data, { headers: this.headers }).subscribe();

  }

  public track_demoId_delegateId(){
    this.track({
        eventName: 'Download',
        eventAction: this.cookie.get("delegateId") + '-' + this.cookie.get("demoId"),
        userType: "Client"
    });

    if(this.cookie.get("delegateId")=="undefined"){
      this.storage.removeDelegateId()
      this.storage.removeDemoId()
    }

    if(this.cookie.get("demoId")=="undefined") this.storage.removeDemoId()
  }

  public track_login_delegate() {
    this.track(
      {
        eventName: 'Login',
        eventAction: 'Login',
        userType: "Delegate"
      });

  }

  public track_login_client() {
    this.track(
      {
        eventName: 'Login',
        eventAction: 'Login',
        userType: "Client"
      });

  }

  public track_view_demo_client(statusId) {

    this.track(
      {
        eventName: 'Demo',
        eventAction: 'View',
        userType: "Client",
        demoCode: this.storage.currentDemoCode(),
        demoStatusId: statusId
      });

      this.storage.setTrackingDemo(this.storage.currentDemoCode());

  }

  public track_view_demo_delegate(statusId) {

    this.track(
      {
        eventName: 'Demo',
        eventAction: 'View',
        userType: "Delegate",
        demoCode: this.storage.currentDemoCode(),
        demoStatusId: statusId
      });

      this.storage.setTrackingDemo(this.storage.currentDemoCode());

  }

  public track_demo_listing_client() {

    this.track(
      {
        eventName: 'Demo',
        eventAction: 'Listing',
        userType: "Client"
      });

      this.storage.setTrackingListing();

  }

  public track_demo_listing_delegate() {

    this.track(
      {
        eventName: 'Demo',
        eventAction: 'Listing',
        userType: "Delegate"
      });

      this.storage.setTrackingListing();

  }

  public track_demo_view_products_client() {

    this.track(
      {
        eventName: 'Demo',
        eventAction: 'View_Products',
        userType: "CLient"
      });
  }

  public track_demo_view_products_delegate() {

    this.track(
      {
        eventName: 'Demo',
        eventAction: 'View_Products',
        userType: "Delegate"
      });
  }

  public track_demo_view_universe_client() {

    this.track(
      {
        eventName: 'Demo',
        eventAction: 'View_Universe',
        userType: "CLient"
      });

  }

  public track_demo_view_post_order_popup_client() {
    this.track(
      {
        eventName: 'Demo',
        eventAction: 'View_PostOrderPopup',
        userType: "CLient"
      });

  }



}

