<div class="point-history-wrapper-container">
  <h2 *ngIf="currentStatus?.statusId<4" class="table-title">{{'STATUS.TITLE.GOTOSTATUSX' | translate: {'X': 'CLIENT.STATUS' + (currentStatus?.statusId + 1) | translate} }}</h2>
  <h2 *ngIf="currentStatus?.statusId===4" class="table-title">{{'STATUS.TITLE.KEEPSTATUSX' | translate: {'X': 'CLIENT.STATUS' + (this.currentStatus?.statusId) | translate} }}</h2>
  <app-status-datas
    *ngIf="loaded"
    [status]="currentStatus"
    [clientActionsDone]="clientActionsDone"
    [clientActions]="datatableService.clientActionDatas$ | async"
  ></app-status-datas>
  <div class="flex center-all loading" *ngIf="!loaded">
    <div class="loader"></div>
  </div>
</div>
