import {Component, Input, OnInit} from '@angular/core';
import {DatatableService} from "../../../../../core/services/datatable.service";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {LoaderService} from "../../../../../core/services/loader.service";
import {OrderService} from "../../../../../core/services/order.service";
import {CookieService} from "ngx-cookie-service";
import {TranslationService} from "../../../../../core/services/translation.service";
import {ClientOrder} from "../../../../../core/models/clientOrder.model";

@Component({
  selector: 'app-delivered-orders-list-data-wrapper',
  templateUrl: './delivered-orders-list-data-wrapper.component.html',
  styleUrls: ['./delivered-orders-list-data-wrapper.component.scss'],
})
export class DeliveredOrdersListDataWrapperComponent implements OnInit {

  @Input() datatableTitle: string;
  loaded = false;
  pageSize = 5;
  pageIndex = 0;
  hasMore: boolean;
  firstCall = false;
  loadedMore = false;
  orders: ClientOrder[];
  getOrders = false;

  constructor(
    public datatableService: DatatableService,
    private authService: OidcSecurityService,
    private loader: LoaderService,
    public orderService: OrderService,
    private translate: TranslationService,
  ) {
    this.loader.loaded$.subscribe(res => {
      if(this.firstCall) {
        this.loadedMore = false;
      }
      if(!this.firstCall) {
        this.firstCall = true;
      }
      this.loaded = res;
    });
  }

  ngOnInit() {
    this.getOrders = false;
    this.orders = [];
    this.orderService.currentDeliveredClientOrders$.subscribe(res => {
      if(this.pageIndex == 0)this.orders = res;
      else this.orders.push(...res);
    })
  }

  getMoreDatas() {
    this.pageIndex += 1;
    this.loadedMore = true;
    this._getValues();
  }

  getDatas() {
    this.pageIndex = 0;
    this.loadedMore = true;
    this.getOrders = true;
    this.loaded = false;
    this._getValues();
  }

  private _getValues() {
    this.orderService.getDeliveredOrders(this.translate.getContextId(), this.pageIndex, true);
  }

}
