import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {

  @Input() loaded = true;
  @Output() endScrollEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  onScroll(event: any) {
    window.setTimeout(_ => {
      if (event.target.scrollLeft + event.target.offsetWidth >= event.target.scrollWidth- 800){
        this.endScrollEvent.emit();
      }
    },500);
  }
}
