<div class="agora-modal-container {{type}}" [ngClass]="classes" id="agora-modal-container" (click)="closeModalFromOutside($event)">
  <div class="modal">
    <div class="close" *ngIf="closable">
      <svg viewBox="0 0 32 32" class="pointer" (click)="closeModal($event)">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div class="scroll-zone">
      <h1 *ngIf="modalTitle">{{modalTitle}}</h1>
      <ng-content></ng-content>
    </div>
    <div class="scroll-icon">
      <svg>
        <use xlink:href="#icon-arrow-right"></use>
      </svg>
    </div>
  </div>
</div>
