import {NgModule} from '@angular/core';
import {CarouselComponent} from './carousel/carousel.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PageTitleComponent} from './page-title/page-title.component';
import {DatatableComponent} from './datatable/datatable.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipComponent} from './tooltip/tooltip.component';
import {CardComponent} from './card/card.component';
import {WalletCardComponent} from './card/wallet-card/wallet-card.component';
import {DelegateCardComponent} from './card/delegate-card/delegate-card.component';
import {NewStatusCardComponent} from './card/status-card/status-card.component';
import {WavedlinkComponent} from './wavedlink/wavedlink.component';
import {BackToTopComponent} from './back-to-top/back-to-top.component';
import {AvatarComponent} from './avatar/avatar.component';
import {ModalComponent} from './modal/modal.component';
import {FormStepperComponent} from './form-stepper/form-stepper.component';
import {FidelityLoginFrameComponent} from './fidelity-login-frame/fidelity-login-frame.component';
import {FidelityRegisterFrameComponent} from './fidelity-register-frame/fidelity-register-frame.component';
import {PipeModule} from 'src/app/core/pipes/pipe.module';
import {OrderShortComponent} from './order-short/order-short.component';
import {OrdersListComponent} from './orders-list/orders-list.component';
import {DelegateInfosComponent} from './delegate-infos/delegate-infos.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {OrderItemComponent} from '../../features/publicPages/components/commande/components/order-summary/order-item/order-item.component';
import {ErrorBoxComponent} from './error-box/error-box.component';
import {WalletDataWrapperComponent} from './datatable/wallet/wallet-data-wrapper/wallet-data-wrapper.component';
import {WalletDatasComponent} from './datatable/wallet/wallet-datas/wallet-datas.component';
import {PointHistoryDatasComponent} from './datatable/point-history/point-history-datas/point-history-datas.component';
import {PointHistoryDataWrapperComponent} from './datatable/point-history/point-history-data-wrapper/point-history-data-wrapper.component';
import {StatusDataWrapperComponent} from './datatable/status/status-data-wrapper/status-data-wrapper.component';
import {StatusDatasComponent} from './datatable/status/status-datas/status-datas.component';
import {WaitingOrdersListDatasComponent} from './datatable/orders-list/waiting-orders-list-datas/waiting-orders-list-datas.component';
import {WaitingOrdersListDataWrapperComponent} from './datatable/orders-list/waiting-orders-list-data-wrapper/waiting-orders-list-data-wrapper.component';
import {DeliveredOrdersListDataWrapperComponent} from './datatable/orders-list/delivered-orders-list-data-wrapper/delivered-orders-list-data-wrapper.component';
import {DeliveredOrdersListDatasComponent} from './datatable/orders-list/delivered-orders-list-datas/delivered-orders-list-datas.component';
import {PointEvolutionDataWrapperComponent} from './datatable/point-evolution/point-evolution-data-wrapper/point-evolution-data-wrapper.component';
import {PointEvolutionDatasComponent} from './datatable/point-evolution/point-evolution-datas/point-evolution-datas.component';
import {InputMaskModule} from '@ngneat/input-mask';
import {WishlistItemComponent} from './wishlist-item/wishlist-item.component';
import {AlertComponent} from './alert/alert.component';
import {DemoListItemComponent} from '../../features/agora/demo/components/demo-list-item/demo-list-item.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {SwiperModule} from 'swiper/angular';
import {ProductItemComponent} from './product-item/product-item.component';
import {DelegateInfosCardComponent} from './delegate-infos-card/delegate-infos-card.component';
import {LoginComponent} from 'src/app/sso/login/login.component';
import {ButtonLoaderComponent} from './button-loader/button-loader.component';
import {IconNotificationComponent} from './icon-notification/icon-notification.component';
import {AgoraModalComponent} from './agora-modal/agora-modal.component';
import {FilterLineComponent} from './filter-line/filter-line.component';
import {ItemStatusComponent} from './item-status/item-status.component';
import {DataLineComponent} from './data-line/data-line.component';
import {ModalConfirmComponent} from "./agora-modal/modal-templates/modal-confirm/modal-confirm.component";
import {CartArticlesComponent} from "./order/cart-articles/cart-articles.component";
import {CartItemComponent} from "./order/cart-item/cart-item.component";
import {CartRecapInfoComponent} from "./order/cart-recap-info/cart-recap-info.component";
import { VoucherEncodingComponent } from './order/voucher-encoding/voucher-encoding.component';
import { VoucherSelectionComponent } from './order/voucher-selection/voucher-selection.component';
import { PaymentModeSelectionComponent } from './order/payment-mode-selection/payment-mode-selection.component';
import { WalletConsumptionComponent } from './order/wallet-consumption/wallet-consumption.component';
import {OrderContactBoxComponent} from "./order/order-contact-box/order-contact-box.component";
import {OptinDipBoxComponent} from "./order/optin-dip-box/optin-dip-box.component";
import { AgoraButtonComponent } from './button/button.component';
import { ModalBecomeHostessDelegateComponent } from './agora-modal/modal-templates/modal-become-hostess-delegate/modal-become-hostess-delegate.component';
import { ActionsSheetComponent } from './actions-sheet/actions-sheet.component';
import { DigitOnlyDirective } from 'src/app/core/directives/digit-only.directive';
import { ModalDeliveryPaymentConfirmationComponent } from './agora-modal/modal-templates/modal-delivery-payment-confirmation/modal-delivery-payment-confirmation.component';
import { BoosterAccessComponent } from './booster-access/booster-access.component';
import {
  ModalSandboxAlertComponent
} from './agora-modal/modal-templates/modal-sandbox-alert/modal-sandbox-alert.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipeModule,
    InputMaskModule,
    SwiperModule,
  ],
    declarations: [
        AgoraModalComponent,
        ModalSandboxAlertComponent,
        AlertComponent,
        AvatarComponent,
        BackToTopComponent,
        ButtonLoaderComponent,
        CardComponent,
        CarouselComponent,
        DataLineComponent,
        DatatableComponent,
        DelegateCardComponent,
        DelegateInfosCardComponent,
        DelegateInfosComponent,
        DeliveredOrdersListDataWrapperComponent,
        DeliveredOrdersListDatasComponent,
        DemoListItemComponent,
        ErrorBoxComponent,
        ErrorBoxComponent,
        FidelityLoginFrameComponent,
        FidelityRegisterFrameComponent,
        FilterLineComponent,
        FormStepperComponent,
        IconNotificationComponent,
        ItemStatusComponent,
        LoginComponent,
        ModalComponent,
        NewStatusCardComponent,
        OrderDetailsComponent,
        OrderItemComponent,
        OrderShortComponent,
        OrdersListComponent,
        PageTitleComponent,
        PointEvolutionDataWrapperComponent,
        PointEvolutionDatasComponent,
        PointHistoryDataWrapperComponent,
        PointHistoryDatasComponent,
        ProductItemComponent,
        ProgressBarComponent,
        StatusDataWrapperComponent,
        StatusDatasComponent,
        TooltipComponent,
        WaitingOrdersListDataWrapperComponent,
        WaitingOrdersListDatasComponent,
        WalletCardComponent,
        WalletDataWrapperComponent,
        WalletDatasComponent,
        WavedlinkComponent,
        WishlistItemComponent,
        ModalConfirmComponent,
        CartArticlesComponent,
        CartItemComponent,
        CartRecapInfoComponent,
        VoucherEncodingComponent,
        VoucherSelectionComponent,
        PaymentModeSelectionComponent,
        WalletConsumptionComponent,
        OrderContactBoxComponent,
        OptinDipBoxComponent,
        AgoraButtonComponent,
        ModalBecomeHostessDelegateComponent,
        ActionsSheetComponent,
        DigitOnlyDirective,
        ModalDeliveryPaymentConfirmationComponent,
        BoosterAccessComponent
    ],
  exports: [
    ModalSandboxAlertComponent,
    AgoraModalComponent,
    AlertComponent,
    AvatarComponent,
    BackToTopComponent,
    ButtonLoaderComponent,
    CardComponent,
    CarouselComponent,
    DataLineComponent,
    DatatableComponent,
    DelegateInfosCardComponent,
    DelegateInfosComponent,
    DeliveredOrdersListDataWrapperComponent,
    DeliveredOrdersListDatasComponent,
    DemoListItemComponent,
    ErrorBoxComponent,
    FidelityLoginFrameComponent,
    FidelityRegisterFrameComponent,
    FilterLineComponent,
    FormStepperComponent,
    IconNotificationComponent,
    ItemStatusComponent,
    LoginComponent,
    ModalComponent,
    NewStatusCardComponent,
    OrderDetailsComponent,
    OrderItemComponent,
    OrderShortComponent,
    OrdersListComponent,
    PageTitleComponent,
    PointEvolutionDataWrapperComponent,
    PointHistoryDataWrapperComponent,
    ProductItemComponent,
    ProgressBarComponent,
    StatusDataWrapperComponent,
    TooltipComponent,
    WaitingOrdersListDataWrapperComponent,
    WalletDataWrapperComponent,
    WavedlinkComponent,
    WishlistItemComponent,
    ModalConfirmComponent,
    CartArticlesComponent,
    CartRecapInfoComponent,
    VoucherEncodingComponent,
    VoucherSelectionComponent,
    PaymentModeSelectionComponent,
    WalletConsumptionComponent,
    OrderContactBoxComponent,
    CartItemComponent,
    OptinDipBoxComponent,
    AgoraButtonComponent,
    ModalBecomeHostessDelegateComponent,
    ActionsSheetComponent,
    DigitOnlyDirective,
    ModalDeliveryPaymentConfirmationComponent,
    BoosterAccessComponent
  ]
})
export class ComponentsModule {
  constructor() {
  }
}
