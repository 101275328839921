import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { FidelityRegistration } from '../models/fidelityRegistration.model';
import { FidelityLogin } from '../models/fidelityLogin.model';
import { FidelityDelegate } from '../models/fidelityDelegate.model';
import { StorageService } from './storage.service';
import { JWTService } from './jwt.service';
import { LoaderService } from './loader.service';
import { ErrorService } from './error.service';
import { ErrorType } from '../enums/errorType.enum';
import { CookieService } from 'ngx-cookie-service';
import { getApiUrlFromOrigin, getCookieDomainByContext } from '../utils/filter.utils';
import {TranslationService} from "./translation.service";
import {OrderService} from "./order.service";

const BASE_URL: string = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root'
})
export class FidelityService {

  private headers: HttpHeaders;

  private _profile$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public profile$: Observable<any>

  private _delegate$: BehaviorSubject<FidelityDelegate> = new BehaviorSubject<FidelityDelegate>(undefined);
  public delegate$: Observable<FidelityDelegate>

  private _communications$ : BehaviorSubject<any> = new BehaviorSubject(undefined);
  public communications$ : Observable<any> = this._communications$.asObservable();

  //INTERNAL
  private profileLoading: boolean = false;

  constructor(public http: HttpClient,
    public storage: StorageService,
    public jwtService: JWTService,
    public loader: LoaderService,
    public error: ErrorService,
    private cookie:CookieService,
    private translate: TranslationService,
    private orderService: OrderService,
              ) {
    this.profile$ = this._profile$.asObservable();
    this.delegate$ = this._delegate$.asObservable();
  }

  public authenticate(contextId: any, data: FidelityLogin): Observable<any> {
    return this.http.post<any>(BASE_URL + `/Gateway/${contextId}/auth/authenticate`, data);
  }

  public register(contextId, data: FidelityRegistration): Observable<any> {
    return this.http.post<any>(BASE_URL + `/Gateway/${contextId}/auth/register`, data);
  }

  public requestResetPassword(contextId: number, email: string, redirectionLink: string): Observable<any> {
    return this.http.put(BASE_URL + `/Gateway/${contextId}/auth/${email}/updatepasswordrequest`, { redirectionLink: redirectionLink });
  }

  public sendNewPassword(contextId: number, email: string, load: any): Observable<any> {
    return this.http.put(BASE_URL + `/Gateway/${contextId}/auth/${email}/password`, load);
  }

  public mustLoadProfile(): boolean {
    return this._profile$.getValue() == undefined;
  }

  //UNUSED
  public getClientProfile(contextId: number, sub: string, notifyLoaded = false, updateDelegate = false): void {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append("Authorization", "Bearer " + this.jwtService.getToken());

    if (!this.profileLoading) {
      this.profileLoading = true;
      this.http.get<any>(BASE_URL + `/Gateway/${contextId}/clients/${sub}/profile`, { headers: this.headers }).subscribe(res => {

        if (res.data) {
          this._profile$.next(res.data);
          this.storage.clientFirstName = res.data.firstName;
          this.profileLoading = false;
        }
        this.error.dropError(ErrorType.PROFILE);
        this.updateDelegateCookies(contextId, updateDelegate, res.data.linkedDelegateId, res.data);
        this.triggerLoad(notifyLoaded);
      },
        err => {
          this.profileLoading = false;
          this.triggerLoad(notifyLoaded);
          this.error.raiseError(ErrorType.PROFILE);
        })
    }
  }

  public updateProfile(data: any) {
    this._profile$.next(data);
  }

  //UNUSED
  public profileDelegateDifferentFromOrder(id){
      return this._profile$.getValue()?.linkedDelegateId != id;
  }

  public updateDelegate(data: any) {
    this._delegate$.next(data);
    this.error.dropError(ErrorType.LOOKUP);
  }

  public lookupForDelegates(contextId, profileData: any, limit: number = 1, notifyLoaded = false) {

    let queryString = `?languageCode=${profileData?.locale?.split("-")[0]}&street=${profileData?.address1}&zipCode=${profileData?.zipCode}&locality=${profileData?.city}&countryCode=${profileData?.country}&limit=${limit}`;

    this.http.get<any>(BASE_URL + `/Gateway/${contextId}/delegates/lookup${queryString}`).subscribe(res => {
      if (res.data) this._delegate$.next(res.data[0]);
      this.error.dropError(ErrorType.LOOKUP);
      this.triggerLoad(notifyLoaded);
    },
      err => {
        this.error.raiseError(ErrorType.LOOKUP);
        this.triggerLoad(notifyLoaded);
      });
  }

  public lookupForOtherDelegates(contextId, profileData: any, formData, limit: number = 5): Observable<any> {
    let queryString = `?query=${formData.name ?? ''}&languageCode=${profileData.locale?.split("-")[0]}&street=${formData.address ?? ''}&zipCode=${formData.zip ?? ''}&locality=${formData.city ?? ''}&countryCode=${formData.country ?? ''}&limit=${limit}`;

    return this.http.get<any>(BASE_URL + `/Gateway/${contextId}/delegates/lookup${queryString}`);
  }

  public getDelegateSiteProfile(contextId, delegateId, profile, notifyLoaded = false) {

    if(delegateId && delegateId!="undefined" && delegateId!=null){
      this.http.get<any>(BASE_URL + `/delegates/${delegateId}/siteprofile`).subscribe(res => {
        if (res.data) this._delegate$.next(res.data);
        this.error.dropError(ErrorType.PROFILE);
        this.triggerLoad(notifyLoaded);

      }, (err:any) => {

        if(profile && profile!=null) this.lookupForDelegates(contextId, profile,1);

        this.triggerLoad(notifyLoaded);
        if(err.error.errors[0]?.type!==201) this.error.raiseError(ErrorType.DELEGATEPROFILE);
      });
    } else if(profile && profile!=null){
      this.lookupForDelegates(contextId, profile,1);
      this.triggerLoad(notifyLoaded);
    }
  }

  //Fidelity / Victoria Club
  public getFidelityUrl(contextId: number): string {

    switch (contextId) {
      case 1: return "https://fidelity.victoria-france.fr/";
      case 2: return "https://fidelity.victoria-benelux.be/?lang=fr";
      case 3: return "https://club.victoria-schmuck.com/";
      default: return "https://fidelity.victoria-france.fr/";
    }
  }

  public getFidelityUrlByRegion(locale: string): string {

    switch (locale) {
      case "fr_fr": return "https://fidelity.victoria-france.fr/";
      case "fr_be":
      case "fr_lu": return "https://fidelity.victoria-benelux.be/?lang=fr";
      case "nl_be":
      case "nl_nl": return "https://fidelity.victoria-benelux.be/?lang=nl";
      case "de_de":
      case "de_at": return "https://club.victoria-schmuck.com/";
      default: return "https://fidelity.victoria-france.fr/";
    }
  }

  public syncCartWishlist(clientId: string, token: string, sub: string) {

    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', 'Bearer  '+ token);
    this.headers = this.headers.append('X-ClientId', clientId);
    this.headers = this.headers.append("X-Locale", this.translate.getCodeLangueFromUserLocale(this.translate.getLanguage()));
    this.headers = this.headers.append("X-ContextId", this.translate.getContextId().toString());
    this.headers = this.headers.append("X-Reference", this.cookie.get('v_ref'));

    if (this.storage.delegateId && this.storage.delegateId !="undefined") this.headers = this.headers.append("X-DelegateId", this.storage.delegateId);
    if (this.storage.demoId &&  this.storage.demoId!="undefined") this.headers = this.headers.append("X-DemoCode", this.storage.demoId);


    this.http.get(BASE_URL + '/clients/' + sub + '/connection', { headers: this.headers }).subscribe(async (res: any) => {
      if(res && res.data?.order) {
        this.orderService.setOrderAndWishList(res.data.order.guestOrder, res.data.order.wishList);
      }

    }, err => {
    });
  }

  private triggerLoad(active) {
    if (active) this.loader.loaded();
  }

  private updateDelegateCookies(contextId, must, delegateId, profile){
    if(must) {
      this.cookie.set("delegateId", delegateId,365,"/", getCookieDomainByContext(contextId));
      this.cookie.set("victoria_profile", JSON.stringify(profile), 365,"/", getCookieDomainByContext(contextId));
    }
  }

}
