import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from "../../../../../core/services/order.service";
import {TranslateService} from "@ngx-translate/core";
import {TranslationService} from "../../../../../core/services/translation.service";
import {Delegate} from "../../../../../core/models/delegate.model";
import {ErrorService} from "../../../../../core/services/error.service";
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {

  currentOrderId: number;
  articlesDatas: any;
  order: any;
  delegate: Delegate;
  resources: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private translate: TranslationService,
    public error: ErrorService,
  ) {
    this.route.params.subscribe(params => {
      this.currentOrderId = params.value;
      this.route.queryParams.subscribe(p => {
        if(p.delivered === 'true') {
          this.orderService.getDeliveredOrderById(this.translate.getContextId(), this.currentOrderId);
        } else {
          this.orderService.getOrderById(this.translate.getContextId(), this.currentOrderId);
        }
      })
    });
  }

  ngOnInit() {
    this.orderService.clientOrderDetails$.subscribe(res => {
      if(res) {
        this.order = res;
        this.delegate = this.order.delegateProfile;
      }
    });
    this.translate.localesLoaded.subscribe(res => {
      if (res === true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });
  }

  backToOrders() {
    this.router.navigate(['orders'], {replaceUrl: true});
  }
}
