import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Pipe({ name: 'agoraCurrency' })
export class AgoraCurrency implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used

  private applicationLocale:string;

  constructor(private cookie:CookieService){

  }

  transform(price: any, locale?:string): string {

    let applicationLocale = locale ?? this.cookie.get('v_locale')?.replace('_', '-');
    let output = "";


        switch(applicationLocale){
            case "de-de":
            case "fr-be":
            case "fr-fr": output = price.toFixed(2) + " €"; break;
            case "nl-be": output = "€ " + price.toFixed(2); break;
            default: output = price.toFixed(2) + " €";
        }

    return output;
  }
}

@Pipe({ name: 'agoraCurrencyInt' })
export class AgoraCurrencyInt implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used

  private applicationLocale:string;

  constructor(private cookie:CookieService){

  }

  transform(price: any, locale?:string): string {

    let applicationLocale = locale ?? this.cookie.get('v_locale')?.replace('_', '-');
    let output = "";


        switch(applicationLocale){
            case "de-de":
            case "fr-be":
            case "fr-fr": output = price.toFixed(0) + " €"; break;
            case "nl-be": output = "€ " + price.toFixed(0); break;
            default: output = price.toFixed(0) + " €";
        }

    return output;
  }
}
