import { Injectable } from '@angular/core';
import { DemoOrdersModel } from 'src/app/features/agora/demo/models/demo-orders.model';
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';
import { ProductDisplayStatus } from '../enums/product-display-status.enum';


@Injectable({
    providedIn: 'root'
})
export class StockService {

    demoOrders: DemoOrdersModel;

    public constructor(private demoService: DemoService) {
        this.demoService.demoOrders$.subscribe(data => this.demoOrders = data);
    }

    private getQuantityFromCart(sku) {
        let totalStock = 0;

        this.demoOrders?.clientLinks?.forEach(client => {
            totalStock += client.orders[0]?.orderItems.find(x => x.sku == sku)?.quantity ?? 0;
        })

        return totalStock;
    }

    public checkStock(article: any, isCV) {
        let variant = null;
        let infos = { hasStock: false, isRAL: true };

        if(article?.variants)
            variant = article.variants[0];
        else if (article?.products)
            variant = article.products[0];
        else variant = article;

        //Default value
        if(!variant) return infos;

        //Is in Stock
        if (variant.stock > this.getQuantityFromCart(variant.id || variant.code || variant.sku)) {
            infos.hasStock = true;
            infos.isRAL = false;
        }
        //Is Stock RAL CV
        else if (variant.isRal && isCV && variant.stock <= this.getQuantityFromCart(variant.id || variant.code || variant.sku) ){
            infos.hasStock = true;
        }
        //Is Stock RAL Cli
        else if (variant.isRal && !isCV && variant.stock <= this.getQuantityFromCart(variant.id || variant.code || variant.sku) ){
            infos.hasStock = false;
        }
        //Is Soldout
        else if ((variant.isRal && variant.stock <= this.getQuantityFromCart(variant.id || variant.code || variant.sku)) || (!variant.isRal && variant.stock <= this.getQuantityFromCart(variant.id || variant.code || variant.sku))) {
            infos.hasStock = false;
            infos.isRAL = false;
        }

        return infos;
    }

    public getArticleStatus(article: any, isCV:boolean) : ProductDisplayStatus{
      let variant=null

      if(article?.variants)
        variant = article.variants[0];
      else if (article?.products)
        variant = article.products[0];
      else variant = article;

      const stockFromCart = this.getQuantityFromCart(variant.id || variant.code || variant.sku)
      const isRAL = (variant.stock <= stockFromCart) && (variant.totalStock > stockFromCart);
      const isSoldout = (variant.stock <= stockFromCart) && (variant.totalStock <= stockFromCart);
      const isPurchasable = variant.isPurchasable

      if(!isPurchasable) return ProductDisplayStatus.notPurchasable
      if(isSoldout) return ProductDisplayStatus.soldout

      if(isRAL && isCV ) return ProductDisplayStatus.backorderForDelegate
      else if (isRAL) return ProductDisplayStatus.backorderForCustomer

      if(variant.stock>stockFromCart) return ProductDisplayStatus.available

 

      return ProductDisplayStatus.notPurchasable

    }
}
