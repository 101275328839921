import { Component, Input, OnInit } from '@angular/core';
import { ClientOrder } from '../../../../core/models/clientOrder.model';
import { PaymentService } from '../../../../core/services/payment.service';
import { LoaderService } from '../../../../core/services/loader.service';
import { ModalService } from '../../../../core/services/modal.service';
import { OrderService } from '../../../../core/services/order.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../../core/services/translation.service';
import { FidelityService } from '../../../../core/services/fidelity.service';
import { JWTService } from '../../../../core/services/jwt.service';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../../../../core/services/storage.service';
import { Router } from '@angular/router';
import { DemoService } from '../../../../features/agora/demo/services/demo.service';
import { OrderActionEnum } from '../../../../features/agora/demo/enums/order-action.enum';
import { DemoModel } from "../../../../features/agora/demo/models/demo.model";
import { CartValidationEnum } from "../../../../features/agora/demo/enums/cart-validation.enum";

@Component({
  selector: 'app-cart-recap-info',
  templateUrl: './cart-recap-info.component.html',
  styleUrls: ['./cart-recap-info.component.scss'],
})
export class CartRecapInfoComponent implements OnInit {

  @Input() order: ClientOrder;
  @Input() wishlist: ClientOrder;
  @Input() resources: any;

  paymentMethods: any;
  locale: string;
  operationPending = false;

  //
  isCartValidationWaiting = false;
  isClientValidationDone = false;

  isCartValid = false;
  isDemoCart = false;

  demoCode: string;
  isCartSent = false;
  orderActionEnum = OrderActionEnum;
  demo: DemoModel;
  cartValidationEnum = CartValidationEnum;

  constructor(
    public modal: ModalService,
    public orderService: OrderService,
    public translation: TranslateService,
    public translate: TranslationService,
    private fidelity: FidelityService,
    private jwtService: JWTService,
    private paymentService: PaymentService,
    public loader: LoaderService,
    public cookie: CookieService,
    public storage: StorageService,
    private router: Router,
    private demoService: DemoService,
    private cookies: CookieService,
  ) {

    this.paymentService.paymentOptions$.subscribe(res => {
      if (res) {
        this.paymentMethods = res;
        this.loader.loaded(true);
      }
    });
  }

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');
    this.demoCode = this.storage.demoId ?? this.order?.demoCode;

    if(this.demoCode && this.demoCode!="undefined") this.demoService.getDemo(this.demoCode, false, false, false);

    // this.isDemoCart = this.order.demoStatusId < 4 && this.order.demoStatusId > 1 && this.demo?.accessLevel > 0;

    this.demoService.demo$.subscribe(res => {
      if (res) {
        this.demo = res;
        this.isCartValidationWaiting = this.demo?.mustValidateCart && this.order?.flowStatuses.cart.status == 1;
        this.isClientValidationDone = this.demo?.mustValidateCart && this.order?.flowStatuses.cart.status == 2;
        this.isDemoCart = this.order.demoStatusId < 4 && this.order.demoStatusId > 1 && this.demo?.accessLevel > 0;


        // console.log("cart validation awaiting : ", this.isCartValidationWaiting)
        // console.log("demo Code : ", this.demoCode)
        // console.log("cart valid : ", this.isCartValid)
        // console.log("must validate cart : ", this.demo.mustValidateCart)
        // console.log("is demo Cart : ", this.isDemoCart)
      }
    });

    this.orderService.panierOrder$.subscribe(res => {

      const order = res;

      if (order?.flowStatuses?.cart.status === this.cartValidationEnum.VALIDATED_BY_DELEGATE || order?.flowStatuses?.cart.status === this.cartValidationEnum.VALIDATED_BY_CLIENT) {
        this.isCartValid = true;
      }

      this.isCartValidationWaiting = this.demo?.mustValidateCart && order?.flowStatuses.cart.status == 1;
      this.isClientValidationDone = this.demo?.mustValidateCart && order?.flowStatuses.cart.status == 2;
    })

    if (this.demoCode) {


    }
  }

  validateOrder() {
    if (this.operationPending) {
      return;
    } else {
      this.operationPending = true;
      this.orderService.validateGuestOrder(this.translate.getContextId()).subscribe(res => {
        if (res.data && res.data.guestOrder && !res.data.guestOrder.hasErrors && res.data.guestOrder.orderItemsCount > 0) {
          this.operationPending = false;
          this.orderService.updateGuestOrderInternaly(res.data.guestOrder);

          if (this.demoCode && this.isDemoCart) {
            this.demoService.postClientGuestOrderAction(this.orderActionEnum.CART_VALIDATED_BY_CLIENT, this.demoCode, this.order.clientSub).
              subscribe(res2 => {
                this.orderService.updateGuestOrderInternaly(res2.data);
                this.checkAuthenticationAndRedirectToCheckout();
              });
          } else {
            this.checkAuthenticationAndRedirectToCheckout();
          }
        } else if (res.data && res.data.guestOrder && res.data.guestOrder.hasErrors) {
          this.operationPending = false;
          this.orderService.updateGuestOrderInternaly(res.data.guestOrder);
        }
      }, err => {
        this.operationPending = false;
      });
    }
  }

  private checkAuthenticationAndRedirectToCheckout(){
    if (this.jwtService.isAuthenticated() && this.jwtService.getToken() != null) {
      this.router.navigate(['/' + this.locale + '/public/checkout']);
    } else {
      this.storage.redirectTo = '/' + this.locale + '/public/checkout';
      this.router.navigateByUrl('/' + this.locale);
    }
  }

  sendOrderValidation() {
    if (this.demoCode && this.order) {
      this.demoService.postClientGuestOrderAction(this.orderActionEnum.CART_SENT_BY_CLIENT, this.demoCode, this.order.clientSub).subscribe(res => {
        if (res) {
          this.orderService.updateGuestOrderInternaly(res.data);
          this.isCartSent = true;
        }
      });
    }
  }

  getTimes(): string {
    const d = new Date();
    const hours = d.getHours();
    const minutes = (d.getMinutes() < 10) ? '0' + d.getMinutes() : d.getMinutes();
    return `${hours}h${minutes}`
  }
}
