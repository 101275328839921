import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscriber, Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { OrderService } from 'src/app/core/services/order.service';
import { SocketService } from 'src/app/core/services/sockets.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { getEshopUrlByContext } from 'src/app/core/utils/filter.utils';
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.scss'],
})
export class PanierComponent implements OnInit, OnDestroy, AfterViewInit {

  public hasCart:boolean = undefined;
  public loaded:boolean = false;

  private clientDatas:any;

  public resources:any;

  //NG Life cycle
  private subsciptions:Subscription[] = []

  constructor(
    public jwtService:JWTService,
    public orderService:OrderService,
    public translate:TranslationService,
    public loader:LoaderService,
    private authService:OidcSecurityService,
    private dataService:GetDatasService,
    private trigger:TriggerService,
    private modalService:ModalService,
    private socketsService: SocketService,
    public breadcrumb:BreadcrumbService,
    public demoService: DemoService,
    public jwt:JWTService
  ) { }

  ngOnInit() {
    //Connect user to socket if connected
    //if(this.jwtService.isAuthenticated())  this.socketsService.connect(this.jwtService.getSubFromToken());
    //
    //console.log("panier component init")

    this.subsciptions.push(this.loader.loaded$.subscribe(_=> this.loaded=true));

    this.subsciptions.push(this.translate.localesLoaded.subscribe(res => {
      if(res==true){
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    }))

    this.subsciptions.push(this.orderService.panierOrder$.subscribe( res => {
        this.hasCart = (res!=undefined && res.orderItems?.length>0);
        this.loader.loaded();
    }))

    this.subsciptions.push(this.trigger.redirectUserInProperContext$.subscribe(d => {
      if (d == true && (environment.production || environment.staging)) {
       // this.openRedirectionModal();
      }
    }))
  }

  ngAfterViewInit(): void {
    this.subsciptions.push(this.dataService.clientData$.subscribe(res => {
      if(res){
        this.clientDatas = res;

        this.authService.checkAuth("/", "desktop").subscribe(data => {
          if (data && data.isAuthenticated && this.clientDatas) {
               if (this.clientDatas.contextId != 0 && this.clientDatas.contextId != this.translate.getContextId()) {
                this.trigger.redirectUserInProperContext(true);
              }
          }
        }, err => {

        })
      }
    }))
  }



  private openRedirectionModal() {
    this.modalService.open("#redirectionModal");
    setTimeout(_ => {
      this.jwtService.deleteTokenCookie();
      this.authService.logoff('desktop');
      window.location.href = getEshopUrlByContext(this.clientDatas.contextId) + "/" + this.clientDatas.locale;
    }, 5000);

  }

  @HostListener('unload')
  ngOnDestroy(){
    //console.log("panier component destroyed")
    this.subsciptions.forEach(x => x.unsubscribe());
  }

}
