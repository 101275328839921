<div class="delegate-card-container" *ngIf="resources">
  <div class="flex j-center">
    <div class="card-img-container flex center-all" *ngIf="delegate.delegateId">
      <img loading="lazy" class="thumb-image" [src]="delegate.profilePath ?? delegate.profileImagePath">
    </div>
    <div *ngIf="!delegate.delegateId">
      <div class="flex j-center delegate-icon">
        <svg viewBox="0 0 26 32" class="icon peach">
          <use xlink:href="#icon-delegate"></use>
        </svg>
      </div>
    </div>
  </div>
  <h2 *ngIf="delegate.delegateId">{{delegate.displayName}}<br><span class="v_certified" *ngIf="delegate.myVictoriaCertificationLevel && delegate.myVictoriaCertificationLevel==3"> <svg viewBox="0 0 37 36" class="icon peach">
    <use xlink:href="#victoria-certification"></use>
  </svg>{{"DEMO.DELEGATE.CERTIFIED" | translate}}</span></h2>
  <h2 class="no-delegate-title" *ngIf="!delegate.delegateId">{{resources.profileConsultant.labelNoConsultants}}</h2>
  <div *ngIf="delegate.delegateId" >
    <div class="flex personal-datas">
      <div class="flex-1 flex ai-center j-center" *ngIf="delegate.city">
        <svg viewBox="0 0 32 32" class="icon peach">
          <use xlink:href="#icon-location"></use>
        </svg> &nbsp; {{delegate.city}}
      </div>
      <div class="flex-1 flex ai-center j-center" *ngIf="delegate.phone">
        <a class="flex ai-center" href="tel: {{delegate.phone}}">
          <svg viewBox="0 0 32 32" class="icon peach">
            <use xlink:href="#icon-telephone"></use>
          </svg> &nbsp; {{delegate.phone}}
        </a>
      </div>
      <div class="flex-1 flex ai-center j-center" *ngIf="delegate.email">
        <a class="flex ai-center" href="mailto: {{delegate.email}}">
          <svg viewBox="0 0 32 32" class="icon peach">
            <use xlink:href="#icon-newsletter"></use>
          </svg> &nbsp; {{delegate.email}}
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="delegate.delegateId && delegate.teaser && delegate.teaser.length > 10" class="about-me">
    <h3>{{'DELEGATE.ABOUTME' | translate}}</h3>
    <p class="left">
      {{delegate.teaser}}
    </p>
  </div>
  <div *ngIf="!delegate.delegateId" class="no-delegate-infos" [innerHtml]="'FIDELITY.DELEGATE.NODELEGATE' | translate"></div>
</div>
