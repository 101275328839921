import { Component, OnInit } from '@angular/core';
import {Delegate} from "../../../../core/models/delegate.model";
import {GetDatasService} from "../../../../core/services/get-datas.service";

@Component({
  selector: 'app-delegate',
  templateUrl: './delegate.component.html',
  styleUrls: ['./delegate.component.scss'],
})
export class DelegateComponent implements OnInit {

  delegate: Delegate;

  constructor(
    private getDatasService: GetDatasService,
  ) { }

  ngOnInit() {
    this.getDatasService.summary$.subscribe(data => {
      if(data) {
        this.delegate = data.delegate;
      }
    });
  }

}
