import { AfterViewInit, Component, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import {getVictoriaUrl, filterURL} from 'src/app/core/utils/filter.utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit{

  @Input() reduce : boolean = false;

  footerLocales : any;
  newsLetterBlock : any;
  newletterLocales : any;
  langueChangedSubscription : Subscription;

  constructor(public dataService : GetDatasService, private translateConfig:TranslationService, private storage:StorageService, public breadcrumb:BreadcrumbService) { }

  ngOnInit() {
    // this.dataService.getJsonLocales().then(data => {
    //   this.footerLocales = data?.footer
    //   this.newletterLocales = data?.header.topNav[1];
    // });
    this.translateConfig.localesLoaded.subscribe( d => {
      if(d==true) {
        let datas = this.translateConfig.getJsonLocales();
        this.footerLocales = datas?.footer
        this.newletterLocales = datas?.header.topNav[0];
        this.newsLetterBlock = datas?.newsLetterBlock;
      }
    })

  }

  ngAfterViewInit(){
    // if(this.langueChangedSubscription) this.langueChangedSubscription.unsubscribe();
    // this.langueChangedSubscription = this.translateConfig.langHasChanded.subscribe(async(value)=> {
    //   if(value===true) {
    //     let datas = this.translateConfig.getJsonLocales();
    //     this.footerLocales = datas?.footer
    //     this.newletterLocales = datas?.header.topNav[1];
    //   }
    // })
  }

  toogleElement(e){
    let target = e.target.closest(".area");
      if(target) target.getElementsByClassName('area-links-container')[0]?.classList.toggle('display');
  }

  getNewsletterUrl(){
    return getVictoriaUrl(this.storage.languageCode) + "/" + this.storage.languageCode?.replace("_","-") + "/newsletter";
  }
}
