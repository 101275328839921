<div class="cart-content flex j-center ai-start" data-testId="cart-block" *ngIf="resources">

  <div class="cart-articles">
    <div class="order-paid-toDelegate flex column j-start ai-center ac-center" *ngIf="order.flowStatuses.payment.status==11" >
      <h1 class="canela center">{{resources.checkoutConfirmation.secondTitle}}</h1>
      <p class='center'>
        {{ 'ORDER.VALIDATION.CLIENT.TODELEGATE'| translate }}
      </p>
    </div>
    <app-cart-articles [order]="order" [wishlist]="wishlist" [resources]="resources"></app-cart-articles>
  </div>

  <div class="cart-recap">
    <app-cart-recap-info [order]="order" [wishlist]="wishlist" [resources]="resources"></app-cart-recap-info>
  </div>
</div>

<app-modal *ngIf="resources" [id]="'detailsModal'" [title]="resources.popupShippingOptions.mainTitle" template="info"></app-modal>
<app-modal *ngIf="resources" [id]="'codeModal'" [title]="resources.shoppingBag.birthdayLabel" template="promo"></app-modal>
