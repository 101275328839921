import {Component, Input, OnInit} from '@angular/core';
import {Status} from '../../../../../core/models/status.model';
import {DatatableService} from '../../../../../core/services/datatable.service';
import {LoaderService} from '../../../../../core/services/loader.service';
import {ClientActionsDone} from "../../../../../core/models/clientActionsDone.model";
import { getContextByUrl } from 'src/app/core/utils/filter.utils';

@Component({
  selector: 'app-status-data-wrapper',
  templateUrl: './status-data-wrapper.component.html',
  styleUrls: ['./status-data-wrapper.component.scss'],
})
export class StatusDataWrapperComponent implements OnInit {

  @Input() currentStatus: Status;
  @Input() clientActionsDone: ClientActionsDone[];
  loaded = false;

  constructor(
    public datatableService: DatatableService,
    private loader: LoaderService
  ) {
    this.loader.loaded$.subscribe(res => {
      this.loaded = res;
    });
  }

  ngOnInit() {
    this.datatableService.getClientActions(true, getContextByUrl(window.origin));
  }
}
