import {Component, Input, OnInit} from '@angular/core';
import {ClientOrderItem} from 'src/app/core/models/clientOrderItem.model';
import {GetDatasService} from 'src/app/core/services/get-datas.service';
import {TranslationService} from '../../../../../../../core/services/translation.service';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit {

  @Input() item: ClientOrderItem;
  @Input() border: boolean;
  resources: any;

  constructor(
    public getDataService: GetDatasService,
    private translate: TranslationService,
  ) {
  }

  ngOnInit() {
    this.translate.localesLoaded.subscribe(res => {
      if (res === true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });
  }

  getItemSize(){
    let product = this.item.productGroup?.products.find(x => x.sku == this.item.sku);
    if(product) return product.size;
    else return "/";
  }

}
