import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { OrderService } from 'src/app/core/services/order.service';
import { SocketService } from 'src/app/core/services/sockets.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { getCookieDomainByLang, getRootUrl, getVictoriaUrl } from 'src/app/core/utils/filter.utils';
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';
import { environment } from 'src/environments/environment';
import {DemoModel} from "../../../features/agora/demo/models/demo.model";
import { ScreenService } from 'src/app/core/services/screen.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {

  showMegaMenu = false
  langSettings = { country: "Belgique (FR)", flag: "#icon-flag-be" }

  headerLocales: any
  resources: any

  orderItemsCount = 0
  wishlistItemsCount = 0

  isUserLogged = false;

  @Input() isMenuOpen = false;
  @Input() isUserMenuOpen = false;

  @Output() openCloseMenu: EventEmitter<boolean> = new EventEmitter();
  @Output() openCloseUserMenu: EventEmitter<boolean> = new EventEmitter();

  @Input() isLogin = false;
  @Output() requestRegistration: EventEmitter<any> = new EventEmitter();

  dataLoaded: Subject<boolean> = new Subject();

  currentClient: Client = undefined

  isMobileMenu = false;

  locale: string;
  demoCode: string;
  demoStatusId = 0;
  isDemoClient = false;
  demos: DemoModel[];


  public hasHighlight = false;
  public highLightValue = "";
  public highLightIcon = "";
  public highLightLink = "";

  private highLightTimer:any;
  public currentElement = 0;

  public craftedHighlight = [
      {
        displayText:"Promotions d'hiver, -50% sur tous les colliers en argent", sortOrder:1,
        displayIcon:  [
          {
            url: "https://dowksrgdy509i.cloudfront.net/site/Logo2024.svg?mtime=20231123131430&focal=none"
          }
        ],
        clickLinks: [
          {
            url: "https://shop-staging.victoria-bijoux.fr/fr-fr/ugc"
          }
        ]
      }
  ];

  public highLights = [];


  constructor(
    public dataService: GetDatasService,
    public storage: StorageService,
    private translate: TranslateService,
    private translateConfig: TranslationService,
    private authService: OidcSecurityService,
    private route: Router,
    private cookies: CookieService,
    private orderService: OrderService,
    public jwt: JWTService,
    public translation: TranslationService,
    public demoService: DemoService,
    public socketService: SocketService,
    public screen: ScreenService
  ) {
    this.checkAuthentification();

    this.demoService.hasDemo$.subscribe( v => {
      if(v) this.demoCode = v;
    })
  }

  ngOnInit() {

    this.locale = this.cookies.get("v_locale")?.replace("_", "-");

    this.orderService.panierOrder$.subscribe(res => {
      if (res != undefined) {
        this.orderItemsCount = res.orderItemsCount;
        this.demoStatusId = res.demoStatusId;
        this.isDemoClient = res.accessLevel > 0;


        if(res.demoCode && res.demoCode!="" &&  res.demoCode!=null && res.demoStatusId==2 && this.isDemoClient) {
          this.socketService.connect(this.jwt.getSubFromToken(), res.demoCode);
        }
      }
    })

    this.orderService.wishlistOrder$.subscribe(res => {
      if (res != undefined) this.wishlistItemsCount = res.orderItemsCount;
    })

    this.translateConfig.localesLoaded.subscribe(d => {
      if (d == true) {
        this.resources = this.translateConfig.getJsonLocales();
        this.headerLocales = this.resources?.header;
        this.craftedHighlight = this.resources?.rseBannerTexts?.rseBannerTexts;
        this.dataLoaded.next(true);

        if(!this.highLightTimer){

          if(this.craftedHighlight && this.craftedHighlight.length>0){
            this.craftedHighlight = this.craftedHighlight.sort((x, y) => {
              if (x.sortOrder > y.sortOrder) return 1;
              else if(x.sortOrder < y.sortOrder) return -1;
              else return 0;
            });

            this.hasHighlight = true;

            this.highLightTimer = setInterval( _ => {
              if(this.currentElement==this.craftedHighlight.length-1) this.currentElement = 0;
              else this.currentElement++
            }, 5000);

          }

        }
      }
    })

    this.dataLoaded.asObservable().subscribe(value => {
      if (value == true) setTimeout(_ => this.registerListeners(), 1000);
    })

    this.dataService.clientData$.subscribe(res => {
      if (res) {
        this.currentClient = res;
      }
      this.checkAuthentification()
    })


    this.dataLoaded.next(true);

    this.checkAuthentification();

    this.demoService.demos$.subscribe(res => {
      if(res) {
        this.demos = res.results;
      }
    });
  }

  ngAfterViewInit(): void {
    this.initLangage(this.storage.languageCode, this.cookies.get('active_language'));
  }

  displayMenu(i) {
    const element = document.getElementsByClassName("mega_" + i)[0];
    if (element) {
      element?.classList.toggle("visible");
    }
  }

  hideOthersSubMenu(i = -1) {
    const elements = Array.from(document.getElementsByClassName("mega-mobile"));
    for(const elem of elements){
      elem.classList.remove("visible")
    }

  }


  public registerListeners() {
    this.requestRegistration.emit();
  }

  public showSideMenu() {
    if (this.isUserMenuOpen) this.showUserMenu();
    this.hideOthersSubMenu();

    if (document.getElementsByClassName("menu-visible")[0]) this.openCloseMenu.emit(false);
    else this.openCloseMenu.emit(true);

    document.getElementsByClassName("side-menu")[0]?.classList.toggle("menu-visible");
    document.getElementsByTagName("main")[0]?.classList.toggle("fixedHeight");
  }

  public closeMenu(i) {
    const element = document.getElementsByClassName("mega_" + i)[0];
    if (element) element?.classList.remove("visible")
  }

  public showUserMenu() {
    if (!this.isUserLogged) {
      this.route.navigate(['/' + this.locale]);
      return;
    }

    if (this.isMenuOpen) this.showSideMenu();

    if (document.getElementsByClassName("user-menu-visible")[0]) this.openCloseUserMenu.emit(false);
    else this.openCloseUserMenu.emit(true);

    document.getElementsByClassName("user-menu")[0]?.classList.toggle("user-menu-visible");
    document.getElementsByClassName("mega-user")[0]?.classList.toggle("visible");
    document.getElementsByTagName("main")[0]?.classList.toggle("fixedHeight");
  }

  public openCountries(mobile = false) {

    this.isMobileMenu = mobile;

    if (mobile) document.getElementsByClassName("lang-options-mobile")[0]?.classList.toggle("open");
    else document.getElementsByClassName("lang-options")[0]?.classList.toggle("open");
  }

  public currentLangage(code) {
    return this.storage.languageCode === code;
  }

  public currentRegion(code) {
    return this.cookies.get('active_language')?.replace('-', '_') === code;
  }


  public initLangage(code: string, region?: string) {
    region = (region || code).replace('-', '_');

    this.translate.use(code).toPromise().then(_ => {
      this.storage.languageCode = code;
      this.updateLangageInfo(region);
      this.translateConfig.langHasChanded.next(true);

      this.cookies.set("v_locale", code, 365, '/', getCookieDomainByLang(code));
      if (!environment.production && region && region != null && region != '') this.cookies.set("active_language", region.replace('_', '-'), 365, '/', getCookieDomainByLang(code));
      //if(environment.production) window.location.href = getRootUrl(code, region);
    });
  }

  public changeLangage(code: string, region?: string) {

    const previousCode = this.storage.languageCode;

    region = (region || code).replace('-', '_');

    this.translate.use(code).toPromise().then(_ => {
      this.storage.languageCode = code;
      this.updateLangageInfo(region);
      this.openCountries(this.isMobileMenu);
      this.translateConfig.langHasChanded.next(true);

      this.cookies.set("v_locale", code, 365, '/', getCookieDomainByLang(code));
      if (region && region != null && region != '') this.cookies.set("active_language", region.replace('_', '-'), 365, '/', getCookieDomainByLang(code));


      const isSameCountry = (previousCode.endsWith("be") && this.storage.languageCode.endsWith("be")) || (previousCode.endsWith("de") && this.storage.languageCode.endsWith("de"));

      if (this.jwt.isAuthenticated() && !isSameCountry) {
        this.jwt.deleteTokenCookieUsingLang(previousCode);
        this.authService.logoff('desktop');

         window.location.href = getRootUrl(code, region);


      } else {
         window.location.href = getRootUrl(code, region);
      }
    });
  }

  private updateLangageInfo(code) {
    switch (code) {
      case "fr_be":
        this.langSettings.country = "Belgique (FR)"
        this.langSettings.flag = "#icon-flag-be"
        break;
      case "nl_be":
        this.langSettings.country = "België (NL)"
        this.langSettings.flag = "#icon-flag-be"
        break;
      case "fr_fr":
        this.langSettings.country = "France (FR)"
        this.langSettings.flag = "#icon-flag-fr"
        break;
      case "de_de":
        this.langSettings.country = "Deutschland (DE)"
        this.langSettings.flag = "#icon-flag-de"
        break;
      case "de_at":
        this.langSettings.country = "Österreich (DE)"
        this.langSettings.flag = "#icon-flag-at"
        break;
      case "nl_nl":
        this.langSettings.country = "Nederland (NL)"
        this.langSettings.flag = "#icon-flag-nl"
        break;
      case "fr_lu":
        this.langSettings.country = "Luxemburg (FR)"
        this.langSettings.flag = "#icon-flag-lu"
        break;
    }

    // document.getElementById("flag").setAttribute("href", this.langSettings.flag)
  }

  public logout() {
    this.route.navigate(['/logout']);
  }

  public localLogout() {
    this.authService.logoffLocal();
    this.jwt.deleteTokenCookie()

    this.logoutRedirect();
  }

  private logoutRedirect() {
    this.route.navigate(["/" + this.locale]);
  }

  private checkAuthentification() {

    this.authService.isAuthenticated$.subscribe((d: AuthenticatedResult) => {
      const currentConfig = d.allConfigsAuthenticated.find(x => x.isAuthenticated == true)

      if (currentConfig) {
        this.isUserLogged = currentConfig.isAuthenticated;
      }
      else this.isUserLogged = false;

      //if(this.isUserLogged) this.demoService.getAllDemos();
    });
  }

  public checkEnvironnementAndLogin() {
    this.route.navigate(['/' + this.locale]);
  }

  public navigateToHome() {
    this.hideAllMegaMenu(null, null);
    this.route.navigate(['/' + this.locale]);
  }

  search() {
    window.location.href = this.getLogoUrl() + "/search";
  }

  public getLogoUrl() {
    return getVictoriaUrl(this.translate.currentLang, true) + '/' + this.translation.getJsonLocales()?.lang;
  }



  //QuickFix Duplicate Code from AppComponent
  public hideAllMegaMenu(current, currentCarret) {
    const megaMenus = document.getElementsByClassName('mega-menu-content');
    const openCarrets = document.getElementsByClassName('carret-area');

    Array.from(megaMenus).forEach(item => {
      if (item != current) item?.classList.remove('block-menu');
    });

    Array.from(openCarrets).forEach(item => {
      if (item != currentCarret) item?.classList.remove('rotate-carret');
    });

    if (!current && !currentCarret) {
      const jumbo = document.getElementsByClassName("jumbo-shadow")[0];
      jumbo?.classList.remove('visible');
    }

  }

  public goToDemo(){
    this.showUserMenu();
    this.hideAllMegaMenu(null,null);
    if(this.demoStatusId === 2) {
      this.route.navigate([`${this.locale}/myvictoria/demo/${this.demoCode}`]);
      return false;
    }
  }

}
