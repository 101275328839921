import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Breadcrumb } from 'src/app/core/models/breadcrumb.model';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { getVictoriaUrl } from 'src/app/core/utils/filter.utils';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.scss'],
})
export class BreadcrumComponent implements OnInit, AfterViewInit {

  breadcrumbs$: Observable<Breadcrumb[]>; 
  langueChangedSubscription : Subscription;

  baseUrl:string=""

  @Input() pinkBreadcrumb : boolean = false;
 
  constructor(public readonly breadcrumb: BreadcrumbService, private translate:TranslateService, 
    private translateConfig:TranslationService,
    private storage:StorageService,
    private route:ActivatedRoute) { 
    this.breadcrumbs$ = breadcrumb.breadcrumbs$; 
  } 

  ngOnInit() { 
    this.baseUrl = getVictoriaUrl(this.translate.currentLang);
  }

  public ngOnDestroy(): void {
    if(this.langueChangedSubscription) this.langueChangedSubscription.unsubscribe();
  }

  ngAfterViewInit(){
    if(this.langueChangedSubscription) this.langueChangedSubscription.unsubscribe();
    this.langueChangedSubscription = this.translateConfig.langHasChanded.subscribe((value)=> {
      if(value===true) {
          this.breadcrumb.reloadBreadcrumbs();
          this.breadcrumbs$ = this.breadcrumb.breadcrumbs$;
      }
    })
  }

}
