import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../../core/services/storage.service';
import { ModalService } from '../../../../../core/services/modal.service';

@Component({
  selector: 'app-modal-sandbox-alert',
  templateUrl: './modal-sandbox-alert.component.html',
  styleUrls: ['./modal-sandbox-alert.component.scss'],
})
export class ModalSandboxAlertComponent implements OnInit {

  constructor(private storage:StorageService, private modal:ModalService) { }

  ngOnInit() {}

  close(){
      this.storage.sandboxModalDismissed = true;
      this.modal.close("sandbox-modal");
  }

}
